import React, { useState } from 'react'
import InnerLayout from "../../../../layouts/internalLayout"
import Left from './listLeft'
import { useMessage } from '../../../../context/messageContext'
import { Oval } from 'react-loader-spinner'
import { Link, useNavigate } from 'react-router-dom'
import { useRightContext } from '../../../../context/rightMenuContext'
import { Card } from 'reactstrap'
import { useEffect } from 'react'
import { getAllQuotations } from '../../../../services/platformService'

const Quotations = () => {

  const navigate = useNavigate()
  const { userDataContext } = useRightContext('')
  const { setMessage, setMessageType } = useMessage();

  const [allowedProducts, setAllowedProducts] = useState([])
  const [loading, setLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState({
    "filter": false,
    "status": 1,
    "date": 30,
    "start_date": '',
    "end_date": '',
  });


  useEffect(() => {
    if (userDataContext?.website_url && userDataContext?.user_id 
      && filterOptions?.filter
    ) {
      if (userDataContext?.role !== 'Supplier') {
        getAllQuotationsService(userDataContext?.website_url, userDataContext?.user_id)
      } else {
        setLoading(false)
      }
    }
    else{
      setLoading(false)
    }
  }, [filterOptions])  

  const getAllQuotationsService = async (host_url, host_userId) => {

    let res = await getAllQuotations(host_url, host_userId, filterOptions);

    if (res?.data?.status === true) {
      if (res?.data?.quotations) {
        setAllowedProducts(res?.data?.quotations);
      }

      setLoading(false);
    } else {
      setAllowedProducts([]);

      if (res?.data?.status === false) {
        navigate("/error/404");
      } else {
        setMessage(res?.data?.message);
        setMessageType("error");
      }

      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      {userDataContext && userDataContext?.role !== 'Supplier' ? (
        <InnerLayout
          pageTitle="Quotations"
          layoutType="full"
          leftContent={
            <Left
              userData={userDataContext}
              setLoading={setLoading}
              allowedProducts={allowedProducts}
              setFilterOptions={setFilterOptions}
            />
          }
          rightContent=""
          headerButton={userDataContext?.role !== "Super Admin" && (<Link to='/create-quotation'
            className='px-3 btn btn-primary'
          >
            Create Quotation
          </Link>)}
        />
      ) : (
        <div className="p-4 mt-5">
          <h4 className="text-center mb-0">
            {loading ? 'Loading.. Please wait!' : `Supplier is not allowed to create quotation right now.`}
          </h4>
        </div>
      )}
    </>
  );
}

export default Quotations