import React, { useEffect, useState } from "react";
import PricingHeader from "../../../../../components/Pricing/PricingHeader";
import { useNavigate } from "react-router-dom";
import { Form, Table } from "react-bootstrap";
import { Button } from "reactstrap";
import axios from "axios";
import { servicePath } from "../../../../../utility/data";
import {
  accessoryHandleInput,
  handleEmptyValues,
  handleFocus,
  newHandleInput,
} from "../../../../../utility/helper";
import { useRightContext } from "../../../../../context/rightMenuContext";
import PricingTableComp from "../../../../../components/PricingTableComp";

const Left = ({
  token,
  styleId,
  pageName,
  setMessage,
  setMessageType,
  loading,
  setLoading,
  userDataContext = { userDataContext },
}) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState();
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");
  const [costPrice, setCostPrice] = useState([]);

  const inputFunction = styleId == 14 ? accessoryHandleInput : newHandleInput;

  const handleInput = (id, key, value) => {
    if (validate(value)) {
      setData((prevData) =>
        prevData.map((item, index) =>
          index === id ? { ...item, [key]: parseFloat(value) } : item
        )
      );
    }
  };

  const validate = (value) => {
    if (value < 0) return false;
    return true;
  };

  useEffect(() => {
    setLoading(true);

    if (styleId && styleId != 14 && selectedPricing && selectedSupplyOnly) {
      getHardwareData(styleId);
    }
    //  else if (styleId == 14 && selectedPricing && selectedSupplyOnly) {
    //   getAccessoryData();
    // }
  }, [styleId, selectedPricing, selectedSupplyOnly]);

  const getHardwareData = (styleID) => {
    const FormData = require("form-data");

    let data = new FormData();

    data.append("PriceMasterId", selectedPricing);
    data.append("Markupmasterid", selectedSupplyOnly);
    data.append("StyleMasterId", styleID);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/GetHardwareStylePricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          setData(res?.data?.entity);
          console.log(data, "data");
        } else if (res?.data?.statusCode === 404) {
          setLoading(false);
          navigate("/error/404");
        } else {
          setLoading(false);
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const getAccessoryData = () => {
    const FormData = require("form-data");

    let data = new FormData();

    data.append("PriceMasterId", selectedPricing);
    data.append("Markupmasterid", selectedSupplyOnly);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/GetAccessoriesPricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          setData(res?.data?.entity);
          console.log(data, "data");
        } else if (res?.data?.statusCode === 404) {
          setLoading(false);
          navigate("/error/404");
        } else {
          setLoading(false);
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleUpdate = () => {
    setLoading(true);
    if (styleId != 14) updateTableData();
    else {
      updateAccessories();
    }
  };

  const updateTableData = () => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/AddUpdateHardwareStylePricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: handleEmptyValues(data),
    };

    axios
      .request(config)
      .then((res) => {
        console.log(res.data, "UpdatedProjectAncillaries");

        if (res?.data?.statusCode === 200) {
          setMessage(res.data.message);
          setMessageType("success");
          getHardwareData(styleId);
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const updateAccessories = () => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/General/AddUpdateAccessoriesPricing`,
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: handleEmptyValues(data),
    };

    axios
      .request(config)
      .then((res) => {
        console.log(res.data, "Updated Accessories");

        if (res?.data?.statusCode === 200) {
          setMessage(res.data.message);
          setMessageType("success");
          getAccessoryData();
        }
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handleFormReset = () => {
    setLoading(true);
    if (styleId != 14) getHardwareData(styleId);
    else getAccessoryData();
  };

  // const data1 = { url: `/product/hardware/styles/${styleId}` };
  // const data2 = { url: "/product/hardware/accessories" };

  return (
    <>
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
        costPrice={costPrice}
      />

      {/* <div className="d-flex align-items-center mb-4 flex-wrap gap-3 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">
          {pageName}
        </h5>

        <CustomizedButton data={styleId == 14 ? data2 : data1} />
      </div> */}

      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_tab has_btns">
          {/* <Table responsive className="w-100 discount_table">
            <thead className="border-bottom">
              <tr className="text-end">
                <th style={{ width: "40%", minWidth: "40%" }}></th>
                <th>
                  {userDataContext?.role === "Supplier"
                    ? "Selling Price"
                    : "Cost"}
                </th>
                <th>
                  {userDataContext?.role === "Supplier"
                    ? "Selling Price"
                    : "Cost"}{" "}
                  + VAT
                </th>
                <th>Mark-up $</th>
                <th>Mark-up %</th>
                {styleId == 14 && <th>Store Mark-up %</th>}
                <th>Total</th>
                <th>Total + VAT</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                <>
                  {data &&
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {styleId == 14 ? item?.description : item?.name}
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.cost}
                            onFocus={handleFocus}
                            readOnly={
                              userDataContext?.role !== "Supplier" &&
                              !item?.isSelf
                            }
                            onChange={(e) =>
                              inputFunction(
                                data,
                                setData,
                                index,
                                "cost",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.costPlusVat}
                            readOnly
                            onChange={(e) =>
                              handleInput(index, "costPlusVat", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.markUp}
                            onFocus={handleFocus}
                            readOnly={userDataContext?.role === "Supplier"}
                            onChange={(e) =>
                              inputFunction(
                                data,
                                setData,
                                index,
                                "markUp",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={item.markUpPercentage}
                            onFocus={handleFocus}
                            readOnly={userDataContext?.role === "Supplier"}
                            onChange={(e) =>
                              inputFunction(
                                data,
                                setData,
                                index,
                                "markUpPercentage",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        {styleId == 14 && (
                          <td>
                            <Form.Control
                              type="number"
                              value={item.storeMarkup}
                              onFocus={handleFocus}
                              onChange={(e) =>
                                inputFunction(
                                  data,
                                  setData,
                                  index,
                                  "storeMarkup",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        )}
                        <td>
                          <Form.Control
                            type="number"
                            value={item.total}
                            readOnly
                            onChange={(e) =>
                              handleInput(index, "total", e.target.value)
                            }
                          />
                        </td>
                        <td className="text-end">
                          <Form.Control
                            type="number"
                            value={item.totalPlusVat}
                            readOnly
                            onChange={(e) =>
                              handleInput(index, "totalPlusVat", e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </>
              ) : (
                <tr>
                  <td colSpan={7}>
                    <h5 className="my-4 text-center text-muted w-100">
                      No data found
                    </h5>
                  </td>
                </tr>
              )}
            </tbody>
          </Table> */}
          <PricingTableComp
            data={data}
            handleInput={handleInput}
            newHandleInput={newHandleInput}
            setData={setData}
            userDataContext={userDataContext}
          ></PricingTableComp>
        </div>
        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={handleUpdate}
          >
            Save Changes
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            onClick={handleFormReset}
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Left;
