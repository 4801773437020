import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    FormText,
} from 'reactstrap';
import isURL from 'validator/lib/isURL';
import { servicePath } from '../../../../../utility/data';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../../../../utility/commonHelper';

const CrmLeft = ({ token, setMessage, setMessageType, loading, setLoading }) => {

    const navigate = useNavigate()

    const initialValues = {
        apiURL: "",
    };

    const [apiURL, setApiURL] = useState('')
    const [validApiURL, setValidApiURL] = useState(false)

    const [updateCRM, setUpdateCRM] = useState(false)
    const [addCRMButton, setAddCRMButton] = useState(false)

    const [formErrors, setFormErrors] = useState({})
    const [formValues, setFormValues] = useState(initialValues)

    const validate = () => {
        let formErrors = {};
        let isValid = true;

        if (apiURL && !isURL(apiURL)) {
            formErrors.apiURL = "Please enter valid API URL";
            isValid = false
        } else {
            isValid = true
        }

        if(!apiURL) {
            isValid = true
        }

        setFormErrors(formErrors)

        return isValid;
    }

    const handleValidationChange = (e) => {

        const { name, value } = e.target

        setFormValues({ ...formValues, [name]: value })
    }

    const handleNameChange = (e) => {
        const value = e.target.value
        setApiURL(value)

        if (e.target.value === '') {
            setUpdateCRM(false)
            setAddCRMButton(false)
        }

        if (isURL(e.target.value)) {
            setValidApiURL(true)
        } else {
            setValidApiURL(false)
        }

        handleValidationChange(e)
    }

    const handleFormSubmit = () => {

        setLoading(true)
        updateCrmDetails()
    }

    const getCrmData = () => {

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${servicePath}/CRM/GetCRM`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios.request(config)
            .then((res) => {
                var values = res.data.entity
                console.log(res.data, "get crm");

                if (res?.data?.statusCode === 200) {
                    setLoading(false)

                    setApiURL(values.apiurl ? values.apiurl : '')
                    setUpdateCRM(values.projectModified ? values.projectModified : false)
                    setAddCRMButton(values.crmbutton ? values.projectModified : false)

                    if(values.apiurl) {
                        setValidApiURL(true)
                    }
                    
                } else if (res?.data?.statusCode === 404) {
                    setLoading(false)
                    navigate('/error/404')
                } else {
                    setLoading(false)
                    setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                    setMessageType('error')
                }
            })
            .catch((error) => {
                console.log(error);

                setLoading(false)
                setMessage(error.message ? error.message : 'Something went wrong');
                setMessageType('error')
                handleError(error,false,navigate)
            });
    };

    const updateCrmDetails = () => {

        const FormData = require('form-data');

        let data = new FormData();

        data.append('Apiurl', apiURL);
        data.append('ProjectModified', updateCRM);
        data.append('Crmbutton', addCRMButton);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${servicePath}/CRM/UpdateCRM`,
            headers: {
                'accept': 'text/plain',
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios.request(config)
            .then((res) => {
                console.log(res.data, "UpdateCRM");

                if(res?.data?.statusCode === 200) {
                    setMessage(res.data.message);
                    setMessageType('success')

                    getCrmData();
                }
            })
            .catch((error) => {
                console.log(error);

                setLoading(false)
                setMessage(error.message ? error.message : 'Something went wrong');
                setMessageType('error')
                handleError(error,false,navigate)
            });
    }

    useEffect(() => {
        setLoading(true)
        
        getCrmData()
    }, [])

    const handleFormReset = () => {
        getCrmData()
    }

    return (
        <div className='d-flex flex-column h-100'>
            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns'>
                    <Row>
                        <Col md="8" lg="6" xl="6">
                            <FormGroup className="form-group">
                                <Label for="apiURL">
                                    API url
                                </Label>
                                <Input className='mb-1' name="apiURL" value={apiURL} onChange={(e) => handleNameChange(e)} />

                                <FormText>
                                    The project json will be posted to this url when a project is created.
                                    <span className='d-block'>Use ‘test’ to view the data in the console.</span>
                                </FormText>

                                {(apiURL && !validApiURL) && (
                                    <div className='mt-1 text-danger'>{formErrors.apiURL}</div>
                                )}
                            </FormGroup>

                            <FormGroup className="form-group">
                                <Label for="updateCRM" className={`${!validApiURL ? 'opacity-75' : ''}`}>
                                    Update the CRM when the project is modified
                                </Label>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="updateCRM"
                                    className='form-switch-lg'
                                    onChange={() => { setUpdateCRM(!updateCRM) }}
                                    checked={updateCRM}
                                    label={updateCRM ? 'On' : 'Off'}
                                    disabled={!validApiURL}
                                />
                            </FormGroup>

                            <FormGroup className="form-group">
                                <Label for="addCRMButton" className={`${!validApiURL ? 'opacity-75' : ''}`}>
                                    Add ‘print to CRM button’
                                </Label>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="addCRMButton"
                                    className='form-switch-lg'
                                    onChange={() => { setAddCRMButton(!addCRMButton) }}
                                    checked={addCRMButton}
                                    label={addCRMButton ? 'On' : 'Off'}
                                    disabled={!validApiURL}
                                />
                            </FormGroup>

                        </Col>
                    </Row>
                </div>
                <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => validate() ? handleFormSubmit() : <></>}
                        disabled={loading}
                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        onClick={handleFormReset}
                    >
                        Reset
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default CrmLeft