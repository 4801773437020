import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import InnerLayout from "../../../../../../layouts/internalLayout";
import Left from "./left";
import Right from "./right";
import { useMessage } from "../../../../../../context/messageContext";
import { Oval } from "react-loader-spinner";
import { getAllColoursCollection } from "../../../../../../services/productServices";
import {
  addUpdateHardwareColorPricingService,
  getHardwareColorPricingService,
} from "../../../../../../services/pricingServices";

const ColorPricing = () => {
  const accessToken = localStorage.getItem("access_token");

  const [pageName, setPageName] = useState("");
  const location = useLocation();

  const locationId = location?.state?.data;

  const [loading, setLoading] = useState(false);
  const [selectItemTableData, setSelectItemTableData] = useState([]);
  const [allRightListItems, setAllRightListItems] = useState([]);

  const { setMessage, setMessageType } = useMessage();
  const {
    fetchSelected,
    setTimestamp,
    activeIndex,
    setActiveIndex,
    setMainMenuActive,
    setSubMenuActive,
    setSubMenu2Active,
  } = useRightContext();

  const navigate = useNavigate();

  useEffect(() => {
    setMainMenuActive("Pricing");
    setSubMenuActive("Hardware");
    setSubMenu2Active("Color Pricing");
  }, []);

  useEffect(() => {
    if (locationId) {
      setTimestamp(locationId);
    } 
  }, [locationId]);

  useEffect(() => {
    setTimestamp("");

    setTimeout(() => {
      getRightMenuListData();
    }, 400);
  }, []);

  // get all right menu list
  const getRightMenuListData = async () => {
    setLoading(true);
    const res = await getAllColoursCollection("2", accessToken);
    if (res.data?.statusCode === 200) {
      setAllRightListItems(res?.data.entity);
      fetchSelected(0, res?.data?.entity[0]?.id);
      setPageName(res?.data?.entity[0]?.name);
      setLoading(false);
      setMessageType("success");
      setMessage(res.message);
    } else {
      setLoading(false);
      setMessageType("error");
      setMessage(res.message);
    }
  };

  // get table data of selected price item
  const getPriceTableDataService = async (data) => {
    setLoading(true);

    const res = await getHardwareColorPricingService(accessToken, data);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          setSelectItemTableData(res.data.entity);
        } else {
          setSelectItemTableData([]);
        }

        setLoading(false);
      } else {
        setLoading(false);
        setMessage(
          res?.data.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
      setLoading(false);
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  // api call to update all selected item details
  const updateItemDetailsServices = async (data) => {
    setLoading(true);

    // console.log(data, tableData, "data 123");

    const res = await addUpdateHardwareColorPricingService(accessToken, data);

    if (res && res && res.status === 200) {
      console.log(res, "1234");

      if (res?.data?.statusCode === 200) {
        setMessage(res?.data?.message ? res.data.message : "Updated pricing");
        setMessageType("success");
        setLoading(false);
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      console.log(res, "tetst");
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  // api call to added new right item
  //   const addNewRightItemServices = async (title) => {
  //     var data = {
  //       id: "",
  //       name: title,
  //     };

  //     setLoading(true);
  //     itemAddedTrue = "added";

  //     // const res = await addNewRightItem(accessToken, data, collectionId);

  //     if (res && res && res.status === 200) {
  //       if (res?.data?.statusCode === 200) {
  //         setMessage(
  //           res?.data?.message ? res.data.message : "Record created successfully!"
  //         );
  //         setMessageType("success");

  //         getRightMenuListData(collectionId);
  //       } else {
  //         setLoading(false);
  //         setMessage(
  //           res?.data?.message ? res.data.message : "Something went wrong"
  //         );
  //         setMessageType("error");
  //       }
  //     } else {
  //       setLoading(false);
  //       setMessage(res?.message ? res.message : "Something went wrong");
  //       setMessageType("error");

  //       if (res?.message === "Network Error") {
  //         navigate("/error/network");
  //       }
  //     }
  //   };

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <InnerLayout
        pageTitle="Hardware Color Price List"
        layoutType=""
        leftContent={
          <Left
            pageName={pageName}
            setPageName={setPageName}
            setSelectItemTableData={setSelectItemTableData}
            selectItemTableData={selectItemTableData}
            getPriceTableDataService={getPriceTableDataService}
            updateItemDetailsServices={updateItemDetailsServices}
            locationId={locationId}
          />
        }
        rightContent={
          <Right
            allRightListItems={allRightListItems}
            setPageName={setPageName}
            locationId={locationId}
          />
        }
      />
    </>
  );
};

export default ColorPricing;
