import React, { memo, useEffect, useReducer, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import astragalColumnLeft from "../../../assets/models/AstragalVertical.glb";
import { useDesigner } from '../../../context/designerContext';
import { deepEqual, getObjectData, getPosition, getSideFromFrameType, mapNumberToRange, removeDuplicates, removeDuplicatesByName, removeDuplicatesByPosition, rgbStringToHex, setInitialColors, toCamelCase, updateArray } from '../../../utility/helper';
import { modelData, servicePath } from '../../../utility/data';
import { getFrameStyles, getInternalColorsWithPrice, getModelData, setModelData, setQuotationModelData } from '../../../services/3dModelServices';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import ConfigurationPoints from '../../../components/3DComponents/configurationPoints';

import { updateFrameStyleAppenture } from '../../../services/commonServices';
import { addHardwareColorPricing, getFramesProfilesDefault, getHexValue, saveModelData, saveModelDataInit, saveModelDataQuotation, storeExtColor, updateShapedCornerData } from '../../../utility/designerHelper';

import { captureAndSaveImages, getModelRenderedSize, setFrameCameraView, updateReferencePoints } from '../../../utility/threeJsHelpers/general';
import environmentHDR from '../../../assets/img/hdr/interior.hdr'
import { setGlazing, addGlazingPanel, glazingPanelDelete, removeAllPanels, setGlassTexture } from '../../../utility/threeJsHelpers/glazingHelper';
import { resizeAdditionalFrame } from '../../../components/3DComponents/ResizeAdditionalFrame';
import { addUiLinesBottomTransom, addUiLinesFrameH, addUiLinesFrameV, calculatePartialWidths, getAllProfileRef, removeFrameLines, removeLayoutLines, updateSashSize } from '../../../utility/threeJsHelpers/uiLinesHelper';
import { useRightContext } from '../../../context/rightMenuContext';
import EditSashSizeModel from '../../../components/3DComponents/EditSashSizeModel';
import useFrameModifier from '../../../components/3DComponents/CreateUpdateAddedFrames';
import EditFrameSizeModel from '../../../components/3DComponents/EditFrameSizeModel';
import SeqNumberRefs from '../../../components/3DComponents/SeqNumberRefs';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useMessage } from '../../../context/messageContext';

import { loadWindowData } from './windowDataLoader';

import { createWindowFrame, getAddOnRef, getFrameRefs, getJointsRef, loadDefaultFrame, updateFrameDimension, updateFrameProfile } from './createWindowFrame.js';

import useDebounce from '../../customHooks/customHook.js';
import { updateColor } from './common.js';
import { editHangings, getSashHangingRefs, getSasProfilehRefs, handlePartialWidthChange, hangingRefs, loadDefaultSashProfile, removeHangings, updateSashProfile } from './sashes.js';
import { addSill, removeSill, removeSillData, sillHornLength, storeSill, updateHornLengthData } from './sills.js';
import useDataStorage from '../../../utility/customHooks/useDataStorage.js';
import { deleteTransomSplit, getTransomPartitionRefs, getTransomRefs, manipulateTransoms } from './transoms.js';
import { addAddOns, deleteAddOns } from './addOns.js';
import { addHardwareOnSash, deleteHardwareFromSash, deleteHardwareOnFrame, deleteMultipleHardwareFromProfile, hardwarePositionChange, loadHandle, storeHardwareOnFrame, storeMultipleHardwaresOnProfile, updateHardwareOnFrame, updateMultiplHardwareOnProfile } from './hardwares.js';
import { applyGlazingSpec } from './glazing.js';
// import GS20TwinTrackbase from "../../../assets/models/GS20TwinTrackbase.glb"

const BlockPanelDesign = memo(({ manufacturingToggle, initialLoad, setInitialLoad, receivedProductId, modelVisible, setModelVisible, collectionDetails, cameraRef, currentModel, isPanelOpen, loading, setLoading, layoutSizing, hardwareType, setHardwareType, allFrameCollection, setLayoutSizing, selectedAnimation, quotationId, modelId, styleId, headerSelectedItem, allHardware, setHardwareData, numeralsText, numeralsFont, newAllHardware, maxZoom, setSaveAllImages, captureImages, setCaptureImages, setAllProfileRefs, addOnList, dataLoaded,
	setDataLoaded, setGotData }) => {

	const addOnFrames = "";
	const modelWrap = useRef(null);
	const containerRef = useRef(null);
	const tempBar = useRef(null);
	const frameStyleRef = useRef([]);
	const navigate = useNavigate()

	const { setMessage, setMessageType } = useMessage()

	const { userDataContext } = useRightContext()

	const { updateAddedFrame, changeAddedFrameAngle, saveAdditionalFrameData } = useFrameModifier()

	const {
		editHardware,
		colorActiveTab,
		internalColor,
		externalColor,
		frameAngle,
		applyAngle,
		internalRAL,
		glazingSpec,
		texture,
		externalRAL,
		frameObject,
		modelHeight,
		modelWidth,
		shapedHeight,
		shapedWidth,
		saveHeightWidth,
		defaultSpec,
		design,
		couplerData,
		rows,
		cols,
		saveGlazingDesign,
		setNumHardware,
		hardwareColor,
		hardwareElPos,
		horizontalPos,
		verticalPos,
		handleSides,
		isHardwareSave,
		frameObjectSaving,
		transomDistance,
		productsData,
		hornLength,
		threshHolData,
		deleteFrameObject,
		setSaveHeightWidth,
		deleteHardware,
		isSettingPos,
		transomType,
		setToggleHelper,
		toggleHelper,
		cancelChanges,
		initialProfile,
		bayPostList,
		deletePressed,
		setDeletePressed,
		overrideWidth,
		overrideHeight,
		panelData,
		setPanelData,
		multiSelectRefPoints, setMultiSelectRefPoints,
		checkSingleSide, setCheckSingleSide, setDeleteRefPoints, setFrameObject, transomsOrientation, checkClickedProfile, setCheckClickedProfile,
		frameProfiles, sashProfileType, saveFrameCustomization, sashHangingNo, sashHanging, deleteItem, delete3DItems, beadList, spacerBar, hardwareObject, sashRadioActive, setSashRadioActive, setWindowHandleOrientation,
		windowHandleOrientation, deleteSashHanging, setDeleteSashHanging, setSashHangingNo, applyPanel, setApplyPanel,
		deletePanel, setDeletePanel, panelObject, panelDataSave, setPanelDataSave, setPanelObject, setDeleteItem, setPricingData, visibleInternalColors, setVisibleInternalColors, setInternalColor, setInternalRAL,
		thresholdType, shapeFrame, sandblastPricing, hardwareReferencePoint, profileJointDetails, uiLinesLocation, setUiLinesLocation, setApplyPartialTrigger, applyPartialTrigger, lockRefIndex, applyPartialObj, setLockRefIndex, setLockRefPoints, setModelImages, modelJson, setModelJson, wide, high, newModelId, newStyleId, setNewModelId, setNewStyleId, jsonIndex, setJsonIndex } = useDesigner();

	const [modelInitialied, setModelInitialied] = useState(false)
	const [tickleVent, setTickleVent] = useState([])
	const [elementData, setElementData] = useState([])
	const [doorHandleData, setDoorHandleData] = useState([])
	const [uniqueSideRefernce, setUniqueSideRefernce] = useState([])
	const [newFrameData, setNewFrameData] = useState([])
	const [frameDrop, setFrameDrop] = useState(false)
	const [styleDrop, setStyleDrop] = useState(false)
	const [frameType, setFrameType] = useState()
	const [styleType, setStyleType] = useState()
	const [popoverIndex, setPopoverIndex] = useState(null)
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const [isUniqueSide, setIsUniqueSide] = useState(false)
	const [tansomsReference, setTansomsReference] = useState([])
	const [panelRefPoints, setPanelRefPoints] = useState([])
	const [checkDeleteSide, setCheckDeleteSide] = useState()
	const [spyHoleRefPoints, setSpyHoleRefPoints] = useState([])
	const [defaultSillScale, setDefaultSillScale] = useState()
	const [handleDeleted, setHandleDeleted] = useState(false)
	const [elementDeleted, setElementDeleted] = useState(false)
	const [masterSlave, setMasterSlave] = useState(false)
	const [frameProfileDefault, setFrameProfileDefault] = useState('')
	const [frameData, setFrameData] = useState(null);
	const [isHangingLoaded, setIsHangingLoaded] = useState(false)
	const [saveColors, setSaveColors] = useState(false)
	const [intColorInit, setIntColorInit] = useState(false)
	const [hingeRefPoints, setHingeRefPoints] = useState([])
	const [panelInitAdded, setPanelInitAdded] = useState(false)
	const [bayPostDeg, setBayPostDeg] = useState(bayPostList[0]?.id)
	const [bayPostAngleManual, setBayPostAngleManual] = useState(120)
	const [allStyleCollection, setAllStyleCollection] = useState([])
	const [refreshUiLines, setRefreshUiLines] = useState(false)
	const [refreshAfterSave, setRefreshAfterSave] = useState(false)
	const [savePartialWidths, setSavePartialWidths] = useState(false)
	const [resizeSashStyles, setResizeSashStyles] = useState(false)

	const [renderedSize, setRenderedSize] = useState({
		width: 2500,
		height: 2100,
	})

	const { customModelData, setCustomModelData, addedFrameData, setAddedFrameData, setAddedFrameList, addedFrameList, hangingPosition } = useDesigner()

	const [isHardwareAdded, setIsHardwareAdded] = useState({
		knocker: false,
		escutcheon: false,
		spyhole: false,
		trickleVent: false,
		numeral: false,
		handle: false,
	})

	let mixer;
	let gltfModel = useRef(null)
	// let glass1, glass2; 
	let loader;
	let tickleVentReference = [];
	const tickleVentRef = useRef([]);
	const handleRef = useRef([]);
	const shapeRef1 = useRef();
	const shapeRef2 = useRef();
	let handleReference = [];
	let heightRef = [];
	let widthRef = [];
	let sashRef = [];
	const addFrameRef = useRef([]);
	const transomRelativePosition = useRef([]);
	const testRef = useRef([]);
	const uiLinesRef = useRef([]);
	const uiLinesRefBottom = useRef([]);
	const uiLinesRefFrame = useRef([]);
	let allProfileRefSeq = useRef([]);
	let glassRefSeq = useRef([]);
	// const hardwareTypeRef = useRef(null);
	const internalFrameRef = useRef([]);
	const externalFrameRef = useRef([]);
	const defaultGlassMaterial = useRef(null);
	const defaultFrameMaterial = useRef(null);
	const windowRef = useRef([]);
	const handleInternal = useRef([]);
	const handleExternal = useRef([]);
	const tickleVentModels = useRef([]);
	const handlePosition = useRef([]);
	const removeReplacedSash = useRef([]);
	// const trickleVent
	const allTrickleVents = useRef([]);
	// const handlePositionInternal = useRef([]);
	const tickleVentPosition = useRef([]);
	const cylinderPosition = useRef([]);
	const handleCylinder = useRef([]);
	const sillRef = useRef(null);
	const addonRef = useRef([]);
	const sillsHeightRef = useRef([]);
	const glass1 = useRef(null);
	const glass2 = useRef(null);
	const glassGroup = useRef([]);
	const frameStyle = useRef([]);
	const addedFrame = useRef([]);
	const transoms = useRef([]);
	const bead = useRef([]);
	const hingeModels = useRef([]);
	const addedFramePosition = useRef([]);
	const frameStyleTop = useRef(null);
	const frameStyleBottom = useRef(null);
	const frameStyleLeft = useRef(null);
	const newFrameStyleSlant = useRef(null);
	const frameStyleRight = useRef(null);
	const windowHeight = useRef(null);
	const windowWidth = useRef(null);
	const frameSash = useRef([]);
	const Frame = useRef([]);
	const SpaceBar = useRef([]);
	const sashGroup = useRef([]);
	const transomFrame = useRef([]);
	const transomFrameSortedX = useRef([]);
	const transomFrameSortedY = useRef([]);
	const replacedSashProfile = useRef([]);
	const handleObjects = useRef([]);
	const tickleObjects = useRef([]);
	const addedSash = useRef([]);
	const sceneRef = useRef(null);
	const widthLine = useRef(null);
	const heightLine = useRef(null);
	const shadowPlaneRef = useRef(null);
	const orbitControlRef = useRef(null);
	const elementRef = useRef(null);
	const objectGroupCol = useRef(null);
	const objectGroupRow = useRef(null);
	const rowStorage = useRef([])
	const colStorage = useRef([]);
	const beadData = useRef(null);
	const handlePath = useRef(null);
	const ticklePath = useRef(null);
	const rendererInitialised = useRef(null);
	const sashHangingAmount = useRef(null);
	const spyHoleModel = useRef([]);
	const spyHolePosition = useRef([]);
	const letterPlateModel = useRef([]);
	const letterPlatePos = useRef([]);
	const knockerModel = useRef([]);
	const knockerPos = useRef([]);
	const sashList = useRef([]);
	const textMeshRef = useRef(null);
	const textMeshPos = useRef(null);
	const previousTransomFrameRef = useRef(null);
	const orientationClicked = useRef(null);
	const radioButtonClicked = useRef(null);
	const transomData = useRef(null);
	const mullionData = useRef(null)
	const escutcheonExternalModel = useRef([]);
	const escutcheonInternalModel = useRef([]);
	const transomPosition = useRef([]);
	const escutcheonExternalPos = useRef([])
	const addedHandles = useRef([]);
	const addedBarHandles = useRef([])
	const windowSash = useRef([]);
	const addedBarHandlesPos = useRef([]);
	const barHandlesOffsetExternal = useRef([])
	const barHandlesOffsetInternal = useRef([])
	const barHandlesOffsetInternalPos = useRef([])
	const barHandlesOffsetExternalPos = useRef([])
	const newFrameRefPoints = useRef([])
	const allLockingPlates = useRef([])
	const storeGrooveCylinder = useRef([])
	const storeGroveCube = useRef([])
	const storeSashes = useRef([])
	const clippedPlanes = useRef([])
	const getUpdatedFrameRefs = useRef([])
	const extraStorage = useRef([])
	const storeBayPost = useRef(null)
	const topLeftCorner = useRef(null);
	const topRightCorner = useRef(null)
	const cornersLeftFrame = useRef({
		currentTopR: null,
		currentBottomR: null,
		currentBottom: null,
		glassScale: null,
		frameLeftBoundingBox: null,
		frameTopBoundingBox: null,
		frameRightBoundBox: null
	})

	const cornersRightFrame = useRef({
		currentTopL: null,
		currentBottomL: null,
		currentBottom: null,
		glassScale: null,
		frameLeftBoundingBox: null,
		frameTopBoundingBox: null,
		frameRightBoundBox: null
	})

	const perpFrameTopBoundingBox = useRef({
		leftProfile: null,
		rightProfile: null
	})


	let sashHandles = useRef([])
	let masterSlaveElements = useRef([])
	const isHeritageDoor = useRef([])
	const glazeModelRef = useRef();

	const [clipGlaze, setClipGlaze] = useState([])

	const windowHandleRefPoint = useRef([]);
	const windowHangingSash = useRef([]);
	// const windowHandleOrientation = useRef('right');
	const glazingRef = useRef([]);
	let beadDefaultScale = null;
	let hingePosition = useRef([])
	// const panelGlazingRef = useRef([])

	const glassText = useRef([]);

	const debouncedValue = useDebounce(sashHangingNo, 300)
	const debounceShapedHeight = useDebounce(shapedHeight, 500)
	const debouncShapedWidth = useDebounce(shapedWidth, 500)
	const debouncedFrameWidth = useDebounce(modelWidth, 1000)
	const debouncedFrameHeight = useDebounce(modelHeight, 1000)
	const debounceHardwareHPosition = useDebounce(horizontalPos, 200);
	const debouceHardwareVPostion = useDebounce(verticalPos, 200);

	const { storeFrameProfile, storeSashProfile, updateSashData, storeAddOnsData, saveHardwareData, updateDeletedAddOnData, deleteHardwareData, updateHardwarePositionColor, storeTransomData, storeSpecData } = useDataStorage()


	let font;
	// const outerContainerRef = useRef(null);
	let pointInScreen;
	const accessToken = localStorage.getItem('access_token')
	let renderer;
	var camera;
	let scene;
	var newFrameStyles

	if (rendererInitialised.current != 4) {
		renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
		camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.25, 30);
		scene = new THREE.Scene();
		renderer.localClippingEnabled = true;
	}

	useEffect(() => {
		setRefreshUiLines(false)
	}, [manufacturingToggle])

	useEffect(() => {
		if (!manufacturingToggle) {
			testRef.current = []
			newFrameRefPoints.current = []
		}
	}, [manufacturingToggle])

	useEffect(() => {
		setIntColorInit(false)
		setLockRefPoints([])
		setLockRefIndex([])
	}, [selectedAnimation?.id])

	useEffect(() => {
		if (allFrameCollection && allFrameCollection?.length > 0 && modelId) {
			frameStyleService(allFrameCollection[0]?.id)
		}
	}, [allFrameCollection, modelId])

	useEffect(() => {
		if (hardwareType === "customize frame style" && multiSelectRefPoints[0]) {
			handleFrameProfiles()
		}
	}, [frameProfiles])

	useEffect(() => {
		if (deleteSashHanging && hardwareType === 'sashHanging') {
			handleDeleteHangings()
		}
	}, [deleteSashHanging])


	useEffect(() => {
		if (hardwareType === 'transomVertical' || hardwareType === "transomHorizontal") {
			handleTransom()
		}
	}, [checkClickedProfile])

	useEffect(() => {
		if (deleteItem === "transoms" && multiSelectRefPoints[0]) {
			handleDeleteTransom(multiSelectRefPoints[0])
		}
	}, [deletePressed])

	const handleTransom = () => {

		const ratioHeight = (modelHeight / modelHeight);
		const ratioWidth = (modelWidth / modelWidth);
		const orientation = hardwareType === "transomVertical" ? "vertical" : "horizontal";
		const halves = orientation === 'vertical' ? ratioWidth / 2 : ratioHeight / 2;
		const initialRatios = [halves, halves]
		const sectionId = checkClickedProfile?.name

		let transomsConfig = {
			orientation,
			initialRatios,
			transomWidth: transomType?.width,
			transomHeight: transomType?.height,
			sectionId
		}

		const updatedJson = manipulateTransoms(transomsConfig, transomType, storeTransomData, jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updatedJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson
		})
		setTimeout(() => {
			testRef.current = getTransomPartitionRefs();
			newFrameRefPoints.current = getTransomRefs();
			setCheckClickedProfile()
		}, 1000);
	}


	const handleDeleteTransom = (deleteItem) => {
		const updateJson = deleteTransomSplit(deleteItem, jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;

		})

		setTimeout(() => {
			testRef.current = getTransomPartitionRefs();
			newFrameRefPoints.current = getTransomRefs();
			setMultiSelectRefPoints([])
		}, 1000);
	}

	useEffect(() => {
		if ((hardwareType === "spec" || hardwareType === "glazing") && glazingSpec) {
			handleApplySpec()
		}
	}, [glazingSpec])


	const handleApplySpec = () => {
		const id = multiSelectRefPoints[0]?.data?.name;
		const index = multiSelectRefPoints[0] ? multiSelectRefPoints[0]?.index : -1;
		const updateJson = applyGlazingSpec(glazingSpec, id, index, jsonIndex, storeSpecData)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		})
	}

	console.log(modelJson, customModelData, "modelJson");

	const handleFrameProfiles = async () => {

		const profileName = multiSelectRefPoints[0]?.data?.name
		createWindowFrame(sceneRef.current, modelJson, "group1", sashGroup, profileName, frameProfiles, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);

		const updatedJson = await updateFrameProfile(frameProfiles, profileName, storeFrameProfile, jsonIndex);
		setModelJson((prevModelJson) => {
			const newJson = updatedJson(prevModelJson);
			return newJson;
		});
		setToggleHelper(false)
	}

	const handleDeleteHangings = () => {
		const updatedJson = removeHangings(jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updatedJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		});
		setTimeout(() => {
			getSashRef()
		}, 200);
	}

	useEffect(() => {
		if (externalColor && hardwareType === "color") {
			let updatedJson = updateColor(externalColor?.colour);
			if (externalColor.name === "Custom RAL" && externalRAL !== undefined) {
				updatedJson = updateColor(rgbStringToHex(externalRAL?.rgbValue));
			}

			setModelJson((prevModelJson) => {
				const newJson = updatedJson(prevModelJson);
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
				return newJson;
			});
		}
	}, [externalColor, externalRAL])

	useEffect(() => {
		if (hardwareType === "Shaped Frame") {
			testRef.current = getJointsRef()
			forceUpdate()
		}
	}, [hardwareType])

	// This is for getting reference point for cutting formula sill
	useEffect(() => {
		if (manufacturingToggle && hardwareType === "sill") {
			const boundingBox = new THREE.Box3().setFromObject(frameStyleBottom?.current);
			const center = new THREE.Vector3()
			boundingBox.getCenter(center)

			const sillPos = {
				position: { x: center?.x, y: center?.y, z: center?.z },
				name: frameStyleBottom?.current?.name
			}
			testRef.current = [sillPos]
			forceUpdate()
		}
	}, [manufacturingToggle, hardwareType])


	console.log(modelJson, "545");




	useEffect(() => {
		if (hardwareType === "addOn" && manufacturingToggle) {
			updateTestRef()
		}
	}, [manufacturingToggle, hardwareType])

	useEffect(() => {
		if (hardwareType === "transom" && manufacturingToggle) {
			getTestRefForTransoms()
		}
	}, [manufacturingToggle, hardwareType])

	useEffect(() => {
		if ((hardwareType === "addOn" || hardwareType === "editAddOn") && !manufacturingToggle && addOnList?.length > 0) {
			updateTestRef()
			setElementData(addOnList)
		}
	}, [hardwareType, addOnList])


	useEffect(() => {
		if (deleteItem === "addon" && deletePressed) {
			handleAddOnDelete()
		}
	}, [deleteItem])


	const handleAddOnDelete = () => {
		const name = multiSelectRefPoints[0]?.data.name;

		const side = getSideFromFrameType(name);

		const updateJson = deleteAddOns(side, updateDeletedAddOnData, jsonIndex);

		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		})
	}

	useEffect(() => {
		if (multiSelectRefPoints.length > 0 && applyAngle) {
			let isTop;
			let isLeft;
			multiSelectRefPoints.forEach((item) => {
				if (item.data.name.includes("topPerpendicular")) {
					isTop = "top"
				} else if (item.data.name.includes("bottomPerpendicular")) {
					isTop = "bottom"
				} else if (item.data.name.includes("rightPerpendicular")) {
					isLeft = true
				} else if (item.data.name.includes("leftPerpendicular")) {
					isLeft = false
				}

				changeAddedFrameAngle(storeBayPost.current, item.data, frameAngle, isTop, isLeft, gltfModel.current, frameStyleTop)
			})
		}
	}, [applyAngle])


	useEffect(() => {
		if ((customModelData && (customModelData?.frame?.framesAndCoupler && customModelData?.frame?.framesAndCoupler?.length > 0) || (customModelData?.frame?.bayPost && customModelData?.frame?.bayPost?.length > 0)) || (customModelData && customModelData?.addedFrames?.length)) {
			let isPerpendicular = false
			let isTop = false
			let isBottom = false
			if (multiSelectRefPoints && multiSelectRefPoints.length) {
				multiSelectRefPoints.forEach((item) => {
					if (item.data.name.includes("rightPerpendicular") || item.data.name.includes("leftPerpendicular")) {
						isPerpendicular = true
						isTop = false
						isBottom = false
					} else if (item.data.name.includes("topPerpendicular")) {
						isPerpendicular = true
						isTop = true
						isBottom = false
					} else if (item.data.name.includes("bottomPerpendicular")) {
						isPerpendicular = true
						isTop = false
						isBottom = true
					}



					if (overrideHeight || overrideWidth) {
						resizeAdditionalFrame(item.data, isPerpendicular, isTop, isBottom, overrideHeight, overrideWidth, headerSelectedItem, topLeftCorner, sceneRef, topRightCorner)

						// const foundItem = customModelData?.addFrames?.find((ele) =>
						// 	deepEqual(ele?.position, item?.data?.position)
						// );

						setCustomModelData((prevData) => {
							const updatedFrames = prevData.addedFrames.map((frame) => {
								if (deepEqual(frame?.position, item?.data?.position)) {
									return {
										...frame,
										height: overrideHeight !== frame?.height ? overrideHeight : frame.height,
										width: overrideWidth !== frame?.width ? overrideWidth : frame.width,
									};
								}
								return frame;
							});

							return {
								...prevData,
								addedFrames: updatedFrames,
							};
						});
					}

				})
			}

			// temporarily using extra storage to refeed the ref as it was getting emptied on save
			if (hardwareType && hardwareType?.name === "Frame") {
				newFrameRefPoints.current = extraStorage.current
			}
		}

	}, [saveHeightWidth])

	useEffect(() => {
		if (design && design?.name === "Sandblasted numerals" || design === 'None') {
			if (colStorage.current.length) {
				clearDesignCols()
			}
			if (rowStorage.current.length) {
				clearDesignRows()
			}
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				glazing: {
					...prevModelData.glazing,
					design: []
				}
			}))
		}
	}, [design])

	useEffect(() => {
		if (deleteItem === "sash") {
			multiSelectRefPoints.forEach((item) => {
				removeSash(item.index, item.data)
			})
		}
	}, [deletePressed])

	useEffect(() => {
		if (modelInitialied && spacerBar) {
			modifySpacerBar(spacerBar, true)
		}
	}, [spacerBar])



	useEffect(() => {
		if (thresholdType) {
			addThreshold(thresholdType)
			let data = {
				...thresholdType,
				isThresholdAdded: true,
			}
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				frame: {
					...prevModelData.frame,
					threshold: data
				}
			}))
		}
	}, [thresholdType])

	useEffect(() => {
		if (customModelData?.numberOfSash?.number === "0") {
			setCustomModelData((prevData) => ({
				...prevData,
				sashData: []
			}))
			replacedSashProfile.current = []
		}
	}, [customModelData?.numberOfSash?.number])

	useEffect(() => {
		if (modelId) {
			getFramesProfilesDefault(accessToken, modelId, setFrameProfileDefault)
		}
	}, [modelId])




	useEffect(() => {

		console.log(dataLoaded, "dataLoaded")
		if (frameProfileDefault && dataLoaded) {
			init()

			handleResize();

			window.addEventListener("resize", handleResize);

			return () => {
				if (renderer) {
					renderer.dispose();
				}
			};
		}
	}, [frameProfileDefault, profileJointDetails, dataLoaded, cancelChanges]);


	// Handle window resize
	const handleResize = () => {
		const newWidth = containerRef.current?.clientWidth;
		const newHeight = containerRef.current?.clientHeight;

		// Update renderer size
		renderer?.setSize(newWidth, newHeight);

		if (camera) {
			// Update camera aspect ratio
			camera.aspect = newWidth / newHeight;
			camera.updateProjectionMatrix();
		}
	};


	useEffect(() => {
		if (tickleVent && tickleVent.length) {
			setNumHardware(tickleVent.length)
		}
	}, [tickleVent])

	useEffect(() => {
		if (textMeshRef.current && !isSettingPos && !isHardwareAdded?.numeral) {
			const numeralNumbersString = customModelData?.hardware?.numeral[0]?.numbers.join("").toString();
			let horizontal = customModelData?.hardware?.numeral[0].horizontalPos;
			let item = ""
			if (typeof (horizontal) === "number" || !horizontal) {
				item = "no"
			}
			else {
				item = customModelData?.hardware?.numeral[0]
			}
			if (numeralNumbersString) {
				createText(numeralNumbersString, customModelData.hardware?.numeral[0]?.position, getHexValue(hardwareColor), false, item);
			}
		}
	}, [customModelData, hardwareColor, isHardwareSave, hardwareType, isHardwareAdded?.numeral]);



	useEffect(() => {
		if (hangingPosition && multiSelectRefPoints[0]) {
			const zPos = hangingPosition == "External" ? 0.02 : hangingPosition == "Internal" ? -0.02 : 0;
			const selectedIndex = multiSelectRefPoints[0]?.index
			sashGroup.current.forEach((child, index) => {

				const start = selectedIndex * 4;
				const end = start + 3;

				if (index >= start && index <= end) {
					child.position.z = zPos;
				}
			})
		}
	}, [hangingPosition])


	// add sash function for sashanging number
	useEffect(() => {
		if (hardwareType === 'sashHanging') {
			if (debouncedValue) {
				(async () => {
					await handleSashHangingChange(debouncedValue);
					setTimeout(() => {
						getSashRef();
					}, 500);
				})();
			}
		}
	}, [debouncedValue]);

	// add sash function for sashanging number
	useEffect(() => {
		if (saveFrameCustomization && hardwareType === 'sashHanging' && ((customModelData?.numberOfSash?.number !== sashHangingNo) || (customModelData?.numberOfSash?.high != high) || (customModelData?.numberOfSash?.wide != wide))) {

			setLockRefPoints([])
			setLockRefIndex([])

			if (collectionDetails && collectionDetails.typeId == 1) {

				let xScaleVal = []
				let sashSize = []

				let sashValue = (parseFloat(customModelData?.layoutFrame?.width) / parseInt(sashHangingNo))

				for (let i = 0; i < sashHangingNo; i++) {
					xScaleVal.push(1)
					sashSize.push(sashValue)
				}

				if (!receivedProductId) {
					setCustomModelData((prevModelData) => ({
						...prevModelData,
						sashData: [],
						numberOfSash: {
							number: sashHangingNo.toString(),
							wide: wide,
							high: high
						},
						xScale: xScaleVal,
						sashSize: sashSize,
					}))
				} else {
					setCustomModelData((prevModelData) => ({
						...prevModelData,
						sashData: [],
						numberOfSash: {
							number: sashHangingNo.toString(),
							wide: wide,
							high: high
						},
						xScale: xScaleVal,
						sashSize: sashSize,
					}))
				}
			}

			if (!receivedProductId) {
				if (sashHangingNo > 0) {
					updateFrameStyleAppenture(accessToken, selectedAnimation?.id, sashHangingNo, wide, high)
				} else {
					updateFrameStyleAppenture(accessToken, selectedAnimation?.id, 1, wide, high)
				}
			}
		}
	}, [saveFrameCustomization])

	useEffect(() => {
		if (multiSelectRefPoints[0] && sashProfileType && hardwareType === "sashprofiles") {
			handleUpdateSashProfile()
		}
	}, [sashProfileType])

	useEffect(() => {
		if (multiSelectRefPoints[0] && hardwareType === "sashHanging" && windowHandleOrientation) {
			handleHangingType()
		}
	}, [windowHandleOrientation])


	const handleHangingType = async () => {
		const handle = allHardware?.find((item) => (item?.name?.toLowerCase() === "window handle" || item?.name?.toLowerCase() === "door handle"))
		const orientation = windowHandleOrientation?.toLowerCase();
		const index = multiSelectRefPoints[0]?.index;
		const handleModel = `${servicePath}/ThreeJSModel/Glb/${handle?.hardwareStyles[0]?.modelFilePath}`

		const cylinder = allHardware?.find((item) => item?.name?.toLowerCase() === "cylinder");

		if (!cylinder) {
			console.error("Cylinder not found. Proceeding without cylinder.");
			cylinder = { hardwareStyles: [{}] }; // Provide a default or empty object
		}

		let sashHangingNo = 1;
		if (modelJson[jsonIndex]?.sash && !modelJson[jsonIndex]?.sash?.sections?.length) {
			sashHangingNo = orientation === "master/slave" || orientation === "slave/master" ? 2 : 1;
			await handleSashHangingChange(sashHangingNo);
		}

		const updatedJson = editHangings(index, orientation, handleModel, saveHardwareData, handle?.hardwareStyles[0], "handle", cylinder.hardwareStyles[0], jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updatedJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		})
	}

	const handleSashHangingChange = async (debouncedValue) => {
		return new Promise((resolve) => {
			setLockRefPoints([]);
			setLockRefIndex([]);
			setUiLinesLocation('');

			let sashSize = [];
			let sashValue = (parseFloat(modelJson[jsonIndex]?.dimensions?.width * 1000) / parseInt(sashHangingNo));
			const initialSpec = defaultSpec?.specification.find((item) => item.default === true);

			for (let i = 0; i < sashHangingNo; i++) {
				sashSize.push(sashValue);
			}

			const defaultSash = initialProfile?.sash.find((item) => item.defaultProfile === true);
			const partialWidth = sashSize;

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				numberOfSash: {
					number: sashHangingNo,
					wide: wide,
					high: high,
				},
				sashSize: sashSize,
			}));

			const updatedJson = loadDefaultSashProfile(debouncedValue, defaultSash, storeSashProfile, partialWidth, initialSpec, jsonIndex);

			setModelJson((prevModelJson) => {
				const newJson = updatedJson(prevModelJson);
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
				return newJson;
			});

			setTimeout(() => {
				getSashRef();
				setMultiSelectRefPoints([]);
				setUiLinesLocation("All");
				resolve(); // Resolve the promise after updates are complete
			}, 200);

			if (!receivedProductId) {
				if (sashHangingNo > 0) {
					updateFrameStyleAppenture(accessToken, selectedAnimation?.id, sashHangingNo, wide, high);
				} else {
					updateFrameStyleAppenture(accessToken, selectedAnimation?.id, 1, wide, high);
				}
			}
		});
	};


	const handleUpdateSashProfile = () => {
		const data = multiSelectRefPoints[0]
		const { height, width } = sashProfileType
		const orientation = data?.data?.name
		let profileIndex = data?.index
		const index = mapNumberToRange(data?.index)
		const updatedJson = updateSashProfile(index, orientation, sashProfileType, customModelData?.sashData, profileIndex, updateSashData, jsonIndex);
		setModelJson((prevModelJson) => {
			const newJson = updatedJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson
		})
		setMultiSelectRefPoints([])
	}



	const modifySpacerBar = (data, storeData) => {
		const material = new THREE.MeshStandardMaterial({
			color: data.hexValue,
			metalness: 1,
			roughness: 0.5
		});

		sceneRef.current.traverse((child) => {
			if (child.name.includes("Spacer")) {
				child.material = material
			}
		})
	}

	const handleDeleteObject = (index) => {
		setCheckDeleteSide(index)
	}

	useEffect(() => {
		if (!quotationId && modelId && (handleDeleted || elementDeleted)) {
			saveModelData(accessToken, modelId, selectedAnimation?.id, customModelData, modelJson, navigate, setMessage, setMessageType)
		}

		if (quotationId && modelId && receivedProductId && (handleDeleted || elementDeleted)) {
			saveModelDataQuotation(accessToken, modelId, selectedAnimation?.id, customModelData, quotationId, receivedProductId, navigate, setMessage, setMessageType, modelJson)
		}
	}, [handleDeleted, elementDeleted]);

	// Debounce function to delay calling addTextOnGlass
	let debounceTimer;

	const debounceAddTextOnGlass = (font, text) => {
		clearTimeout(debounceTimer);

		debounceTimer = setTimeout(() => {
			if (multiSelectRefPoints.length > 0) {
				multiSelectRefPoints.forEach((item) => {
					if (numeralsText != '') {
						addTextOnGlass(font, text, item.index);
					} else {
						if (numeralsText == '') {
							removeTextOnGlass(item.index)
						}
					}
				})
			} else {
				if (numeralsText != '') {
					addTextOnGlass(font, text, null);
				} else {
					if (numeralsText == '') {
						removeTextOnGlass(null)
					}
				}
			}
		}, 1000); // Adjust the delay time as needed (e.g., 1000 milliseconds)
	};

	useEffect(() => {
		debounceAddTextOnGlass(numeralsFont, numeralsText);
	}, [numeralsText, numeralsFont])

	useEffect(() => {
		if (!layoutSizing) {
			// forceUpdate()
			testRef.current = []
			newFrameRefPoints.current = []
		}
	}, [layoutSizing])




	useEffect(() => {

		if (hardwareType) {
			handleHardwares()
			forceUpdate()
		}

		if (hardwareType && newAllHardware?.length > 0 && hardwareReferencePoint !== null && hardwareType !== "addOn") {
			const selectedHardware = newAllHardware.filter((item) => item?.name === hardwareType?.name)
			setElementData(selectedHardware[0]?.hardwareStyles)
		}

	}, [hardwareType, modelInitialied, hardwareReferencePoint, newAllHardware])



	useEffect(() => {
		if (hardwareType) {
			handleHardwares()
		}
	}, [hardwareType])



	useEffect(() => {
		if (headerSelectedItem.name === "French Door" && hardwareType === "sashHanging") {
			setElementData(productsData)
		}
	}, [headerSelectedItem, hardwareType])


	useEffect(() => {
		if (hardwareType && hardwareType?.name === "Frame" && modelInitialied) {
			handleHardwares()
		}
	}, [newFrameData, modelInitialied])

	useEffect(() => {
		if (hardwareType !== "sashHanging") {
			if (modelInitialied && customModelData?.hardware && customModelData?.hardware?.handle?.length > 0) {
				customModelData?.hardware?.handle?.forEach((obj, index) => {
					addHandle(obj.file, sashGroup.current[obj.index], true, "", "", obj.id)
				})
			}
		}
	}, [modelInitialied, sashGroup.current])


	useEffect(() => {
		if (customModelData?.hardware?.handle && modelInitialied) {
			setTimeout(() => {
				customModelData?.hardware?.handle?.forEach((item) => {
					setHandlePosition(handleSides, true, item?.index, !item?.horizontalPos ? 0 : parseInt(item?.horizontalPos), true)
					setHandlePosition(handleSides, false, item?.index, !item.verticalPos ? 0 : parseInt(item?.verticalPos), true)
				})

			}, 300);
		}
	}, [isHardwareAdded?.handle, modelInitialied])


	// useEffect(() => {
	// 	if (modelInitialied) {
	// 		if (hardwareColor && hardwareType?.name === '3 Star') {
	// 			setHandleCylinderColor(hardwareElPos, hardwareColor?.hex)
	// 			addHardwareColorPricing(hardwareColor, hardwareType?.type, hardwareType?.name, setCustomModelData)
	// 		} else if (hardwareColor && hardwareColor?.hex && hardwareType?.name === "Slim Security Flip Lock") {
	// 			setHandleColor(hardwareElPos, hardwareColor?.hex)
	// 			addHardwareColorPricing(hardwareColor, hardwareType?.type, hardwareType?.name, setCustomModelData)
	// 		} else if (hardwareColor && hardwareType?.type === "trickle") {
	// 			setTrickleVentColor(hardwareElPos, hardwareColor?.hex)
	// 			addHardwareColorPricing(hardwareColor, hardwareType?.type, hardwareType?.name, setCustomModelData)
	// 		} else if (hardwareColor && hardwareType?.type === "spyhole") {
	// 			setSpyHoleColor(hardwareColor?.hex)
	// 			addHardwareColorPricing(hardwareColor, hardwareType?.type, hardwareType?.name, setCustomModelData)
	// 		} else if (hardwareColor && hardwareType?.type === 'letterplate') {
	// 			setLetterPlateColor(hardwareColor?.hex)
	// 			addHardwareColorPricing(hardwareColor, hardwareType?.type, hardwareType?.name, setCustomModelData)
	// 		} else if (hardwareColor && hardwareType?.type === 'knocker') {
	// 			setKnockerColors(hardwareColor?.hex)
	// 			addHardwareColorPricing(hardwareColor, hardwareType?.type, hardwareType?.name, setCustomModelData)
	// 		} else if (hardwareColor && hardwareType?.type === "escutcheon") {
	// 			setEscutcheonColor(hardwareElPos, hardwareColor?.hex)
	// 			addHardwareColorPricing(hardwareColor, hardwareType?.type, hardwareType?.name, setCustomModelData)
	// 		} else if (hardwareColor && hardwareType?.type === "hinge") {
	// 			setHingeColor(hardwareColor.hex)
	// 			addHardwareColorPricing(hardwareColor, hardwareType?.type, hardwareType?.name, setCustomModelData)
	// 		}
	// 	}
	// }, [hardwareType, hardwareColor, modelInitialied])


	const getHardwareApplyDetails = (hardwareModel, orientation, data, profileIndex, type) => {
		const trimmedFilePath = hardwareModel.replace(/\s+/g, '');
		type = type.replace(/\s+/g, '');
		if (type === "doorhandle" || type === "barhandlesoffset") {
			type = "doorhandle"
		}
		// else if (type==="tricklevent"){
		// 	type="trickle vent"
		// }
		let model = `${servicePath}/ThreeJSModel/Glb/${trimmedFilePath}`;
		const index = mapNumberToRange(profileIndex);

		return { model, index, data, orientation, type }
	}


	useEffect(() => {
		if (editHardware === "edithandle" && multiSelectRefPoints[0]) {
			handleDeleteHardwareSash()
		} else if (editHardware === "edittrickleVent" && multiSelectRefPoints[0]) {
			handleDeleteMultipleHardware()
		} else if (editHardware === "editspyhole" && multiSelectRefPoints[0]) {
			handleDeleteHardwareFrame()
		}
	}, [deleteHardware])


	const handleDeleteMultipleHardware = async () => {
		const { index, orientation, type } = getHardwareApplyDetails("", multiSelectRefPoints[0]?.data?.name, "", multiSelectRefPoints[0]?.index, "tricklevent");
		let side
		if (orientation.includes("Frame")) {
			side = orientation === "FrameTop" ? "top" : orientation === "FrameRight" ? "right" : orientation === "FrameLeft" ? "left" : "bottom";
		} else {
			side = orientation;
		}
		const updateJson = await deleteMultipleHardwareFromProfile(index, side, type, "trickleVent", deleteHardwareData, jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;

		})

	}



	const handleDeleteHardwareSash = async () => {
		const { index, orientation, type } = getHardwareApplyDetails("", multiSelectRefPoints[0]?.data?.name, "", multiSelectRefPoints[0]?.index, "doorhandle")
		const updateJson = await deleteHardwareFromSash(multiSelectRefPoints[0]?.index, index, orientation, type, deleteHardwareData, "handle");
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		})
	}


	const handleDeleteHardwareFrame = () => {
		const { index, orientation, type } = getHardwareApplyDetails("", multiSelectRefPoints[0]?.data?.name, "", multiSelectRefPoints[0]?.index, "spyhole")
		let side
		if (orientation.includes("Frame")) {
			side = orientation === "FrameTop" ? "top" : orientation === "FrameRight" ? "right" : orientation === "FrameLeft" ? "left" : "bottom";
		} else {
			side = orientation;
		}
		let hardwaretype = "";
		if (type === "door handle") {
			hardwaretype = "handle"
		}
		else {
			hardwaretype = toCamelCase(type)
		}

		const updateJson = deleteHardwareOnFrame(multiSelectRefPoints[0]?.index, side, hardwaretype, deleteHardwareData, jsonIndex);
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		})
	}



	const hardwareRefs = () => {
		testRef.current = [];
		testRef.current = [...getSasProfilehRefs(), ...getFrameRefs()]
	}

	useEffect(() => {
		if (editHardware) {
			forceUpdate()
			hardwareRefs()
		} else {
			forceUpdate()
			testRef.current = []
		}
	}, [editHardware])


	useEffect(() => {

		if (multiSelectRefPoints[0]) {
			const index = mapNumberToRange(multiSelectRefPoints[0]?.index);
			const name = multiSelectRefPoints[0]?.data?.name
			if (editHardware === 'edithandle' && (horizontalPos || verticalPos || hardwareColor)) {
				handleHardwareUpdate(index, name, debounceHardwareHPosition || horizontalPos, debouceHardwareVPostion || verticalPos, hardwareColor, "doorhandle", "handle", multiSelectRefPoints[0]?.index)
			} else if (editHardware === 'edittrickleVent' && (horizontalPos || verticalPos || hardwareColor)) {
				handleMultipleHardwareUpdate(index, name, hardwareColor, "tricklevent", "trickleVent", multiSelectRefPoints[0]?.index)
			} else if (editHardware === "editspyhole" && (horizontalPos || verticalPos || hardwareColor)) {
				handleHardwareUpdateOnFrame(index, name, debounceHardwareHPosition || horizontalPos, debouceHardwareVPostion || verticalPos, hardwareColor, "spyhole", multiSelectRefPoints[0]?.index)
			}
		}

	}, [debouceHardwareVPostion, debounceHardwareHPosition, hardwareColor])


	const handleHardwareUpdate = (index, name, offsetX, offsetY, color, type, hardwareType, hardwareIndex) => {
		const updateJson = hardwarePositionChange(index, name, offsetX, offsetY, color, type, updateHardwarePositionColor, hardwareType, hardwareIndex, jsonIndex);
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		})
	}

	const handleHardwareUpdateOnFrame = (index, name, offsetX, offsetY, color, type, hardwareIndex) => {
		let side
		if (name.includes("Frame")) {
			side = name === "FrameTop" ? "top" : name === "FrameRight" ? "right" : name === "FrameLeft" ? "left" : "bottom";
		} else {
			side = name;
		}
		const updateJson = updateHardwareOnFrame(index, side, offsetX, offsetY, color, type, hardwareIndex, updateHardwarePositionColor, jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		})
	}

	const handleMultipleHardwareUpdate = (index, name, color, type, hardwareType, hardwareIndex) => {

		let side
		if (name.includes("Frame")) {
			side = name === "FrameTop" ? "top" : name === "FrameRight" ? "right" : name === "FrameLeft" ? "left" : "bottom";
		} else {
			side = name;
		}
		const updateJson = updateMultiplHardwareOnProfile(index, side, color, type, hardwareType, hardwareIndex, updateHardwarePositionColor, jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		})
	}


	useEffect(() => {
		if (isHardwareSave && hardwareType?.name === "Slim Security Flip Lock" && verticalPos) {
			setHandlePosition(handleSides, false, hardwareElPos, parseInt(verticalPos), false)
			// setHandleCylinderPosition(false, parseInt(verticalPos), hardwareElPos)
		} else if (isHardwareSave && hardwareType?.type === "trickle" && verticalPos) {
			setTrickleVentPosition(false, hardwareElPos, parseInt(verticalPos))
		} else if (isHardwareSave && hardwareType?.type === 'spyhole' && verticalPos) {
			setSpyHolePosition(false, verticalPos)
		} else if (isHardwareSave && hardwareType?.type === 'letterplate' && verticalPos) {
			setLetterPlatePosition(false, verticalPos)
		} else if (isHardwareSave && hardwareType?.type === 'knocker' && verticalPos) {
			setKnockerPosition(false, verticalPos)
		} else if (isHardwareSave && hardwareType?.type === 'numeral' && verticalPos) {
			setTextPosition(false, parseInt(verticalPos))
		} else if (isHardwareSave && hardwareType?.type === "escutcheon") {
			setEscutecheonPosition(handleSides, false, hardwareElPos, parseInt(verticalPos))
		} else if (isHardwareSave && hardwareType?.type === "hinge") {
			setHingePosition(false, hardwareElPos, parseInt(verticalPos))
		} else if (isHardwareSave && hardwareType.type === "bar handles offset" && verticalPos) {
			setBarHandlePostion(handleSides, false, hardwareElPos, parseInt(verticalPos))
		}
	}, [isHardwareSave])

	useEffect(() => {
		if (hardwareType === "resizing" || hardwareType === "Shaped Frame") {
			resizeWindow(debouncedFrameWidth, debouncedFrameHeight, sashRadioActive, false);
		}
	}, [debouncedFrameHeight, debouncedFrameWidth])

	// useEffect(() => {
	// 	if (customModelData?.numberOfSash?.number === "0") {
	// 		setCustomModelData((prevData) => ({
	// 			...prevData,
	// 			sashData: []
	// 		}))
	// 		replacedSashProfile.current = []
	// 	}
	// }, [customModelData?.numberOfSash?.number])

	// Function add external and RAL COLOR
	useEffect(() => {
		if (externalColor && modelId) {
			if (externalRAL !== undefined) {
				setExternal(rgbStringToHex(externalRAL?.rgbValue))
			} else {
				setExternal(externalColor?.colour)
			}

			if (externalColor?.primaryId) {
				internalColorService(modelId, externalColor?.primaryId)
			}

			// setPricingData((prevPricing) => ({
			// 	...prevPricing,
			// 	frameColorExt: externalColor,
			// }))
		}
	}, [externalColor, externalRAL])

	// function to add internal and ral color
	useEffect(() => {
		if (internalColor !== undefined) {
			if (internalRAL !== undefined) {
				setInternal(rgbStringToHex(internalRAL?.rgbValue));
			} else {
				setInternal(internalColor?.colour);
			}
		}
	}, [internalColor, internalRAL])

	const internalColorService = async (fetchId, primaryId) => {
		const res = await getInternalColorsWithPrice(accessToken, fetchId, primaryId)

		if (res && res.status === 200) {
			if (res?.data?.statusCode === 200) {
				setIntColorInit(true)
				setVisibleInternalColors(res.data?.entity)
			}
		}
	}

	useEffect(() => {
		if (modelInitialied) {
			if (modelId && visibleInternalColors && visibleInternalColors.length > 0 && customModelData) {
				var getSavedColor = customModelData?.frame?.internalColor

				if (customModelData?.frame?.internalColor.itemId !== 0) {
					if (customModelData?.frame?.internalColor?.name === 'Custom RAL') {
						var jsonColor = {
							id: getSavedColor?.itemId,
							name: getSavedColor?.name,
							colour: getSavedColor?.hex,
							primaryId: getSavedColor?.primaryId,
							price: getSavedColor?.price,
							priceInpercentage: getSavedColor?.priceInpercentage,
							barLengthPrice: getSavedColor?.barLengthPrice,
						}

						var json = {
							id: getSavedColor?.custom_Id,
							name: getSavedColor?.customRALName,
							rgbValue: getSavedColor?.rgbValue,
							number: getSavedColor?.customRALCode,
						}

						setInternalColor(jsonColor)
						setInternalRAL(json)
					} else {
						var json = {
							id: getSavedColor?.itemId,
							name: getSavedColor?.name,
							colour: getSavedColor?.hex,
							primaryId: getSavedColor?.primaryId,
							price: getSavedColor?.price,
							priceInpercentage: getSavedColor?.priceInpercentage,
							barLengthPrice: getSavedColor?.barLengthPrice,
						}

						setInternalRAL()
						setInternalColor(json)
					}
					setIntColorInit(true)
				} else {
					if (!quotationId) {
						const defaultIntColor = visibleInternalColors?.find((p) => p.default === true)

						var testKey = customModelData?.frame?.internalColor?.hasOwnProperty("custom_Id")

						setTimeout(() => {
							if (!testKey) {
								if (defaultIntColor === undefined) {
									setInternalColor(visibleInternalColors[0])

									storeExtColor(externalColor, undefined, visibleInternalColors[0], undefined, rgbStringToHex, setCustomModelData, setSaveColors)
								} else {
									setInternalColor(defaultIntColor)

									storeExtColor(externalColor, undefined, defaultIntColor, undefined, rgbStringToHex, setCustomModelData, setSaveColors)
								}
							}
						}, 100);
					}
					setIntColorInit(true)
				}
			}
		}
	}, [visibleInternalColors, modelInitialied])

	useEffect(() => {
		if (saveColors !== '' && saveColors && customModelData && modelId && selectedAnimation?.id) {
			saveModelDataInit(accessToken, modelId, selectedAnimation?.id, customModelData, modelJson, setSaveColors)
		}
	}, [saveColors]);

	console.log(modelJson, "windowData")

	useEffect(() => {
		if (frameStyleBottom.current && modelJson[jsonIndex]?.sill) {
			addSill(modelJson[jsonIndex], sillRef, sceneRef, frameStyleBottom, setDefaultSillScale, sillsHeightRef)
		}
	}, [frameStyleBottom.current])


	useEffect(() => {
		if (frameObject && Object?.keys(frameObject)?.length > 0 && frameStyleBottom.current) {
			const updateJson = storeSill(frameObject, jsonIndex)
			setModelJson((prevData) => {
				const newJson = updateJson(prevData)
				addSill(newJson[jsonIndex], sillRef, sceneRef, frameStyleBottom, setDefaultSillScale, sillsHeightRef)
				return newJson
			})
		}
	}, [frameObject])


	useEffect(() => {
		if (deleteFrameObject) {
			removeSill(sillRef, sceneRef)
			removeSillData(setModelJson, setCustomModelData)
		}
	}, [deleteFrameObject])




	useEffect(() => {
		if (hornLength >= 0 && sillRef.current) {
			const updateJson = updateHornLengthData(hornLength, jsonIndex)
			setModelJson((prevModelData) => {
				const newJson = updateJson(prevModelData)
				sillHornLength(sillRef, newJson[jsonIndex], defaultSillScale)
				return newJson;
			})
		}
	}, [hornLength])



	// This will persist the added hardware on page load.
	useEffect(() => {
		if (modelInitialied && hardwareObject) {
			if (hardwareObject?.escutcheon?.length > 0 && !hardwareType && gltfModel.current) {
				hardwareObject?.escutcheon?.forEach((item) => addDoubleSidedElement(item?.file, item?.position, item, true))
			}
			if (hardwareObject?.knocker?.length > 0 && !hardwareType) {
				hardwareObject?.knocker?.forEach((item) => addTopElements(item?.file, item?.position, item, true))
			}
			// if (hardwareObject?.handle?.length > 0 && !hardwareType) {
			// 	hardwareObject?.handle?.forEach((item) => {
			// 		// if (item?.index) {
			// 			addHandle(item?.file, item?.position, true)
			// 		// }
			// 	})
			// }
			if (hardwareObject?.trickleVent?.length > 0 && !hardwareType) {
				hardwareObject?.trickleVent?.forEach((item) => addElement(item?.file, item?.position, true, item))
			}
			if (hardwareObject?.spyhole?.length > 0 && !hardwareType) {
				hardwareObject?.spyhole?.forEach((item) => addTopElements(item?.file, item?.position, item, true))
			}
			if (hardwareObject?.numeral?.length > 0 && !hardwareType) {
				hardwareObject?.numeral?.forEach((item) => createText(item?.numbers?.join("").toString(), item?.position, "", true, item))
			}
		}
	}, [hardwareObject, modelInitialied]);


	// This chunk will add the hardware on their respective positions.
	useEffect(() => {
		if (modelInitialied && hardwareObject) {

			if (isHardwareAdded?.knocker) {
				hardwareObject?.knocker?.forEach((item) => setKnockerPosition(false, item?.verticalPos))
				hardwareObject?.knocker?.forEach((item) => setKnockerPosition(true, item?.horizontalPos))
			}
			if (isHardwareAdded?.escutcheon) {
				hardwareObject?.escutcheon?.forEach((item) => setEscutecheonPosition(handleSides, false, hardwareElPos, parseFloat(item?.verticalPos)))
				hardwareObject?.escutcheon?.forEach((item) => setEscutecheonPosition(handleSides, true, hardwareElPos, parseFloat(item?.horizontalPos)))
			}
			if (isHardwareAdded?.spyhole) {
				hardwareObject?.spyhole?.forEach((item) => setSpyHolePosition(true, item?.horizontalPos))
				hardwareObject?.spyhole?.forEach((item) => setSpyHolePosition(false, item?.verticalPos))
			}
			if (isHardwareAdded?.trickleVent) {
				hardwareObject?.trickleVent?.forEach((item) => setTrickleVentPosition(true, hardwareElPos, parseInt(item?.horizontalPos)))
				hardwareObject?.trickleVent?.forEach((item) => setTrickleVentPosition(false, hardwareElPos, parseInt(item?.verticalPos)))
			}
			if (isHardwareAdded?.numeral) {
				hardwareObject?.numeral?.forEach((item) => setTextPosition(true, parseInt(item?.horizontalPos)))
				hardwareObject?.numeral?.forEach((item) => setTextPosition(false, parseInt(item?.verticalPos)))
			}

			// if (isHardwareAdded?.handle && modelInitialied) {
			// 	hardwareObject?.handle?.forEach((item) => {
			// 		setHandlePosition(handleSides, true, item?.index, !item?.horizontalPos ? 0 : parseInt(item?.horizontalPos))
			// 	})
			// 	hardwareObject?.handle?.forEach((item) => {
			// 		setHandlePosition(handleSides, false, item?.index, !item.verticalPos ? 0 : parseInt(item?.verticalPos))

			// 	})
			// }
		}
	}, [isHardwareAdded, hardwareObject, modelInitialied])

	const toggleFrameDrop = () => {
		setFrameDrop(!frameDrop)
	}

	const toggleStyleDrop = () => {
		setStyleDrop(!styleDrop)
	}

	const handleFrameDrop = (data) => {
		setFrameType(data)
	}

	const handleStyleDrop = (data) => {
		setStyleType(data)
	}



	// This will get the data for the frame to be added on the basis of the selected frame collection and style
	useEffect(() => {
		if (modelId && selectedAnimation?.id
			&& newModelId == "" && newStyleId == ""
		) {
			getModelDataServices(modelId, selectedAnimation?.id)
		}
		else if (newModelId !== "" && newStyleId !== "") {
			getModelDataServices(newModelId, newStyleId)
		}
	}, [
		frameType, styleType, allStyleCollection, allFrameCollection, allStyleCollection,
		modelId, selectedAnimation?.id,
		frameProfileDefault,
		cancelChanges, newModelId, newStyleId, defaultSpec])

	useEffect(() => {

		if (frameType) {
			frameStyleService(frameType?.id)
		}
	}, [frameType])

	useEffect(() => {
		if (addedFrameData && addedFrameData.hasOwnProperty('position')) {
			setAddedFrameList(prevAddedFrameList => {
				if (!prevAddedFrameList.includes(addedFrameData)) {
					return [...prevAddedFrameList, addedFrameData];
				}
				return prevAddedFrameList;
			});
		}
	}, [addedFrameData]);

	const getModelDataServices = async (frameId, styleId) => {
		const res = await getModelData(accessToken, frameId, styleId)

		const inititalSpec = defaultSpec?.specification?.find((item) => item?.default === true)

		const frame1 = await loadWindowData();
		const finalData = frame1.windowSetup.windows;
		const updatedJson = await loadDefaultFrame(frameProfileDefault, profileJointDetails, finalData, storeFrameProfile, inititalSpec, storeSpecData);

		// setFrameData(frame1); // This will trigger a re-render
		if (res?.status === 200) {
			if (res?.data?.statusCode === 200) {
				setLoading(false)
				if (res?.data?.entity?.jsonblob !== null) {
					setAddedFrameData(JSON.parse(res?.data?.entity?.jsonblob))
				} else {
					setAddedFrameData(modelData)
				}
				const blocksJson = res.data.entity.blocksJson && res.data.entity.blocksJson !== "undefined" ? JSON?.parse(res.data.entity.blocksJson) : null
				const jsonblob = res.data.entity.jsonblob && res.data.entity.jsonblob !== "undefined" ? JSON?.parse(res.data.entity.jsonblob) : null


				if (blocksJson) {
					setModelJson(blocksJson)
				} else {
					setModelJson(updatedJson)
				}

				// setCustomModelData(jsonblob)

				setDataLoaded(true)
				setTimeout(() => {
					setInitialLoad(true)
					setGotData(true)
				}, 200);
				// setNewModelId('')
				// setNewStyleId('')
			}
		}
	}

	const frameStyleService = async (modelId) => {
		const res = await getFrameStyles(accessToken, modelId)
		if (res && res.status === 200) {
			if (res?.data?.statusCode === 200) {
				setAllStyleCollection(res?.data?.entity)
				setStyleType(res?.data?.entity[0])
			}
		}
		else {
			setAllStyleCollection([])
		}
	}

	useEffect(() => {
		if (multiSelectRefPoints && multiSelectRefPoints?.length && sashProfileType && sashProfileType.name === "Locking Plate") {
			multiSelectRefPoints.forEach((item) => addLockingPlate(item, sashProfileType))
			setToggleHelper(false)
		}
	}, [sashProfileType, toggleHelper, sashList.current])





	const createGlassMaterial = (glassOutline, rotation, leftOffset, topOffset, frameType) => {
		const extrudeSettings = {
			steps: 10,
			depth: 0.01,
			bevelEnabled: false,
		};


		const glassGeometry = new THREE.ExtrudeGeometry(glassOutline, extrudeSettings);

		// Create a material and mesh
		const glassMaterialOld = sceneRef.current.getObjectByProperty('name', "GlassPanel034").material;
		const glassMaterial = new THREE.MeshPhongMaterial({
			color: glassMaterialOld.color,
			transparent: true,
			needsUpdate: true
		});

		glassMaterial.opacity = 0.3;
		const glassMesh = new THREE.Mesh(glassGeometry, glassMaterial);
		glassMesh.name = "svgGlass";

		const glassMeshBoundingBox = new THREE.Box3().setFromObject(glassMesh);
		const depth = glassMeshBoundingBox.max.x - glassMeshBoundingBox.min.x

		glassMesh.scale.x -= leftOffset - 0.01
		glassMesh.scale.y -= topOffset

		glassMesh.position.z -= leftOffset;


		if (frameType === "right") {
			glassMesh.position.x += depth / 2
		} else {
			glassMesh.position.x -= depth / 2
		}


		// Apply rotation if necessary
		if (rotation) {
			glassMesh.rotation.set(rotation.x, -(Math.PI / 2), rotation.z);
		}
		sceneRef.current.add(glassMesh);
	}



	function createGlassOutline(a, b, c, d) {

		const path = new THREE.Shape();
		// Add top frame
		path.moveTo(a.z, a.y);
		path.lineTo(b.z, b.y);
		path.lineTo(c.z, c.y);
		path.lineTo(d.z, d.y)
		path.lineTo(a.z, a.y)


		return path;
	}

	const scaleAddedFrames = (isLeft, isTop) => {
		const topFrame = addedFrame.current.find((item) => item.name.includes("topPerpendicular"))
		const leftFrame = addedFrame.current.find((item) => item.name.includes("rightPerpendicular"))
		const rightFrame = addedFrame.current.find((item) => item.name.includes("leftPerpendicular"))

		if (topFrame) {
			topFrame.traverse((child) => {
				if (child.name.includes("FrameLeft")) {
					const topLeftBB = new THREE.Box3().setFromObject(child);
					perpFrameTopBoundingBox.current.leftProfile = topLeftBB
				}

				if (child.name.includes("FrameRight")) {
					const topRightBB = new THREE.Box3().setFromObject(child);
					perpFrameTopBoundingBox.current.rightProfile = topRightBB
				}
			})
		}

		if (leftFrame && !isLeft) {

			leftFrame.traverse((child) => {

				if (child.name.includes("GlassPanel")) {
					child.scale.set(0, 0, 0)
				}

				if (child.name === "FrameLeft") {
					const profileBoundingBox = new THREE.Box3().setFromObject(child);

					const currentTop = profileBoundingBox.max.y;
					cornersLeftFrame.current.currentBottom = new THREE.Vector3(profileBoundingBox.min.x, profileBoundingBox.min.y, profileBoundingBox.max.z)
					const requiredScale = (topLeftCorner.current.y - profileBoundingBox.min.y) / (currentTop - profileBoundingBox.min.y);
					let initialHeight = profileBoundingBox.min.y;
					child.scale.x *= requiredScale;
					cornersLeftFrame.current.glassScale = requiredScale

					const updatedBoundingBox = new THREE.Box3().setFromObject(child);

					const bottomOffset = initialHeight - updatedBoundingBox.min.y;
					child.position.y += bottomOffset;

					const updatedBoundingBox2 = new THREE.Box3().setFromObject(child);
					cornersLeftFrame.current.frameLeftBoundingBox = updatedBoundingBox2

				}


				if (child.name === "FrameRight") {
					const profileBoundingBox = new THREE.Box3().setFromObject(child);
					cornersLeftFrame.current.frameRightBoundBox = profileBoundingBox
					cornersLeftFrame.current.currentBottomR = new THREE.Vector3(profileBoundingBox.min.x, profileBoundingBox.min.y, profileBoundingBox.max.z)

				}


				if (child.name === "FrameTop") {
					const topProfileBoundingBox = new THREE.Box3().setFromObject(child);
					const topFrameRight = new THREE.Vector3(topProfileBoundingBox.max.x, topProfileBoundingBox.max.y, topProfileBoundingBox.max.z)
					cornersLeftFrame.current.currentTopR = topFrameRight
					const diffX = topLeftCorner.current.x - topFrameRight.x;
					const diffY = topLeftCorner.current.y - topFrameRight.y;

					const angleRadians = Math.atan2(diffX - diffY);
					const angleDegrees = THREE.MathUtils.radToDeg(angleRadians);

					child.rotation.z *= angleDegrees

					const newBoundingBox = new THREE.Box3().setFromObject(child);
					cornersLeftFrame.current.frameTopBoundingBox = newBoundingBox
					const newTopFrameRight = new THREE.Vector3(newBoundingBox.max.x, newBoundingBox.max.y, newBoundingBox.max.z);

					const offsetX = topFrameRight.x - newTopFrameRight.x;
					const offsetY = topFrameRight.y - newTopFrameRight.y;

					child.position.x -= offsetX;
					child.position.y -= offsetY;

				}


				if (child.name === "SpaceBarTop011") {
					child.scale.set(0, 0, 0)
				}


			});



			const leftOffset = cornersLeftFrame.current.frameRightBoundBox.max.x - cornersLeftFrame.current.frameRightBoundBox.min.x
			const topOffset = perpFrameTopBoundingBox.current.leftProfile.max.x - perpFrameTopBoundingBox.current.leftProfile.min.x

			const rotation = new THREE.Vector3(0, 0, 0);
			const glassOutline = createGlassOutline(cornersLeftFrame.current.currentTopR, topLeftCorner.current, cornersLeftFrame.current.currentBottom, cornersLeftFrame.current.currentBottomR)

			createGlassMaterial(glassOutline, rotation, leftOffset, topOffset, "left")
		}

		if (rightFrame) {
			rightFrame.traverse((child) => {
				if (child.name.includes("GlassPanel")) {
					child.scale.set(0, 0, 0)
				}
				if (child.name.includes("FrameLeft")) {
					const profileBoundingBox = new THREE.Box3().setFromObject(child);
					const currentTop = profileBoundingBox.max.y;

					cornersRightFrame.current.frameRightBoundBox = profileBoundingBox

					cornersRightFrame.current.currentBottom = new THREE.Vector3(profileBoundingBox.min.x, profileBoundingBox.min.y, profileBoundingBox.max.z)
					const requiredScale = (topLeftCorner.current.y - profileBoundingBox.min.y) / (currentTop - profileBoundingBox.min.y);
					let initialHeight = profileBoundingBox.min.y;
					child.scale.x *= requiredScale;
					const updatedBoundingBox = new THREE.Box3().setFromObject(child);
					const bottomOffset = initialHeight - updatedBoundingBox.min.y;
					child.position.y += bottomOffset;
				}

				if (child.name === "FrameRight") {
					const profileBoundingBox = new THREE.Box3().setFromObject(child);
					cornersRightFrame.current.currentBottomL = new THREE.Vector3(profileBoundingBox.min.x, profileBoundingBox.min.y, profileBoundingBox.max.z)
				}

				if (child.name === "FrameTop") {
					let topProfileBoundingBoxR = new THREE.Box3().setFromObject(child);
					let topFrameLeftR = new THREE.Vector3(topProfileBoundingBoxR.max.x, topProfileBoundingBoxR.max.y, topProfileBoundingBoxR.max.z)
					cornersRightFrame.current.currentTopL = topFrameLeftR

					let diffXR = topFrameLeftR.x - topRightCorner.current.x
					let diffYR = topFrameLeftR.y - topRightCorner.current.y

					let angleRadiansR = Math.atan2(diffXR - diffYR);
					let angleDegreesR = THREE.MathUtils.radToDeg(angleRadiansR);
					child.rotation.z *= angleDegreesR
				}

				if (child.name === "SpaceBarTop011") {
					child.scale.set(0, 0, 0)
				}
			})

			const leftOffset = cornersRightFrame.current.frameRightBoundBox.max.z - cornersRightFrame.current.frameRightBoundBox.min.z
			const topOffset = perpFrameTopBoundingBox.current.rightProfile.max.x - perpFrameTopBoundingBox.current.rightProfile.min.x

			const rotation = new THREE.Vector3(0, 0, 0);
			const glassOutline = createGlassOutline(cornersRightFrame.current.currentTopL, topRightCorner.current, cornersRightFrame.current.currentBottom, cornersRightFrame.current.currentBottomL)
			createGlassMaterial(glassOutline, rotation, leftOffset, topOffset, "right")

		}


	}

	useEffect(() => {
		if (widthLine.current) {
			widthLine.current.visible = false;
			heightLine.current.visible = false;
		}
	}, [layoutSizing])


	const storeHardware = (element, pos, type) => {
		if (type === "door handle") {
			let cylinder = allHardware?.find((item) => item?.name?.toLowerCase() === "cylinder")
			var indexToRemove = -1;
			indexToRemove = addedHandles.current.findIndex(obj => obj.hasOwnProperty(pos));

			// If the index is found, remove the object at that index
			if (indexToRemove !== -1) {
				addedHandles.current.splice(indexToRemove, 1);
			}
			var finalIndex = 0;
			sashGroup?.current?.forEach((child, index) => {
				if (child == pos) {
					finalIndex = index;
				}
			});

			let data = {
				id: element?.id,
				index: finalIndex,
				file: `${servicePath}/ThreeJSModel/Glb/${element?.modelFilePath}`,
				price: element?.price,
				name: element?.name,
				color: "",
				position: pos.position,
				side: 0,
				horizontalPos: 0,
				verticalPos: 0,
				imagePath: "",
				type: "handle"

			}

			let cylinderData = {
				id: cylinder?.hardwareStyles[0]?.id,
				index: finalIndex,
				file: `${servicePath}/ThreeJSModel/Glb/${cylinder?.hardwareStyles[0]?.modelFilePath}`,
				price: cylinder?.hardwareStyles[0]?.price,
				name: cylinder?.hardwareStyles[0]?.name,
				color: "",
				position: pos.position,
				side: 0,
				horizontalPos: 0,
				verticalPos: 0,
				imagePath: cylinder?.hardwareStyles[0].image,
				type: "cylinder"
			}

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					handle: [
						...(prevModelData.hardware?.handle || []),
						data
					],
					cylinder: [
						...(prevModelData.hardware?.cylinder || []),
						cylinderData
					]
				},
			}));
		}

		if (type === 'bar handles offset') {

			let finalIndex = 0;
			sashGroup.current.forEach((child, index) => {
				if (child.position === pos) {
					finalIndex = index
				}
			})

			let availableIndexesArr = addedBarHandles.current && addedBarHandles?.current?.filter((obj) => obj.position === pos)

			let indexToRemove = -1;

			if (availableIndexesArr?.length > 1 && addedBarHandles?.current?.length) {
				indexToRemove = addedBarHandles?.current?.findIndex((obj) => obj.position === pos)
				addedBarHandles.current.splice(indexToRemove, 1)
			}

			let data = {
				id: 0,
				file: `${servicePath}/ThreeJSModel/Glb/${element?.modelFilePath}`,
				name: element.name,
				color: "",
				type: type,
				index: finalIndex,
				side: 0,
				position: pos,
				horizontalPos: 0,
				verticalPos: 0,
				price: element.price,
				imagePath: "",
				isOutside: availableIndexesArr.length ? false : true
			}

			addedBarHandles.current.push(data)

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					barHandlesOffset: addedBarHandles.current
				}
			}))
		}
	}


	const addLockingPlate = (refs, data) => {

		const gltfLoader = new GLTFLoader()
		//add lock plate
		gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${data?.customePath}`, function (gltf) {
			gltf.scene.position.y = refs.data.position.y;

			const boundingBoxPlate = new THREE.Box3().setFromObject(gltf.scene);
			const plateHeight = boundingBoxPlate.max.y - boundingBoxPlate.min.y;
			const plateWidth = boundingBoxPlate.max.x - boundingBoxPlate.min.x

			const boundingBoxGlass = new THREE.Box3().setFromObject(refs.data);

			if (Math.abs(boundingBoxGlass.min.x) < Math.abs(boundingBoxGlass.max.x)) {
				gltf.scene.position.x = boundingBoxGlass.min.x + (plateWidth / 2)
			} else {
				gltf.scene.position.x = boundingBoxGlass.max.x - (plateWidth / 2)
			}

			const glassHeight = boundingBoxGlass.max.y - boundingBoxGlass.min.y;

			gltf.scene.scale.y = glassHeight / plateHeight;
			allLockingPlates.current.push(gltf.scene)
			setDoorHandleData((prevData) => [...prevData, gltf.scene])

			// applying sash colors for internal and external layers
			gltf.scene.traverse((child) => {
				if (child.isMesh) {
					if (child.name.includes("External")) {
						externalFrameRef.current.push(child);
					} else {
						internalFrameRef.current.push(child);
					}
				}
			})

			setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

			sceneRef.current.add(gltf.scene);
		})
	}

	const sashHardwareMapping = [
		"window handle",
		"bar handles offset",
		"door handle",
		"spyhole"
	]

	const frameProfileHardwareMapping = [
		"trickle vent",
		"spyhole"
	]



	const handleAddHardware = async (element, pos) => {

		if (hardwareType === 'addOn') {
			const side = getSideFromFrameType(pos?.name)
			const updatedJson = addAddOns(side, element, storeAddOnsData, jsonIndex);
			setModelJson((prevModelJson) => {
				const newJson = updatedJson(prevModelJson);
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
				return newJson;
			})
		} else if (sashHardwareMapping.includes(hardwareType?.name) && pos.name.includes("Sash")) {
			handleApplyDoorHandle(element, pos)

		} else if (frameProfileHardwareMapping.includes(hardwareType?.name) && hardwareType.name !== "spyhole") {
			handleApplyMultipleHardware(element, pos)
		} else if (frameProfileHardwareMapping.includes(hardwareType?.name) && pos.name.includes("Frame")) {
			handleApplyHardwareOnFrame(element, pos)
		}

	}




	const handleApplyDoorHandle = async (element, pos) => {
		const { index, model, data, orientation, type } = getHardwareApplyDetails(element?.modelFilePath, pos?.name, hardwareType?.hardwareStyles[0], hardwareReferencePoint, hardwareType?.name)
		let cylinder = allHardware?.find((item) => item?.name?.toLowerCase() === "cylinder")
		let hardwaretype = "";
		console.log("Type", type);

		if (!cylinder) {
			// console.error("Cylinder not found. Proceeding without cylinder.");
			cylinder = { hardwareStyles: [{}] }; // Provide a default or empty object
		}

		if (type === "door handle") {
			hardwaretype = "handle"
		} else {
			hardwaretype = toCamelCase(type)
		}
		const updateJson = await addHardwareOnSash(model, data, hardwareReferencePoint, index, orientation, hardwaretype, saveHardwareData, cylinder.hardwareStyles[0], jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		})
	}


	const handleApplyMultipleHardware = async (element, pos) => {
		const { index, model, data, orientation, type } = getHardwareApplyDetails(element?.modelFilePath, pos?.name, hardwareType?.hardwareStyles[0], hardwareReferencePoint, hardwareType?.name)
		let side
		side = orientation === "FrameTop" ? "top" : orientation === "FrameRight" ? "right" : orientation === "FrameLeft" ? "left" : "bottom";
		let updateJson
		let hardwaretype = ""
		if (type === "door handle") {
			hardwaretype = "handle"
		}
		else {
			hardwaretype = toCamelCase(type)
		}
		if (orientation.includes("Frame")) {
			updateJson = await storeMultipleHardwaresOnProfile(0, side, model, data, hardwaretype, saveHardwareData, jsonIndex)
		} else {
			updateJson = await storeMultipleHardwaresOnProfile(index, orientation, model, data, hardwaretype, saveHardwareData, jsonIndex)
		}

		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		})
	}

	const handleApplyHardwareOnFrame = async (element, pos) => {

		const { index, model, data, orientation, type } = getHardwareApplyDetails(element?.modelFilePath, pos?.name, hardwareType?.hardwareStyles[0], hardwareReferencePoint, hardwareType?.name);

		const side = orientation === "FrameTop" ? "top" : orientation === "FrameRight" ? "right" : orientation === "FrameLeft" ? "left" : "bottom";
		let hardwaretype = ""
		if (type === "door handle") {
			hardwaretype = "handle"
		}
		else {
			hardwaretype = toCamelCase(type)
		}
		const updateJson = await storeHardwareOnFrame(side, model, hardwaretype, index, data, saveHardwareData, jsonIndex)
		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson);
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			return newJson;
		})

	}



	// useEffect(() => {
	// 	// Async function to load data

	// 	fetchData(); // Call fetch function to load data

	// }, [frameData, modelJson]);


	async function fetchData() {
		const frame1 = await loadWindowData();
		setFrameData(frame1); // This will trigger a re-render
		setModelJson(frame1.windowSetup.windows[0])
	}

	async function initializeFrame() {

		createWindowFrame(sceneRef.current, modelJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
		animate();
		setLoading(false)
		setDataLoaded(false)
	}


	// function to initialize model
	async function init() {
		setModelInitialied(false)

		if (scene == null) {
			renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
			renderer.localClippingEnabled = true;
			camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.25, 20);

			scene = new THREE.Scene();
			renderer.localClippingEnabled = true;
		}

		windowWidth.current = 2500;
		windowHeight.current = 2100;
		rendererInitialised.current = 4;
		cameraRef.current = camera;
		const container = containerRef.current;
		sceneRef.current = scene;

		await initRenderer(container);
		setLoading(true);
		loader = new GLTFLoader();
		glassGroup.current = [];
		glazingRef.current = [];
		frameStyle.current = [];
		frameSash.current = [];
		windowRef.current = [];
		frameStyleRef.current = [];
		bead.current = [];
		Frame.current = [];
		transoms.current = [];
		sashGroup.current = [];
		SpaceBar.current = [];
		handleExternal.current = [];
		handleInternal.current = [];
		handleCylinder.current = [];
		handlePosition.current = [];
		handleObjects.current = [];
		tickleObjects.current = [];
		tickleVentPosition.current = [];
		tickleVentModels.current = [];
		cylinderPosition.current = [];
		addedFrame.current = [];
		addedFramePosition.current = [];
		transomFrame.current = [];
		addedSash.current = [];
		glassText.current = [];
		orientationClicked.current = false;
		radioButtonClicked.current = false;

		setUniqueSideRefernce([]);

		await initializeFrame()

		initControls();

		setModelVisible(true)
	}


	function addTextOnGlass(fontSize, textToAdd, index) {
		// Add text on glass
		if (sceneRef.current) {
			removeTextOnGlass(index)
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			context.font = `${fontSize}px sans-serif`;
			context.fillStyle = '#1a1a1a';

			// Measure the text width
			const textWidth = context.measureText(textToAdd).width;

			// Calculate the center coordinates of the canvas
			const centerX = canvas.width / 2;
			const centerY = canvas.height / 2;

			// Calculate the starting position for the text to be centered
			const textX = centerX - (textWidth / 2);
			const textY = centerY + (fontSize / 2) - 10;

			context.fillText(textToAdd, textX, textY); // Text and position

			// Create texture from canvas
			const textureNew = new THREE.CanvasTexture(canvas);

			// Create plane geometry to apply the texture
			const geometry = new THREE.PlaneGeometry(10, 0.5); // Adjust the size as needed
			const materialText = new THREE.MeshBasicMaterial({ map: textureNew, transparent: true, metalness: 1, side: THREE.DoubleSide });


			if (index !== null) {
				textOnSelectedGlass(glazingRef.current[index], materialText, geometry, index, textToAdd)
			} else {
				glazingRef.current.forEach((item, i) => {
					textOnSelectedGlass(item, materialText, geometry, i, textToAdd)
				})
			}
		}
	}

	function textOnSelectedGlass(glazing, materialText, geometry, index, text) {
		const textMesh = new THREE.Mesh(geometry, materialText);

		// Scale the text mesh to fit the glass
		const boundingBox = new THREE.Box3().setFromObject(glazing);
		const center = new THREE.Vector3();
		boundingBox.getCenter(center);

		textMesh.position.set(center.x, center.y, center.z + 0.01);

		const boundingBox2 = new THREE.Box3().setFromObject(textMesh);
		const height = boundingBox.max.y - boundingBox.min.y;
		const width = boundingBox.max.x - boundingBox.min.x;
		const height2 = boundingBox2.max.y - boundingBox2.min.y;
		const width2 = boundingBox2.max.x - boundingBox2.min.x;
		textMesh.scale.set(width / width2, height / height2, 1);

		// Add text mesh to the scene and store a reference
		sceneRef.current.add(textMesh);
		glassText.current.push({ index, text3D: textMesh, text, price: sandblastPricing, name: "Sandblasted numerals" });
		setCustomModelData((prevModelData) => ({
			...prevModelData,
			glazing: {
				...prevModelData.glazing,
				sandblasted: glassText.current
			}
		}))
	}

	function removeTextOnGlass(index) {
		// Add text on glass
		if (sceneRef.current) {
			// Check if the text model already exists and remove it
			if (glassText.current.length > 0) {
				if (index === null) {
					glassText.current.forEach((item) => {
						sceneRef.current.remove(item.text3D);
					})
					glassText.current = []; // Clear the array
				} else {
					glassText.current = glassText.current.filter(textObj => {
						if (textObj.index === index) {
							sceneRef.current.remove(textObj.text3D);
							return false;
						}
						return true;
					});
				}

			}
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				glazing: {
					...prevModelData.glazing,
					sandblasted: glassText.current
				}
			}))
		}
	}



	const removeMasterSlaveElement = (currIndex) => {
		// masterSlaveElements.current.forEach((item) => {
		// 	sceneRef.current.remove(item)
		// })

		const removeMasterSlave = masterSlaveElements.current.find((item, index) => index === currIndex)
		if (removeMasterSlave) {
			removeMasterSlave.scale.x = 0
			removeMasterSlave.scale.y = 0
			removeMasterSlave.scale.z = 0

			sceneRef.current.remove(removeMasterSlave)
		}
		if (currIndex > -1) {
			masterSlaveElements.current = masterSlaveElements.current.filter((item, index) => index !== currIndex)

		}

		const removeWindowHanging = windowHangingSash.current.find((item, index) => index === currIndex)
		if (removeWindowHanging) {
			sceneRef.current.remove(removeWindowHanging)
		}

		if (currIndex > -1) {
			windowHangingSash.current = windowHangingSash.current.filter((item, index) => index !== currIndex)
		}

		if (glassGroup.current[0]) {
			glassGroup.current[0].visible = true
		}
		removeHinge();
	}

	const removeHinge = () => {
		hingeModels.current.forEach((item) => {
			sceneRef.current.remove(item)
		})
		hingeModels.current = [];
	}


	const beadSize = () => {
		bead.current = []
		sceneRef.current.traverse((child) => {
			if (child.name.includes("Bead")) {
				bead.current.push(child)
				beadDefaultScale = beadDefaultScale === null ? child.scale : beadDefaultScale
			}
		})
		bead.current.forEach((item) => {
			if (beadData.current) {
				item.scale.y = (beadData.current.y / 20) * beadDefaultScale.y;
				item.scale.z = (beadData.current.z / 20) * beadDefaultScale.z;
			}
		})

	}


	function hangingMasterSlave(transomFrameIndex) {

		let mullion = initialProfile?.floatingMullion[0]
		removeSashHandles(transomFrameIndex)
		setMasterSlave(true)
		var group = [];
		var baseFrame

		if (!(transoms.current.length > 0)) {
			transomFrameIndex = null;
		}

		if (transomFrameIndex == null) {
			baseFrame = glassGroup.current[0];
		} else {
			baseFrame = transomFrame.current[transomFrameIndex];

		}

		let glassClone = baseFrame.clone()
		baseFrame.visible = false
		group.push(glassClone);

		const boundingBox = new THREE.Box3().setFromObject(baseFrame);
		const width = boundingBox.max.x - boundingBox.min.x;
		const height = boundingBox.max.y - boundingBox.min.y;
		let isMax = false
		sceneRef.current.traverse((item) => {
			if (item != null && item.name != null) {
				if (item.name.includes("Bar")) {

					const newWorldPosition = new THREE.Vector3();
					item.getWorldPosition(newWorldPosition);

					const newScale = new THREE.Vector3();
					item.getWorldScale(newScale);

					const newWorldRotation = new THREE.Quaternion();
					item.getWorldQuaternion(newWorldRotation);

					group.push(item);

					const newWorldPositionBead = new THREE.Vector3(newWorldPosition.x, newWorldPosition.y, newWorldPosition.z);

					item.position.copy(newWorldPositionBead);
					item.scale.copy(newScale);
					item.quaternion.copy(newWorldRotation);
					//todo : fix the condition for vertical sash bars!
					if (item.name.includes('vertical')) {
						//	const boundingBox2 = new THREE.Box3().setFromObject(item);
						//	const width2 = boundingBox2.max.x - boundingBox2.min.x;
						//	const height2 = boundingBox2.max.y - boundingBox2.min.y;
						let hingeModel = allHardware?.find((item) => item?.name?.toLowerCase() === "hinge")
						item.scale.y *= 2;
						if (hingeModel) {
							addHinge(item, isMax, hingeModel)
						}
						isMax = !isMax
						setHingeRefPoints((prev) => [...prev, item])

					}
				}
			}
		})

		var groupThree = new THREE.Group();

		group.forEach((child, index) => {
			groupThree.add(child);
		});

		const gltfLoader = new GLTFLoader();
		gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${mullion?.customePath}`, function (gltf) {

			masterSlaveElements.current.push({ ...gltf.scene, index: transomFrameIndex })

			gltf.scene.name = 'window_mullion'

			sceneRef.current.add(gltf.scene);

			gltf.scene.traverse((child) => {
				if (child.name.includes("Internal")) {
					internalFrameRef.current.push(child);
				}

				if (child.name.includes("External")) {
					externalFrameRef.current.push(child);
				}
			})

			var base;
			gltf.scene.traverse((child) => {

				if (child.name.includes("Base")) {
					base = child;

					if (mullion?.width && mullion?.height) {
						child.scale.z = customModelData?.floatingMullion ? customModelData?.floatingMullion?.width / 70 : mullion?.width / 70
						child.scale.y = customModelData?.floatingMullion ? customModelData?.floatingMullion?.height / 70 : mullion?.height / 70
					} else {
						child.scale.z = 1
						child.scale.y = 1
					}

				}
			})

			const baseBoundingBox = new THREE.Box3().setFromObject(
				base
			);

			gltf.scene.traverse((child) => {

				if (child.name.includes("Front")) {
					//	child.scale.y = transomData.current.widthScale
					const childBoundingBox = new THREE.Box3().setFromObject(
						child
					);
					child.position.z = baseBoundingBox.min.z;
				}
			})


			setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

			gltf.scene.position.z = 0;
			gltf.scene.position.y = baseFrame.position.y;
			gltf.scene.position.x = baseFrame.position.x

			const boundingBox3 = new THREE.Box3().setFromObject(groupThree);
			const width3 = boundingBox3.max.x - boundingBox3.min.x;

			const boundingBox4 = new THREE.Box3().setFromObject(gltf.scene);
			const width4 = boundingBox4.max.x - boundingBox4.min.x;
			const height4 = boundingBox4.max.y - boundingBox4.min.y;

			const scaleRatio = ((width3 - (width4 / 2)) / width3);

			const boundingBox5 = new THREE.Box3().setFromObject(baseFrame);
			const height = boundingBox5.max.y - boundingBox5.min.y;

			gltf.scene.scale.y = height / height4;

			var n = 1;

			for (let i = -n / 2; i <= n / 2; i++) {
				const clone = groupThree.clone();
				clone.scale.x = scaleRatio / 2;
				clone.position.x = (i * (width + (width4 / 2)) / (n + 1)) + baseFrame.position.x;
				clone.visible = true;
				// clone.position.y = baseFrame.position.y;


				clone.traverse((child) => {
					if (child.name.includes("Internal")) {
						internalFrameRef.current.push(child);
					}

					if (child.name.includes("External")) {
						externalFrameRef.current.push(child);
					}

				})

				if (transomFrameIndex !== null) {
					const cloneBoundingBox = new THREE.Box3().setFromObject(clone)
					let cloneWidth = cloneBoundingBox?.max?.x - cloneBoundingBox?.min?.x
					cloneWidth.position.x = gltf?.scene?.position?.x + (i * cloneWidth)
				}

				windowHangingSash.current.push(clone)


				setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

				sceneRef.current.add(clone);

				const redTransparentMaterial = new THREE.MeshBasicMaterial({
					color: "#0000FF",
					transparent: true,
					opacity: 0.3, // 50% transparency
				});
				sceneRef.current.traverse((child) => {
					if (child.name.includes('Glass')) {
						child.material = redTransparentMaterial
						if (!glazingRef.current.includes(child)) {
							glazingRef.current.push(child)
						}
					}
				})
				setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipGlaze)
			}

			//set min z aligned with frame min
			const boundingBox6 = new THREE.Box3().setFromObject(addFrameRef.current[0]);
			const boundingBox7 = new THREE.Box3().setFromObject(gltf.scene);
			if (boundingBox7.max.z > boundingBox6.max.z) {
				const newWidth = (boundingBox7.max.z - boundingBox7.min.z) / 2;
				gltf.scene.position.z = boundingBox6.max.z - newWidth;

			}

			let mullionObj = {
				id: mullion?.id,
				model: mullion?.customePath,
				imagePath: mullion?.imagePath,
				name: mullion?.name,
				price: mullion?.price,
				height: mullion?.height,
				width: mullion?.width,
				profileTypePrice: mullion?.profileTypePrice,
				internalPaintSurfaceArea: mullion?.internalPaintSurfaceArea,
				externalPaintSurfaceArea: mullion?.externalPaintSurfaceArea,
				frameType: "Mullion"
			}

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				floatingMullion: mullionObj
			}))

			addWindowHandleOnSashMaster(transomFrameIndex)

			setIsHangingLoaded(false)
			setCheckSingleSide()
		});
	}


	function addWindowHandleOnSash(orientation, index) {
		try {

			let handleModel = allHardware?.find((item) => (item?.name?.toLowerCase() === "window handle" || item?.name?.toLowerCase() === "door handle"))
			let hingeModel = allHardware.find((item) => item?.name?.toLowerCase() === 'hinge')

			removeSashHandles(index)
			windowHandleRefPoint.current = []

			if (hingeModel) {
				sceneRef?.current?.traverse((child) => {
					if (child?.name?.includes('verticalBarLeft') && orientation?.toLowerCase() === 'left') {
						addHinge(child, false, hingeModel)
					} else if (child?.name?.includes('verticalBarRight') && orientation?.toLowerCase() === 'right') {
						addHinge(child, true, hingeModel)
					}
				})
			}

			var handleRotation = Math.PI

			if (orientation != 'Top') {
				handleRotation = - (Math.PI / 180) * 90
			}

			if (orientation == 'top') {
				handleRotation = (Math.PI / 180) * 360
			}

			if (orientation?.toLowerCase() === 'right') {
				handleRotation = Math.PI * 2
			}


			if (orientation?.toLowerCase() === 'left' && handleModel?.hardwareStyles[0]?.name !== "Espag Cranked") {
				handleRotation = Math.PI * 2
			} else if ((orientation?.toLowerCase() === 'left' || orientation?.toLowerCase() === 'right') && handleModel?.hardwareStyles[0]?.name === "Espag Cranked") {
				handleRotation = -(Math.PI / 2)
			}

			if (orientation == 'Bottom' || orientation == 'Reversible') {
				handleRotation = (Math.PI / 180) * 360
			}

			var targetElement;
			const orientationMap = {
				"left": "verticalBarRight",
				"right": "verticalBarLeft",
				"stable left": "horizontalBarBottom",
				"reversible": "horizontalBarBottom",
				"bottom": "horizontalBarTop",
				"top": "horizontalBarBottom"
			};


			const targetKey = orientation.toLowerCase();
			if (orientationMap[targetKey]) {
				sashGroup?.current[index]?.traverse((item) => {
					if (item.name.includes(orientationMap[targetKey])) {
						targetElement = item;
					}
				});
			}


			// if (orientation == "top") {
			// 	sceneRef?.current?.traverse((item) => {
			// 		if (item.name.includes("horizontalBarBottom")) {
			// 			targetElement = item;
			// 		}
			// 	})
			// }


			if (targetElement == null) {
				return;
			}

			if (orientation != 'Fixed') {
				const boundingBoxBead = new THREE.Box3().setFromObject(targetElement);

				const beadWidth = boundingBoxBead.max.z - boundingBoxBead.min.z;

				const gltfLoader = new GLTFLoader();

				if (handleRotation && headerSelectedItem?.name !== "Heritage Door") {

					gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${handleModel?.hardwareStyles[0]?.modelFilePath}`, function (gltf) {

						const boundingBoxHandle = new THREE.Box3().setFromObject(gltf.scene);
						const handleWidth = boundingBoxHandle.max.z - boundingBoxHandle.min.z;

						const newWorldPosition = new THREE.Vector3();
						const handleOpposite = gltf.scene.clone()

						targetElement.getWorldPosition(newWorldPosition);
						let xCoordinatePos = orientation?.toLowerCase() === 'left' ? newWorldPosition.x - 0.013 : newWorldPosition.x + 0.020
						// windowHangingBox.max.x + 0.045, 0, (windowHangingBox.min.z - (handleWidth / 2)) + 0.05

						const newWorldPositionBead = new THREE.Vector3(xCoordinatePos, transomFrame?.current[checkSingleSide]?.position?.y, (boundingBoxBead.min.z - (handleWidth / 2)) + 0.12);
						// gltf.scene.position.z = -1

						gltf.scene.position.copy(newWorldPositionBead);
						handleOpposite.position.x = xCoordinatePos
						handleOpposite.position.y = transomFrame?.current[checkSingleSide]?.position?.y
						handleOpposite.scale.z = -2
						handleOpposite.position.z -= 0.035
						// gltf.scene.rotation.x = -Math.PI / 2;
						gltf.scene.rotation.z = handleRotation;
						handleOpposite.rotation.z = handleRotation
						gltf.scene.rotation.x = Math.PI * 2;
						// handleOpposite.rotation.z = -(Math.PI * 2)

						// handleOpposite.rotation.z = handleRotation
						gltf.scene.name = 'window_handle';
						sashHandles.current.push({ ...gltf.scene, index })
						sceneRef.current.add(gltf.scene);

						if (handleModel?.hardwareStyles[0]?.name != "Slim Security Flip Lock") {
							sashHandles.current.push({ ...handleOpposite, index })
							sceneRef.current.add(handleOpposite)
						}


						if (orientation == 'Top') {
							windowHandleRefPoint.current.push(targetElement)
						}
					});
					setCheckSingleSide()
				}
			}

			setWindowHandleOrientation('')

		} catch (error) {
			console.error(error);
		}
	}


	const removeSash = (selectedIndex, data) => {
		addedSash.current.forEach(obj => {
			if (obj.index === selectedIndex) {
				obj.scene.visible = false
				obj.scene.scale.set(0, 0, 0)
				setDeletePressed(false)
			}
		});

		setCustomModelData((prevModelData) => ({
			...prevModelData,
			sashData: prevModelData.sashData.map((item) => {
				if (selectedIndex === item?.index) {
					return {
						...item,
						visible: false
					}
				}
				return item;
			})
		}))
	}

	const removeSashHandles = (index) => {
		if (sashHandles.current.length) {
			let handles = sashHandles.current.find((child) => child.index === index)
			if (handles) {
				handles.visible = false
				handles.scale.x = 0
				handles.scale.y = 0
				handles.scale.z = 0
				sceneRef.current.remove(handles)
			}

			sashHandles.current = sashHandles.current.filter((child) => child.index !== index)
		}
	}


	function addWindowHandleOnSashMaster(index) {
		let handleModel = allHardware?.find((item) => (item?.name?.toLowerCase() === "window handle" || item?.name?.toLowerCase() === "door handle"))

		windowHandleRefPoint.current = []
		removeSashHandles(index)

		var handleRotation = - (Math.PI / 180) * 90

		// removing old handles
		// if (sceneRef.current) {
		// 	var handleLocal
		// 	sceneRef.current.traverse(function (object) {
		// 		if (object.name.includes("window_handle")) {
		// 			object.visible = false;
		// 			handleLocal = object
		// 		}
		// 	});

		// 	if (handleLocal) {
		// 		sceneRef.current.remove(handleLocal);
		// 	}
		// }

		const gltfLoader = new GLTFLoader();

		if (handleRotation && headerSelectedItem?.name !== "Heritage Door" && handleModel?.hardwareStyles[0]) {
			gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${handleModel?.hardwareStyles[0]?.modelFilePath}`, function (gltf) {

				isHeritageDoor.current = true;
				// handle width
				const boundingBoxHandle = new THREE.Box3().setFromObject(gltf.scene);
				const handleWidth = boundingBoxHandle.max.z - boundingBoxHandle.min.z;

				let windowHangingBox;
				let windowHangingBox2;

				if (index) {
					windowHangingBox = new THREE.Box3().setFromObject(windowHangingSash?.current[index]);
					windowHangingBox2 = new THREE.Box3().setFromObject(windowHangingSash?.current[index]);
				} else {
					windowHangingBox = new THREE.Box3().setFromObject(windowHangingSash?.current[0]);
					windowHangingBox2 = new THREE.Box3().setFromObject(windowHangingSash?.current[0]);
				}

				gltf.scene.scale.set(1, 1, 1);
				var handle = gltf.scene.clone();

				// defining position for 1st handle and adding in scene
				const handlePosition1 = new THREE.Vector3(windowHangingBox.max.x + 0.045, transomFrame?.current[checkSingleSide]?.position?.y, (windowHangingBox.min.z - (handleWidth / 2)) + 0.08);
				gltf.scene.position.copy(handlePosition1);


				// gltf.scene.rotation.y = Math.PI;
				// gltf.scene.rotation.z = handleRotation;
				gltf.scene.name = 'window_handle';


				const handlePosition2 = new THREE.Vector3(windowHangingBox2.max.x - 0.025, transomFrame?.current[checkSingleSide]?.position?.y, (windowHangingBox.min.z - (handleWidth / 2)) + 0.08);
				handle.position.copy(handlePosition2);
				handle.scale.x = -1


				var handle2 = gltf.scene.clone()
				handle2.scale.z = -1
				handle2.position.z = -handle2.position.z
				// handle2.position.x += 0.013

				handle2.rotation.y = Math.PI * 2

				var handle3 = handle.clone()
				handle3.scale.z = -1
				handle3.position.z = -handle3.position.z
				// handle3.position.x -= 0.015
				handle.name = 'window_handle';


				sashHandles.current.push(handle3)
				sceneRef.current.add(handle3)

				sashHandles.current.push(handle)
				sceneRef.current.add(handle);

				sashHandles.current.push(gltf.scene)
				sceneRef.current.add(gltf.scene);

				sashHandles.current.push(handle2)
				sceneRef.current.add(handle2)
			});
		}

		// setIsWindowHaning(() => ({
		// 	isHangingAdded: true,
		// 	hangingType: orientation,
		// 	handleDirection: 'right',
		// }))

		// setCustomModelData(prevData => ({
		// 	...prevData,
		// 	windowData: isWindowHaning,
		// }));
	}

	async function changeFrameWidthData(width, height, newSashSize) {
		// modelJson.dimensions.width = width / 1000;
		// modelJson.dimensions.height = height / 1000

		const newWidth = width / 1000;
		const newHeight = height / 1000;

		const updateJson = updateFrameDimension(newWidth, newHeight, jsonIndex, newSashSize)

		setModelJson((prevModelJson) => {
			const newJson = updateJson(prevModelJson)
			createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef);
			return newJson;
		})
		// blockWindowReload();
	}

	async function resizeWindow(width, height, modelType, initLoad) {
		setLoading(true)
		setModelVisible(false)
		setUiLinesLocation('')
		// setResizeSashStyles(false)

		let newSashSize = []

		if (customModelData && customModelData?.numberOfSash?.number > 0) {
			newSashSize = updateSashSize(customModelData?.sashSize, customModelData?.layoutFrame?.width, width, customModelData?.numberOfSash?.number ? customModelData?.numberOfSash?.number : 1, lockRefIndex)
		} else {
			newSashSize = [modelWidth]
		}

		setCustomModelData((prevModelData) => ({
			...prevModelData,
			layoutFrame: {
				...prevModelData.layoutFrame,
				width: parseFloat(width),
				height: parseFloat(height),
			},
			sashSize: newSashSize,
		}));

		await changeFrameWidthData(width, height, newSashSize);

		setModelVisible(true)
		setLoading(false)

		setTimeout(() => {
			setUiLinesLocation("All")
			// setResizeSashStyles(true)
		}, 100);
	}


	useEffect(() => {
		if (applyPartialTrigger && sashGroup.current && sashGroup?.current?.length > 0) {

			setRefreshUiLines(false)

			const savedData = {
				width: customModelData?.layoutFrame?.width,
				height: customModelData?.layoutFrame?.height,
				count: customModelData?.numberOfSash?.number,
				xScale: (customModelData?.sashSize && customModelData?.sashSize.length > 0) ? customModelData?.xScale : [1],
				sashSize: (customModelData?.sashSize && customModelData?.sashSize.length > 0) ? customModelData?.sashSize : [customModelData?.layoutFrame?.width]
			}

			calculatePartialWidths(sashGroup, applyPartialObj, savedData, setApplyPartialTrigger, setSavePartialWidths, setCustomModelData, lockRefIndex, setResizeSashStyles, wide, high, setRefreshUiLines);
		}
	}, [applyPartialTrigger]);

	useEffect(() => {
		if (savePartialWidths && customModelData) {
			setSavePartialWidths(false)
			// setRefreshAfterSave(true)

			if (quotationId && receivedProductId) {
				const res = setQuotationModelData(accessToken, modelId, selectedAnimation?.id, customModelData, quotationId, receivedProductId, modelJson)
			} else {
				let tempModelId = newModelId ? newModelId : modelId;
				let tempStyleId = newStyleId ? newStyleId : selectedAnimation?.id
				const res = setModelData(accessToken, tempModelId, tempStyleId, customModelData, modelJson)
			}
		}
	}, [savePartialWidths]);

	useEffect(() => {
		if (resizeSashStyles) {
			setResizeSashStyles(false)

			const updatedJson = handlePartialWidthChange(customModelData.sashSize, jsonIndex)

			setModelJson((prevModelJson) => {
				const newJson = updatedJson(prevModelJson);
				createWindowFrame(sceneRef.current, newJson, "group1", sashGroup, null, null, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
				return newJson;
			});

			setTimeout(() => {
				getSashRef()
				setMultiSelectRefPoints([])
			}, 200);
		}
	}, [resizeSashStyles])

	const removeGrooveElements = () => {
		if (storeGrooveCylinder.current.length) {
			storeGrooveCylinder.current.forEach((item) => {
				sceneRef.current.remove(item)
			})
		}

		if (storeGroveCube.current.length) {
			storeGroveCube.current.forEach((item) => {
				sceneRef.current.remove(item)
			})
		}
	}


	function addSlideAndTurnGrooves(storeData) {
		removeGrooveElements()
		let doorTopHardware;

		let cylinderStore = []
		allHardware.forEach((item) => {
			doorTopHardware = item.hardwareStyles.find((hardware) => hardware.name.includes("Slide and Turn Hardware"))
		})

		sashGroup.current.forEach((child, index) => {
			const gltfLoader = new GLTFLoader();
			if (doorTopHardware) {
				gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${doorTopHardware.modelFilePath}`, function (gltf) {
					const boundingBox = new THREE.Box3().setFromObject(child);
					const cube = gltf.scene;
					sceneRef.current.add(cube);
					cube.position.y = boundingBox.max.y - 0.01;
					cube.position.x = boundingBox.min.x + 0.2;
					storeGrooveCylinder.current.push(cube)

					if (index == sashGroup?.current?.length - 1) {
						const geometry = new THREE.BoxGeometry(0.05, 0.04, 0.05);
						const material = new THREE.MeshBasicMaterial({ color: customModelData?.frame?.externalColor?.hex, metalness: 0.9, roughness: 0.2 });
						const cube2 = new THREE.Mesh(geometry, material);
						cube2.position.y = boundingBox.max.y - 0.005;
						cube2.position.x = boundingBox.min.x + 0.2;
						cube2.position.z = 0.04;
						sceneRef.current.add(cube2);
						storeGroveCube.current.push(cube2)

					}

					if (!storeData) {
						cylinderStore.push(doorTopHardware)
						setCustomModelData((prevModelData) => ({
							...prevModelData,
							doorTopHardware: cylinderStore
						}))
					}

				});
			}
		})
	}

	function deleteSashGroup() {
		sashGroup.current.forEach((child) => {
			sceneRef.current.remove(child);
		})

		sashGroup.current = [];
		setDeleteSashHanging(false)
	}

	function updateSashOnPart(sashModel, glassPartIndex, sashHangingNumber) {
		//load sash
		const gltfLoader = new GLTFLoader();
		gltfLoader.load(sashModel, function (gltf) {


			var topSash = gltf.scene;

			//add sash on all 4 sides of glass!
			const boundingBox = new THREE.Box3().setFromObject(transomFrame.current[glassPartIndex]);
			const widthX = boundingBox.max.x - boundingBox.min.x;
			const widthY = boundingBox.max.y - boundingBox.min.y;

			const boundingBox2 = new THREE.Box3().setFromObject(topSash);
			const sashWidthY = boundingBox2.max.y - boundingBox2.min.y;
			const sashWidthX = boundingBox2.max.x - boundingBox2.min.x;

			topSash.position.y = boundingBox.max.y - (sashWidthY / 2);
			topSash.scale.x = widthX / sashWidthX;

			var bottomSash = topSash.clone();
			bottomSash.position.y = boundingBox.min.y + (sashWidthY / 2);

			var leftSash = topSash.clone();
			leftSash.rotation.z = Math.PI / 2;
			leftSash.scale.x = widthY / sashWidthX;
			leftSash.position.x = boundingBox.min.x + (sashWidthY / 2);
			leftSash.position.y = transomFrame.current[glassPartIndex].position.y;

			var rightSash = leftSash.clone();
			rightSash.position.x = boundingBox.max.x - (sashWidthY / 2);


			sceneRef.current.add(topSash);
			sceneRef.current.add(bottomSash);
			sceneRef.current.add(leftSash);
			sceneRef.current.add(rightSash);
		})

	}





	const addThreshold = (model) => {
		//make bottom frame inactive
		var frameTop, base;

		if (!model) {
			return
		}

		Frame.current.forEach((item) => {
			if (item.name === "FrameBottom") {
				item.traverse((child) => {
					child.visible = false;
					//		child.scale.set(0,0,0);
				})
				item.visible = false;
				item.scale.set(0, 0, 0);
			}

			if (item.name === "FrameTop") {
				frameTop = item;
			}
		})

		//add threshold
		const gltfLoader = new GLTFLoader();
		gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${model?.customePath}`, function (gltf) {
			sceneRef.current.add(gltf.scene);
			const thresholdModel = gltf.scene;
			const boundingBox = new THREE.Box3().setFromObject(thresholdModel);
			const widthX = boundingBox.max.x - boundingBox.min.x;

			const boundingBoxGlass = new THREE.Box3().setFromObject(glassGroup.current[0]);
			const widthXGlass = boundingBoxGlass.max.x - boundingBoxGlass.min.x;

			thresholdModel.scale.x = widthXGlass / widthX;

			thresholdModel.traverse((item) => {
				if (item.name.includes("Base")) {

					//use thresholdData.current here
					item.scale.y = model.width / 70;
					item.scale.z = model.height / 70;
					base = item;
				}
			})

			thresholdModel.traverse((item) => {
				if (item.name.includes("Front")) {
					const boundingBox2 = new THREE.Box3().setFromObject(base);
					const boundingBox3 = new THREE.Box3().setFromObject(item);
					const itemWidth = boundingBox3.max.z - boundingBox3.min.z;
					const itemHeight = boundingBox3.max.y - boundingBox3.min.y;
					item.position.y = boundingBox2.max.y + itemHeight / 2;
					item.position.z = boundingBox2.min.z + itemWidth / 2;
				}
			})


			//set min z aligned with frame min
			const boundingBox4 = new THREE.Box3().setFromObject(frameTop);
			const boundingBox5 = new THREE.Box3().setFromObject(gltf.scene);
			if (boundingBox5.max.z > boundingBox4.max.z) {
				const newWidth = (boundingBox5.max.z - boundingBox5.min.z) / 2;
				gltf.scene.position.z = -(boundingBox4.max.z - newWidth);

			}

			const thresholdHeight = boundingBox5.max.y - boundingBox5.min.y;

			thresholdModel.position.y = boundingBoxGlass.min.y - thresholdHeight / 2;
		})

		//resize a/c profile size
	}



	const getSashRef = () => {
		testRef.current = getSashHangingRefs()
		forceUpdate()
	}




	function getSashFrameRef() {
		testRef.current = getSasProfilehRefs()
	}


	function getSashList() {
		sashList.current = [];
		sashGroup.current.forEach((child) => {
			child.traverse((item) => {
				if (item?.name.includes("verticalBar") || item?.name.includes("horizontalBar")) {
					sashList.current.push(item);
				}
			})
		})

		// setApplySashInit(true)
	}

	function setSashGroupAlignment(sashIndex, alignIndex) {
		if (sashGroup.current[sashIndex]?.position) {
			if (alignIndex == 1) {
				sashGroup.current[sashIndex].position.z = 0.02;
			}
			if (alignIndex == 2) {
				sashGroup.current[sashIndex].position.z = 0;
			}
			if (alignIndex == 0) {
				sashGroup.current[sashIndex].position.z = -0.02;
			}
		}
	}

	function addHinge(sash, isMax, hingeModel) {

		let model = hingeModel?.hardwareStyles?.length ? hingeModel?.hardwareStyles[0]?.modelFilePath : hingeModel?.modelFilePath

		const gltfLoader = new GLTFLoader();
		gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${model}`, function (gltf) {
			var hinge = gltf.scene
			hinge.scale.x = 3;
			hinge.scale.y = 3;
			hinge.scale.z = 3;

			const boundingBox = new THREE.Box3().setFromObject(sash);

			if (isMax) {
				hinge.position.x = boundingBox.max.x;
			} else {
				hinge.position.x = boundingBox.min.x + 0.05;
			}
			hinge.position.z = boundingBox.max.z;
			hinge.rotation.y = Math.PI

			var clone = hinge.clone()
			clone.position.y = boundingBox.max.y - 0.2;

			var clone2 = hinge.clone()
			clone2.position.y = boundingBox.min.y + 0.2;
			hingeModels.current.push(hinge);
			hingeModels.current.push(clone);
			hingeModels.current.push(clone2);

			hingePosition.current.push(new THREE.Vector3(hinge.position.x, hinge.position.y, hinge.position.z))
			hingePosition.current.push(new THREE.Vector3(clone.position.x, clone.position.y, clone.position.z))
			hingePosition.current.push(new THREE.Vector3(clone2.position.x, clone2.position.y, clone2.position.z))

			const allHinges = hingeModels.current.map((item, index) => {
				return {
					id: hingeModel?.hardwareStyles?.length ? hingeModel?.hardwareStyles[0]?.id : hingeModel?.id,
					hexValue: customModelData?.frame?.externalColor?.hex,
					color: customModelData?.frame?.externalColor?.name,
					hingeModel: hingeModel?.hardwareStyles?.length ? hingeModel?.hardwareStyles[0]?.modelFilePath : hingeModel.modelFilePath,
					name: hingeModel?.hardwareStyles?.length ? hingeModel?.hardwareStyles[0]?.name : hingeModel?.name,
					type: "hinge",
					verticalPos: 0,
					horizontalPos: 0,
					price: hingeModel?.hardwareStyles?.length ? hingeModel?.hardwareStyles[0]?.price : hingeModel?.price,
				}
			})

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					hingeData: allHinges
				}
			}))


			sceneRef.current.add(hinge)
			sceneRef.current.add(clone)
			sceneRef.current.add(clone2)
			// hingesRef.current.push(hinge)
		});
	}


	function setHandleCylinderColor(currentIndex, hex) {
		//create material
		const mat = new THREE.MeshBasicMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		handleCylinder.current.forEach((cylinder, index) => {
			if (index == currentIndex * 2 || index == (currentIndex * 2) + 1) {
				//set cylinder material
				cylinder.material = mat;
			}
		});
	}

	function setHandleCylinderPosition(horizontal, units, currentIndex) {
		units = units * 0.01;
		handleCylinder?.current?.forEach((cylinder, index) => {
			if (index == currentIndex * 2 || index == (currentIndex * 2) + 1) {
				//move horizontally
				if (horizontal) {
					cylinder.position.x = cylinderPosition?.current[index]?.x + units;
				} else //move vertically
				{
					cylinder.position.y = cylinderPosition?.current[index]?.y + units;
				}
			}
		});
	}

	function setTrickleVentColor(currentIndex, hex) {
		//create material
		const mat = new THREE.MeshStandardMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		tickleVentModels.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.children[0].material = mat;
			}
		});

	}

	function setTrickleVentPosition(horizontal, currentIndex, units) {
		units = units * 0.01;
		tickleVentModels.current.forEach((handle, index) => {
			if (index == currentIndex) {
				if (horizontal) {
					if (handle && tickleVentPosition)
						handle.position.x = tickleVentPosition.current[index].x + units;
				} else {
					if (handle && handlePosition)
						handle.position.y = tickleVentPosition.current[index].y + units;
				}
			}
		});

	}

	const setHardwareColor = (hex, elementModels) => {
		const mat = new THREE.MeshStandardMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		elementModels.current.forEach((spyHole, index) => {
			spyHole.children[0].material = mat;
		})
	}

	const setSpyHoleColor = (hex) => {
		setHardwareColor(hex, spyHoleModel)
	}

	const setLetterPlateColor = (hex) => {
		setHardwareColor(hex, letterPlateModel)
	}

	const setKnockerColors = (hex) => {
		setHardwareColor(hex, knockerModel)
	}

	function setPositionForElement(elementModels, elementPositions, horizontal, units) {
		units *= 0.01;
		elementModels.current.forEach((element, index) => {
			if (element) {
				if (horizontal) {
					if (elementPositions.current)
						element.position.x = elementPositions.current[index].x + units;
				} else {
					if (elementPositions.current)
						element.position.y = elementPositions.current[index].y + units;
				}
			}
		});
	}


	function setTextPosition(horizontal, units) {
		units *= 0.01;
		if (textMeshRef.current) {
			if (horizontal) {
				if (textMeshPos.current)
					textMeshRef.current.position.x = textMeshPos.current.x + units;
			} else {
				if (textMeshPos.current) {
					textMeshRef.current.position.y = textMeshPos.current.y + units
				}
			}
		}
	}

	function setSpyHolePosition(horizontal, units) {
		setPositionForElement(spyHoleModel, spyHolePosition, horizontal, units);
	}

	function setLetterPlatePosition(horizontal, units) {
		setPositionForElement(letterPlateModel, letterPlatePos, horizontal, units);
	}

	function setKnockerPosition(horizontal, units) {
		setPositionForElement(knockerModel, knockerPos, horizontal, units);
	}

	// function setSpyHolePosition(horizontal, units) {
	// 	
	// 	units = units * 0.01;
	// 	spyHoleModel.current.forEach((spyHole, index) => {
	// 		if (horizontal) {
	// 			if (spyHole && tickleVentPosition)
	// 				spyHole.position.x = spyHolePosition.current[index].x + units;
	// 		} else {
	// 			if (spyHole && handlePosition)
	// 				spyHole.position.y = spyHolePosition.current[index].y + units;
	// 		}

	// 	});
	// }

	// function setLetterPlatePosition(horizontal, units) {
	// 	
	// 	units = units * 0.01;
	// 	letterPlateModel.current.forEach((letterPlate, index) => {
	// 		if (horizontal) {
	// 			if (letterPlate && letterPlatePos)
	// 				letterPlate.position.x = letterPlatePos.current[index].x + units;
	// 		} else {
	// 			if (letterPlate && letterPlatePos)
	// 				letterPlate.position.y = letterPlatePos.current[index].y + units;
	// 		}

	// 	});
	// }


	// function deleteSpyHole(index) {
	// 	const newArray = [
	// 		...customModelData?.hardware?.spyhole?.slice(0, index),
	// 		...customModelData?.hardware?.spyhole?.slice(index + 1),
	// 	];
	// 	if (index >= 0 && index < spyHoleModel.current.length) {
	// 		spyHoleModel.current[index].visible = false;
	// 		sceneRef.current.remove(spyHoleModel.current[index]);
	// 		spyHoleModel.current.splice(index, 1);
	// 		spyHolePosition.current.splice(index, 1);
	// 	}
	// 	setCustomModelData((prevModelData) => ({
	// 		...prevModelData,
	// 		hardware: {
	// 			...prevModelData.hardware,
	// 			spyHole: newArray,
	// 		},
	// 	}));
	// }

	function deleteElement(type, index) {
		let newArray = [];
		switch (type) {
			case 'spyhole':
				newArray = [
					...customModelData?.hardware?.spyhole?.slice(0, index),
					...customModelData?.hardware?.spyhole?.slice(index + 1),
				];
				if (index >= 0 && index < spyHoleModel.current.length) {
					spyHoleModel.current[index].visible = false;
					sceneRef.current.remove(spyHoleModel.current[index]);
					spyHoleModel.current.splice(index, 1);
					spyHolePosition.current.splice(index, 1);
				}
				break;
			case 'letterPlate':
				newArray = [
					...customModelData?.hardware?.letterPlate?.slice(0, index),
					...customModelData?.hardware?.letterPlate?.slice(index + 1),
				];
				if (index >= 0 && index < letterPlateModel.current.length) {
					letterPlateModel.current[index].visible = false;
					sceneRef.current.remove(letterPlateModel.current[index]);
					letterPlateModel.current.splice(index, 1);
					letterPlatePos.current.splice(index, 1);
				}
				break;
			case 'knocker':
				newArray = [
					...customModelData?.hardware?.knocker?.slice(0, index),
					...customModelData?.hardware?.knocker?.slice(index + 1)
				]

				if (index >= 0 && index < knockerModel.current.length) {
					knockerModel.current[index].visible = false;
					sceneRef.current.remove(knockerModel.current[index]);
					knockerModel.current.splice(index, 1);
					knockerPos.current.splice(index, 1);
				}
				break;
			case 'numeral':
				setCustomModelData((prevModelData) => ({
					...prevModelData,
					hardware: {
						...prevModelData.hardware,
						numeral: [], // Remove the numeral key
					},
				}));
				setHardwareType('')
				if (textMeshRef.current) {
					sceneRef.current.remove(textMeshRef.current)
					textMeshRef.current = null
				}
				break;
			default:
				break;
		}
		if (type !== "numeral") {
			setCustomModelData((prevModelData) => {
				const { [type]: _, ...restHardware } = prevModelData.hardware;
				return {
					...prevModelData,
					hardware: restHardware,
				};
			});
		}
		setHardwareData();
		setElementDeleted(true)
	}

	function deleteTrickleVent(index) {
		const newArray = [
			...customModelData?.hardware?.trickleVent?.slice(0, index),
			...customModelData?.hardware?.trickleVent?.slice(index + 1),
		];
		if (index >= 0 && index < tickleVentModels.current.length) {
			tickleVentModels.current[index].visible = false;
			sceneRef.current.remove(tickleVentModels.current[index]);
			tickleVentModels.current.splice(index, 1);
			tickleVentPosition.current.splice(index, 1);
		}
		setCustomModelData((prevModelData) => ({
			...prevModelData,
			hardware: {
				...prevModelData.hardware,
				trickleVent: newArray,
			},
		}));
		setElementDeleted(true)
		setHardwareData()
	}

	function twoSideElementColor(external, internal, currentIndex, hex) {
		const mat = new THREE.MeshBasicMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		external.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.material = mat;
			}
		});

		internal.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.material = mat;
			}
		});
	}


	function setTwoSidedElementPosition(side, horizontal, currentIndex, units, external, internal, pos) {
		units = units * 0.01;
		//inside
		if (side == 2) {
			internal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && pos.current.length)
							handle.position.x = units;
					} else {
						if (handle && pos.current.length)
							handle.position.y = units;
					}
				}
			});
		}

		//outside
		if (side == 1) {
			external.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && pos.current.length)
							handle.position.x = units;
					} else {
						if (handle && pos.current.length)
							handle.position.y = units;
					}
				}
			});
		}

		//both
		if (side == 3) {
			internal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && pos.current.length)
							handle.position.x = units;
					} else {
						if (handle && pos.current.length)
							handle.position.y = units;
					}
				}
			});

			external.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && pos.current.length)
							handle.position.x = units;
					} else {
						if (handle && pos.current.length)
							handle.position.y = units;
					}
				}
			});
		}
	}

	function setEscutecheonPosition(side, horizontal, currentIndex, units) {
		setTwoSidedElementPosition(side, horizontal, currentIndex, units, escutcheonExternalModel, escutcheonInternalModel, escutcheonExternalPos)
	}

	const setEscutcheonColor = (currentIndex, hex) => {
		twoSideElementColor(escutcheonExternalModel, escutcheonInternalModel, currentIndex, hex)
	}

	const setHingeColor = (hex) => {
		const mat = new THREE.MeshBasicMaterial({
			color: hex ? hex : customModelData.frame.externalColor.hex,
			metalness: 1,
			roughness: 0.5
		});
		hingeModels?.current?.forEach((hinge) => {
			hinge?.traverse((subChild) => {
				subChild.material = mat
			})
		})
	}

	const setHingePosition = (horizontal, currentIndex, units) => {
		units = units * 0.001;
		hingeModels.current.forEach((child, index) => {
			if (currentIndex <= 2 && index <= 2) {
				if (horizontal) {
					if (child && hingePosition)
						child.position.x = hingePosition.current[index].x + units;
				} else {
					if (child && hingePosition)
						child.position.y = hingePosition.current[index].y + units;
				}
			} else if (currentIndex > 2 && index > 2) {
				if (horizontal) {
					if (child && hingePosition)
						child.position.x = hingePosition.current[index].x + units;
				} else {
					if (child && hingePosition)
						child.position.y = hingePosition.current[index].y + units;
				}
			}


			// child.traverse((subChild) => {
			// 	if (currentIndex <= 2 && index <= 2) {
			// 		if (horizontal) {
			// 			if (subChild && hingePosition)
			// 				subChild.position.x = hingePosition.current[index].x + units;
			// 		} else {
			// 			if (subChild && hingePosition)
			// 				subChild.position.y = hingePosition.current[index].y + units;
			// 		}
			// 	} else if (currentIndex > 2 && index > 2) {
			// 		if (horizontal) {
			// 			if (subChild && hingePosition)
			// 				subChild.position.x = hingePosition.current[index].x + units;
			// 		} else {
			// 			if (subChild && hingePosition)
			// 				subChild.position.y = hingePosition.current[index].y + units;
			// 		}
			// 	}
			// })
		});
	}

	const deleteHinge = (index) => {
		const newArray = [
			...customModelData?.hardware?.hingeData?.slice(0, index),
			...customModelData?.hardware?.hingeData?.slice(index + 1),
		];
		if (index > -1 && index < hingeModels?.current?.length) {
			hingeModels.current[index].visible = false;
			gltfModel.current.remove(hingeModels.current[index]);
			sceneRef.current.remove(hingeModels.current[index]);
			hingeModels.current.splice(index, 1);
			// handlePosition.current.splice(index, 1);
		}
		setCustomModelData((prevData) => ({
			...prevData,
			hardware: {
				...prevData.hardware,
				hingeData: newArray
			}
		}))
	}


	function setHandleColor(currentIndex, hex) {
		//create material
		const mat = new THREE.MeshBasicMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		handleExternal.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.material = mat;
			}
		});

		handleInternal.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.material = mat;
			}
		});
	}

	function setBarHandleColor(currentIndex, hex) {
		//create material
		const mat = new THREE.MeshBasicMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		barHandlesOffsetExternal.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.material = mat;
			}
		});

		barHandlesOffsetInternal.current.forEach((handle, index) => {
			if (index == currentIndex || index == currentIndex - 1) {
				handle.material = mat;
			}
		});

		if (hex && currentIndex) {

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					barHandlesOffset: prevModelData.hardware.barHandlesOffset.map((item, index) => {
						if (index === currentIndex) {
							return {
								...item,
								color: hex
							}
						}
						return item
					})
				}
			}))


		}
	}

	function setHandlePosition(side, horizontal, currentIndex, units, isStored) {
		units = units * 0.001;
		let handleCoordinates;
		//inside
		if (side == 1) {
			handleInternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = handlePosition.current[index].x + units;
					} else {
						if (handle && handlePosition)
							handle.position.y = handlePosition.current[index].y + units;
					}
					handleCoordinates = handle.position
				}
			});
		}

		//outside
		if (side == 2) {
			handleExternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = handlePosition.current[index].x + units;
					} else {
						if (handle && handlePosition)
							handle.position.y = handlePosition.current[index].y + units;
					}
					handleCoordinates = handle.position
				}
			});
		}

		//both
		if (side == 3) {
			handleInternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = units;
					} else {
						if (handle && handlePosition)
							handle.position.y = units;
					}
				}
			});


			handleExternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = units;
					} else {
						if (handle && handlePosition)
							handle.position.y = units;
					}
					handleCoordinates = handle.position

				}
			});
		}

		if (!isStored) {
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					handle: customModelData?.hardware?.handle.map((item) => {
						if (currentIndex === item.index) {
							return {
								...item,
								side: side,
								horizontalPos: horizontalPos,
								verticalPos: verticalPos,
								position: handleCoordinates

							}
						} else {
							return item
						}
					})
				}
			}))
		}

	}



	function setBarHandlePostion(side, horizontal, currentIndex, units) {
		let originalUnit = units
		units = units * 0.001;

		if (side == 2) {
			barHandlesOffsetInternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && barHandlesOffsetInternalPos)
							handle.position.x = barHandlesOffsetInternalPos.current[index].x + units;

					} else {
						if (handle && barHandlesOffsetInternalPos)
							handle.position.y = barHandlesOffsetInternalPos.current[index].y + units;
					}
					customModelData.hardware.barHandlesOffset.forEach((item) => {
						if (item.index === index && !item.isOutside) {
							setCustomModelData((prevModelData) => ({
								...prevModelData,
								hardware: {
									...prevModelData.hardware,
									barHandlesOffset: prevModelData.hardware.barHandlesOffset.map((offset) => {
										if (offset.index === index && offset.isOutside) {
											return {
												...offset,
												horizontalPos: horizontal ? originalUnit : offset.horizontalPos,
												verticalPos: !horizontal ? originalUnit : offset.verticalPos

											};
										}
										return offset;
									})
								}
							}))
						}
					})
				}
			});
		}

		if (side == 1) {
			barHandlesOffsetExternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle)
							handle.position.x = barHandlesOffsetExternalPos.current[index].x + units;;
					} else {
						if (handle)
							handle.position.y = barHandlesOffsetExternalPos.current[index].y + units;;
					}
					customModelData.hardware.barHandlesOffset.forEach((item) => {
						if (item.index === index && item.isOutside) {
							setCustomModelData((prevModelData) => ({
								...prevModelData,
								hardware: {
									...prevModelData.hardware,
									barHandlesOffset: prevModelData.hardware.barHandlesOffset.map((offset) => {
										if (offset.index === index && offset.isOutside) {
											return {
												...offset,
												horizontalPos: horizontal ? originalUnit : offset.horizontalPos,
												verticalPos: !horizontal ? originalUnit : offset.verticalPos

											};
										}
										return offset;
									})
								}
							}))
						}
					})
				}
			});
		}

		if (side == 3) {
			barHandlesOffsetInternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = units;
					} else {
						if (handle && handlePosition)
							handle.position.y = units;
					}
					customModelData.hardware.barHandlesOffset.forEach((item) => {
						if (item.index === index && !item.isOutside) {
							setCustomModelData((prevModelData) => ({
								...prevModelData,
								hardware: {
									...prevModelData.hardware,
									barHandlesOffset: prevModelData.hardware.barHandlesOffset.map((offset) => {
										if (offset.index === index && !offset.isOutside) {
											return {
												...offset,
												horizontalPos: horizontal ? originalUnit : offset.horizontalPos,
												verticalPos: !horizontal ? originalUnit : offset.verticalPos

											};
										}
										return offset;
									})
								}
							}))
						}
					})
				}
			});


			barHandlesOffsetExternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = units;
					} else {
						if (handle && handlePosition)
							handle.position.y = units;
					}
					customModelData.hardware.barHandlesOffset.forEach((item) => {
						if (item.index === index && !item.isOutside) {
							setCustomModelData((prevModelData) => ({
								...prevModelData,
								hardware: {
									...prevModelData.hardware,
									barHandlesOffset: prevModelData.hardware.barHandlesOffset.map((offset) => {
										if (offset.index === index && offset.isOutside) {
											return {
												...offset,
												horizontalPos: horizontal ? originalUnit : offset.horizontalPos,
												verticalPos: !horizontal ? originalUnit : offset.verticalPos

											};
										}
										return offset;
									})
								}
							}))
						}
					})
				}
			});
		}

	}



	function deleteHandleCylinder(currentIndex) {

		if (!handleCylinder.current.length) {
			return;
		}

		let index = currentIndex + 1;
		if (currentIndex >= -1 && currentIndex <= handleExternal.current.length) {
			handleCylinder.current[currentIndex].visible = false;
			handleCylinder.current[index].visible = false;
			sceneRef.current.remove(handleCylinder.current[currentIndex]);
			sceneRef.current.remove(handleCylinder.current[index]);
			handleCylinder.current.splice(currentIndex, index + 1);

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					cylinder: customModelData.hardware.cylinder.filter((item, index) => index !== currentIndex)
				}

			}))
		}
	}

	function deleteHandle(index) {
		if (index > -1) {
			const newArray = [
				...customModelData?.hardware?.handle?.slice(0, index),
				...customModelData?.hardware?.handle?.slice(index + 1),
			];
			if (index > -1 && index < handleExternal.current.length) {
				handleExternal.current[index].visible = false;
				gltfModel.current.remove(handleExternal.current[index]);
				sceneRef.current.remove(handleExternal.current[index]);
				handleExternal.current.splice(index, 1);
				handlePosition.current.splice(index, 1);
			}

			if (index > -1 && index < handleInternal.current.length) {
				handleInternal.current[index].visible = false;
				gltfModel.current.remove(handleInternal.current[index]);
				sceneRef.current.remove(handleInternal[index]);
				handleInternal.current.splice(index, 1);
			}

			handleObjects.current.splice(index, 1);
			addedHandles.current.splice(index, 1);

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				storedHandles: addedHandles
			}))
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					handle: newArray,
				},
			}));
			setHandleDeleted(true)
			setHardwareData()
		}
	}


	function deleteBarHandles(index, side) {
		if (side === 3) {
			const newArray = customModelData.hardware.barHandlesOffset.filter(item => item.index !== index);

			if (index > -1 && index < barHandlesOffsetExternal.current.length) {
				barHandlesOffsetExternal.current[index].visible = false;
				gltfModel.current.remove(barHandlesOffsetExternal.current[index]);
				sceneRef.current.remove(barHandlesOffsetExternal.current[index]);
				barHandlesOffsetExternal.current.splice(index, 1);
				barHandlesOffsetExternalPos.current.splice(index, 1);
			}

			if (index > -1 && index < barHandlesOffsetInternal.current.length) {
				barHandlesOffsetInternal.current[index].visible = false;
				gltfModel.current.remove(barHandlesOffsetInternal.current[index]);
				sceneRef.current.remove(barHandlesOffsetInternal.current[index]);
				barHandlesOffsetInternal.current.splice(index, 1);
				barHandlesOffsetInternalPos.current.splice(index, 1)
			}
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					barHandlesOffset: newArray,
				},
			}));
			setHandleDeleted(true)
			// setHardwareData()
		}
	}




	function deleteTwoSidedElement(index, type) {
		let newArray = [];
		switch (type) {
			case "escutcheon":
				newArray = [
					...customModelData?.hardware?.[type]?.slice(0, index),
					...customModelData?.hardware?.[type]?.slice(index + 1),
				];
				if (index >= 0 && index < escutcheonExternalModel.current.length) {
					escutcheonExternalModel.current[index].visible = false;
					gltfModel.current.remove(escutcheonExternalModel.current[index]);
					sceneRef.current.remove(escutcheonExternalModel.current[index]);
					escutcheonExternalModel.current.splice(index, 1);
					escutcheonExternalPos.current.splice(index, 1);
				}

				if (index >= 0 && index < escutcheonInternalModel.current.length) {
					escutcheonInternalModel.current[index].visible = false;
					gltfModel.current.remove(escutcheonInternalModel.current[index]);
					sceneRef.current.remove(escutcheonInternalModel[index]);
					escutcheonInternalModel.current.splice(index, 1);
				}

				// handleObjects.current.splice(index, 1);
				break;

			default:
				break;
		}

		setCustomModelData((prevModelData) => ({
			...prevModelData,
			hardware: {
				...prevModelData.hardware,
				[type]: newArray,
			},
		}));
		setHardwareData()
		setElementDeleted(true)
	}

	function setGlassDesignColumn(numOfColumn, modelPath, uniqueIndex = null) {

		let i = 0;
		for (i; i < glazingRef.current.length; i++) {
			if (uniqueIndex == null) {
				updateGlassDesignColumn(astragalColumnLeft, numOfColumn, i, uniqueIndex);
			} else {
				if (i == uniqueIndex) {
					updateGlassDesignColumn(astragalColumnLeft, Math.ceil(numOfColumn / (glazingRef.current.length)), i, uniqueIndex);
				}
			}
		}
	}


	//-0.38 to 0.4
	function updateGlassDesignRow(pathModel, numberOfRow, uniqueIndex = null) {
		if (numberOfRow <= 1) {
			// If number of rows is 0 or 1, do nothing
			return;
		}

		objectGroupRow.current = new THREE.Object3D();

		// Load the GLTF model (assuming you've already loaded the model and assigned it to a variable)
		const loader = new GLTFLoader();
		let gltfModel = pathModel;

		loader.load(pathModel, (gltf) => {

			gltfModel = gltf.scene;

			const boundingBox = new THREE.Box3().setFromObject(glassGroup.current[0]);
			const modelWidth = frameStyleRight.current.position.x - frameStyleLeft.current.position.x;

			const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);
			const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;

			// Calculate the scaling factor to match the width of the model
			const scaleFactor = gltf.scene.scale.x * (modelWidth / modelWidth2);

			gltf.scene.scale.set(scaleFactor, 1, 1);

			if (uniqueIndex === null) {
				gltfModel.position.set(glassGroup.current[0]?.position.x, 0, 0);
			} else {
				const boundingBoxGlazing = new THREE.Box3().setFromObject(glazingRef.current[uniqueIndex]);
			}


			objectGroupRow.current.add(gltfModel);

			if (numberOfRow === 2) {
				// If the number of rows is 2, do nothing
			} else {
				const boundingBox3 = new THREE.Box3().setFromObject(frameStyleBottom.current);
				const minPoint = boundingBox3.min.y;
				const maxPoint = frameStyleTop.current.position.y;
				const numberOfParts = numberOfRow;

				const partLength = (maxPoint - minPoint) / numberOfParts;
				gltfModel.position.set(0, 0, 0);
				let currentIndex = 0;
				uniqueSideRefernce.forEach((element) => {

					let glassRowGroup = new THREE.Object3D();


					let boundingBox

					if (uniqueIndex === null) {
						boundingBox = new THREE.Box3().setFromObject(glassGroup.current[0]);
					} else {
						boundingBox = new THREE.Box3().setFromObject(glazingRef.current[uniqueIndex]);
					}

					const width = boundingBox.max.x - boundingBox.min.x;
					gltfModel.scale.x = width / modelWidth2

					// Get the center of the bounding box
					const center = new THREE.Vector3();
					boundingBox.getCenter(center);


					const clonedModel1 = gltfModel.clone();
					clonedModel1.position.set(center.x, minPoint + partLength, 0); //element.position.x
					objectGroupRow.current.add(clonedModel1);


					// If the number of rows is greater than 2, spawn additional GLTF elements and position them accordingly
					for (let i = 1; i < numberOfRow - 1; i++) {
						const clonedModel = gltfModel.clone();
						const xPos = minPoint + (i + 1) * partLength; // Calculate position for each cloned model
						clonedModel.position.set(center.x, xPos, 0);
						objectGroupRow.current.add(clonedModel);
					}

					var originalWorldPosition = new THREE.Vector3();

					glassGroup.current[currentIndex].getWorldPosition(originalWorldPosition);
					glassGroup.current[currentIndex].add(glassRowGroup);

					glassRowGroup.position.x -= originalWorldPosition.x;

					currentIndex++;
				});
			}

			gltfModel.visible = false;
			if (uniqueIndex) {
				if (rowStorage.current.length) {
					const existingIndex = rowStorage.current.findIndex(item => item.index === uniqueIndex);
					if (existingIndex !== -1) {
						rowStorage.current[existingIndex] = { index: uniqueIndex, rows3D: objectGroupRow.current };
					} else {
						rowStorage.current.push({ index: uniqueIndex, rows3D: objectGroupRow.current });
					}
				}
			} else {
				rowStorage.current = []
				glazingRef.current.forEach((item, index) => {
					rowStorage.current.push({ index: index, rows3D: objectGroupRow.current })
				})
			}

			sceneRef.current.add(objectGroupRow.current);
		});
	}



	function updateGlassDesignColumn(pathModel, numberOfRow, index, uniqueIndex = null) {

		if (numberOfRow <= 1) {
			return;
		}

		index = 0;

		objectGroupCol.current = new THREE.Object3D();

		let glassColGroup = new THREE.Object3D();

		const loader = new GLTFLoader();
		let gltfModel;

		let boundingBox;

		if (uniqueIndex === null) {
			boundingBox = new THREE.Box3().setFromObject(glassGroup.current[0]);
		} else {
			boundingBox = new THREE.Box3().setFromObject(glazingRef.current[uniqueIndex]);
		}
		const modelWidth = boundingBox.max.x - boundingBox.min.x;
		const modelHeight = boundingBox.max.y - boundingBox.min.y;

		const minPoint = boundingBox.min.x
		const maxPoint = boundingBox.max.x

		const boundingBox2 = new THREE.Box3().setFromObject(glassGroup.current[index]);

		const center = new THREE.Vector3();
		boundingBox2.getCenter(center);

		loader.load(pathModel, (gltf) => {
			const boundingBox3 = new THREE.Box3().setFromObject(gltf.scene);
			const sceneWidth = boundingBox3.max.x - boundingBox3.min.x;
			const sceneHeight = boundingBox3.max.y - boundingBox3.min.y;

			gltfModel = gltf.scene;
			gltfModel.position.set(center.x, 0, 0);
			gltfModel.scale.y *= modelHeight / sceneHeight
			tempBar.current = gltfModel;
			if (numberOfRow === 2 && !uniqueIndex) {
			} else {

				const numberOfParts = numberOfRow;
				const partLength = (maxPoint - minPoint) / numberOfParts;
				gltfModel.position.set((minPoint + (numberOfRow - 1) * partLength), 0, 0); //uniqueSideRefernce[index].position.x
				for (let i = 1; i < numberOfRow; i++) {
					const clonedModel = gltfModel.clone();
					const xPos = minPoint + (i) * partLength; // Calculate position for each cloned model
					clonedModel.position.set(xPos, 0, 0);
					objectGroupCol.current.add(clonedModel);
				}
			}

			if (uniqueIndex) {
				if (colStorage.current.length) {
					const existingIndex = colStorage.current.findIndex(item => item.index === uniqueIndex);
					if (existingIndex !== -1) {
						colStorage.current[existingIndex] = { index: uniqueIndex, cols3D: objectGroupCol.current };
					} else {
						colStorage.current.push({ index: uniqueIndex, cols3D: objectGroupCol.current });
					}
				}
				// else {
				// 	colStorage.current.push({ index: uniqueIndex, cols3D: objectGroupCol.current });
				// }
			} else {
				colStorage.current = []
				glazingRef.current.forEach((item, index) => {
					colStorage.current.push({ index: index, cols3D: objectGroupCol.current })
				})
			}
			sceneRef.current.add(objectGroupCol.current);

		});
	}


	const clearDesignCols = (index) => {
		if (!index) {
			colStorage.current.forEach((item) => {
				sceneRef.current.remove(item.cols3D)
			})
			colStorage.current = []
		} else {
			const findCols = colStorage.current.find((col) => col.index === index)
			if (findCols) {
				sceneRef.current.remove(findCols.cols3D)
			}
		}
	}

	const clearDesignRows = (index) => {
		if (!index) {
			rowStorage.current.forEach((item) => {
				sceneRef.current.remove(item.rows3D)
			})
			rowStorage.current = []
		} else {
			const findRows = rowStorage.current.find((row) => row.index === index)
			if (findRows) {
				sceneRef.current.remove(findRows.rows3D)
			}
		}

	}


	const getGlazingRef = () => {
		glazingRef.current = []

		sceneRef.current.traverse((child) => {
			if (child.name.includes("GlassPanel")) {
				glazingRef.current.push(child);
			}
		})
	}

	function addHardwareElement(data) {
		return new Promise((resolve, reject) => {
			if (data) {
				let hardwareData = data;
				let element = `${servicePath}/ThreeJSModel/Glb/${hardwareData?.modelFilePath}`;
				let hardwareType = "";
				const hardwareName = hardwareData?.name?.toLocaleLowerCase();
				const pos = getPosition(hardwareName);
				if (hardwareName === "letter") {
					// createText(0, 0)
				}
				else {
					const isBarHandle =
						hardwareName?.includes("offset") ||
						hardwareName?.includes("inline");
					hardwareType =
						hardwareName?.includes("spyhole")
							? "spyhole"
							: hardwareName?.includes("knob")
								? "door_knob"
								: hardwareName?.includes("escutcheon")
									? "escutcheon"
									: isBarHandle
										? "bar_offset"
										: "";
				}
				let posZ;
				let posY;

				if (hardwareData?.verticalOrigin !== "" && hardwareData?.horizontalOrigin !== "") {
					posZ = parseFloat(
						getAlignmentPosition(hardwareData?.horizontalOrigin, "horizontalOri") / 1000
					);
					posY = parseFloat(
						getAlignmentPosition(hardwareData?.verticalOrigin, "verticalOri") / 1000
					);
				} else if (hardwareData?.verticalAlignment !== "" && hardwareData?.horizontalAlignment !== "") {
					posZ = parseFloat(
						getAlignmentPosition(hardwareData?.horizontalAlignment, "horizontal") / 1000
					);
					posY = parseFloat(
						getAlignmentPosition(hardwareData?.verticalAlignment, "vertical") / 1000
					);
				} else if (hardwareData?.verticalAlignment !== "") {
					posZ = parseFloat(hardwareData?.horizontalPos / 1000);
					posY = parseFloat(
						getAlignmentPosition(hardwareData?.verticalAlignment, "vertical") / 1000
					);
				} else if (hardwareData?.horizontalAlignment !== "") {
					posZ = parseFloat(
						getAlignmentPosition(hardwareData?.horizontalAlignment, "horizontal") / 1000
					);
					posY = parseFloat(hardwareData?.verticalPos / 1000);
				} else if (hardwareData?.horizontalOrigin !== "") {
					posZ = parseFloat(
						getAlignmentPosition(hardwareData?.horizontalOrigin, "horizontalOri") / 1000
					);
					posY = parseFloat(hardwareData?.verticalPos / 1000);
				} else if (hardwareData?.verticalOrigin !== "") {
					posZ = parseFloat(hardwareData?.horizontalPos / 1000);
					posY = parseFloat(
						getAlignmentPosition(hardwareData?.verticalOrigin, "verticalOri") / 1000
					);
				} else {
					posZ = parseFloat(hardwareData?.horizontalPos / 1000);
					posY = parseFloat(hardwareData?.verticalPos / 1000);
				}
				const elementLoader = new GLTFLoader();
				if (element) {
					elementLoader.load(element, function (gltf) {
						const scale = new THREE.Vector3(pos?.scale?.posX * 3, pos?.scale?.posY * 3, pos?.scale?.posZ * 2);
						gltf.scene.scale.copy(scale);

						let posX = -0.03;
						if (hardwareType === "spyhole") {
							posX = -0.023
						} else if (hardwareType === "bar_offset") {
							posX = -0.059
						} else if (hardwareType === "door_knob") {
							posX = -0.05
						} else if (hardwareType === "escutcheon") {
							posX = -0.0099
						}

						const position = new THREE.Vector3(posX, 0.19, 0);
						gltf.scene.position.copy(position);
						// gltf.scene.rotation.set(0, 4.7, 0);
						gltf.scene.position.y += posY;
						gltf.scene.position.z -= posZ;
						gltf.scene.name = hardwareData?.type;

						resolve(gltf.scene);
					}, undefined, function (error) {
						reject(error);
					});
				} else {
					reject(new Error('Element is not defined'));
				}
			}
		});

	}

	const getAlignmentPosition = (alignment, type) => {
		let position = 0;

		switch (type) {
			case "vertical":
				position = alignment === "Centre" ? 0 : alignment === "Top" ? 300 : -300;
				break;
			case "horizontal":
				position =
					alignment === "Centre"
						? 0
						: alignment === "Left" || alignment === "Lock"
							? 380
							: -380;
				break;
			case "verticalOri":
			case "horizontalOri":
				position =
					alignment === "Slab"
						? 50
						: alignment === "Outside frame"
							? type === "horizontalOri"
								? 50
								: -50
							: 50;
				break;
		}
		return position;
	};

	useEffect(() => {
		if (applyPanel && customModelData?.glazing?.panel?.length > 0) {

			const panelItem = customModelData?.glazing?.panel?.find((p) => p.glassIndex == checkSingleSide)

			if (hardwareType === 'glazingPanel') {
				addGlazingPanel(checkClickedProfile, checkSingleSide, false, panelItem, sceneRef, glazeModelRef, setPanelObject, setApplyPanel, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
			}

			setPanelInitAdded(true)
		}
	}, [customModelData?.glazing?.panel]);

	useEffect(() => {
		if (modelInitialied && panelObject && panelObject.length > 0 && externalColor !== undefined && internalColor !== undefined) {
			if (glazingRef && glazingRef?.current?.length > 0) {
				panelObject?.forEach((item) => {
					addGlazingPanel(glazingRef?.current[item?.glassIndex], item?.glassIndex, true, item, sceneRef, glazeModelRef, setPanelObject, setApplyPanel, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
				})

				setPanelInitAdded(true)
			}
		}
	}, [panelObject, glazingRef?.current, modelInitialied])


	useEffect(() => {
		if (deletePanel) {
			if (hardwareType === 'glazingPanel') {
				glazingPanelDelete(checkClickedProfile, checkSingleSide, sceneRef, panelDataSave, setPanelDataSave, externalFrameRef, internalFrameRef, setDeletePanel)
			}
		}
	}, [deletePanel]);

	const getSashHangingAndProfileRef = () => {
		newFrameRefPoints.current = getSashHangingRefs()
		testRef.current = getSasProfilehRefs()
	}

	const handleHardwares = () => {
		const hardwareRefMap = {};
		newFrameRefPoints.current = [];
		const staticValues = {
			"Trickle vent": [...getSasProfilehRefs(), ...getFrameRefs()],
			"Escutcheon": tickleVent, // Assuming both "Trickle vent" and "Escutcheon" share the same reference
			"Door Handle": [...getSasProfilehRefs(), ...getFrameRefs()],
			"Bar Handles Offset": [...getSasProfilehRefs(), ...getFrameRefs()],
			"Frame": newFrameData,
			// "glazing": uniqueSideRefernce,
			"sashprofiles": hardwareType === "sashprofiles" && getSashFrameRef(), // Assuming getSashFrameRef returns a reference
			"sashHanging": hardwareType === "sashHanging" && getSashRef(), // Assuming updateSash is a function
			// "Panel": panelRefPoints,
			"Spyhole": [...getSasProfilehRefs(), ...getFrameRefs()],
			"Letterplate": spyHoleRefPoints,
			"Knocker": spyHoleRefPoints,
			"Numeral": spyHoleRefPoints,
			"handleDirection": windowHandleRefPoint,
			// "glazingPanel": [...getSashHangingRefs()],
			"hinge": hingeRefPoints
		};


		if (hardwareType == "sashHangingProfile") {
			getSashHangingAndProfileRef()
		}


		if ((hardwareType === "sashHanging" && collectionDetails.typeId == 1) && ((customModelData?.numberOfSash?.high != high) || (customModelData?.numberOfSash?.wide != wide))) {
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				numberOfSash: {
					number: sashHangingNo.toString(),
					// wide: wide,
					// high: high
				}
			}))
		}

		const lowercaseHardwareNames = allHardware.map(name => name?.name?.toLowerCase());

		// Populate hardwareRefMap with case-insensitive comparison
		Object.keys(staticValues).forEach(name => {
			if (lowercaseHardwareNames.includes(name.toLowerCase())) {
				hardwareRefMap[name?.toLocaleLowerCase()] = staticValues[name];
			}
		});

		if (hardwareType && hardwareRefMap[hardwareType?.name]) {
			testRef.current = hardwareRefMap[hardwareType?.name];
		} else if (hardwareType === "") {
			// forceUpdate();
			testRef.current = [];
			setPopoverIndex(null);
			newFrameRefPoints.current = []
			delete3DItems(false, '');
		}

		//setting transom refs points
		if (hardwareType && (hardwareType === "transomVertical" || hardwareType === 'transomHorizontal')) {
			testRef.current = getTransomPartitionRefs();
			newFrameRefPoints.current = getTransomRefs()
		}

		if (hardwareType && hardwareType.name === "Frame") {
			testRef.current = addFrameRef.current
		}

		//setting frame refs points
		if (hardwareType && hardwareType === "customize frame style") {
			testRef.current = getFrameRefs()
		}


		//handle Ref point
		if (hardwareType && hardwareType === "handleDirection") {
			testRef.current = windowHandleRefPoint.current
		}

		//handle Ref point
		if (hardwareType && hardwareType === "glazingPanel" || hardwareType === 'glazing') {
			testRef.current = getSashHangingRefs();
		}
	}

	console.log("Hardware4718", hardwareType);




	// use this function to add frame
	const frameClick = (element, pos) => {

		const bayPostData = bayPostList.find((item) => item.id === bayPostDeg)
		let angle = bayPostData && bayPostData.name === "Variable Bay Post 120°" ? bayPostAngleManual : 90
		let frameDirection = "";
		if (pos?.name.includes("FrameTop") && !pos?.name.includes("FrameTopPerpendicular")) {
			frameDirection = "FrameTop"
			addFrameTop(true, couplerData[0], frameDirection);
		}
		if (pos?.name.includes("FrameBottom") && !pos?.name.includes("FrameBottomPerpendicular")) {
			frameDirection = "FrameBottom"
			addFrameTop(false, couplerData[0], frameDirection);
		}
		if (pos?.name.includes("FrameRight") && !pos?.name.includes("FrameRightPerpendicular")) {
			frameDirection = "FrameRight"
			addFrame(false, couplerData[0], frameDirection);
		}
		if (pos?.name.includes("FrameLeft") && !pos?.name.includes("FrameLeftPerpendicular")) {
			frameDirection = "FrameLeft"
			addFrame(true, couplerData[0], frameDirection);
		}
		if (pos?.name.includes("FrameRightPerpendicular")) {
			frameDirection = "FrameRightPerpendicular"
			addFramePerpendicular(`${servicePath}/ThreeJSModel/Glb/${bayPostData?.customePath}`, true, angle, bayPostData, false, frameDirection);
		}
		if (pos?.name.includes("FrameLeftPerpendicular")) {
			frameDirection = "FrameLeftPerpendicular"
			addFramePerpendicular(`${servicePath}/ThreeJSModel/Glb/${bayPostData?.customePath}`, false, angle, bayPostData, false, frameDirection);
		}

		if (pos?.name.includes("FrameBottomPerpendicular")) {
			frameDirection = "FrameBottomPerpendicular"
			addFramePerpendicular(`${servicePath}/ThreeJSModel/Glb/${bayPostData?.customePath}`, false, angle, bayPostData, "bottom", frameDirection);
		}

		if (pos?.name.includes("FrameTopPerpendicular") && !pos?.name.includes("FrameLeftPerpendicular") && headerSelectedItem?.name !== "Eaves Frame") {
			frameDirection = "FrameTopPerpendicular"
			addFramePerpendicular(`${servicePath}/ThreeJSModel/Glb/${bayPostData?.customePath}`, false, angle, bayPostData, true, frameDirection);
		}

		if (pos?.name.includes("FrameTopPerpendicular") && !pos?.name.includes("FrameLeftPerpendicular") && headerSelectedItem?.name === "Eaves Frame") {
			frameDirection = "FrameTopPerpendicular"
			addEvesFrame(angle, "", frameDirection)
		}

		setLayoutSizing(true)
	}

	useEffect(() => {
		if (modelVisible && sashGroup.current?.length > 0) {
			setUiLinesLocation('All')
			setRefreshUiLines(true)
		}
	}, [modelVisible, sashGroup.current])

	// Start: adding UI lines for sash
	useEffect(() => {
		if (refreshUiLines) {
			setRefreshUiLines(false)

			console.log(refreshUiLines, uiLinesLocation, uiLinesRefBottom.current.length, customModelData?.hideDimentions, "aadi partial")

			if (uiLinesLocation === '' || customModelData.hideDimentions || modelJson[jsonIndex]?.sash?.hangings < 2) {
				uiLinesRef.current = []
				uiLinesRefBottom.current = []
				removeLayoutLines(sceneRef)
			} else {
				if (modelJson[jsonIndex]?.sash?.hangings > 1) {
					uiLinesRef.current = []
					uiLinesRefBottom.current = []
					removeLayoutLines(sceneRef)

					if ((uiLinesLocation === "All") && (sashGroup.current && sashGroup?.current?.length > 0)) {
						addUiLinesBottomTransom(sashGroup.current, uiLinesRefBottom, sceneRef, userDataContext?.company_color || '#44C8F5', frameStyleBottom.current)
					} else {
						removeLayoutLines(sceneRef)
					}
				}
			}

			// setSavePartialWidths(true)
		}
	}, [refreshUiLines]);

	useEffect(() => {
		console.log(refreshUiLines, customModelData?.hideDimentions, "aadi partial")
		if (!refreshUiLines) {
			setRefreshUiLines(true)
		}
	}, [uiLinesLocation, sashGroup.current, customModelData?.hideDimentions, modelJson[jsonIndex]?.sash?.hangings, modelJson[jsonIndex]?.dimensions?.width, modelJson[jsonIndex]?.dimensions?.height])

	// Start: this is for single glass on which overall frame height and width will be changed
	// useEffect(() => {

	// frameStyleRef.current = getFrameRefs()


	// 	if (frameStyleRef.current && frameStyleRef.current.length > 0) {
	// 		if (customModelData.hideDimentions) {
	// 			// uiLinesRefFrame.current = []
	// 			// removeFrameLines(sceneRef)
	// 		} else {
	// 			if (frameStyleRef.current && frameStyleRef.current.length > 0) {
	// 				console.log(frameStyleRef, "frameStyleRef")
	// 				// addUiLinesFrameH(frameStyleRef.current, uiLinesRefFrame, sceneRef, userDataContext?.company_color || '#44C8F5')
	// 				// addUiLinesFrameV(frameStyleRef.current, uiLinesRefFrame, sceneRef, userDataContext?.company_color || '#44C8F5')
	// 			}
	// 		}
	// 	}
	// }, [uiLinesLocation, refreshUiLines, customModelData.hideDimentions]);
	// End: this is for single glass on which overall frame height and width will be changed


	// capture and save all images
	useEffect(() => {
		if (captureImages) {
			captureAndSaveImages(setFrameCameraView, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom, getAllProfileRef, allProfileRefSeq, glassRefSeq, frameStyleRef, sashList, sillRef, sashGroup, uiLinesRefFrame, uiLinesRefBottom, removeFrameLines, removeLayoutLines, sceneRef, setModelImages, modelWrap, setSaveAllImages, setCaptureImages, setRefreshUiLines, setUiLinesLocation, setRefreshAfterSave)
		}
	}, [captureImages]);

	useEffect(() => {
		if (refreshAfterSave) {
			setRefreshAfterSave(false)

			if (!customModelData?.hideDimentions) {
				setUiLinesLocation("All")
				setRefreshUiLines(true)
			}
		}
	}, [refreshAfterSave])


	const addBead = (beadModel, spacerBar, isVertical, data) => {
		return

		const loader = new GLTFLoader();

		const lastPart = beadModel.split('/').filter(Boolean).pop()

		if (lastPart.includes('.glb')) {
			loader.load(beadModel, (gltf) => {

				gltf.scene.traverse((child) => {
					child.name += " Internal"
					if (child.name.includes("Internal")) {
						internalFrameRef.current.push(child);
					}
				})
				const worldPosition = new THREE.Vector3();
				spacerBar.getWorldPosition(worldPosition);

				gltf.scene.position.x = worldPosition.x;
				gltf.scene.position.y = worldPosition.y;


				const bB = new THREE.Box3().setFromObject(spacerBar);
				let width = bB.max.x - bB.min.x;
				if (isVertical) {
					width = bB.max.y - bB.min.y;
				}

				const bB2 = new THREE.Box3().setFromObject(gltf.scene);

				const width2 = bB2.max.x - bB2.min.x;
				const depth2 = bB2.max.z - bB2.min.z;
				const height2 = bB2.max.y - bB2.min.y;

				gltf.scene.scale.z = data.width / 30; //for base bead widthScale is width/20
				gltf.scene.scale.y = data.height / 20; //for base bead heightScale is height/20

				gltf.scene.scale.x *= width / width2;

				if (isVertical) {
					gltf.scene.rotation.z = Math.PI / 2;
				}
				gltf.scene.position.z = bB.min.z - depth2 / 2;
				setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
				sceneRef.current.add(gltf.scene);
			})
		}

	}

	const storeBead = () => {
		let bead = initialProfile?.bead[0]
		let beadData = {
			id: bead.id,
			modelUrl: `${servicePath}/ThreeJSModel/Glb/${initialProfile?.bead[0].customePath}`,
			height: bead.height,
			width: bead.width,
			price: bead.price
		}
		setCustomModelData((prevModelData) => ({
			...prevModelData,
			beads: [
				...prevModelData.beads || [],
				beadData
			]
		}))
	}


	//test with replaced sash in window model

	useEffect(() => {
		if (shapeFrame && checkClickedProfile && hardwareType === "Shaped Frame") {

			const cornerName = checkClickedProfile?.name
			const targetCorner = modelJson[jsonIndex]?.frame.corners[cornerName];
			targetCorner.type = shapeFrame;
			targetCorner.size.width = shapeFrame == "Corner" ? 0 : debouncShapedWidth / 1000;
			targetCorner.size.height = shapeFrame == "Corner" ? 0 : debounceShapedHeight / 1000;

			const savingJson = {
				name: shapeFrame,
				height: debounceShapedHeight,
				width: debouncShapedWidth,
				cornerName: cornerName
			}

			createWindowFrame(sceneRef.current, modelJson, "group1", sashGroup, null, null, null, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents);
			updateShapedCornerData(setCustomModelData, customModelData, savingJson)
		}

	}, [shapeFrame, frameStyleRef, debouncShapedWidth, debounceShapedHeight])


	function setInternal(hex) {
		if (hex) {
			const material = new THREE.MeshStandardMaterial({
				color: hex,
				metalness: 1,
				roughness: 0.5,
				clippingPlanes: clippedPlanes.current,
			});

			if (internalFrameRef?.current?.length > 0) {
				internalFrameRef.current.map((child) => {
					if (child?.material?.clippingPlanes != null) {
						child?.material?.color.set(hex)
					} else {

						material.transparent = true;
						// material.opacity = 0;
						child.material = material
					}
				})
			}
		}
	}

	function setExternal(hex) {
		if (hex) {
			const material = new THREE.MeshStandardMaterial({
				color: hex,
				metalness: 1,
				roughness: 0.5,
				clippingPlanes: clippedPlanes.current,
			});

			if (externalFrameRef?.current?.length > 0) {
				externalFrameRef.current.map((child) => {
					if (child?.material?.clippingPlanes != null) {
						child?.material?.color.set(hex)
					} else {
						child.material = material
					}
				})
			}
		}
	}

	function initRenderer(container) {
		if (sceneRef.current)
			sceneRef.current.traverse(object => {
				if (object.material) {
					object.material.dispose();
				}
				if (object.geometry) {
					object.geometry.dispose();
				}
				if (object.texture) {
					object.texture.dispose();
				}
			});

		// Clear the old sceneRef.current
		if (sceneRef.current)
			sceneRef.current.children.length = 0;

		new RGBELoader().load(environmentHDR, function (hdrEquirect) {
			// new RGBELoader().setPath('./public/image/hdr/').load('interior.hdr', function (hdrEquirect) {
			hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
			sceneRef.current.environment = hdrEquirect;
		});

		cameraRef.current.position.set(0, -1, 4)

		const pointLight = new THREE.PointLight(0xffffff, 2, -100); // Color, intensity, and distance
		pointLight.position.set(0, 0, 6);
		pointLight.shadow.mapSize.width = 512; // Higher resolution shadow map for sharper shadows
		pointLight.shadow.mapSize.height = 512;
		pointLight.shadow.radius = 200; // Increase radius for softer shadows
		// sceneRef.current.add(pointLight);

		const pointLight2 = new THREE.PointLight(0xffffff, 2.1, 10); // Color, intensity, and distance
		pointLight2.position.set(0, 0, -2);
		sceneRef.current.add(pointLight2);

		// Add Ambient Light
		const ambientLight = new THREE.AmbientLight(0xffffff, 2.5);
		sceneRef.current.add(ambientLight);

		// Add DirectionalLight with Shadows
		const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
		directionalLight.position.set(0, 10, 0); // Position the light
		directionalLight.castShadow = true; // Enable shadows

		// Configure shadow camera for better shadow quality
		directionalLight.shadow.mapSize.width = 400; // Higher resolution for shadows
		directionalLight.shadow.mapSize.height = 400;
		directionalLight.shadow.camera.near = 0.5;
		directionalLight.shadow.camera.far = 50;
		directionalLight.shadow.camera.left = -10;
		directionalLight.shadow.camera.right = 10;
		directionalLight.shadow.camera.top = 10;
		directionalLight.shadow.camera.bottom = -10;
		directionalLight.shadow.radius = 8; // Soft shadows
		directionalLight.shadow.blurSamples = 0.5;
		sceneRef.current.add(directionalLight);


		const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1.5);
		directionalLight2.position.set(-1, 5, -5);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight2);
		}


		const directionalLight4 = new THREE.DirectionalLight(0xffffff, .8);
		directionalLight4.position.set(0, 0, -5);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight4);
		}

		// light from left
		const directionalLight5 = new THREE.DirectionalLight(0xffffff, 1.5);
		directionalLight5.position.set(-5, 0, 2);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight5);
		}

		// light from right
		const directionalLight6 = new THREE.DirectionalLight(0xffffff, 1.5);
		directionalLight6.position.set(5, 0, 2);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight6);
		}


		// Add a ground plane to receive shadows
		const planeGeometry = new THREE.PlaneGeometry(100, 100);
		const planeMaterial = new THREE.ShadowMaterial({ opacity: 0.4, side: THREE.DoubleSide, });
		// const planeMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
		const plane = new THREE.Mesh(planeGeometry, planeMaterial);
		plane.rotation.x = -Math.PI / 2; // Rotate to make it flat
		plane.position.y = -1.2;
		plane.receiveShadow = true; // Ensure plane receives shadows
		sceneRef.current.add(plane);

		// Create a new renderer
		renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
		renderer.localClippingEnabled = true;
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.setClearColor(0xffffff);
		renderer.shadowMap.enabled = true;
		renderer.shadowMap.type = THREE.PCFSoftShadowMap;

		// Remove the old canvas from the container
		if (container) {
			while (container.firstChild) {
				container.removeChild(container.firstChild);
			}
			// Append the new renderer canvas to the container
			container.appendChild(renderer.domElement);
		}
	}

	function initScene() {
		if (cameraRef.current) {
			cameraRef.current.position.set(0, 0.3, -3.9);
		}

		// Soft white light
		const ambientLight = new THREE.AmbientLight(0xffffff);
		if (sceneRef.current) {
			sceneRef.current.add(ambientLight);
		}


		const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5);
		directionalLight.position.set(1, 5, 5);
		if (sceneRef.current) {
			sceneRef.current.add(directionalLight);
		}

	}

	function initControls() {
		if (cameraRef.current) {
			const controls = new OrbitControls(cameraRef.current, renderer.domElement);
			controls.addEventListener('change', render);
			controls.minDistance = 1;
			controls.maxDistance = 15;
			controls.target.set(0, 0, 0);
			controls.update();
			orbitControlRef.current = controls;
			window.addEventListener('resize', onWindowResize);

			controls.addEventListener('start', cameraMoved);
			controls.addEventListener('end', cameraStopped);
		}
	}

	function cameraStopped() {
		const elements = document.getElementById('elements')
		forceUpdate()

		elements.classList.remove("hide")
		// elements.style.display = 'block'
	}

	function cameraMoved() {
		elementRef.current.hidden = false
		const elements = document.getElementById('elements')
		// elements.style.display = 'none'

		elements.classList.add("hide")
	}

	function onWindowResize() {
		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();
		renderer.setSize(window.innerWidth, window.innerHeight);
		render();
	}

	function addHandle(element, objectPosition, isAlreadyAdded, price, image, id) {


		handlePath.current = element;
		const handleLoader = new GLTFLoader()
		handleObjects.current.push(objectPosition);
		var finalIndex = 0;
		sashGroup?.current?.forEach((child, index) => {
			if (child == objectPosition) {
				finalIndex = index;
			}
		});

		if (element) {
			handleLoader?.load(element, function (gltf) {
				const scale = new THREE.Vector3(1, 1, 1);
				gltf.scene.scale.copy(scale);
				const boundingBox = new THREE.Box3().setFromObject(objectPosition);
				let position = new THREE.Vector3(boundingBox.min.x + 0.03, objectPosition.position.y, objectPosition.position.z);
				// const position = new THREE.Vector3(boundingBox.max.x - 0.03, objectPosition.position.y, objectPosition.position.z);

				if (headerSelectedItem.name === "Heritage Door") {
					position = new THREE.Vector3(boundingBox.min.x + 0.05, objectPosition.position.y, objectPosition.position.z + 0.05);
				}

				gltf.scene.position.copy(position);
				sceneRef.current.add(gltf.scene);
				gltfModel.current.add(gltf.scene);

				gltf.scene.traverse((child) => {
					if (child.name.includes("HandleExt")) {
						handleExternal.current.push(child);
						handlePosition.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z));
					}

					if (child.name.includes("HandleInt")) {
						handleInternal.current.push(child);
						// handlePositionInternal.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z))
					}

					if (child.name.includes("Cylinder") || child.name.includes("cylinder")) {
						handleCylinder.current.push(child);

					}

				});
				setHardwareType('')
				//  windowRef.current.add(gltf.scene);
				render();
			});
		}


		else {
			setIsHardwareAdded((prevAdded) => ({
				...prevAdded,
				handle: true
			}))
		}
	}

	function addBarHandleOffset(element, objectPosition, data) {
		addBarHandles(element, objectPosition, data, false, "bar handles offset")

	}


	const updateTestRef = () => {
		testRef.current = getAddOnRef()
	}

	// for getting reference points for transoms
	const getTestRefForTransoms = () => {
		testRef.current = getTransomRefs()
	}


	function addBarHandles(element, objectPosition, data, alreadyAdded, type) {

		const barHandleLoader = new GLTFLoader()

		if (element) {
			barHandleLoader.load(element, function (gltf) {
				const scale = new THREE.Vector3(1, 1, 1);
				gltf.scene.scale.copy(scale);
				const position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z + 0.05);
				gltf.scene.position.copy(position);
				setHardwareType('')
				const alreadyAdded = addedBarHandlesPos?.current?.length && addedBarHandlesPos.current.some((pos) => pos.equals(position))
				addedBarHandlesPos.current.push(position)

				if (alreadyAdded) {
					const clonedModel = gltf.scene.clone();
					const oppositePosition = position.clone();
					oppositePosition.z = -oppositePosition.z;
					clonedModel.position.copy(oppositePosition);
					clonedModel.name += ` ${type}internal`
					clonedModel.rotation.x += Math.PI;
					sceneRef.current.add(clonedModel);
					barHandlesOffsetInternal.current.push(clonedModel)
					barHandlesOffsetInternalPos.current.push(oppositePosition)
				} else {
					gltf.scene.name += ` ${type}external`
					sceneRef.current.add(gltf.scene);
					barHandlesOffsetExternal.current.push(gltf.scene)
					barHandlesOffsetExternalPos.current.push(position)
				}
			});
		}
	}

	function addDoubleSidedElement(element, objectPosition, data, alreadyAdded) {
		const doubleSideModelLoader = new GLTFLoader()

		if (element) {
			doubleSideModelLoader.load(element, function (gltf) {
				const scale = new THREE.Vector3(1, 1, 1);
				gltf.scene.scale.copy(scale);
				const position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z - 0.01);
				gltf.scene.position.copy(position);


				gltf.scene.traverse((child) => {
					if (child.name.includes("External")) {
						escutcheonExternalModel.current.push(child);
						escutcheonExternalPos.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z));
					}

					if (child.name.includes("Internal")) {
						escutcheonInternalModel.current.push(child);
						// escutcheonInternalPos.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z))
					}

					// if (child.name.includes("Cylinder")) {
					// 	handleCylinder.current.push(child);
					// 	// handlePosition.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z));
					// }

				});
				setHardwareType('')
				//  windowRef.current.add(gltf.scene);
				// render();
				sceneRef.current.add(gltf.scene);

				if (!alreadyAdded) {
					setCustomModelData(prevModelData => {
						let escutcheonArray = prevModelData.hardware?.escutcheon ?? []; // Retrieve the existing array or initialize to empty array
						return {
							...prevModelData,
							hardware: {
								...prevModelData.hardware,
								escutcheon: [
									...escutcheonArray,
									{
										id: data?.id, // Assuming length is defined elsewhere
										name: data?.name, // Store the element in the name array
										color: "Anthracite grey",
										type: "escutcheon",
										position: { position: objectPosition?.position },
										file: element,
										price: data?.price,
										imagePath: data?.imagePath
									}
								]
							}
						};
					});
				}
				else {
					setIsHardwareAdded((prevAdded) => ({
						...prevAdded,
						escutcheon: true
					}))
				}
			});
		}
	}

	function addElement(element, objectPosition, isAlreadyAdded, data) {
		const elementLoader = new GLTFLoader()

		if (element) {
			elementLoader.load(element, function (gltf) {
				const scale = new THREE.Vector3(1, 1, 1);
				gltf.scene.scale.copy(scale);

				var position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z + 0.06);

				if (collectionDetails && collectionDetails.typeId == 2) {
					position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z + 0.035);
				}

				gltf.scene.position.copy(position);
				if (element.includes("TickleVent")) {
					ticklePath.current = element;
					tickleObjects.current.push(objectPosition);
					tickleVentModels.current.push(gltf.scene);
					tickleVentPosition.current.push(new THREE.Vector3(gltf.scene.position.x, gltf.scene.position.y, gltf.scene.position.z));
				}

				setHardwareType('')
				//  gltf.scene.rotation.set(0,90,0); 
				sceneRef.current.add(gltf.scene);
				// gltfModel.current.add(gltf.scene);
				// render();
			});

		}

		if (!isAlreadyAdded) {
			setCustomModelData((prevModelData) => {
				const trickleArrayy = prevModelData?.hardware?.trickleVent || [];
				return {
					...prevModelData,
					hardware: {
						...prevModelData.hardware,
						trickleVent: [
							...(trickleArrayy.length > 0 ? trickleArrayy : []),
							getObjectData("trickle", element, objectPosition, data)
						],
					},
				};
			});
		}
		else {
			setIsHardwareAdded((prevAdded) => ({
				...prevAdded,
				trickleVent: true
			}))
		}
	}

	//   helper function to store data in the get object function
	function updateCustomModelData(type, element, objectPosition, data) {
		setCustomModelData(prevModelData => ({
			...prevModelData,
			hardware: {
				...prevModelData?.hardware,
				[type]: [
					...(Array.isArray(prevModelData?.hardware?.[type]) ? prevModelData?.hardware?.[type] : []),
					getObjectData(type?.toLowerCase(), element, objectPosition, data)
				]
			}
		}));
	}

	function addTopElements(element, objectPosition, data, isAlreadyAdded) {
		const elementLoader = new GLTFLoader();

		if (element) {
			elementLoader.load(element, function (gltf) {
				var scale = new THREE.Vector3(6, 6, 6);
				gltf.scene.scale.copy(scale);

				var position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z - 0.05);
				gltf.scene.position.copy(position);

				if (element.includes("Spyhole")) {
					const newSpyHole = gltf.scene;
					spyHoleModel.current.push(newSpyHole);
					position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z - 0.02);
					gltf.scene.position.copy(position);
					spyHolePosition.current.push(new THREE.Vector3(newSpyHole.position.x, newSpyHole.position.y, newSpyHole.position.z));
					if (!isAlreadyAdded) {
						// updateCustomModelData('spyhole', element, objectPosition);
						setCustomModelData((prevModelData) => {
							const spyholeArray = prevModelData?.hardware?.spyHole || [];
							return {
								...prevModelData,
								hardware: {
									...prevModelData.hardware,
									spyhole: [
										...(spyholeArray.length > 0 ? spyholeArray : []),
										getObjectData("spyhole", element, objectPosition, data)
									],
								},
							};
						});
					}
					else {
						setIsHardwareAdded((prevAdded) => ({
							...prevAdded,
							spyhole: true
						}))
					}
				}

				if (element.includes("Letterplate")) {
					const newLetterPlate = gltf.scene;
					letterPlateModel.current.push(newLetterPlate);
					letterPlatePos.current.push(new THREE.Vector3(newLetterPlate.position.x, newLetterPlate.position.y, newLetterPlate.position.z));
					updateCustomModelData('letterPlate', element, objectPosition, data);
				}

				if (element.includes("Knocker")) {
					const newKnocker = gltf.scene;
					knockerModel.current.push(newKnocker);
					knockerPos.current.push(new THREE.Vector3(newKnocker.position.x, newKnocker.position.y, newKnocker.position.z));
					if (!isAlreadyAdded) {
						updateCustomModelData('knocker', element, objectPosition, data);
					}
					else {
						setIsHardwareAdded((prevAdded) => ({
							...prevAdded,
							knocker: true
						}))
					}
				}

				setHardwareType('');
				sceneRef.current.add(gltf.scene);
			});
		}
	}

	function createText(text, position, hex, isAlreadyAdded, element) {

		let textMesh1
		let textGeo
		const loader = new FontLoader()

		if (textMeshRef.current && gltfModel.current) {
			sceneRef.current.remove(textMeshRef.current)
		}

		loader.load("https://jackodiamond.github.io/babylonGroundTracking/Roboto_Regular.json", function (font) {
			textGeo = new TextGeometry(text?.toString(), {
				font: font,
				size: 0.7,
				height: 0.7,
			});

			const materials = new THREE.MeshStandardMaterial({ color: hex ? hex : 0x373f43, metalness: 1 }) // side

			textMesh1 = new THREE.Mesh(textGeo, materials);
			let units = 0.01
			textMesh1.position.x = position.position?.x;
			textMesh1.position.z = position.position?.z - 0.07;
			textMesh1.position.y = position.position?.y - 0.06;
			// textMesh1.position.x = element==="no"?position.position?.x - 0.07:(parseInt(element?.horizontalPos))*units;
			// textMesh1.position.z = position.position?.z - 0.07;
			// textMesh1.position.y = element==="no"?position.position?.y - 0.06:(parseInt(element?.verticalPos))*units;
			textMesh1.scale.x = 0.1;
			textMesh1.scale.y = 0.1;
			textMesh1.scale.z = 0.03;

			textMeshRef.current = textMesh1;
			textMeshPos.current = new THREE.Vector3(textMesh1.position.x, textMesh1.position.y, textMesh1.position.z)
			if (textMeshRef.current) {
				sceneRef.current.add(textMeshRef.current)
			}
		})
		if (isAlreadyAdded) {
			setIsHardwareAdded((prevAdded) => ({
				...prevAdded,
				numeral: true
			}))
		}
		// else{
		// 	setIsHardwareAdded((prevAdded) => ({
		// 		...prevAdded,
		// 		numeral: false
		// 	}))
		// }

	}



	function addNumerals(element, objectPosition, isAlreadyAdded, data) {

		createText(element, objectPosition, '', false, "no")
		if (!isAlreadyAdded) {
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					numeral: [...(prevModelData.hardware?.numeral || []),
					getObjectData("numeral", element, objectPosition, data)],
				},
			}));
		}
		setHardwareType('')
	}

	function addFrameTop(isTop, data, direction) {
		//replace with couplerWidth = couplerData.width/20, same for couplerHeight
		const couplerWidth = data?.width / 20
		const couplerHeight = data?.height / 20;
		var countTop = 1
		var countBottom = 1
		sceneRef.current.traverse((item) => {
			if (item?.name.includes("parallel top")) {
				countTop++
			}
			if (item?.name.includes("parallel bottom")) {
				countBottom++
			}
		})
		const loader = new GLTFLoader()
		loader.load(`${servicePath}/ThreeJSModel/Glb/${data?.customePath}`, function (gltf) {
			sceneRef.current.add(gltf.scene);
			const boundingBox4 = new THREE.Box3().setFromObject(gltf.scene);
			const boundingBox2 = new THREE.Box3().setFromObject(gltfModel.current);
			const width4 = boundingBox4.max.y - boundingBox4.min.y;
			const width2 = boundingBox2.max.x - boundingBox2.min.x;
			const gltfModelDepth = boundingBox2.max.z - boundingBox2.min.z;

			gltf.scene.scale.y = width2 / width4;

			gltf.scene.rotation.z = Math.PI / 2;

			gltf.scene.position.z = boundingBox2.max.z;

			var base;
			gltf.scene.traverse((child) => {
				if (child.name.includes("Base")) {
					base = child;
					base.scale.x *= couplerWidth;
					base.scale.z *= couplerHeight;
					const boundingBoxBase = new THREE.Box3().setFromObject(base);
					const baseDepth = boundingBoxBase.max.z - boundingBoxBase.min.z;
					base.position.z -= baseDepth / 2;
					child.name = "BaseInternal";
					internalFrameRef.current.push(child);
				}

				if (child.name.includes("Front")) {
					child.name = "FrontExternal";
					externalFrameRef.current.push(child);
				}
			})

			const boundingBoxBase = new THREE.Box3().setFromObject(base);
			const baseWidth = boundingBoxBase.max.x - boundingBoxBase.min.x;
			const baseDepth = boundingBoxBase.max.z - boundingBoxBase.min.z;

			if (isTop) {
				gltf.scene.position.y = boundingBox2.max.y + baseWidth / 2;
			} else {
				gltf.scene.position.y = boundingBox2.min.y - baseWidth / 2;
			}

			if (baseDepth < gltfModelDepth / 2) {
				const backSideCoupler = gltf.scene.clone();

				backSideCoupler.scale.z = -1;
				backSideCoupler.position.z = boundingBox2.min.z;
				sceneRef.current.add(backSideCoupler)

				backSideCoupler.traverse((child) => {

					if (child.name.includes("Front")) {
						child.name = "FrontInternal";
						internalFrameRef.current.push(child);
					}
				})
			}

			newFrameRefPoints.current.push(gltfModel.current)

			extraStorage.current.push(gltfModel.current)

			const clonedModel = gltfModel.current.clone();
			//	clonedModel.scale.y = 0.3;
			const boundingBox = new THREE.Box3().setFromObject(gltfModel.current);
			const boundingBox5 = new THREE.Box3().setFromObject(clonedModel);
			const height = boundingBox5.max.y - boundingBox5.min.y;
			// Set the position and scale of the cloned model (adjust as needed)
			if (isTop) {
				clonedModel.name += ` parallel top ${countTop}`
				clonedModel.position.set(0, (boundingBox.max.y + baseWidth + height / 2) * countTop, 0);
				addedFramePosition.current.push("top");
			} else {
				clonedModel.name += ` parallel bottom ${countBottom}`
				clonedModel.position.set(0, (boundingBox.min.y - baseWidth - height / 2) * countBottom, 0);
				addedFramePosition.current.push("bottom");
			}


			addedFrame.current.push(clonedModel);
			//clonedModel.scale.y = gltfModel.current.scale.y;
			sceneRef.current.add(clonedModel);

			clonedModel.traverse((child) => {
				if (child.name === "FrameLeft" || child.name === "FrameRight" || child.name === "FrameTop" || child.name === "FrameBottom") {
					getUpdatedFrameRefs.current.push(child)
				}
			})

			if (addedFrameData) {
				setTimeout(() => {
					updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, false, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
				}, 100);
			}
			newFrameRefPoints.current.push(...newFrameRefPoints.current, clonedModel)
			extraStorage.current.push(...extraStorage.current, clonedModel)
			testRef.current.push(...getUpdatedFrameRefs.current, ...addFrameRef.current)
			// Saving data 
			saveAdditionalFrameData(setCustomModelData, addedFrameData, couplerData[0], direction, allFrameCollection, allStyleCollection, clonedModel, "", "parallel")
		})

	}


	function addFrame(isLeft, data, direction) {

		//replace with couplerWidth = couplerData.width/20, same for couplerHeight
		const couplerWidth = data && data?.width / 20
		const couplerHeight = data && data?.height / 20;
		const emptyMesh = new THREE.Mesh();
		let tempObj = {};
		const loader = new GLTFLoader()
		var countLeft = 1
		var countRight = 1
		sceneRef.current.traverse((item) => {
			if (item?.name.includes("parallel left")) {
				countLeft++
			}
			if (item?.name.includes("parallel right")) {
				countRight++
			}
		})
		if (data)
			loader.load(`${servicePath}/ThreeJSModel/Glb/${data?.customePath}`, function (gltf) {
				sceneRef.current.add(gltf.scene);
				const boundingBox4 = new THREE.Box3().setFromObject(gltf.scene);
				const boundingBox2 = new THREE.Box3().setFromObject(gltfModel.current);
				const width4 = boundingBox4.max.y - boundingBox4.min.y;
				const width2 = boundingBox2.max.y - boundingBox2.min.y;
				const gltfModelDepth = boundingBox2.max.z - boundingBox2.min.z;

				gltf.scene.scale.y = width2 / width4;


				gltf.scene.position.z = boundingBox2.max.z;

				var base;
				gltf.scene.traverse((child) => {
					if (child.name.includes("Base")) {
						base = child;
						base.scale.x *= couplerWidth;
						base.scale.z *= couplerHeight;
						const boundingBoxBase = new THREE.Box3().setFromObject(base);
						const baseDepth = boundingBoxBase.max.z - boundingBoxBase.min.z;
						base.position.z -= baseDepth / 2;
						child.name += " Internal";

					}

					if (child.name.includes("Front")) {
						child.name += " External";
					}
				})

				gltf.scene.traverse((child) => {
					if (child.name.includes("Internal")) {
						internalFrameRef.current.push(child);
					} else if (child.name.includes("External")) {
						externalFrameRef.current.push(child);

					}
				})

				const boundingBoxBase = new THREE.Box3().setFromObject(base);
				const baseWidth = boundingBoxBase.max.x - boundingBoxBase.min.x;
				const baseDepth = boundingBoxBase.max.z - boundingBoxBase.min.z;

				if (isLeft) {
					gltf.scene.position.x = boundingBox2.max.x + baseWidth / 2;
				} else {
					gltf.scene.position.x = boundingBox2.min.x - baseWidth / 2;
				}

				if (baseDepth < gltfModelDepth / 2) {
					const backSideCoupler = gltf.scene.clone();

					backSideCoupler.scale.z = -1;
					backSideCoupler.position.z = boundingBox2.min.z;
					sceneRef.current.add(backSideCoupler)

					backSideCoupler.traverse((child) => {

						if (child.name.includes("Front")) {
							child.name = "FrontInternal";
							internalFrameRef.current.push(child);
						}
					})
				}

				extraStorage.current.push(gltfModel.current)

				// Clone the original model
				const clonedModel = gltfModel.current.clone();
				newFrameRefPoints?.current.push(gltfModel.current)
				const boundingBox = new THREE.Box3().setFromObject(gltfModel.current);
				const width = boundingBox.max.x - boundingBox.min.x;
				// Set the position and scale of the cloned model (adjust as needed)
				if (isLeft) {
					clonedModel.position.set((boundingBox.max.x + baseWidth + width / 2) * (countLeft), 0, 0);//0.42
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}
					addedFramePosition.current.push("left");
					clonedModel.name += ` parallel left ${countLeft}`
				} else {
					clonedModel.position.x += (boundingBox.min.x - baseWidth - width / 2) * countRight
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}
					addedFramePosition.current.push("right");
					clonedModel.name += ` parallel right ${countRight}`

				}
				addedFrame.current.push(clonedModel);
				sceneRef.current.add(clonedModel);
				clonedModel.traverse((child) => {
					const boundingBox = new THREE.Box3().setFromObject(child);
					// Check conditions before pushing the frame into the getUpdatedFrameRefs array
					if (child.name === "FrameLeft" && isLeft) {
						// Don't push its right child
						emptyMesh.position.set(child.position)
						emptyMesh.position.x = child.position.x + boundingBox.max.x
						emptyMesh.name = "FrameLeftPerpendicular"
						getUpdatedFrameRefs.current.push(child, emptyMesh);
					} else if (child.name === "FrameRight" && !isLeft) {
						// Don't push its left child
						emptyMesh.position.set(child.position)
						emptyMesh.position.x = child.position.x + boundingBox.min.x
						emptyMesh.name = "FrameRightPerpendicular"

						getUpdatedFrameRefs.current.push(child, emptyMesh);
					} else if (child.name === "FrameTop") {
						// Don't push its bottom child
						emptyMesh.position.set(child.position)
						emptyMesh.position.y = child.position.y - boundingBox.min.y + 0.5
						emptyMesh.name = "FrameTopPerpendicular"

						getUpdatedFrameRefs.current.push(child, emptyMesh);
					} else if (child.name === "FrameBottom") {
						// Don't push its top child

						emptyMesh.position.set(child.position)
						emptyMesh.position.y = child.position.y - boundingBox.max.y - 0.5
						emptyMesh.name = "FrameBottomPerpendicular"
						getUpdatedFrameRefs.current.push(child, emptyMesh);
					}
					if (child.name.includes("Frame")) {
						setNewFrameData(prevFrameData => [...prevFrameData, child])
					}
				});


				// 

				// Object.keys(tempObj).forEach((key) => {
				// 	const existingIndex = getUpdatedFrameRefs.current.findIndex(frame => frame.name === key);
				// 	if (existingIndex !== -1) {
				// 		getUpdatedFrameRefs.current[existingIndex] = tempObj[key];
				// 	} else {
				// 		getUpdatedFrameRefs.current.push(tempObj[key]);
				// 	}
				// });



				newFrameRefPoints?.current?.push(...newFrameRefPoints.current, clonedModel)
				testRef.current.push(...getUpdatedFrameRefs.current)
				setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

				if (addedFrameData) {
					setTimeout(() => {
						updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, false, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
					}, 100);
				}
				//saving data
				saveAdditionalFrameData(setCustomModelData, addedFrameData, couplerData[0], direction, allFrameCollection, allStyleCollection, clonedModel, "", "parallel")
			});
	}

	function addEvesFrame(angle, data, direction) {

		// Clone the original model
		const clonedModel = gltfModel.current.clone();
		newFrameRefPoints.current.push(gltfModel.current)
		extraStorage.current.push(gltfModel.current)
		const boundingBox = new THREE.Box3().setFromObject(gltfModel.current);
		const width = boundingBox.max.x - boundingBox.min.x;
		const widthZ = boundingBox.max.z - boundingBox.min.z


		if (angle === "" || angle === undefined || angle === null || angle < 90) {
			angle = 90
		}


		angle = 100


		// if (headerSelectedItem.name !== "Oriel Window") {
		clonedModel.rotation.y = (180 - angle) * (Math.PI / 180);
		// }


		const m = (0.11 - 0.04) / (180 - 90)
		const b = 0.04
		const offset = m * (angle - 90) + b

		if (headerSelectedItem.name !== "Oriel Window") {
			clonedModel.rotation.x = Math.PI / 2;

		}
		clonedModel.rotation.y = 0;

		clonedModel.rotation.z = Math.PI;

		newFrameRefPoints?.current.push(gltfModel.current)
		extraStorage.current.push(gltfModel.current)

		const boundingBox6 = new THREE.Box3().setFromObject(frameStyleTop.current);
		const boundingBoxLeft = new THREE.Box3().setFromObject(frameStyleRight.current)
		clonedModel.rotation.x = (angle) * (Math.PI / 180);
		const boundingBoxClone = new THREE.Box3().setFromObject(clonedModel);


		let adjustment = headerSelectedItem.name === "Oriel Window" ? 0.05 : 0
		const yFinalIncrement = (clonedModel.position.y - boundingBoxClone.min.y) - adjustment;
		const xFinalIncrement = clonedModel.position.x - boundingBoxClone.min.x

		const zFinalIncrement = clonedModel.position.z - boundingBoxClone.min.z;

		let pitchDisplacement = 0.18
		const height = boundingBox.max.y - boundingBox.min.y;

		// clonedModel.rotation.y = Math.PI;


		clonedModel.scale.x *= -1




		if (angle === 90) {
			clonedModel.position.set(0, boundingBox6.min.y + yFinalIncrement, (boundingBox6.max.z - (height * 0.5)));

			clonedModel.scale.z = -1

			const tiltAngle = THREE.MathUtils.degToRad(angle)
			clonedModel.rotation.x = tiltAngle


			if (addedFrameData) {
				setAddedFrameData((prevData => ({
					...prevData,
					position: clonedModel?.position
				})))
			}
		} else {
			clonedModel.position.set(0, frameStyleTop.current.position.y + yFinalIncrement, boundingBox6.max.z - zFinalIncrement);

			if (addedFrameData) {
				setAddedFrameData((prevData => ({
					...prevData,
					position: clonedModel?.position
				})))
			}
		}



		clonedModel.name = " topPerpendicular"
		addedFramePosition.current.push("topPerpendicular");

		if (headerSelectedItem.name !== "Oriel Window") {
			clonedModel.traverse((child) => {
				if (child.name.includes("Bottom")) {
					child.visible = false;

				}
			})
		}




		clonedModel.traverse((child) => {
			if (child.isMesh) {
				if (child.name.includes("External")) {
					externalFrameRef.current.push(child);
				}
				if (child.name.includes("Internal")) {
					internalFrameRef.current.push(child);
				}
			}
		});
		saveAdditionalFrameData(setCustomModelData, addedFrameData, data, direction, allFrameCollection, allStyleCollection, clonedModel, angle, "perpendicuar", true)
		newFrameRefPoints?.current?.push(...newFrameRefPoints.current, clonedModel)
		extraStorage.current.push(...extraStorage.current, clonedModel)
		setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

		sceneRef.current.add(clonedModel)

	}





	function addFramePerpendicular(modelUrl, isLeft, angle, data, isTop, direction) {

		const loader = new GLTFLoader()
		var countTop = 1
		var countBottom = 1
		var countLeft = 1
		var countRight = 1
		sceneRef.current.traverse((item) => {
			if (item?.name.includes("topPerpendicular")) {
				countTop += 1.9
			}
			if (item?.name.includes("bottomPerpendicular")) {
				countBottom += 1.9
			}
			if (item?.name.includes("leftPerpendicular")) {
				countLeft += 1.9
			}
			if (item?.name.includes("rightPerpendicular")) {
				countRight += 1.9
			}
		})

		var topFrameBoundingBox;





		if (modelUrl.includes(undefined)) {
			modelUrl = "https://testorbitapp.augursapps.com/api/ThreeJSModel/Glb/SquareFramelessJoint90°.glb"
		}


		loader.load(modelUrl, function (gltf) {
			if (headerSelectedItem.name !== "Oriel Window") {
				sceneRef.current.add(gltf.scene);
				storeBayPost.current = gltf.scene
			}
			const boundingBox4 = new THREE.Box3().setFromObject(gltf.scene);
			const boundingBox2 = new THREE.Box3().setFromObject(gltfModel.current);
			const width4 = boundingBox4.max.y - boundingBox4.min.y;
			const width2 = boundingBox2.max.y - boundingBox2.min.y;
			const width3 = boundingBox2.max.x - boundingBox2.min.x;

			const gltfModelDepth = boundingBox2.max.z - boundingBox2.min.z;

			gltf.scene.scale.y = width2 / width4;

			//	gltf.scene.scale.x = data.width * 0.05;
			//	gltf.scene.scale.z = data.height * 0.05;

			const boundingBox7 = new THREE.Box3().setFromObject(gltf.scene);
			const depth7 = boundingBox7.max.z - boundingBox7.min.z;
			const xWidth7 = boundingBox7.max.x - boundingBox7.min.x;
			gltf.scene.position.z = boundingBox4.max.z - depth7 / 2;

			gltf.scene.traverse((child) => {
				child.name += " External"
			});

			gltf.scene.traverse((child) => {
				if (child.name.includes("External")) {
					externalFrameRef.current.push(child);
				}
			});

			// Clone the original model
			const clonedModel = gltfModel.current.clone();
			newFrameRefPoints.current.push(gltfModel.current)
			extraStorage.current.push(gltfModel.current)
			const boundingBox = new THREE.Box3().setFromObject(gltfModel.current);

			const width = boundingBox.max.x - boundingBox.min.x;
			const widthZ = boundingBox.max.z - boundingBox.min.z;
			const height = boundingBox.max.y - boundingBox.min.y;

			// load the baypost
			// set its position to right of the bounding box
			// to add it to its left use this formula boundingBox.max.x - ((addOnBoundingBox.max.x - addOnBoundingBox.min.x) / 2);
			// bounding box of baypost
			// x position of clonedModel baypostboundingbox.max.x - (cloneModel.max.z - addOnBoundingBox.min.z) / 2)
			// Set the position and scale of the cloned model (adjust as needed)

			if (angle === "" || angle === undefined || angle === null || angle < 90) {
				angle = 90
			}

			//angle =150

			clonedModel.rotation.y = (180 - angle) * (Math.PI / 180);
			const boundingBoxClone = new THREE.Box3().setFromObject(clonedModel);
			const xFinalIncrement = clonedModel.position.x - boundingBoxClone.min.x;
			const zFinalIncrement = clonedModel.position.z - boundingBoxClone.min.z;
			const yHeight = boundingBoxClone.max.y - boundingBoxClone.min.y;
			const zWidth = boundingBoxClone.max.z - boundingBoxClone.min.z;

			const m = (0.11 - 0.04) / (180 - 90)
			const b = 0.04
			const offset = m * (angle - 90) + b

			if (isTop && isTop !== "bottom") {

				gltf.scene.scale.y = width3 / width4;
				clonedModel.rotation.x = Math.PI / 2;
				clonedModel.rotation.y = 0;
				clonedModel.rotation.z = Math.PI;
				gltf.scene.rotation.z = Math.PI / 2;
				gltf.scene.position.y = boundingBox2.max.y + xWidth7 / 2;
				const boundingBox6 = new THREE.Box3().setFromObject(gltf.scene);
				clonedModel.rotation.x = (angle) * (Math.PI / 180);
				const boundingBoxClone = new THREE.Box3().setFromObject(clonedModel);
				const xFinalIncrement = clonedModel.position.x - boundingBoxClone.min.x;
				let adjustment = headerSelectedItem.name === "Oriel Window" ? 0.12 : 0
				let zAdjustment = headerSelectedItem.name === "Oriel Window" ? 0.05 : 0
				const yFinalIncrement = clonedModel.position.y - boundingBoxClone.min.y - adjustment;

				const zFinalIncrement = clonedModel.position.z - boundingBoxClone.min.z;
				const yHeight = boundingBoxClone.max.y - boundingBoxClone.min.y;
				const zWidth = boundingBoxClone.max.z - boundingBoxClone.min.z;

				let pitchDisplacement = 0
				if (headerSelectedItem.name === "Oriel Window") {
					pitchDisplacement = 0.18
				}

				if (angle === 90) {
					clonedModel.position.set(0, boundingBox6.min.y + yFinalIncrement + pitchDisplacement - 0.03, (((boundingBox6.min.z - height / 2) * countTop) + zAdjustment));
					if (headerSelectedItem.name === "Oriel Window") {
						const tiltAngle = THREE.MathUtils.degToRad(95)
						clonedModel.rotation.x = tiltAngle
					}
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}
				} else {
					clonedModel.position.set(0, gltf.scene.position.y + yFinalIncrement, boundingBox6.max.z - zFinalIncrement);
					// clonedModel.position.y = boundingBox6.max.y + yFinalIncrement
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}
				}



				topFrameBoundingBox = new THREE.Box3().setFromObject(clonedModel);
				topLeftCorner.current = new THREE.Vector3(topFrameBoundingBox.min.x, topFrameBoundingBox.max.y, topFrameBoundingBox.min.z)
				topRightCorner.current = new THREE.Vector3(topFrameBoundingBox.max.x, topFrameBoundingBox.max.y, topFrameBoundingBox.min.z)
				// const geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
				// const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
				// const cube = new THREE.Mesh(geometry, material);
				// cube.position.set(topRightCorner.current.x, topRightCorner.current.y, topRightCorner.current.z)

				// // Add the cube to the scene
				// sceneRef.current.add(cube);
				clonedModel.name = " topPerpendicular"

				addedFramePosition.current.push("topPerpendicular");

			} else if (isTop === "bottom") {
				gltf.scene.scale.y = width3 / width4;
				clonedModel.rotation.x = Math.PI / 2;
				clonedModel.rotation.y = 0;
				clonedModel.rotation.z = Math.PI;
				gltf.scene.rotation.z = Math.PI / 2;
				gltf.scene.position.y = boundingBox2.min.y - xWidth7 / 2;
				const boundingBox6 = new THREE.Box3().setFromObject(gltf.scene);

				if (angle === 90) {
					clonedModel.position.set(0, -boundingBox2.max.y, ((boundingBox6.min.z - height / 2) * (countBottom)));
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}

				} else {
					clonedModel.position.set(0, -boundingBox2.max.y + 0.05, (boundingBox6.min.z - yHeight / 2) * countBottom);
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}
				}
				clonedModel.name = " bottomPerpendicular"
				addedFramePosition.current.push("bottomPerpendicular");
			}
			else {
				if (isLeft) {
					gltf.scene.position.x = boundingBox2.max.x + xWidth7 / 2;
					const boundingBox6 = new THREE.Box3().setFromObject(gltf.scene);

					if (angle === 90) {
						clonedModel.position.set((boundingBox6.min.x + widthZ / 2 - 0.05), 0, (boundingBox6.max.z - width / 2));
						if (addedFrameData) {
							setAddedFrameData((prevData => ({
								...prevData,
								position: clonedModel?.position
							})))
						}
					} else {
						clonedModel.position.set((boundingBox6.min.x + xFinalIncrement - widthZ / 2 - 0.05), 0, (boundingBox6.max.z - zFinalIncrement + offset) * countLeft);
						if (addedFrameData) {
							setAddedFrameData((prevData => ({
								...prevData,
								position: clonedModel?.position
							})))
						}
					}



					addedFramePosition.current.push("leftPerpendicular");
					clonedModel.name += " leftPerpendicular"


				} else {


					clonedModel.rotation.y = (angle) * (Math.PI / 180);
					const boundingBoxClone = new THREE.Box3().setFromObject(clonedModel);
					const xFinalIncrement = clonedModel.position.x - boundingBoxClone.min.x;
					const zFinalIncrement = clonedModel.position.z - boundingBoxClone.min.z;

					gltf.scene.rotation.y = Math.PI;
					gltf.scene.position.x = boundingBox2.min.x - xWidth7 / 2;
					const boundingBox6 = new THREE.Box3().setFromObject(gltf.scene);
					if (angle === 90) {
						clonedModel.position.set(boundingBox6.min.x + widthZ / 2 + 0.05, 0, (boundingBox6.max.z - width / 2));
						if (addedFrameData) {
							setAddedFrameData((prevData => ({
								...prevData,
								position: clonedModel?.position
							})))
						}


					} else {
						clonedModel.position.set(boundingBox6.max.x - xFinalIncrement - widthZ / 2 + 0.05, 0, (boundingBox6.max.z - zFinalIncrement + offset) * countRight);
						if (addedFrameData) {
							setAddedFrameData((prevData => ({
								...prevData,
								position: clonedModel?.position
							})))
						}

					}

					clonedModel.name += " rightPerpendicular"
					addedFramePosition.current.push("rightPerpendicular");
				}
			}

			clonedModel.traverse((child) => {
				if (child instanceof THREE.Mesh) {
					// Do something with each child 
					if (child.name.includes("GlassPanel")) {
						glassGroup.current.push(child);
						defaultGlassMaterial.current = child.material;
						const newObject = new THREE.Object3D();
						const worldPosition = new THREE.Vector3();
						child.getWorldPosition(worldPosition);
						newObject.position.copy(worldPosition);
						setUniqueSideRefernce((prevReference) => [...prevReference, newObject])
					}
				}


				addedFrame.current.push(clonedModel);

				if (child.isMesh) {
					if (child.name.includes("External")) {
						externalFrameRef.current.push(child);
					}
					if (child.name.includes("Internal")) {
						internalFrameRef.current.push(child);
					}
				}
			});

			setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

			if (!isLeft || isTop === "top") {
				clonedModel.scale.z *= -1;
			}

			if (isLeft || isTop === "bottom") {
				clonedModel.scale.z *= 1;
			}

			if (headerSelectedItem.name === "Oriel Window") {
				if (isTop && isTop !== "bottom") {
					resizeAdditionalFrame(clonedModel, true, true, false, customModelData.layoutFrame.width, customModelData.layoutFrame.width, headerSelectedItem, topLeftCorner, sceneRef, topRightCorner)
				}
			}

			if (headerSelectedItem.name === "Oriel Window" && addedFramePosition.current.includes("topPerpendicular") && (addedFramePosition.current.includes("leftPerpendicular") || addedFramePosition.current.includes("rightPerpendicular"))) {
				scaleAddedFrames(isLeft, isTop)
			}

			sceneRef.current.add(clonedModel);

			// if (isTop && isTop !== "bottom") {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, false, true, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }
			// if (isLeft) {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, true, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }
			// if (!isLeft) {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, true, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }
			// if (isTop && isTop !== "bottom") {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, false, true, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }
			// if (isLeft) {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, true, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }
			// if (!isLeft) {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, true, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }

			clonedModel.traverse((child) => {
				if (child.name === "FrameLeft" || child.name === "FrameRight" || child.name === "FrameTop" || child.name === "FrameBottom") {
					getUpdatedFrameRefs.current.push(child)
				}
			})
			newFrameRefPoints.current.push(...newFrameRefPoints.current, clonedModel)
			extraStorage.current.push(...extraStorage.current, clonedModel)
			getUpdatedFrameRefs.current = removeDuplicatesByPosition(getUpdatedFrameRefs.current)
			testRef.current.push(...getUpdatedFrameRefs.current, ...addFrameRef.current)
			testRef.current = removeDuplicatesByPosition(testRef.current)
			setBayPostDeg(0)

			let isOriel = headerSelectedItem.name === "Oriel Window" ? true : false

			// Saving data
			saveAdditionalFrameData(setCustomModelData, addedFrameData, data, direction, allFrameCollection, allStyleCollection, clonedModel, angle, "perpendicuar", isOriel)
		});
	}


	const handleFrameSingleSide = (data, index, checked) => {
		if (checked) {
			setCheckSingleSide(index)
			setCheckClickedProfile(data)
			setIsUniqueSide(checked)
		} else {
			setCheckSingleSide()
			setCheckClickedProfile()
			setIsUniqueSide(checked)
		}
	}

	function render() {
		renderer.render(scene, camera);
	}

	function animate() {
		requestAnimationFrame(animate);

		// updateOverlayPosition(gltfModel, camera, pointInScreen, testRef, newFrameRefPoints, uiLinesRef, uiLinesRefBottom, uiLinesRefFrame, allProfileRefSeq, glassRefSeq)
		if (testRef.current.length && !newFrameRefPoints.current.length) {
			updateReferencePoints(testRef.current, camera)
		} else if (!testRef.current.length && newFrameRefPoints.current.length) {
			updateReferencePoints(newFrameRefPoints.current, camera)
		} else if (testRef.current.length && newFrameRefPoints.current.length) {
			const refs = [...newFrameRefPoints.current, ...testRef.current]
			updateReferencePoints(refs, camera)
		}

		if (uiLinesRefBottom.current.length > 0) {
			updateReferencePoints(uiLinesRefBottom.current, camera)
		}

		if (mixer) {
			mixer.update(0.01);
		}
		render();
	}


	useEffect(() => {
		if (modelVisible && gltfModel.current && cameraRef.current) {
			getModelRenderedSize(gltfModel, cameraRef, setRenderedSize)
		}
	}, [modelVisible, gltfModel, cameraRef]);


	return (
		<div className='position-relative model_wrap'>
			<div ref={modelWrap}
				style={{
					width: `${renderedSize?.width + 100}px`,
					height: `${renderedSize?.height + 100}px`,
					marginLeft: '-100px',
					marginTop: '-80px'
				}}
			>
				<div id='container' ref={containerRef}>
				</div>

				<div ref={elementRef} id='elements'>
					{(testRef?.current?.length > 0) &&
						<ConfigurationPoints newFrameRefPoints={newFrameRefPoints} bayPostAngleManual={bayPostAngleManual} setBayPostAngleManual={setBayPostAngleManual} setBayPostDeg={setBayPostDeg} bayPostDeg={bayPostDeg} headerSelectedItem={headerSelectedItem} multiSelectRefPoints={multiSelectRefPoints} handleDeleteObject={handleDeleteObject} hardwareType={hardwareType} testRef={testRef} setPopoverIndex={setPopoverIndex} popoverIndex={popoverIndex} elementData={elementData} handleAddHardware={handleAddHardware} frameDrop={frameDrop} toggleFrameDrop={toggleFrameDrop} checkSingleSide={checkSingleSide} handleFrameSingleSide={handleFrameSingleSide} frameType={frameType} currentModel={currentModel} handleFrameDrop={handleFrameDrop} manufacturingToggle={manufacturingToggle} allFrameCollection={allFrameCollection} allStyleCollection={allStyleCollection} toggleStyleDrop={toggleStyleDrop} styleDrop={styleDrop} handleStyleDrop={handleStyleDrop} styleType={styleType} />
					}

					{(allProfileRefSeq?.current?.length > 0) &&
						<SeqNumberRefs profileRef={allProfileRefSeq} glassRef={glassRefSeq} />
					}

					{/* {(uiLinesRef?.current?.length > 0) &&
						<EditSashSizeModel uiLinesRef={uiLinesRef} savedData={customModelData} type="V" />
					} */}

					{(uiLinesRefBottom?.current?.length > 0) &&
						<EditSashSizeModel uiLinesRef={uiLinesRefBottom} savedData={modelJson[jsonIndex]} type="H" />
					}

					{/* {(uiLinesRefFrame?.current?.length > 0) &&
						<EditFrameSizeModel
							uiLinesRef={uiLinesRefFrame}
							accessToken={accessToken}
							setLayoutSizing={setLayoutSizing}
							collectionId={modelId}
							setLoading={setLoading}
							quotationId={quotationId}
							receivedProductId={receivedProductId}
							frameStyleProdId={selectedAnimation}
							frameProfileDefault={frameProfileDefault}
						/>
					} */}
				</div>
			</div>

			<div className='design_footer'>
				<div>
					{/* <input type="range" id="slider" ref={sliderRef} onChange={handleSliderChange} /> */}
				</div>

				{quotationId && (
					<Button
						type='button'
						color="primary"
						size="md"
						className="px-4"
						onClick={() => setCaptureImages(true)}
					>
						Save
					</Button>
				)}
			</div>
		</div>
	);
})

export default BlockPanelDesign;