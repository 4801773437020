import * as THREE from 'three';


let partitionArr = [];
let transomsArr = [];

export const storeTransomPartitionRefs = (obj, callBack) => {
  partitionArr.push(obj);
  if (typeof callBack === 'function') {
    callBack();
  }

}

export const storeTransomRefs = (obj, callBack) => {
  transomsArr.push(obj);
  if (typeof callBack === "function") {
    callBack();
  }
}

export const getTransomPartitionRefs = () => {
  return partitionArr;
}

export const getTransomRefs = () => {
  return transomsArr;
}

export const emptyTransomPartitions = () => {
  partitionArr = [];
}

export const emptyTransomRefs = () => {
  transomsArr = [];
}

export const manipulateTransoms = (transomConfig,data,setMethod , jsonIndex=0) => {
  const {
    transomHeight,
    transomWidth,
    initialRatios,
    orientation,
    sectionId,
    material = "wood",
    // depth = 0.1,
    // height = 0.04,
  } = transomConfig;

  const height = transomHeight / 1000;
  const depth = transomWidth / 1000;

  setMethod(data,0)

  return (prevModelJson) => {

    const updateModelArray = [...prevModelJson]
    const updatedModelJson = { ...updateModelArray[jsonIndex] };

    // Default transom data structure
    const createTransomData = (ratios, orientation, parentId) => ({
      id: `${parentId}_split`,
      type: "transom",
      orientation,
      ratios,
      material: "glass",
      sections: ratios.map((ratio, index) => ({
        id: `${parentId}_section_${index}`,
        material: "glass",
        opacity: 0.6,
        reflectivity: 0.5,
        color: "#B0E0E6",
      })),
    });

    const findAndAddSplit = (node, sectionId, orientation) => {
      if (!node.splits) {
        // Create initial split with two sections
        node.splits = [
          createTransomData([0.5, 0.5], orientation, node.id),
        ];
        return;
      }

      // Traverse splits to find matching sectionId
      for (const split of node.splits) {
        for (const section of split.sections) {
          if (section.id === sectionId) {
            if (!section.splits) {
              section.splits = [
                createTransomData(
                  [0.5, 0.5],
                  orientation, // Alternate orientation
                  section.id
                ),
              ];
            } else {
              findAndAddSplit(section, sectionId, orientation);
            }
            return;
          } else if (section.splits) {
            findAndAddSplit(section, sectionId, orientation);
          }
        }
      }
    };

    // Stack to keep track of nodes to visit
    const stack = [{ node: updatedModelJson }];

    while (stack.length > 0) {
      const { node } = stack.pop();

      // If node has a transom, manipulate its splits
      if (node.transom) {
        if (!node.transom.splits) {
          // Add a default split if none exist
          node.transom = {
            type: "transom",
            material,
            height,
            depth,
            splits: [
              createTransomData([0.5, 0.5], orientation, "transom_split_0"),
            ],
          };
        } else {
          // Add further splits to the specific sectionId in existing splits
          findAndAddSplit(node.transom, sectionId, orientation);
        }
      }
    }

    updateModelArray[jsonIndex] = updatedModelJson;

    return updateModelArray;
  };
};


export const deleteTransomSplit = (deleteConfig , jsonIndex = 0) => {
  const sectionId  = deleteConfig?.data?.name;

  return (prevModelJson) => {
    const updateModelArray = [...prevModelJson]
    const updatedModelJson = { ...updateModelArray[jsonIndex] };

    const deleteSplitArray = (node, sectionId) => {
      if (!node.splits || node.splits.length === 0) return false;

      const lastSplit = node.splits[node.splits.length - 1];

      // Check if the last split contains the matching sectionId
      if (lastSplit.sections.some(section => section.id === sectionId)) {
        delete node.splits; // Remove the entire splits array
        return true;
      }

      // Recursively check nested splits
      for (const section of lastSplit.sections) {
        if (section.splits && deleteSplitArray(section, sectionId)) {
          return true;
        }
      }

      return false;
    };

    const stack = [{ node: updatedModelJson }];

    while (stack.length > 0) {
      const { node } = stack.pop();

      if (node.transom) {
        deleteSplitArray(node.transom, sectionId);
      }
    }

    updateModelArray[jsonIndex] = updatedModelJson;

    return updateModelArray;
  };
};



// export const isButtonDisabled = (modelJson, sectionId) => {
//   const updatedModelJson = { ...modelJson };

//   const checkLastSplitMatch = (node, sectionId) => {
//     if (!node.splits || node.splits.length === 0) return false;

//     const lastSplit = node.splits[node.splits.length - 1];

//     // Check if the last split contains the matching sectionId
//     if (lastSplit.sections.some(section => section.id === sectionId)) {
//       return true; // Last split matches the sectionId
//     }

//     return false; // Last split does not match the sectionId
//   };

//   const stack = [{ node: updatedModelJson }];
//   let matchFound = false;

//   while (stack.length > 0) {
//     const { node } = stack.pop();

//     if (node.transom) {
//       if (checkLastSplitMatch(node.transom, sectionId)) {
//         matchFound = true;
//         break; // Stop searching once a match is found
//       }
//     }
//   }

//   return matchFound; // Return true if a match was found, otherwise false

// };

export const processSplit = (split, parentGroup, startPosX, startPosY, parentWidth, parentHeight, transomFrameHeight, transomFrameDepth, materials) => {
  const orientation = split?.orientation;
  const splitRatios = split?.ratios;

  if (splitRatios && splitRatios.length < 2) {
    console.error("Error: Invalid split ratios. Expected an array with at least two values.");
    return;
  }


  const startPosX2 = startPosX + (orientation === "vertical" ? splitRatios[0] * parentWidth : 0);
  const startPosY2 = startPosY - (orientation === "horizontal" ? splitRatios[0] * parentHeight : 0);
  const section = split?.sections[0];
  const section2 = split?.sections[1];


  if (!section || !section.id || !section2 || !section2.id) {
    console.error("Error: Invalid sections in split. Sections must have an id.");
    return;
  }


  if (orientation === "horizontal") {
    const transomGeometry = new THREE.BoxGeometry(
      parentWidth,
      transomFrameHeight,
      transomFrameDepth
    );
    const transomMesh = new THREE.Mesh(transomGeometry, materials.frameMaterial);
    transomMesh.position.y = startPosY2 - transomFrameHeight / 2;
    transomMesh.position.x = startPosX2 + parentWidth / 2;

    transomMesh.name = section?.id;

    const transomBounding = new THREE.Box3().setFromObject(transomMesh);
    const center = new THREE.Vector3()
    transomBounding.getCenter(center)

    const transomPos = {
      position: { x: center?.x, y: center?.y, z: center?.z },
      name: transomMesh.name
    }
    storeTransomRefs(transomPos, getTransomRefs);

    parentGroup.add(transomMesh);
  } else {
    const transomGeometry = new THREE.BoxGeometry(
      transomFrameHeight,
      parentHeight,
      transomFrameDepth
    );
    const transomMesh = new THREE.Mesh(transomGeometry, materials.frameMaterial);
    transomMesh.position.y = startPosY2 - parentHeight / 2;
    transomMesh.position.x = startPosX2 + transomFrameHeight / 2;
    transomMesh.name = section2?.id;

    const transomBounding2 = new THREE.Box3().setFromObject(transomMesh);
    const center = new THREE.Vector3()
    transomBounding2.getCenter(center)

    const transomPos = {
      position: { x: center?.x, y: center?.y, z: center?.z },
      name: transomMesh.name
    }
    storeTransomRefs(transomPos, getTransomRefs)
    parentGroup.add(transomMesh);
  }

  const splitHeight = split?.orientation === 'horizontal'
    ? splitRatios[0] * parentHeight // Constrain height to parent's height
    : parentHeight;
  const splitWidth = split?.orientation === 'horizontal'
    ? parentWidth
    : splitRatios[0] * parentWidth;
  const splitHeight2 = split?.orientation === 'horizontal'
    ? splitRatios[1] * parentHeight // Constrain height to parent's height
    : parentHeight;
  const splitWidth2 = split?.orientation === 'horizontal'
    ? parentWidth
    : splitRatios[1] * parentWidth;

  if (section.splits) {
    processSplit(
      section.splits[0],
      parentGroup,
      startPosX,
      startPosY,
      splitWidth,
      splitHeight, transomFrameHeight, transomFrameDepth, materials
    );
  } else {
    const glassGeometry = new THREE.BoxGeometry(splitWidth, splitHeight, 0.02);
    const glassMesh = new THREE.Mesh(glassGeometry, materials.glassMaterial);
    glassMesh.position.set(
      startPosX + splitWidth / 2,
      startPosY - splitHeight / 2,
      -0.02 / 2
    );
    glassMesh.name = section?.id;
    const glassBounding = new THREE.Box3().setFromObject(glassMesh);
    const center = new THREE.Vector3()
    glassBounding.getCenter(center)
    const glassPos = {
      position: { x: center?.x, y: center?.y, z: center?.z },
      name: glassMesh.name
    }
    storeTransomPartitionRefs(glassPos, getTransomPartitionRefs)
    parentGroup.add(glassMesh);
  }
  if (section2.splits) {
    processSplit(
      section2.splits[0],
      parentGroup,
      startPosX2,
      startPosY2,
      splitWidth2,
      splitHeight2, transomFrameHeight, transomFrameDepth, materials
    );
  } else {
    const glassGeometry2 = new THREE.BoxGeometry(splitWidth2, splitHeight2, 0.02);
    const glassMesh2 = new THREE.Mesh(glassGeometry2, materials.glassMaterial);
    glassMesh2.position.set(
      startPosX2 + splitWidth2 / 2,
      startPosY2 - splitHeight2 / 2,
      -0.02 / 2
    );
    glassMesh2.name = section2?.id;

    const glassBounding2 = new THREE.Box3().setFromObject(glassMesh2);
    const center = new THREE.Vector3()
    glassBounding2.getCenter(center)
    const glassPos2 = {
      position: { x: center?.x, y: center?.y, z: center?.z },
      name: glassMesh2.name
    }
    storeTransomPartitionRefs(glassPos2, getTransomPartitionRefs)


    parentGroup.add(glassMesh2);
  }
}

export function createTransomGlassPath(windowWidth, windowHeight, sideThickness, transomData, materials) {
  emptyTransomPartitions();
  emptyTransomRefs();

  const transomFrameHeight = transomData.height || 0.01;
  const windowFrameHeight = 0.04;
  const transomFrameDepth = transomData.depth || 0.05;

  const group = new THREE.Group();

  const material = new THREE.MeshStandardMaterial({
    color: transomData.material === 'wood' ? '#8B4513' : '#B0E0E6',
    opacity: 1,
    transparent: false,
    metalness: transomData.reflectivity || 0,
  });

  // Create transom splits
  // transomData?.splits?.forEach((split) => {
  //   const splitRatios = split.ratios;
  //   let startPosition = split.orientation === 'horizontal' ?  
  //   (windowHeight / 2) - windowFrameHeight : 
  //   -(windowWidth / 2) + windowFrameHeight;

  //   let partionIndex = 1;
  //   splitRatios.forEach((ratio) => {
  //     const splitHeight = split.orientation === 'horizontal' ? 
  //     ratio * ((windowHeight - (2 * windowFrameHeight)) - (transomFrameHeight)) :
  //     windowHeight - 2 * windowFrameHeight;

  //     const splitWidth = split.orientation === 'horizontal' ? 
  //     windowWidth - (2 * windowFrameHeight) : 
  //     ratio * ((windowWidth - (2 * windowFrameHeight)) - transomFrameHeight);

  //     const glassGeometry = new THREE.BoxGeometry(
  //       splitWidth,
  //       splitHeight,
  //       0.02
  //     );
  //     const glassMesh = new THREE.Mesh(glassGeometry, materials.glassMaterial);
  //     let transomGeometry = glassGeometry.clone();
  //     const transomMesh = new THREE.Mesh(transomGeometry, materials.frameMaterial);
  //     let transXoffset = 0, transYoffset = 0;
  //     if (split.orientation === 'horizontal') {          
  //       glassMesh.position.set(
  //         0,
  //       startPosition - splitHeight/2,
  //         -0.02 / 2
  //       );
  //       startPosition -= transomFrameHeight + splitHeight;
  //       if(partionIndex == 1){
  //           transomGeometry = new THREE.BoxGeometry(
  //               splitWidth,
  //               transomFrameHeight,
  //               transomFrameDepth
  //           );
  //           transYoffset = startPosition + transomFrameHeight/2;
  //       }

  //     } else {
  //       glassMesh.position.set(
  //         startPosition + splitWidth / 2,
  //         0,
  //         -0.02 / 2
  //       );
  //       startPosition += transomFrameHeight + splitWidth//(splitWidth + 2 * transomFrameHeight);
  //       if(partionIndex == 1){
  //           transomGeometry = new THREE.BoxGeometry(
  //               transomFrameHeight,
  //               splitHeight,
  //               transomFrameDepth
  //           );
  //           transXoffset = startPosition - transomFrameHeight/2;
  //       }

  //     }

  //     split.sections.forEach((section) => {
  //       glassMesh.name = section?.id
  //     })

  //     group.add(glassMesh);
  //     const boundingBox = new THREE.Box3().setFromObject(glassMesh);
  //     const center = new THREE.Vector3()
  //     boundingBox.getCenter(center)  
  //     const glassPos = {
  //       position: { x: center?.x, y: center?.y, z: center?.z },
  //       name: glassMesh?.name
  //     }
  //     storeTransomPartitionRefs(glassPos , getTransomPartitionRefs)

  //     if(partionIndex == 1){
  //       transomMesh.geometry = transomGeometry;
  //       transomMesh.position.y = transYoffset;
  //       transomMesh.position.x = transXoffset;
  //       group.add(transomMesh);
  //       partionIndex++;
  //     }

  //   });
  // });
  // console.log("transomData :::: ", transomData);
  // transomData ={
  //     "type": "transom",
  //     "material": "wood",
  //     "height": 0.03,
  //     "depth": 0.1,
  //     "splits": [
  //         {
  //             "id": "transom_split_0",
  //             "type": "transom",
  //             "orientation": "horizontal",//vertical, horizontal
  //             "ratios": [
  //                 0.5,
  //                 0.5
  //             ],
  //             "material": "glass",
  //             "sections": [
  //                 {
  //                     "id": "transom_section_1",
  //                     "material": "glass",
  //                     "opacity": 0.6,
  //                     "reflectivity": 0.5,
  //                     "color": "#B0E0E6",
  //                     "splits": [
  //                         {
  //                             "id": "transom_0_1_split",
  //                             "type": "transom",
  //                             "orientation": "vertical",//vertical, horizontal
  //                             "ratios": [
  //                                 0.5,
  //                                 0.5
  //                             ],
  //                             "material": "glass",
  //                             "sections": [
  //                                 {
  //                                     "id": "transom_section_2_nested_section_0",
  //                                     "material": "glass",
  //                                     "opacity": 0.6,
  //                                     "reflectivity": 0.5,
  //                                     "color": "#B0E0E6"
  //                                 },
  //                                 {
  //                                     "id": "transom_section_2_nested_section_1",
  //                                     "material": "glass",
  //                                     "opacity": 0.6,
  //                                     "reflectivity": 0.5,
  //                                     "color": "#B0E0E6"
  //                                 }
  //                             ]
  //                         }
  //                     ]
  //                 },
  //                 {
  //                     "id": "transom_section_2",
  //                     "material": "glass",
  //                     "opacity": 0.6,
  //                     "reflectivity": 0.5,
  //                     "color": "#B0E0E6",
  //                     "splits": [
  //                         {
  //                             "id": "transom_0_1_split",
  //                             "type": "transom",
  //                             "orientation": "vertical",//vertical, horizontal
  //                             "ratios": [
  //                                 0.5,
  //                                 0.5
  //                             ],
  //                             "material": "glass",
  //                             "sections": [
  //                                 {
  //                                     "id": "transom_section_2_nested_section_0",
  //                                     "material": "glass",
  //                                     "opacity": 0.6,
  //                                     "reflectivity": 0.5,
  //                                     "color": "#B0E0E6"
  //                                 },
  //                                 {
  //                                     "id": "transom_section_2_nested_section_1",
  //                                     "material": "glass",
  //                                     "opacity": 0.6,
  //                                     "reflectivity": 0.5,
  //                                     "color": "#B0E0E6"
  //                                 }
  //                             ]
  //                         }
  //                     ]
  //                 }
  //             ]
  //         }
  //     ]
  // };

  function alterRatios(newRatios) {
    const firstNumber = newRatios[0];
    const alteredRatios = newRatios.slice(1).map(currentNumber => {
      return currentNumber / (1 - firstNumber);
    });
    return alteredRatios;
  }

function updateTransomData(transomData, heightSplit, widthSplit) {
  function updateSplits(splits, orientation, newRatios) {
    splits.ratios[0] = newRatios[0];
    splits.ratios[1] = 1 - newRatios[0];
    newRatios = alterRatios(newRatios);
    let widthSplit1, heightSplit1;
    if(orientation==='horizontal')heightSplit1=newRatios;
    if(orientation==='vertical')widthSplit1=newRatios;
    const split1 = splits.sections[0].splits;
    const split2 = splits.sections[1].splits;
    if(split1){
      if(split1[0].orientation == "horizontal")updateSplits(split1[0], "horizontal", heightSplit1 || heightSplit);
      if(split1[0].orientation == "vertical")updateSplits(split1[0], "vertical", widthSplit1 || widthSplit);
    }
    if(split2){
      if(split2[0].orientation == "horizontal")updateSplits(split2[0], "horizontal", heightSplit1 || heightSplit1);
      if(split2[0].orientation == "vertical")updateSplits(split2[0], "vertical", widthSplit1 || widthSplit);
    }
  }
  if(transomData.splits[0].orientation == "horizontal")updateSplits(transomData.splits[0], "horizontal", heightSplit);
  if(transomData.splits[0].orientation == "vertical")updateSplits(transomData.splits[0], "vertical", widthSplit);
  return transomData;
}

function getSplitsFromTransomData(transomData) {
  function extractSplits(splits, orientation, collectedSplits) {
    if (orientation === 'horizontal') {
      collectedSplits.height = splits.ratios;
    }
    if (orientation === 'vertical') {
      collectedSplits.width = splits.ratios;
    }

    const split1 = splits.sections[0]?.splits;
    const split2 = splits.sections[1]?.splits;

    if (split1) {
      if (split1[0].orientation === "horizontal") {
        extractSplits(split1[0], "horizontal", collectedSplits);
      }
      if (split1[0].orientation === "vertical") {
        extractSplits(split1[0], "vertical", collectedSplits);
      }
    }

    if (split2) {
      if (split2[0].orientation === "horizontal") {
        extractSplits(split2[0], "horizontal", collectedSplits);
      }
      if (split2[0].orientation === "vertical") {
        extractSplits(split2[0], "vertical", collectedSplits);
      }
    }
  }

  const collectedSplits = { height: [], width: [] };

  if (transomData.splits[0].orientation === "horizontal") {
    extractSplits(transomData.splits[0], "horizontal", collectedSplits);
  }
  if (transomData.splits[0].orientation === "vertical") {
    extractSplits(transomData.splits[0], "vertical", collectedSplits);
  }

  return collectedSplits;
}

// const { height: heightSplit2, width: widthSplit2 } = getSplitsFromTransomData(transomData);
// console.log("Aadi test Height Split:", heightSplit2);
// console.log("Aadi test Width Split:", widthSplit2);


  // its static values
  const heightSplit = [0.3, 0.5, 0.2];
  const widthSplit = [0.2, 0.8];
  // const updatedTransomData = updateTransomData(transomData, heightSplit, widthSplit);
  // transomData = updatedTransomData;
  // console.log(JSON.stringify(updatedTransomData, null, 2));

  transomData?.splits?.forEach((split, index) => {//its working fine *********    

    if (!split || !split.sections || split.sections.length < 2 || !split.orientation || !split.ratios || split.ratios.length < 2) {
      console.error(`Error: Invalid split at index ${index}. Missing required properties.`);
      return; // Skip processing this split if it's invalid
    }

    



    processSplit(
      split,
      group,
      -(windowWidth / 2) + windowFrameHeight,
      (windowHeight / 2) - windowFrameHeight,
      windowWidth - (2 * windowFrameHeight), // Pass total width
      windowHeight - (2 * windowFrameHeight), transomFrameHeight, transomFrameDepth, materials
    );
  })



  return group;
}