import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import { createSashPath } from './lineShapes';
import { createShapeFromWidthHeight } from './utils/threeUtils'
import {  addHardwareInSteps, loadHardware, positionHardwareOnFrame, positionHardwareOnSash, transonsFormedHandle } from './hardwares';
import { getTransomPartitionRefs, storeTransomPartitionRefs } from './transoms';
// import barHandleOffset from "../../../"
import { processSplit } from './transoms'


export let hangingRefs = []
let sashRefs = []

const storeHangingRefs = (obj, callBack) => {
    hangingRefs.push(obj)
    if (typeof callBack === 'function') {
        callBack()
    }
}


export const getSashHangingRefs = () => {
    return hangingRefs;
}

const storeSashRefs = (obj) => {
    sashRefs.push(obj);
}

export const getSasProfilehRefs = () => {
    return sashRefs;
}

export const removeSashRefs = () => {
    sashRefs = []
}

export const removeHangingRefs = () => {
    hangingRefs = []
}

export const removeHangings = (index = 0) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updatedModelJson = { ...updatedModelArray[index] };
        updatedModelJson.sash.hangings = 0;
        updatedModelJson.sash.sections = []
        updatedModelArray[index] = updatedModelJson;
        return updatedModelArray
    }
}


export const loadDefaultSashProfile = (numberOfIteration, defaultSash, setMethod, partialWidth , initialSpec , index = 0) => {

    const { height = 0, width = 0 } = defaultSash || {};
    const  { hexValue  } = initialSpec || {};

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson];
        const updatedModelJson = { ...updatedModelArray[index] };
        updatedModelJson.sash.hangings = numberOfIteration;
        updatedModelJson.sash.sashSize = partialWidth

        if (numberOfIteration >= 1) {
            updatedModelJson.sash.sections = Array.from(
                { length: numberOfIteration },
                (_, index) => {
                    const profileWidth = width / 1000;
                    const profileHeight = height / 1000;
                    let sashProfileObj = {
                        SashTop: { width: profileWidth, height: profileHeight },
                        SashRight: { width: profileWidth, height: profileHeight },
                        SashBottom: { width: profileWidth, height: profileHeight },
                        SashLeft: { width: profileWidth, height: profileHeight },
                    };
                    // Calculate dynamic dimensions for sashProfile

                    const maxComputedIndex = numberOfIteration * 4 - 1;
                    Object.keys(sashProfileObj)?.forEach((k, keyIndex) => {
                        const computedIndex = index * 4 + keyIndex;
                        if (computedIndex > maxComputedIndex) {
                            return;
                        }
                        setMethod(computedIndex, defaultSash, k, maxComputedIndex);
                    });

                    return {
                        id: index,
                        material: "glass",
                        opacity: 0.6,
                        reflectivity: 0.5,
                        color: hexValue,
                        type: "fixed",
                        model: null,
                        sashProfile: {
                            SashTop: { width: profileWidth, height: profileHeight },
                            SashRight: { width: profileWidth, height: profileHeight },
                            SashBottom: { width: profileWidth, height: profileHeight },
                            SashLeft: { width: profileWidth, height: profileHeight },
                        },
                    };

                });
        } else {
            updatedModelJson.sash.sections = [];
            setMethod((prevData) => ({
                ...prevData,
                sashData: [],
            }));
        }
        updatedModelArray[index] = updatedModelJson;


        return updatedModelArray;
    }
}


const handleTrickleVents = async (allTrickleVents , boundingBox , trickleVentData , group) => {
    if (trickleVentData && trickleVentData?.length) {
        // const boundingBox = frames[key][2];
        // const triclkleVentCount = trickleVent?.length;
        const trickleModel = await Promise.all(
            trickleVentData.map(async (item) => {
                const hardware = await loadHardware(item.model);
                const hardwareClone = hardware.clone()
                group.add(hardwareClone); // Add to group immediately
                return hardwareClone;
            })
        );

        allTrickleVents.current = trickleModel
        // Call addHardwareInSteps after all hardware models are loaded
        await addHardwareInSteps(boundingBox, allTrickleVents , trickleVentData);
        // const modelClone = transformedModel.clone();
    }

}

        

export const updateSashProfile = (
    index,
    orientation,
    updatedSash,
    method,
    profileIndex,
    updateSashData,
    jsonIndex = 0
) => {
    const { height, width } = updatedSash;
    const newHeight = height / 1000;
    const newWidth = width / 1000;

    let searchedItem = method?.find((item) => item?.index === profileIndex);

    updateSashData(updatedSash, searchedItem, profileIndex)

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson];
        const updatedModelJson = { ...updatedModelArray[jsonIndex] };
        const section = updatedModelJson.sash.sections[index];
        section.sashProfile[orientation].width = newWidth;
        section.sashProfile[orientation].height = newHeight;

        updatedModelArray[jsonIndex] = updatedModelJson;
        return updatedModelArray;
    };
};

export const editHangings = (
    index,
    type,
    handleModel,
    setMethod,
    data,
    hardwareType,
    cylinderData = null,
    jsonIndex = 0
  ) => {
    // Save handleData
    setMethod(data, null, index, hardwareType, cylinderData);
  
    return (prevModelJson) => {
      // Clone the entire array to avoid mutating the original
      const updatedModelArray = [...prevModelJson];
  
      // Clone the specific object at the given jsonIndex
      const updatedModelJson = { ...updatedModelArray[jsonIndex] };
  
      // Update the specified section
      const section = updatedModelJson.sash.sections[index];
      if (section) {
        section.model = handleModel;
        section.type = type;
      }
  
      // Replace the updated object back into the array
      updatedModelArray[jsonIndex] = updatedModelJson;
  
      // Return the updated array
      return updatedModelArray;
    };
  };


  
const extrudeSettings = (path) => ({
    steps: 100,
    extrudePath: path,
    bevelEnabled: true,
    bevelThickness: 0.01,
    bevelSize: 0.01,
    bevelOffset: 0,
    bevelSegments: 5,
    curveSegments: 12
});

export async function createSash(sashes, group, frameSides, frameWidth, frameHeight, sashHangingValue, sashGroup, partialWidth , allTrickleVents, masterSlave, windowData) {
    
    const { color } = windowData
    
    let mullionWidth = 0;
    // if(sashHangingValue == 2){
    //     masterSlave = {
    //         mullion: {
    //             width: 0.1,
    //             depth: 0.14
    //         }
    //     }
    // }
    
    if (masterSlave && typeof masterSlave === 'object' && Object.keys(masterSlave).length > 0) {
        console.log("masterSlave is non empty object.");
        mullionWidth = masterSlave.mullion.width;
    }
    removeHangingRefs()
    const offsetL = frameSides.left.dimensions.height,
        offsetT = frameSides.top.dimensions.height,
        // offsetT = 0,
        offsetR = frameSides.right.dimensions.height,
        offsetB = frameSides.bottom.dimensions.height;
    const setSashHangingNo = sashHangingValue;

    const sashMaterial = new THREE.MeshPhongMaterial({
        color: color,
        specular: 0xffffff,
        shininess: 10,
        flatShading: false,
        emissive: 0x000000,
        specular: 0x555555,
        bumpScale: 1,
    });

    sashGroup.current = [];
    
    const sashPaths = ["SashTop", "SashRight", "SashBottom", "SashLeft"];
    let sashWidth = (frameWidth - (offsetL + offsetR + mullionWidth)) / setSashHangingNo;

    let offset = -frameWidth / 2 + sashWidth / 2 + offsetL;


    const partitions = {}
    // const newPartitons = {};

    for (let index = 0; index < setSashHangingNo; index++) {

        let paths = [];
        const sashHeight = frameHeight - (offsetT + offsetB);
        if (partialWidth && partialWidth[index]) {
            sashWidth = (frameWidth - (offsetL + offsetR + mullionWidth)) * (partialWidth[index] / (frameWidth * 1000));

            if (index == 0) {
                offset = -(frameWidth / 2) + (sashWidth / 2) + offsetL;
                if(mullionWidth > 0)createMullion(masterSlave, group, sashWidth, sashHeight, sashMaterial);
            } else if(index == 1){
                offset += (sashWidth / 2) + mullionWidth;
            } else {
                offset += sashWidth / 2;
            }
        }

        
        let nextOffset = 0;
        sashPaths.forEach(async (sashPosition) => {
            const sashPath = createSashPath(sashWidth, sashHeight, sashPosition, offset);
            paths.push(sashPath);

            const shapeHt = sashes.sections[index].sashProfile[sashPosition].height
            const shapeW = sashes.sections[index].sashProfile[sashPosition].width

            const sashShape = createShapeFromWidthHeight(shapeW, shapeHt);
            // const sashGeometry = new THREE.ExtrudeGeometry(sashShape, extrudeSettings(sashPath));
            const sashGeometry1 = new THREE.ExtrudeGeometry(sashShape, extrudeSettings(sashPath));
            const sashGeometry = BufferGeometryUtils.mergeVertices(sashGeometry1);
            sashGeometry.computeVertexNormals();
            const sashMesh = new THREE.Mesh(sashGeometry, sashMaterial);
            sashMesh.position.y -= (offsetT - offsetB) / 2;

            // sashMesh.position.y += 0.01;
            sashMesh.castShadow = true;
            sashMesh.receiveShadow = true;
            sashMesh.name = sashPosition;
            group.add(sashMesh);


            sashGroup.current.push(sashMesh);
            const sashBounding = new THREE.Box3().setFromObject(sashMesh);
            if (sashPosition == "SashRight") {
                nextOffset = sashBounding.max.x;
            }
            const sashCenter = new THREE.Vector3()
            sashBounding.getCenter(sashCenter)

            if (!partitions[index]) {
                partitions[index] = {}; // Create a new object for the current index
            }

            partitions[index][sashPosition] = sashCenter;
            const section = sashes?.sections[index]
            const profile = sashes?.sections[index]?.sashProfile[sashPosition]

            setTimeout(async () => {
                if (section?.type && section?.type !== 'fixed') {
                    const handle = await transonsFormedHandle(sashes, partitions, index)
                    group.add(handle)
                }
                if (profile?.doorhandle) {
                    const model = profile?.doorhandle?.model;
                    let sashHandle = await loadHardware(model)  
                    let cloneSashHandle = sashHandle.clone();
                    cloneSashHandle = await positionHardwareOnSash(profile?.doorhandle ,sashMesh , cloneSashHandle , sashBounding , "");
                    group.add(cloneSashHandle);
                }

                if(profile?.tricklevent && profile?.tricklevent?.length){
                    await handleTrickleVents(allTrickleVents ,sashMesh , profile?.tricklevent,group)
                }

                if(profile?.spyhole){
                    // if(spyHole){
                    const spyHole = profile?.spyhole
                        const hardware = await loadHardware(spyHole.model);
                        const spyHoleClone = hardware.clone();
                        const positionSpyHole = await positionHardwareOnSash(spyHole ,sashMesh , spyHoleClone , sashBounding , "inside")
                        group.add(positionSpyHole);
                    // }
                }
            }, 100);

            const sashPos = {
                position: { x: sashCenter?.x, y: sashCenter?.y, z: sashCenter?.z },
                name: sashMesh?.name
            }
            storeSashRefs(sashPos)
        })

        const partition = sashes?.sections[index];

        const glassThickness = 0.02;
        let glassMaterial = new THREE.MeshPhysicalMaterial({
            color: partition?.color,
            roughness: 0.05,
            metalness: 0.9,
            reflectivity: 0.6,
            clearcoat: 1,
            clearcoatRoughness: 0.05,
            envMapIntensity: 1,
            opacity: 0.5,
            transparent: true
        });
        const glassShape = getGlassShape(paths);
        const glassExtrudeSettings = {
            depth: glassThickness,
            bevelEnabled: false,
        };

        const glassGeometry = new THREE.ExtrudeGeometry(glassShape, glassExtrudeSettings);
        const glassMesh = new THREE.Mesh(glassGeometry, glassMaterial);
        glassMesh.name = "GlassPanel";
        glassMesh.scale.set(0.999, 0.999, 1);
        glassMesh.position.z = -glassThickness * 0.5;
        

        const boundingBox = new THREE.Box3().setFromObject(glassMesh);
        const center = new THREE.Vector3()
        boundingBox.getCenter(center)

        const glassPos = {
            position: { x: center?.x, y: center?.y, z: center?.z },
            name: glassMesh?.name
        }
        // glassMaterial = glassData.material.clone()
        if(!sashes.sections[index].splits){

            group.add(glassMesh);

            
        }else{
            // (parentGroup, startPosX, startPosY, parentWidth, parentHeight, transomFrameHeight, transomFrameDepth, materials)
            // const sashProfileHeight = 0.04;
            const sashProfileHeight = sashes.sections[index].sashProfile.SashRight.height || 0.04;
            let parentGroup, startPosX = boundingBox.min.x + sashProfileHeight, startPosY = (sashHeight/2) - sashProfileHeight, 
            parentWidth = sashWidth - (sashProfileHeight * 2), 
            parentHeight = sashHeight - (sashProfileHeight * 2), transomFrameHeight = 0.04, 
            transomFrameDepth = sashes.sections[index].sashProfile.SashRight.width, 
            materials = {"frameMaterial" : sashMaterial, "glassMaterial": glassMaterial};
            console.log("startPosX :::: ", startPosX);

            processSplit(sashes.sections[index].splits[0], group, startPosX, startPosY, parentWidth, parentHeight, transomFrameHeight, transomFrameDepth, materials);
        }
        storeHangingRefs(glassPos, getSashHangingRefs)
            storeTransomPartitionRefs(glassPos , getTransomPartitionRefs)
        // offset += sashWidth;
        offset = nextOffset;
    };
}

function getGlassShape(paths) {
    const shape = new THREE.Shape();
    if (paths[0]) shape.moveTo(paths[0].v1.x, paths[0].v1.y);
    paths.forEach(path => {
        if (path) shape.lineTo(path.v2.x, path.v2.y);
    });
    shape.lineTo(paths[0]?.v1?.x, paths[0]?.v1?.y);
    return shape;
}

export const handlePartialWidthChange = (sashSize , jsonIndex = 0) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updatedModelJson = { ...updatedModelArray[jsonIndex] };
        updatedModelJson.sash.sashSize = sashSize
        return updatedModelArray;
    }
}

function createMullion(masterSlave, group, sashWidth, sashHeight, material){
    console.log("masterSlave :::: ", masterSlave);
    const {width, depth} = masterSlave.mullion;
    const mullionShape = createShapeFromWidthHeight(depth, width, 0.008);
    // const mullionPath = createSashPath(sashWidth, sashHeight, "SashRight", 0);
    const mullionPath = createSashPath(sashWidth, sashHeight, "SashRight", -(sashWidth/2) + (width/2));
    const mullionGeometry = new THREE.ExtrudeGeometry(mullionShape, extrudeSettings(mullionPath));
    const mullionMesh = new THREE.Mesh(mullionGeometry, material);
//     mullionMesh.position.y -= (offsetT - offsetB) / 2;

        // mullionMesh.position.x = 0;
        // mullionMesh.position.z = depth/2;
// //         mullionMesh.castShadow = true;
// //         mullionMesh.receiveShadow = true;
// //         mullionMesh.name = mullionPosition;
    group.add(mullionMesh);
}