import React, { useEffect, useLayoutEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { getCountForStatus } from "../../utility/commonHelper";
import { useDesigner } from "../../context/designerContext";
import { Alert, Button } from "reactstrap";
import { MdInfoOutline } from "react-icons/md";
import { BiTrash } from "react-icons/bi";
import { getSashHangingData } from "../../services/3dModelServices";

const HandleHanging = ({
    collectionDetails,
    accessToken,
    collectionId,
    sashProductId,
    frameStyleProdId,
    hideDelete
}) => {

    const {
        customModelData,
        setCustomModelData,
        checkSingleSide,
        checkClickedProfile,
        setWindowHandleOrientation,
        setDeleteSashHanging,
        multiSelectRefPoints,
        setSashHangingNo
    } = useDesigner();

    const [sashHangingData, setSashHangingData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [showSashHangingAlert, setShowSashHangingAlert] = useState(false);

    useEffect(() => {
        getCountForStatus(sashHangingData, setShowSashHangingAlert);
    }, [sashHangingData]);



    useEffect(() => {
        if (multiSelectRefPoints[0] && sashProductId && frameStyleProdId) {
            getSashDataService(multiSelectRefPoints[0]?.index);
        }
    }, [multiSelectRefPoints[0]]);

    // This will enable the selected fix type
    useEffect(() => {
        if (
            multiSelectRefPoints?.length > 0 &&
            customModelData?.windowData &&
            customModelData?.windowData?.length > 0 &&
            sashHangingData &&
            sashHangingData?.length > 0
        ) {
            let searchedItem = null;
            multiSelectRefPoints?.forEach((item) => {
                searchedItem = customModelData?.windowData?.find(
                    (ele) => ele?.index === item?.index
                );
            });
            if (searchedItem) {
                let index = sashHangingData?.findIndex(
                    (item) => item?.id == searchedItem?.hangingId
                );
                setActiveIndex(index);
            } else {
                setActiveIndex(-1);
            }
        }
    }, [multiSelectRefPoints, sashHangingData]);

    const getSashDataService = async (referencePoint) => {
        let data = {
            collectionId: collectionId,
            sashProductId: sashProductId?.id,
            frameStyleId: frameStyleProdId?.id,
            referencePoint: referencePoint,
        };
        const res = await getSashHangingData(accessToken, data);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setSashHangingData(res?.data?.entity);
            }
        }
    };

    const handleSashAdd = (item, index) => {
        setActiveIndex(index);

        var handleJson = {
            isHangingAdded: true,
            hangingType: item.hangingName,
            hangingId: item.id,
            handleDirection: "right",
            productMasterId: item.sashProductMasterId,
            // index: checkSingleSide,
            index: multiSelectRefPoints[0]?.index,
            position: checkClickedProfile?.position,
        };
        // setCustomModelData((prevData = {}) => ({
        //   ...prevData,
        //   windowData: [
        //     ...(prevData?.windowData ? prevData?.windowData : []),
        //     handleJson
        //   ],
        // }));
        // This will replace the data for the same index
        setCustomModelData((prevData = {}) => {
            const windowData = prevData.windowData ? [...prevData.windowData] : [];
            const existingIndex = windowData.findIndex(
                (data) => data.index === handleJson.index
            );
            if (existingIndex !== -1) {
                windowData[existingIndex] = handleJson;
            } else {
                windowData.push(handleJson);
            }
            return {
                ...prevData,
                windowData: windowData,
            };
        });
        setWindowHandleOrientation(item.hangingName);
    };

    const handleDeleteHanging = () => {
        setDeleteSashHanging(true);
        setSashHangingNo(0)

        if (collectionDetails && collectionDetails.typeId == 2) {
            var handleJson = {
                isHangingAdded: false,
                hangingType: "",
                hangingId: "",
                handleDirection: "",
            };

            setCustomModelData((prevData) => ({
                ...prevData,
                windowData: handleJson,
            }));

            setWindowHandleOrientation("");
        }
        setTimeout(() => {
            setDeleteSashHanging(false)
        }, 1000);
    };


    return (
        <>
            {multiSelectRefPoints[0] ? (
                <>
                    <div className="mt-1 right_list">
                        {sashHangingData &&
                            sashHangingData?.map((item, index) => (
                                <>
                                    {item?.status && !showSashHangingAlert && (
                                        <div
                                            key={index}
                                            className="position-relative d-flex align-items-center right_item_wrap"
                                        >
                                            <div
                                                // className={`right_item w-100 
                                                //     ${customModelData?.windowData?.hangingId
                                                //     ? customModelData?.windowData
                                                //         ?.hangingId === item.id &&
                                                //     "active"
                                                //     : ""
                                                //     }`
                                                // }
                                                className={`right_item w-100 ${index == activeIndex ? "active" : ""}`}
                                                onClick={() => handleSashAdd(item, index)}
                                            >
                                                <span className="me-2 image_icon">
                                                    {item.imagePath ? (
                                                        <Image src={item.imagePath} />
                                                    ) : (
                                                        <svg
                                                            viewBox="0 0 100 50"
                                                            width="100"
                                                            height="50"
                                                        >
                                                            <path
                                                                className="stroke"
                                                                stroke="#989898"
                                                                fill="#fff"
                                                                strokeWidth="2"
                                                                d={`M 0,50 0,0 100,0 100,50 Z`}
                                                            ></path>
                                                        </svg>
                                                    )}
                                                </span>

                                                <div className="right_title">
                                                    <span className="text-truncate">
                                                        {item.name}
                                                    </span>
                                                    <span className="text-truncate d-block text-small">
                                                        {item.description}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))}

                        {showSashHangingAlert && (
                            <Alert
                                color="secondary"
                                className="d-flex gap-2 p-2 my-2"
                            >
                                <span>
                                    <MdInfoOutline size={18} />
                                </span>
                                <span>
                                    Sash Hanging is not enabled for this reference
                                    point.
                                </span>
                            </Alert>
                        )}
                    </div>

                    {!hideDelete && sashHangingData && sashHangingData.length > 0 && (
                        <Button
                            color="light"
                            size="md"
                            className="px-4 w-100 d-flex justify-content-between fw-medium mb-2 mt-4"
                            onClick={() => handleDeleteHanging()}
                        >
                            <BiTrash size={20} />
                            Delete
                            <span></span>
                        </Button>
                    )}
                </>
            ) : (
                <>
                    <Alert
                        color="secondary"
                        className="d-flex gap-2 p-2 my-2"
                    >
                        <span>
                            <MdInfoOutline size={18} />
                        </span>
                        <span>Select Ref point to add hanging</span>
                    </Alert>
                </>
            )}
        </>
    );
};

export default HandleHanging;
