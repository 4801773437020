import * as THREE from 'three';
import { panelData } from './data';
import { setModelData, setQuotationModelData } from '../services/3dModelServices';

export const normalizeValue = (value, minVal, maxVal) => {
    return (value - minVal) / (maxVal - minVal)
}

export const mapNumberToRange = (number, rangeSize = 4) => {
    if (number < 0) return -1; // Handle negative numbers if needed
    return Math.floor(number / rangeSize);
};

export const denormalizeValue = (normalizedValue, minVal, maxVal) => {
    const denormalized = normalizedValue * (maxVal - minVal) + minVal;
    return Math.floor(denormalized);
};

export const rgbStringToHex = (rgbString) => {
    // Extract individual RGB components from the string
    let hex
    if (rgbString) {

        const [r, g, b] = rgbString?.match(/\d+/g).map(Number);

        // Ensure that the input values are within the valid range (0-255)
        const clamp = (value) => Math.max(0, Math.min(255, value));

        // Clamp RGB values
        const red = clamp(r);
        const green = clamp(g);
        const blue = clamp(b);


        // Convert each component to hexadecimal and concatenate
        hex = ((red << 16) | (green << 8) | blue).toString(16).padStart(6, '0');
    }
    return `#${hex}`;
}

export const replaceHashWithOx = (hexCode) => {
    let decimalValue;
    if (hexCode?.startsWith('#')) {
        decimalValue = hexCode?.slice(1);
    } else {
        decimalValue = hexCode;
    }
    return Number(decimalValue);
}

// new handle input
// The formula is to get the cost then add 20% to get costPlus Vat and then get either of markUp value of percentage then add that in the cost to get the total and for total plus vat add the vat calculated earlier
export const newHandleInput = (state, setState, id, key, value) => {

    let cost = parseFloat(state[id]["cost"]);

    let markUpValue = 0;
    let total = 0;
    let totalPlusVat = 0;
    let costVat = 0;
    let vatPer = .2

    if (validate(value)) {
        if (key === "cost") {
            costVat = vatPer * value + parseFloat(value);

            if (state[id]["markUp"] !== 0) {
                markUpValue = parseFloat(state[id]["markUp"]);

                total = parseFloat(value) + markUpValue;

            } else if (state[id]["markUpPercentage"] !== 0) {
                markUpValue = parseFloat(state[id]["markUpPercentage"]) / 100;

                total = parseFloat(value) + (parseFloat(value) * markUpValue);
            } else {
                total = parseFloat(value) + markUpValue;
            }

            totalPlusVat = total + (total * vatPer);

            setState((prevData) =>
                prevData.map((item, index) =>
                    index === id
                        ? {
                            ...item,
                            [key]: parseFloat(value),
                            ["costPlusVat"]: formatValue(costVat),
                            ["total"]: formatValue(total),
                            ["totalPlusVat"]: formatValue(totalPlusVat),
                        }
                        : item
                )
            );
        }

        if (key === "markUp") {
            costVat = vatPer * cost + cost;
            total = parseFloat(cost) + parseFloat(value);

            totalPlusVat = total + (total * vatPer);

            setState((prevData) =>
                prevData.map((item, index) =>
                    index === id
                        ? {
                            ...item,
                            [key]: parseFloat(value),
                            ["total"]: formatValue(total),
                            ["markUpPercentage"]: 0,
                            ["totalPlusVat"]: formatValue(totalPlusVat),
                        }
                        : item
                )
            );
        }

        if (key === "markUpPercentage") {
            cost = parseFloat(cost);
            costVat = vatPer * cost + cost;
            total = parseFloat(cost) + parseFloat((value / 100) * cost);

            totalPlusVat = total + (total * vatPer);

            setState((prevData) =>
                prevData.map((item, index) =>
                    index === id
                        ? {
                            ...item,
                            [key]: parseFloat(value),
                            ["total"]: formatValue(total),
                            ["markUp"]: 0,
                            ["totalPlusVat"]: formatValue(totalPlusVat),
                        }
                        : item
                )
            );
        }
    }
};

// This is for accessory pricing I have made this function seperately because if i am adding the condtions in the above code to storeMarkup then in that case i am getting error in the table and in ordet to avoid that I am using this function.
export const accessoryHandleInput = (state, setState, id, key, value) => {

    let cost = parseFloat(state[id]["cost"]);
    let markUpValue = 0;
    let total = 0;
    let totalPlusVat = 0;
    let costVat = 0;
    let vatPer = 0.2;

    if (validate(value)) {
        if (key === "cost") {
            costVat = vatPer * value + parseFloat(value);

            if (state[id]["markUp"] !== 0) {
                markUpValue = parseFloat(state[id]["markUp"]);
                total = parseFloat(value) + markUpValue;
            } else if (state[id]["markUpPercentage"] !== 0) {
                markUpValue = parseFloat(state[id]["markUpPercentage"]) / 100;
                total = parseFloat(value) + (parseFloat(value) * markUpValue);
            } else {
                total = parseFloat(value) + markUpValue;
            }

            if (state[id]["storeMarkup"] !== 0) {
                total += (parseFloat(value) * parseFloat(state[id]["storeMarkup"])) / 100;
            }

            totalPlusVat = total + (total * vatPer);

            setState((prevData) =>
                prevData.map((item, index) =>
                    index === id
                        ? {
                            ...item,
                            [key]: parseFloat(value),
                            ["costPlusVat"]: formatValue(costVat),
                            ["total"]: formatValue(total),
                            ["totalPlusVat"]: formatValue(totalPlusVat),
                        }
                        : item
                )
            );
        }

        if (key === "markUp") {
            costVat = vatPer * cost + cost;
            total = parseFloat(cost) + parseFloat(value);

            if (state[id]["storeMarkup"] !== 0) {
                total += (total * parseFloat(state[id]["storeMarkup"])) / 100;
            }

            totalPlusVat = total + (total * vatPer);

            setState((prevData) =>
                prevData.map((item, index) =>
                    index === id
                        ? {
                            ...item,
                            [key]: parseFloat(value),
                            ["total"]: formatValue(total),
                            ["markUpPercentage"]: 0,
                            ["totalPlusVat"]: formatValue(totalPlusVat),
                        }
                        : item
                )
            );
        }

        if (key === "markUpPercentage") {
            cost = parseFloat(cost);
            costVat = vatPer * cost + cost;
            total = parseFloat(cost) + parseFloat((value / 100) * cost);

            if (state[id]["storeMarkup"] !== 0) {
                total += (total * parseFloat(state[id]["storeMarkup"])) / 100;
            }

            totalPlusVat = total + (total * vatPer);

            setState((prevData) =>
                prevData.map((item, index) =>
                    index === id
                        ? {
                            ...item,
                            [key]: parseFloat(value),
                            ["total"]: formatValue(total),
                            ["markUp"]: 0,
                            ["totalPlusVat"]: formatValue(totalPlusVat),
                        }
                        : item
                )
            );
        }

        if (key === "storeMarkup") {
            let markupValue = parseFloat(state[id]["markUp"]);
            let markupPercentageValue = parseFloat(state[id]["markUpPercentage"]);

            if (markupValue !== 0) {
                total = parseFloat(state[id]["cost"]) + markupValue;
            } else if (markupPercentageValue !== 0) {
                total = parseFloat(state[id]["cost"]) + (parseFloat(state[id]["cost"]) * (markupPercentageValue / 100));
            } else {
                total = parseFloat(state[id]["cost"]);
            }

            if (value !== 0) {
                total += (total * parseFloat(value)) / 100;
            }

            totalPlusVat = total + (total * vatPer);

            setState((prevData) =>
                prevData.map((item, index) =>
                    index === id
                        ? {
                            ...item,
                            [key]: parseFloat(value),
                            ["total"]: formatValue(total),
                            ["totalPlusVat"]: formatValue(totalPlusVat),
                        }
                        : item
                )
            );
        }
    }
};

const validate = (value) => {
    if (value < 0) {
        return false;
    }
    return true;
};

export function handleEmptyValues(arr) {
    // Iterate through each object in the array
    for (let obj of arr) {
        // Check if the "cost" key is an empty string, then set it to 0
        if (isNaN(obj.cost)) {
            obj.cost = 0;
            obj.total = 0;
            obj.totalPlusVat = 0;
            obj.markUp = 0;
            obj.markUpPercentage = 0;
            obj.storeMarkup = 0;
            obj.costPlusVat = 0;
        }
        // Check if the "markUp" key is an empty string, then set it to 0
        if (isNaN(obj.markUp)) {
            obj.markUp = 0;
            obj.total = obj.cost ? obj.cost : obj.total;
            obj.totalPlusVat = obj.costPlusVat ? obj.costPlusVat : obj.totalPlusVat;

        }
        // Check if the "markUpPercentage" key is an empty string, then set it to 0
        if (isNaN(obj.markUpPercentage)) {
            obj.markUpPercentage = 0;
            obj.total = obj.cost ? obj.cost : obj.total;
            obj.totalPlusVat = obj.costPlusVat ? obj.costPlusVat : obj.totalPlusVat;

        }
        // Check if the "storeMarkup" key is an empty string, then set it to 0
        if (isNaN(obj.storeMarkup)) {
            obj.storeMarkup = 0;
            obj.total = obj.cost ? obj.cost : obj.total;
            obj.totalPlusVat = obj.costPlusVat ? obj.costPlusVat : obj.totalPlusVat;
        }
        if (isNaN(obj.costPlusVat)) {
            obj.costPlusVat = 0;
        }
        if (isNaN(obj.total)) {
            obj.total = 0;
            obj.total = obj.total;
            obj.totalPlusVat = obj.totalPlusVat;

        }
        if (isNaN(obj.totalPlusVat)) {
            obj.totalPlusVat = 0;
            obj.total = obj.cost ? obj.cost : obj.total;
            obj.totalPlusVat = obj.costPlusVat ? obj.costPlusVat : obj.totalPlusVat;
        }
    }
    return arr;
}

export function formatValue(value) {
    // Check if the value has decimal places
    const hasDecimal = value % 1 !== 0;

    // If it has decimal places, round to 2 decimal places, otherwise keep it as is
    const formattedValue = hasDecimal ? value?.toFixed(2) : value;

    return parseFloat(value?.toFixed(2));
}

export const getUniversalPricing = (items) => {
    let totalPrice = 0.0;
    items.forEach((item) => {
        totalPrice += parseFloat(item.price);
    });
    return totalPrice
};

export const getAnicllaryPricing = (ancillaries, totalPrice) => {
    let ancillaryPricing = 0;
    ancillaries.forEach((item) => {
        // if (item?.ancillariesPrice !== 0 && item?.percentage !== 0) {
        //     ancillaryPricing += (parseFloat(item?.percentage / 100) * parseFloat(totalPrice)) + (parseFloat(item.ancillariesPrice) * parseFloat(item?.quantity ? item?.quantity : 1));
        // }
        // if (item?.ancillariesPrice !== 0 && item?.percentage === 0)
        //     ancillaryPricing += parseFloat(item.ancillariesPrice) * parseFloat(item?.quantity ? item?.quantity : 1);
        // else if (item?.percentage !== 0 && item?.ancillariesPrice === 0) {
        //     let ancillariesPrice = parseFloat(item?.percentage / 100) * parseFloat(totalPrice)
        //     ancillaryPricing += ancillariesPrice * parseFloat(item?.quantity ? item?.quantity : 1)
        // }

        if (item.percentage > 0) {
            ancillaryPricing += ((parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) + (totalPrice * (item.percentage / 100))) * (item?.quantity > 0 ? item.quantity : 0));
        } else {
            ancillaryPricing += (parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) * (item?.quantity > 0 ? item.quantity : 0));
        }
    });

    return ancillaryPricing;
};

// This is for updating the array in case for ancillary
export function updateArray(arr, newObj) {
    // Check if the array has an object with the same id as newObj
    const index = arr.findIndex(obj => obj.id === newObj.id);

    if (index !== -1) {
        // If found, replace the object at that index with newObj
        arr[index] = newObj;
    } else {
        // If not found, add newObj to the array
        arr.push(newObj);
    }

    return arr;
}

export const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const interchangeFirstIndex = (inputArray) => {
    const newArray = [...inputArray];
    const temp = newArray[0];
    newArray[0] = newArray[1];
    newArray[1] = temp;

    return newArray;
}

export const rgbToHex = (r, g, b) => {
    // Ensure RGB values are within the valid range (0-255)
    const clamp = (value) => Math.max(0, Math.min(255, value));
    const clampedR = clamp(r);
    const clampedG = clamp(g);
    const clampedB = clamp(b);

    // Convert each RGB component to a two-digit hexadecimal representation
    const hexComponents = [clampedR, clampedG, clampedB].map(component => {
        const hex = component.toString(16).toUpperCase();
        return hex.length === 1 ? '0' + hex : hex;
    });

    // Combine the hex components to form the hex code
    const hexCode = '#' + hexComponents.join('');
    return hexCode;
}

export const getObjectData = (type, file, objectPosition, data) => {
    if (type === "handle") {
        return {
            id: data.id,
            file: file,
            name: data?.name,
            color: "Anthracite Grey",
            type: "handle",
            side: 3,
            position: { position: objectPosition?.position },
            price: data.price,
            imagePath: data?.imagePath
        }
    }
    else if (type === "trickle") {
        return {
            id: data.id,
            name: data?.name,
            internalColor: "Grey",
            externalColor: "Anthracite Grey",
            type: "trickle",
            file: file,
            position: { position: objectPosition?.position },
            price: data.price,
            imagePath: data?.imagePath
        }
    }
    else if (type === "Cylinder") {
        return {
            id: data.id,
            name: data?.name,
            color: "Black",
            type: "cylinder",
            file: file,
            position: objectPosition,
            price: data.price,
            imagePath: data?.imagePath
        }
    }
    else if (type === "numeral") {
        return {
            id: data.id,
            name: data?.name,
            color: "Anthracite grey",
            type: "numeral",
            numbers: [0, ""],
            file: file,
            position: { position: objectPosition?.position },
            price: data.price,
            imagePath: data?.imagePath
        }
    } else if (type === "spyhole") {
        return {
            id: data.id,
            name: data?.name,
            color: "Anthracite grey",
            type: "spyhole",
            file: file,
            // numbers: [0, ""],
            position: { position: objectPosition?.position },
            price: data.price,
            imagePath: data?.imagePath
        }
    } else if (type === 'letterplate') {
        return {
            id: data.id,
            name: data?.name,
            color: "Anthracite grey",
            type: "letterplate",
            file: file,
            numbers: [0, ""],
            position: objectPosition,
            price: data.price,
            imagePath: data?.imagePath
        }
    } else if (type === 'knocker') {
        return {
            id: data.id,
            name: data?.name,
            color: "Anthracite grey",
            type: "knocker",
            file: file,
            // numbers: [0, ""],
            position: { position: objectPosition?.position },
            price: data.price,
            imagePath: data?.imagePath
        }
    }
    else if (type === 'escutcheon') {
        return {
            id: data.id,
            name: data?.name,
            color: "Anthracite grey",
            type: "escutcheon",
            file: file,
            position: objectPosition,
            price: data.price,
            imagePath: data?.imagePath
        }
    }
}

export const getHandleObject = (type, file, objectPosition, index, horizontalPos, verticalPos, price, image) => {
    if (type === "handle") {
        return {
            id: index,
            file: file,
            name: "Slim Security Flip Lock",
            color: "Anthracite Grey",
            type: type,
            index: index,
            side: 3,
            position: { position: objectPosition?.position },
            horizontalPos: horizontalPos,
            verticalPos: verticalPos,
            price: price,
            imagePath: image
        }
    } else if (type === "cylinder") {
        return {
            id: index,
            file: file,
            name: "3 Star",
            color: "Anthracite Grey",
            type: type,
            index: index,
            side: 3,
            position: { position: objectPosition?.position },
            horizontalPos: horizontalPos,
            verticalPos: verticalPos,
            price: price,
            imagePath: image
        }
    }

}

export const removeDuplicatesByPosition = (array) => {
    const uniqueNames = new Set();
    return array.filter(item => {
        if (uniqueNames.has(item.position)) {
            return false;
        } else {
            uniqueNames.add(item.position);
            return true;
        }
    });

}


export const getFrameType = (side) => {
    let frameType;

    switch (side) {
        case 'top':
            frameType = 'FrameTop';
            break;
        case 'left':
            frameType = 'FrameLeft';
            break;
        case 'right':
            frameType = 'FrameRight';
            break;
        case 'bottom':
            frameType = 'FrameBottom';
            break;
        default:
            frameType = 'UnknownFrame';
    }

    return frameType;
};

export const getSashType = (side) => {
    let sashType;

    switch (side) {
        case 'top':
            sashType = 'SashTop';
            break;
        case 'left':
            sashType = 'SashLeft';
            break;
        case 'right':
            sashType = 'SashRight';
            break;
        case 'bottom':
            sashType = 'SashBottom';
            break;
        default:
            sashType = 'UnknownSash';
    }

    return sashType;
};


export const getSideFromFrameType = (frameType) => {
    let side;
    switch (frameType) {
        case 'FrameTop':
            side = 'top';
            break;
        case 'FrameLeft':
            side = 'left';
            break;
        case 'FrameRight':
            side = 'right';
            break;
        case 'FrameBottom':
            side = 'bottom';
            break;
        default:
            side = 'unknown';
    }

    return side;
};



export const getBgColorClass = (hexColor) => {
    switch (hexColor) {
        case "#E81123":
            return "bg-danger";
        case "#FFB900":
            return "bg-warning";
        case "#107C10":
            return "bg-success";
        case "#E3008C":
            return "bg-purple";
        case "#00BCF2":
            return "bg-info";
        default:
            return "";
    }
};

export const deleteKey = (obj, keyToDelete) => {
    if (obj && keyToDelete && obj.hasOwnProperty(keyToDelete)) {
        // Create a copy of the object to avoid modifying the original object
        const newObj = { ...obj };

        // Delete the specified key from the new object
        delete newObj[keyToDelete];

        return newObj;
    } else {
        // If the object is null, undefined, or the key doesn't exist, return the original object
        return obj;
    }
};

export function extractRGBValues(rgbString) {
    if (!rgbString) {
        return ''; // Return an empty string if rgbString is undefined
    }
    const values = rgbString.substring(5, rgbString.length - 1).split(', ');
    const result = values.map(val => parseInt(val)).join('');
    return result;
}


export const removeDuplicateIds = (arr) => {
    const uniqueIds = new Set();
    const uniqueArray = (arr && arr.length > 0) && arr?.filter(item => {
        if (!uniqueIds.has(item.id)) {
            uniqueIds.add(item.id);
            return true; // Include item in the filtered array
        }
        return false; // Exclude item from the filtered array
    });
    return uniqueArray ? uniqueArray : []
}



let capturedFile = '';

export const getCanvasAsBase64 = (canvas) => {
    return canvas.toDataURL("image/svg");
};

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    for (let i = 0; i < n; i++) {
        u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
}

export function convertCanvasToImageFile() {
    const canvases = document.querySelectorAll("#ballContainer");
    const arr = [];
    let base64String = '';

    canvases?.forEach((canvas) => {
        base64String = getCanvasAsBase64(canvas, "image/svg");
        arr.push(base64String);
    });

    // function dataURLtoFile(dataurl, filename) {
    //     var arr = dataurl.split(','),
    //         mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[arr.length - 1]),
    //         n = bstr.length,
    //         u8arr = new Uint8Array(n);
    //     while (n--) {
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     return new File([u8arr], filename, { type: mime });
    // }

    if (base64String) {
        capturedFile = dataURLtoFile(base64String, 'spare_color.jpg');
    }

    return capturedFile;
}

export const getPosition = (name) => {
    if (name?.includes("inline")) {
        return panelData?.barHandle;
    } else if (name?.includes("offset")) {
        return panelData?.barHandleOffset;
    } else if (name?.includes("knocker") || name?.includes("ring")) {
        return panelData?.knocker;
    } else if (name?.includes("spyhole")) {
        return panelData?.spyHole;
    } else if (name?.includes("escutcheon") || name?.includes("sweet")) {
        return panelData?.escutcheon;
    } else if (name?.includes("knob")) {
        return panelData?.doorknob;
    } else if (name?.includes("trickle") || name?.includes("400") || name?.includes("300")) {
        return panelData?.trickleVent
    } else if (name?.includes("hinge") || name?.includes("butt")) {
        return panelData?.hinge
    }
    else if (name === ("architectural")) {
        return panelData?.letterPlate
    }
};


// export function updateOrPushObject(array, newObject) {
//   const index = array.findIndex((obj) => obj.id === newObject.id);

//   if (index !== -1) {
//     // If object with the same id exists
//     if (array[index].orientation !== newObject.orientation) {
//         // If orientation are different, append new orientation with comma
//         array[index].orientation += `, ${newObject.orientation}`;
//       }
//     // Otherwise, replace the object with newObject
//     else {
//       array[index] = newObject;
//     }
//   } else {
//     // If object with the same id does not exist, push the new object
//     array.push(newObject);
//   }

//   return array;
// }

export function updateOrPushObject(array, newObject) {
    const index = array.findIndex(obj => obj.id === newObject.id);

    if (index !== -1) {
        // If object with the same id exists
        if (array[index].orientation !== newObject.orientation) {
            // If orientation are different, append new orientation with comma
            array[index].orientation += `, ${newObject.orientation}`;
        }
        // Otherwise, replace the object with newObject
        else {
            array[index] = newObject;
        }
    } else {
        // If object with the same id does not exist, push the new object
        const existingObjWithSameOrientation = array.find(obj => {
            const orientation = obj.orientation.split(',').map(o => o.trim());
            return orientation.includes(newObject.orientation);
        });

        if (existingObjWithSameOrientation) {
            // Update existing object's orientation
            const existingorientation = existingObjWithSameOrientation.orientation?.split(',').map(o => o.trim());
            const updatedorientation = existingorientation?.filter(o => o !== newObject.orientation);
            existingObjWithSameOrientation.orientation = getOrientation(updatedorientation.join(','));
        }

        // Check if the old object has only one orientation
        if (array[index]?.orientation.split(',').length === 1) {
            array = array.filter(obj => obj.id !== array[index].id);
        }

        // Push the new object
        array.push(newObject);
    }

    return array;
}

export const removeDuplicates = (array) => {
    const uniqueArray = [];
    const names = new Set();
    array.forEach(item => {
        if (!names.has(item.name)) { // Check if name is not already in the set
            names.add(item.name); // Add the name to the set
            uniqueArray.push(item); // Push the item to the unique array
        }
    });

    return uniqueArray;
}

export const getOrientation = (orientation) => {
    if (orientation?.includes(",")) {
        const tempArr = new Set(orientation.split(","));
        const newOrientation = [...tempArr].join(", ");
        return newOrientation;
    }
    return orientation;
};

export function updatePricingData(data) {
    const filteredData = [];
    const nameMap = {};
    let totalPrice = 0;
    data?.forEach(item => {
        const { name, orientation, price, ...rest } = item;
        if (!nameMap[name]) {
            nameMap[name] = { orientation: [], price: 0 };
        }
        if (!nameMap[name].orientation.includes(orientation)) {
            nameMap[name].orientation.push(orientation);
        }
        nameMap[name].price = price;
        // totalPrice += price;
        Object.assign(nameMap[name], rest);
    });




    for (const name in nameMap) {
        const { orientation, price, ...rest } = nameMap[name];

        const formattedTotalPrice = parseFloat(price)?.toFixed(2);

        if (orientation.length > 0) {
            const item = { name, orientation: orientation.join(', '), price: formattedTotalPrice, ...rest };
            filteredData.push(item);
        } else {
            filteredData.push({ name, price, ...rest });
        }
    }

    return filteredData;
}


export const setInitialColors = (rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal) => {

    if (externalColor !== undefined && internalColor !== undefined) {
        // reset initial color
        if (internalRAL !== undefined) {
            setInternal(rgbStringToHex(internalRAL?.rgbValue));
            
        } else {
            setInternal(internalColor?.colour);
        }
        
        
        
        if (externalRAL !== undefined) {
            setExternal(rgbStringToHex(externalRAL?.rgbValue))
        } else {
            setExternal(externalColor?.colour)
        }
    } else {
        if (customModelData && customModelData.frame.internalColor) {
            
            setInternal(customModelData.frame.internalColor?.hex);
        }

        if (customModelData && customModelData.frame.externalColor) {
            
            setExternal(customModelData.frame.externalColor?.hex);
        }
    }
};

export function updateArrayWithCount(array) {
    const idMap = {};

    // Iterate through the array to create a map of IDs and their counts
    for (const obj of array) {
        const { id } = obj;

        // If ID already exists in the map, increment the count
        if (id in idMap) {
            idMap[id].count++;
        } else {
            idMap[id] = { ...obj, count: 1 };
        }
    }

    // Create a new array with updated objects
    const updatedArray = Object.values(idMap);

    return updatedArray;
}

export function updateArrayWithCountSumTotal(array) {
    const idMap = {};

    // Iterate through the array to create a map of IDs and their counts and total sums
    for (const obj of array) {
        const { id, total } = obj;

        // If the ID already exists in the map, increment the count and add to total
        if (id in idMap) {
            idMap[id].count++;
            idMap[id].total += total;  // Sum the total price
        } else {
            // If the ID does not exist, initialize the object with count = 1
            idMap[id] = { ...obj, count: 1 };
        }
    }

    // Create a new array with updated objects
    const updatedArray = Object.values(idMap);

    return updatedArray;
}

export function updateArrayWithArea(array) {
    const idMap = {};

    // Iterate through the array to create a map of IDs and their counts and total sums
    for (const obj of array) {
        const { id, priceableArea } = obj;

        // If the ID already exists in the map, increment the count and add to total
        if (id in idMap) {
            idMap[id].count++;
            idMap[id].priceableArea += priceableArea;  // Sum the total price
        } else {
            // If the ID does not exist, initialize the object with count = 1
            idMap[id] = { ...obj, count: 1 };
        }
    }

    // Create a new array with updated objects
    const updatedArray = Object.values(idMap);

    return updatedArray;
}

export function updateArrayWithNameCount(array) {
    const nameMap = {};

    // Iterate through the array to create a map of names and their counts
    for (const obj of array) {
        const { name } = obj;

        // If name already exists in the map, increment the count
        if (name in nameMap) {
            nameMap[name].count++;
        } else {
            nameMap[name] = { ...obj, count: 1 };
        }
    }

    // Create a new array with updated objects
    const updatedArray = Object.values(nameMap);

    return updatedArray;
}

export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const getAlertForOverWeight = (sashArray) => {
    let overWeightArray = [];
    sashArray.forEach((item) => {
        if (item?.alert) {
            let obj = {
                name: item?.name,
                orientation: item?.orientation
            }
            overWeightArray.push(obj)
        }
    })
    return overWeightArray
}

export const getGlazingData = (arr) => {
    let seenIds = {};
    let result = [];
    for (let i = 0; i < arr.length; i++) {
        let currentId = arr[i].id;
        if (!seenIds[currentId] && currentId !== false && currentId !== null) {
            seenIds[currentId] = true;
            result.push(arr[i]);
        }
    }
    return result;
}

export function combineModelImages(arr1, arr2) {
    // Combine both arrays into one
    const combinedArray = arr1.concat(arr2);

    // Extract modelImage values from combined array
    const modelImages = combinedArray.map(obj => obj.modelImage || obj?.imagePath);

    return modelImages;
}


export const extractPerMMLength = (width, obj, height) => {

    const formula = obj?.widthFormula;

    const parts = formula && formula?.split('-');

    let result = 0;
    if (parts && parts?.length) {

        const pMatch = parts[0]?.match(/P(\d+)/);
        const hMatch = parts[0]?.match(/H/);
        const wMatch = parts[0]?.match(/W/);

        if (pMatch) {

            const index = parseInt(pMatch[1]) - 1;

            if (index >= 0 && index < width?.length) {
                result = width[index]
            }

        } else if (wMatch) {
            result = width;
        } else if (hMatch) {
            result = height;

        }

        for (let i = 1; i < parts?.length; i++) {
            const numericValue = parseInt(parts[i]);
            if (!isNaN(numericValue)) {
                result -= numericValue;
            }
        }
    }

    return result / 1000;
}

// export function calculateDiagonalLength(theta, width, height) {
//     var thetaRad = theta * Math.PI / 180;

//     // Rectangle diagonal length
//     var diagonal = Math.sqrt((width * width) + (height * height));

//     var d;
//     if (theta <= 90) {
//         var d0 = diagonal;
//         d = (Math.sin(thetaRad) * d0) / Math.sin(Math.PI / 2 - thetaRad);
//     } else {
//         var complementaryAngle = 180 - theta;
//         var complementaryThetaRad = complementaryAngle * Math.PI / 180;
//         d = Math.sqrt((height * height) + (width * width) * Math.pow(Math.sin(complementaryThetaRad), 2));
//     }

//     d = Math.min(d, diagonal);

//     return Math.abs(d);
// }

export function calculateDiagonalLength(angle, width, height) {
    // Convert angle to radians
    var angleRad = angle * Math.PI / 180;

    // Calculate length of line
    var length = 0;

    if (Math.abs(Math.cos(angleRad)) > Math.abs(Math.sin(angleRad))) {
        // Use width
        length = width / Math.cos(angleRad);
    } else {
        // Use height
        length = height / Math.sin(angleRad);
    }

    return Math.abs(length);
}

export function filterUniqueSpecs(array) {
    const uniqueSpecsMap = new Map();

    // Iterate through the array and store unique spec objects based on their id
    array.forEach(item => {
        const specId = item.spec.id;
        if (!uniqueSpecsMap.has(specId)) {
            uniqueSpecsMap.set(specId, item);
        }
    });

    // Convert the map values back to an array
    const uniqueSpecsArray = Array.from(uniqueSpecsMap.values());

    return uniqueSpecsArray;
}

export const updateProfileJointsData = (prevData, data, referencePoint) => {
    // Check if the profileJointsData array exists and is an array
    if (Array.isArray(prevData.profileJointsData)) {
        // Check if there is an item with a matching referencePoint
        const exists = prevData.profileJointsData.some(
            (item) => item.referencePoint === referencePoint?.data?.name
        );

        // If it exists, map through the array and replace the matching item
        if (exists) {
            return {
                ...prevData,
                profileJointsData: prevData.profileJointsData.map((item) =>
                    item.referencePoint === referencePoint?.data?.name ? data : item
                ),
            };
        }

        // If it doesn't exist, add the new data
        return {
            ...prevData,
            profileJointsData: [...prevData.profileJointsData, data],
        };
    }

    // If profileJointsData is not an array, initialize it with the new data
    return {
        ...prevData,
        profileJointsData: [data],
    };
};

//   This is for deep comparing two objects
export function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || obj1 === null ||
        typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!obj2.hasOwnProperty(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export const assignOrientations = (arr) => {
    const orientations = [];
    const baseOrientations = ["Left", "Right"];
    let topBottomCount = 0;

    // Determine the number of 'Top' and 'Bottom' orientations
    if (arr.length >= 8) {
        topBottomCount = (arr.length - 2) / 4;
    }

    // Add base orientations ('Left' and 'Right')
    orientations.push(...baseOrientations);

    // Add 'Top' and 'Bottom' orientations
    for (let i = 0; i < topBottomCount; i++) {
        orientations.push("Top");
    }
    for (let i = 0; i < topBottomCount; i++) {
        orientations.push("Bottom");
    }

    // Add remaining 'Left' and 'Right' orientations
    for (let i = 0; i < topBottomCount; i++) {
        orientations.push("Left");
        orientations.push("Right");
    }

    // Assign orientations to the array objects
    return arr.map((obj, index) => ({
        ...obj,
        orientation: orientations[index] || "Undefined",
    }));
};

export const transformData = (inputData) => {
  const result = [];

  inputData?.forEach((item, dataIndex) => {
    Object.values(item).forEach((products) => {
      if (Array.isArray(products)) {
        products?.forEach((product) => {
          let ori = `${product.orientation?.charAt(0)?.toUpperCase()} / ${
            product.index + 1
          }`;
          const existingProduct = result.find((p) => p.name === product.name);
          const orientationFormatted = ori ? ori : 1;
          const detail = {
            position: dataIndex, // static value
            seqNo: (product.index + 1).toString(),
            quantity: "1", // static value
            cuttingLength: product?.cuttingLength, // static value
            orientation: orientationFormatted,
            cut: "I - I", // static value
            status: product?.cuttingStatus,
            uniqueKey: product?.uniqueKey,
            frameType: product?.frameType,
          };

          if (existingProduct) {
            existingProduct.details.push(detail);
          } else {
            result.push({
              name: product.name,
              image: product.imagePath || product?.url,
              details: [detail],
              description: product.description,
              frameType: product?.frameType,
            });
          }
        });
      }
    });
  });

  return getCorrectSeqNo(result);
};

const getCorrectSeqNo = (data) => {
    let frameProfileCount = 0;
    let sashProfileCount = 0;

    data.forEach((item) => {
        if (item?.frameType?.toLowerCase() === "frame profile") {
            frameProfileCount = item.details.length;
        } else if (item?.frameType?.toLowerCase() === "sash profile") {
            sashProfileCount = item.details.length;
        }
    });

    // Step 2: Update the seqNo for the "Sash profile" and "Sash" objects
    data.forEach((item) => {
        if (item?.frameType?.toLowerCase() === "sash profile") {
            item?.details?.forEach((detail, index) => {
                let sqNo = (frameProfileCount + index + 1).toString();
                detail.seqNo = (frameProfileCount + index + 1).toString();
                detail.orientation = `${detail?.orientation?.split("/")?.[0]}/${sqNo}`;
            });
        } else if (item?.frameType?.toLowerCase() === "sill") {
            item?.details?.forEach((detail, index) => {
                let sqNo = (
                    frameProfileCount +
                    sashProfileCount +
                    index +
                    1
                ).toString();
                detail.seqNo = sqNo;

                detail.orientation = `${detail?.orientation?.split("/")?.[0]}/${sqNo}`;
            });
        }
    });
    return data;
};

export const toggleStatus = (arr, key) => {
    return arr.map(item => {
        // Find the object in the details array that matches the id and seqNo
        const updatedDetails = item.details.map(detail => {

            if (detail.uniqueKey === key) {
                // Toggle the status
                return { ...detail, status: !detail.status };
            }
            return detail;
        });

        // Return the item with updated details
        return { ...item, details: updatedDetails };
    });
}

export const toggleCuttingStatus = (arr, uniqueKey) => {

    return arr.map(item => {
        // Find the key that contains the dynamic array
        const dynamicArrayName = Object.keys(item).find(key => Array.isArray(item[key]));

        if (dynamicArrayName) {
            const updatedDynamicArray = item[dynamicArrayName].map(detail => {
                if (detail.uniqueKey === uniqueKey) {
                    // Toggle the cuttingStatus
                    return { ...detail, cuttingStatus: !detail.cuttingStatus };
                }
                return detail;
            });

            // Return the updated item with the modified dynamic array
            return { ...item, [dynamicArrayName]: updatedDynamicArray };
        }
        return item;
    });
};

export const handleFocus = (event) => {
    event.target.select();
};

export function handleQuantity(e) {
    // Allow only numbers
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value === "" || parseInt(e.target.value, 10) <= 0) {
        e.target.value = ""; // Clear if value is not a positive integer
    } else if (parseInt(e.target.value, 10) > 999) {
        e.target.value = "999"; // Limit the value to 999
    }
}

export function handlePositiveNumber(e) {
    // Allow only numbers
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value === "" || parseInt(e.target.value, 10) < 0) {
        e.target.value = ""; // Clear if value is not a positive integer
    } else if (parseInt(e.target.value, 10) > 999) {
        e.target.value = "999"; // Limit the value to 999
    }
}

export const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const toCamelCase = (str) => {
  return str
    ?.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match?.toLowerCase() : match?.toUpperCase()
    )
    .replace(/\s+/g, "");
};