import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import * as THREE from 'three';
import { getFrameType, getSashType } from '../../../utility/helper';
import { getHexValue } from '../../../utility/designerHelper';

let hardwares = [];
let hardwareList = [];


export const storeHandleRefPoints = (obj) => {
    hardwares.push(obj)
}

export const getHandleRefs = () => {
    return hardwares;
}

export const hardwarePositionChange = (
    index,
    name,
    offsetX,
    offsetY,
    color,
    type,
    setMethod,
    hardwareType,
    hardwareIndex,
    jsonIndex= 0
) => {
    // This will update data
    setMethod(hardwareIndex, offsetX, offsetY, color, hardwareType);
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updatedJson = { ...updatedModelArray[jsonIndex] };
        const section = updatedJson?.sash?.sections[index];
        const hardware = section?.sashProfile[name][type];

        if (hardware) {
            hardware.offsetX = offsetX || 0;
            hardware.offsetY = offsetY || 0;
            hardware.color = getHexValue(color);
        }

        updatedModelArray[jsonIndex] = updatedJson;

        return updatedModelArray;
    };
};


export const storeMultipleHardwaresOnProfile = async (index ,side, model,data, type,setMethod , jsonIndex ) => {

    return (prevModelJson) => {

        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };

        if (!side.includes("Sash")) {
            const frameSide = updateJson.frame.sides[side];
            if (!Array.isArray(frameSide[type])) {
                frameSide[type] = [];
            }

            frameSide[type].push({
                model,
                color: "#000000",
                offsetX: 0,
                offsetY: 0,
            });
        } else {
            const section = updateJson?.sash?.sections[index];
            const sashProfile = section?.sashProfile[side];

            if (!Array.isArray(sashProfile[type])) {
                sashProfile[type] = [];
            }

            sashProfile[type].push({
                model,
                color: "#000000",
                offsetX: 0,
                offsetY: 0,
            });

        }

        // Saving data
        setMethod(data, index, null,type);
        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray;
    }
}


export const updateMultiplHardwareOnProfile = (index, side, color, type, newType, hardwareIndex,setMethod , jsonIndex = 0) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...prevModelJson[jsonIndex] };

        if (!side.includes("Sash")) {
            const frameSide = updateJson.frame.sides[side];
            if (frameSide[type]) {
                frameSide[type].forEach((item) => {
                    item.color = color?.hex;
                })
            }
        } else {
            const section = updateJson?.sash?.sections[index];
            const sashProfile = section?.sashProfile[side];

            if (sashProfile[type]) {
                sashProfile[type].forEach((item) => {
                    item.color = color?.hex;
                })
            }

        }

        // Saving data
        // setMethod(data, index, null, newType);
        setMethod(hardwareIndex,null,null,color,newType)
        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray;
    }
}

export const storeHardwareOnFrame = async (side, model, type, index, data, setMethod , jsonIndex = 0) => {
    setMethod(data, index, null, type, null)

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };
        const frameSide = updateJson?.frame?.sides[side];

        if (!frameSide) {
            console.error("Invalid section hangingIndex or sash data");
            return prevModelJson; // Return unchanged JSON if validation fails
        }

        if (!frameSide[type]) {
            frameSide[type] = {
                model,
                color: "#00000",
                offsetX: 0,
                offsetY: 0,
            }
        } else {
            frameSide[type] = {
                ...frameSide[type],
                model,
                color: "#00000",
                offsetX: 0,
                offsetY: 0,
            };
        }

        updatedModelArray[jsonIndex] = updateJson;
        return updatedModelArray;
    }
}


export const updateHardwareOnFrame = (index, side, offsetX, offsetY, color, type, hardwareIndex,setMethod , jsonIndex = 0) => {
    // setMethod(data,index,null,type,null)

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };
        const frameSide = updateJson?.frame?.sides[side];

        if (!frameSide) {
            console.error("Invalid section hangingIndex or sash data");
            return prevModelJson; // Return unchanged JSON if validation fails
        }

        const hardware = frameSide[type];

        if (hardware) {
            hardware.offsetX = offsetX;
            hardware.offsetY = offsetY;
            hardware.color = color?.hex
        }
        setMethod(hardwareIndex,offsetX,offsetY,color,type)
        updatedModelArray[jsonIndex] = updateJson;
        return updatedModelArray;
    }
}


export const deleteHardwareOnFrame = (index, side,type,setMethod , jsonIndex = 0) => {
    // setMethod(data,index,null,type,null)
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson];
        const updateJson = { ...updatedModelArray[jsonIndex] };
        const frameSide = updateJson?.frame?.sides[side];

        if (!frameSide) {
            console.error("Invalid section hangingIndex or sash data");
            return prevModelJson; // Return unchanged JSON if validation fails
        }

        if(frameSide[type]){
            delete frameSide[type];
        }

        setMethod(index,null,type)

        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray;
    }
}


export const deleteMultipleHardwareFromProfile = async (
    index,
    side,
    type,
    hardwareType,
    setMethod,
    jsonIndex = 0
) => {
    setMethod(index, null, hardwareType);

    return (prevModelJson) => {
        try {
            if (!prevModelJson || !side || !type) {
                throw new Error("Invalid inputs provided.");
            }

            const updatedModelArray = [...prevModelJson]

            const updateJson = { ...updatedModelArray[jsonIndex] };

            if (!side.includes("Sash")) {
                const frameSide = updateJson?.frame?.sides[side];
                const hardware = frameSide[type];
                if (Array.isArray(hardware) && hardware.length > 0) hardware.pop();
                else
                    console.warn(
                        `No hardware of type '${type}' in frame side '${side}'.`
                    );
            } else {
                const hardware =
                    updateJson?.sash?.sections?.[index]?.sashProfile?.[side][type];
                if (Array.isArray(hardware) && hardware.length > 0) hardware.pop();
                else
                    console.warn(`No hardware of type '${type}' in sash side '${side}'.`);
            }

            updatedModelArray[jsonIndex] = updateJson
            return updatedModelArray;
        } catch (error) {
            console.error("Error deleting hardware:", error.message);
            return prevModelJson;
        }
    };
};


export const addHardwareOnSash = async (element, data, index, hangingIndex, orientation, type, setMethod,cylinderData=null , jsonIndex) => {    
    
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };
        const sash = updateJson?.sash;

        if (!sash?.sections?.length || !sash?.sections[hangingIndex]) {
            console.error("Invalid section hangingIndex or sash data");
            return prevModelJson; // Return unchanged JSON if validation fails
        }

        const section = sash?.sections[hangingIndex];
        const profile = section?.sashProfile[orientation];

        if (!profile) {
            console.error(`Invalid orientation '${orientation}' in sash profile`);
            return prevModelJson;
        }

        if (!profile[type]) {
            profile[type] = {
                model: element,
                color: "#00000",
                offsetX: 0,
                offsetY: 0,
            };
        } else {
            profile[type] = {
                ...profile[type],
                model: element,
                color: "#0000",
                offsetX: 0,
                offsetY: 0,
            };
        }

        // For saving data
        setMethod(data, index, hangingIndex, type,cylinderData);
        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray; // Return the updated JSON object
    };
};


export const deleteHardwareFromSash = async (index, hangingIndex, orientation, type, setMethod, hardwareType , jsonIndex = 0) => {
    setMethod(index, hangingIndex, hardwareType)

    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };
        const sash = updateJson?.sash;

        // Validate that sash, sections, and the specific section exist
        if (!sash?.sections?.length || !sash?.sections[hangingIndex]) {
            console.error("Invalid section hangingIndex or sash data");
            return prevModelJson; // Return unchanged JSON if validation fails
        }

        const section = sash?.sections[hangingIndex];
        const profile = section?.sashProfile[orientation];

        // Validate that the profile exists
        if (!profile) {
            console.error(`Invalid orientation '${orientation}' in sash profile`);
            return prevModelJson;
        }

        // Remove the hardware object from the profile
        if (profile[type]) {
            delete profile[type]; // Deletes the entire object
        } else {
            console.warn(`No hardware of type '${type}' found in profile`);
        }

        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray;
    };
}




// PW = parent width => Width of the profile
// hc = harwareCount
// shw = sum of hardware widths
// ss = (PW - shw) / PW
// position will be
// ss + hardwarewidth/2

// ss = space between the hardwares
export const addHardwareInSteps = async (profile, allTrickleVents, hardwareData) => {

    const model = allTrickleVents.current;

    if (!profile || !model) {
        console.error("Invalid profile, config, or model.");
        return;
    }

    const hardwareCount = model?.length;
    // Calculate profile width (PW) and bounding box dimensions
    const boundingBox = new THREE.Box3().setFromObject(profile);
    // const zCenter = boundingBox.max.z - boundingBox.min.z;
    const profileCenter = new THREE.Vector3();
    boundingBox.getCenter(profileCenter);
    const profileWidth = boundingBox.max.x - boundingBox.min.x;
    const profileOffset = -profileWidth / 2
    // const hardwareCount = 3;

    let shw = 0;
    model.forEach((child, index) => {
        const hardwareBox = new THREE.Box3().setFromObject(child);
        const hardwareWidth = hardwareBox.max.x - hardwareBox.min.x;
        shw += hardwareWidth;
    })


    let positionX;
    const ss = (profileWidth - shw) / (hardwareCount + 1);
    model.forEach((child, index) => {
        const hardwareBox = new THREE.Box3().setFromObject(child);
        const hardwareWidth = hardwareBox.max.x - hardwareBox.min.x;
        positionX = (ss * (index + 1) + (hardwareWidth / 2) + (hardwareWidth * index) + profileOffset);
        child.position.set(positionX, boundingBox.min.y, profileCenter.z);


        const config = hardwareData[index] ? hardwareData[index] : {};

        if (child.material) {
            child.material = child.material.clone(); // Clone the material to avoid shared references
            child.material.color.set(config.color);; // Set color to red
        } else if (child.children && child.children.length > 0) {
            child.children.forEach((subChild) => {
                if (subChild.material) {
                    subChild.material = subChild.material.clone(); // Clone the material to avoid shared references
                    subChild.material.color.set(config.color);
                    // subChild.material.color.set(new THREE.Color(config?.color)); // Set color to red
                }
            });
        }

    })

};



export const positionHardwareOnSash = async (config, profile, model, boundingBox , direction) => {

    if (!profile || !config || !model) {
        console.error('Invalid profile or element.');
        return;
    }

    const modelExternal = model?.children?.find(child => child.name.includes("Ext"));
    const modelInternal = model?.children?.find(child => child.name.includes("Int"));

    const profileCenter = new THREE.Vector3();
    boundingBox.getCenter(profileCenter);


    const profileBottom = boundingBox.min.y; // Bottom position of the profile
    const profileHeight = boundingBox.max.y - boundingBox.min.y;

    const Xoffset = Number(config.offsetX) / 1000 || 0;
    const Yoffset = Number(config.offsetY) / 1000 || 0;

    const zAxis = direction === "inside" ? boundingBox.min.z : direction === "outside" ? boundingBox.max.z : profileCenter.z

    let verticalPosition;
    if (profile.name === 'SashLeft' || profile.name === 'SashRight') {

        const targetHeight = 1050 / 1000; // Desired position from the bottom
        const normalizedHeight = targetHeight / profileHeight; // Normalized height (0 to 1)

        verticalPosition = profileBottom + normalizedHeight * profileHeight;
        // verticalPosition = profileBottom + (1050 / 1000) * profileHeight;
        model.position.set(profileCenter.x + Xoffset, verticalPosition + Yoffset, zAxis);
    } else {
        model.position.set(profileCenter.x + Xoffset, profileCenter.y + Yoffset, zAxis);
    }

    if (modelExternal && modelInternal) {

        const { externalOffsetZ, internalOffsetZ } = calculateZoffset(model, model.position, boundingBox)
        modelInternal.position.z -= internalOffsetZ;
        modelExternal.position.z -= externalOffsetZ;
    }


    if (config.color) {
        if (model.isMesh && model.material) {
            model.material = model.material.clone(); // Clone the material to avoid shared references
            model.material.color.set(config.color);
        } else if (model.children && model.children.length > 0) {
            // Apply color to child materials (if model has multiple parts)
            model.children.forEach(child => {
                if (child.material) {
                    // child.material.color.set(config.color);
                    child.material = child.material.clone(); // Clone each child's material
                    child.material.color.set(config.color);
                }
            });
        }
    }


    return model;
}


export const positionHardwareOnFrame = async (config, profile, model, direction) => {

    const boundingBox = new THREE.Box3().setFromObject(profile);
    const profileCenter = new THREE.Vector3();
    boundingBox.getCenter(profileCenter);


    const profileBottom = boundingBox.min.y; // Bottom position of the profile
    const profileHeight = boundingBox.max.y - boundingBox.min.y;

    const Xoffset = Number(config.offsetX) / 1000 || 0;
    const Yoffset = Number(config.offsetY) / 1000 || 0;

    let positionZ = direction === "inside" ? boundingBox.min.z : direction === "outside" ? boundingBox.max.z : profileCenter.z

    let verticalPosition;
    if (profile.name === 'FrameLeft' || profile.name === 'FrameRight') {

        const targetHeight = 1050 / 1000; // Desired position from the bottom
        const normalizedHeight = targetHeight / profileHeight; // Normalized height (0 to 1)

        verticalPosition = profileBottom + normalizedHeight * profileHeight;
        // verticalPosition = profileBottom + (1050 / 1000) * profileHeight;
        model.position.set(profileCenter.x + Xoffset, verticalPosition + Yoffset, positionZ);
    } else {
        model.position.set(profileCenter.x + Xoffset, profileCenter.y + Yoffset, positionZ);
    }

    if (config.color) {
        if (model.isMesh && model.material) {
            model.material = model.material.clone(); // Clone the material to avoid shared references
            model.material.color.set(config.color);
        } else if (model.children && model.children.length > 0) {
            // Apply color to child materials (if model has multiple parts)
            model.children.forEach(child => {
                if (child.material) {
                    // child.material.color.set(config.color);
                    child.material = child.material.clone(); // Clone each child's material
                    child.material.color.set(config.color);

                }
            });
        }
    }
    return model;
}


export function getHandlePosition(partitionGroup, type) {
    const typeMapping = {
        SashLeft: "SashRight",
        SashRight: "SashLeft",
        SashTop: "SashBottom",
        SashBottom: "SashTop",
    };

    const oppositeType = typeMapping[type];

    if (!oppositeType || !partitionGroup[oppositeType]) {
        console.log(`Invalid or missing sash position for type: ${type}`);
        return null; // Return null if the type or partition is invalid
    }

    const { x, y, z } = partitionGroup[oppositeType];
    return new THREE.Vector3(x, y, z);
}



export const loadHardware = (() => {
    // Cache to store loaded handles
    const handleCache = new Map();
    return (element) => {
        // Check if the handle is already loaded
        if (handleCache.has(element)) {
            // Return the cached model
            return Promise.resolve(handleCache.get(element));
        }
        const handleLoader = new GLTFLoader();
        return new Promise((resolve, reject) => {
            handleLoader.load(
                element,
                (gltf) => {
                    const hardware = gltf.scene;
                    handleCache.set(element, hardware);
                    resolve(hardware);
                },
                undefined,
                (error) => {
                    console.error(`Error loading GLB for hardware: ${error.message}`);
                    reject(error);
                }
            );
        });
    };
})();


export const calculateZoffset = (handle, handlePosition, profile) => {
    try {

        if (!handle || !handle.children || handle.children.length === 0) {
            throw new Error("Invalid handle object or handle has no children.");
        }


        const handleExternal = handle.children.find(child => child.name.includes("Ext"));
        const handleInternal = handle.children.find(child => child.name.includes("Int"));

        const handleBoundingExternal = new THREE.Box3().setFromObject(handleInternal);
        const handleBoundingInternal = new THREE.Box3().setFromObject(handleExternal);

        const handleSizeExt = new THREE.Vector3();
        handleBoundingExternal.getSize(handleSizeExt);

        const handleSizeInt = new THREE.Vector3();
        handleBoundingInternal.getSize(handleSizeInt)

        const profileSize = new THREE.Vector3();
        profile.getSize(profileSize);


        // Use handlePosition (sashCenter) to calculate minZ and maxZ
        // const handleMinZ = handlePosition.z - handleSizeInt.z / 2;
        // const handleMaxZ = handlePosition.z + handleSizeExt.z / 2;
        // const internalOffsetZ = profile.min.z - handleBoundingInternal.max.z;
        const internalOffsetZ = profile.max.z - handleBoundingInternal.min.z;
        const externalOffsetZ = profile.min.z - handleBoundingExternal.max.z;

        return { externalOffsetZ, internalOffsetZ }
    } catch (error) {
        console.error("Error in calculateZoffset:", error.message);
        return { externalOffsetZ: 0, internalOffsetZ: 0 };
    }
}

export const transonsFormedHandle = async (sashes, partitions, index) => {

    const sectionId = sashes?.sections[index]?.id;
    const type = sashes?.sections[index]?.type;
    const model = sashes?.sections[index]?.model;

    const newType = getSashType(type)

    const partitionGroup = partitions[sectionId];

    // Get handle position based on partition group and sash type
    const handlePosition = getHandlePosition(partitionGroup, newType);

    // Add handle and create a clone
    const handle = await loadHardware(model);
    const handleClone = handle.clone();

    // Set the position of the handle clone
    handleClone.position.set(handlePosition.x, handlePosition.y, handlePosition.z);

    // Calculate Z offsets for internal and external parts
    const { externalOffsetZ, internalOffsetZ } = calculateZoffset(handle, handlePosition);

    // Adjust positions of the external and internal handle parts
    const externalHandle = handleClone.children.find((child) => child.name.includes("HandleExt"));
    const internalHandle = handleClone.children.find((child) => child.name.includes("HandleInt"));

    if (externalHandle) {
        externalHandle.position.z += internalOffsetZ; // Internal offset adjustment
    }
    if (internalHandle) {
        internalHandle.position.z += externalOffsetZ; // External offset adjustment
    }

    // Return the adjusted handle clone
    return handleClone;
};
