import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import { useDesigner } from "../../context/designerContext";
import NumberInput from "../NumberInput";
import { BiSolidCheckSquare, BiX } from "react-icons/bi";
import { FaGripLines } from "react-icons/fa";
import {
  setModelData,
  setQuotationModelData,
} from "../../services/3dModelServices";
import { useMessage } from "../../context/messageContext";
import { useNavigate } from "react-router-dom";
import { updateSashSize } from "../../utility/threeJsHelpers/uiLinesHelper";
import { handleError } from "../../utility/commonHelper";

function EditFrameSizeModel({
  uiLinesRef,
  accessToken,
  setLayoutSizing,
  collectionId,
  setLoading,
  quotationId,
  receivedProductId,
  frameStyleProdId,
  frameProfileDefault,
}) {
  // this is minimum values of frame based on frame profile
  let frameDValue = frameProfileDefault.height * 2;

  const {
    saveHeightWidth,
    setSaveHeightWidth,
    customModelData,
    setCustomModelData,
    setModelWidth,
    modelWidth,
    modelHeight,
    setModelHeight,
    lockRefIndex,
    modelJson,
  } = useDesigner();

  const [popoverIndex, setPopoverIndex] = useState(null);
  const [isSave, setIsSave] = useState(false);
  const [frameType, setFrameType] = useState("H");
  const [isDisabled, setIsDisabled] = useState(false);
  const [dimentionRecheck, setDimentionRecheck] = useState(false);

  const dimensionRef = useRef(null);

  const { setMessage, setMessageType } = useMessage();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      dimensionRef?.current &&
      uiLinesRef?.current &&
      uiLinesRef?.current?.length > 0
    ) {
      dimensionRef?.current?.focus();
      dimensionRef?.current?.select();
    }
  }, [popoverIndex]);

  const handleApply = (e) => {
    // e.preventDefault();
    setPopoverIndex(!popoverIndex);

    if (modelWidth < frameDValue) {
      setModelWidth(frameDValue);
    }

    if (modelHeight < frameDValue) {
      setModelHeight(frameDValue);
    }

    setTimeout(() => {
      setDimentionRecheck(true);
    }, 10);
  };

  useEffect(() => {
    if (
      frameDValue > 0 &&
      modelWidth > 0 &&
      modelHeight > 0 &&
      dimentionRecheck
    ) {
      setSaveHeightWidth(true);
      storeFrameData();
    }
  }, [dimentionRecheck]);

  useEffect(() => {
    if (saveHeightWidth) {
      setDimentionRecheck(false);
      setTimeout(() => {
        setSaveHeightWidth(false);
      }, 100);
    }
  }, [saveHeightWidth]);

  useEffect(() => {
    if (
      modelWidth < frameStyleProdId?.minimumWidth ||
      modelWidth > frameStyleProdId?.maximumWidth ||
      modelHeight < frameStyleProdId?.minimumHeight ||
      modelHeight > frameStyleProdId?.maximumHeight ||
      modelWidth === "" ||
      modelHeight === ""
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [modelHeight, modelWidth]);

  useEffect(() => {
    if (modelWidth < 0) {
      setModelWidth(0);
    }
    if (modelHeight < 0) {
      setModelHeight(0);
    }
  }, [modelHeight, modelWidth]);

  const storeFrameData = () => {
    let newSashSize = [];

    if (
      customModelData &&
      customModelData?.numberOfSash?.number &&
      customModelData?.numberOfSash?.number > 1
    ) {
      newSashSize = updateSashSize(
        customModelData?.sashSize,
        customModelData?.layoutFrame?.width,
        modelWidth,
        customModelData?.numberOfSash?.number
          ? customModelData?.numberOfSash?.number
          : 1,
        lockRefIndex
      );
    } else {
      newSashSize = [modelWidth];
    }

    setCustomModelData((prevModelData) => ({
      ...prevModelData,
      layoutFrame: {
        ...prevModelData.layoutFrame,
        width: parseFloat(modelWidth),
        height: parseFloat(modelHeight),
      },
      sashSize: newSashSize,
    }));
    setIsSave(true);
  };

  const handleRefClick = (item) => {
    setModelWidth(customModelData?.layoutFrame?.width);
    setModelHeight(customModelData?.layoutFrame?.height);

    if (item.name.includes("Top")) {
      setFrameType("H");
    } else {
      setFrameType("V");
    }
  };

  const saveGlazingService = async (data) => {
    setLoading(true);

    const res = await setModelData(
      accessToken,
      collectionId,
      frameStyleProdId?.id,
      data,
      modelJson
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setMessage(res?.data?.message);
        setMessageType("success");
        // setLayoutSizing(false)
        setIsSave(false);
      }
    } else {
      setLoading(false);
      setMessageType("error");
      setMessage(
        res?.data?.message ? res?.data?.message : "Something went wrong !"
      );
      handleError(res, false, navigate);
    }
  };

  const saveQuotationGlazingService = async (data) => {
    setLoading(true);
    const res = await setQuotationModelData(
      accessToken,
      collectionId,
      frameStyleProdId?.id,
      data,
      quotationId,
      receivedProductId,
      modelJson
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
        setMessage(res?.data?.message);
        setMessageType("success");
        setLayoutSizing(false);
      }
    } else {
      setLoading(false);
      setMessageType("error");
      setMessage(
        res?.data?.message ? res?.data?.message : "Something went wrong !"
      );
      handleError(res, false, navigate);
    }
  };

  useEffect(() => {
    let saveDesignerTimer;
    let saveQuotationTimer;

    if (!quotationId && isSave) {
      saveDesignerTimer = setTimeout(() => {
        saveGlazingService(customModelData);
      }, 100);
    } else if (quotationId && isSave) {
      saveQuotationTimer = setTimeout(() => {
        saveQuotationGlazingService(customModelData);
      }, 100);
    }
    return () => {
      if (saveDesignerTimer) {
        clearTimeout(saveDesignerTimer);
      }
      if (saveQuotationTimer) {
        clearTimeout(saveQuotationTimer);
      }
    };
  }, [customModelData, isSave]);

  // if (uiLinesRef?.current?.length > 0) {
  //   console.log(uiLinesRef, "ui ref data")

  //   // const elements = document.getElementById('elements')
  //   // elements.style.display = 'block'
  // } else {
  //   console.log("no refs")
  //   // const elements = document.getElementById('elements')
  //   // elements.style.display = 'none'
  // }

  // useEffect(()=>{
  //   if(!modelHeight){
  //     setModelHeight(customModelData?.layoutFrame?.height)
  //   }
  //   if(!modelWidth){
  //     setModelWidth(customModelData?.layoutFrame?.width)
  //   }
  // },[modelHeight,modelWidth])

  const handleApplyOnEnter = (e) => {
    if (e?.key === "Enter" && !isDisabled) {
      handleApply(e);
    }
  };

  return (
    <>
      {uiLinesRef?.current &&
        uiLinesRef?.current?.length > 0 &&
        uiLinesRef?.current?.map((item, index) => {
          return (
            <div
              id={`overlayLines${index}`}
              key={index}
              className="ref_btn_wrap ui_ref"
            >
              <div
                className={`btn_parent ref_sq ${
                  item.name.includes("Right") && "ref_sq_left"
                } ${item.name.includes("Top") && "ref_sq_top"}`}
                style={{
                  position: "absolute",
                  left: item.overlayX,
                  top: item.overlayY,
                }}
              >
                <div className="potition-relative">
                  <Button
                    type="button"
                    color="light"
                    className={`rounded ref_btn position-relative type_2 ${
                      item.name.includes("Right") ? "is_lef" : ""
                    }`}
                    id={`frameLinesBtn${index}`}
                    onClick={() => handleRefClick(item)}
                  >
                    <span>
                      {item.name.includes("Top")
                        ? parseInt(customModelData?.layoutFrame?.width)
                        : parseInt(customModelData?.layoutFrame?.height)}
                    </span>
                  </Button>

                  <UncontrolledPopover
                    placement="bottom"
                    target={`frameLinesBtn${index}`}
                    trigger="legacy"
                    isOpen={popoverIndex === index}
                    toggle={() => {
                      setPopoverIndex(popoverIndex === index ? null : index);
                    }}
                    className="custom_shadow"
                  >
                    <PopoverBody className="p-0 position-relative">
                      <div className="p-2">
                        <Button
                          color="primary"
                          className="circle_cross box_right_top rounded"
                          onClick={() => {
                            setPopoverIndex(!popoverIndex);
                          }}
                        >
                          <BiX size={20} />
                        </Button>

                        <React.Fragment>
                          <FormGroup className="mb-0">
                            {item.name.includes("Top") ? (
                              <Label
                                for="width"
                                className="text-dark fs-6 fw-bold"
                              >
                                Width
                              </Label>
                            ) : (
                              <Label
                                for="height"
                                className="text-dark fs-6 fw-bold"
                              >
                                Height
                              </Label>
                            )}

                            <div className="d-flex gap-2">
                              <div className="w-50">
                                {item.name.includes("Top") ? (
                                  <>
                                    {/* <NumberInput
                                    name="width"
                                    value={modelWidth}
                                    setValue={setModelWidth}
                                    className="border-primary"
                                  /> */}
                                    <Input
                                      onKeyDown={(e) => handleApplyOnEnter(e)}
                                      id="partialFrameWidth"
                                      innerRef={dimensionRef}
                                      type="number"
                                      name="width"
                                      value={modelWidth}
                                      onChange={(e) =>
                                        setModelWidth(e.target.value)
                                      }
                                      className="border-primary"
                                    ></Input>
                                    {parseFloat(modelWidth) <
                                      frameStyleProdId?.minimumWidth ||
                                    modelWidth === "" ? (
                                      <span className="text-danger text-small">{`Min value ${frameStyleProdId?.minimumWidth}`}</span>
                                    ) : null}
                                    {parseFloat(modelWidth) >
                                    frameStyleProdId?.maximumWidth ? (
                                      <span className="text-danger text-small">{`Max value ${frameStyleProdId?.maximumWidth}`}</span>
                                    ) : null}
                                  </>
                                ) : (
                                  <>
                                    {/* <NumberInput
                                    name="height"
                                    value={modelHeight}
                                    setValue={setModelHeight}
                                    className="border-primary"
                                  /> */}
                                    <Input
                                      onKeyDown={(e) => handleApplyOnEnter(e)}
                                      id="partialFrameHeight"
                                      innerRef={dimensionRef}
                                      type="number"
                                      name="height"
                                      value={modelHeight}
                                      onChange={(e) =>
                                        setModelHeight(e.target.value)
                                      }
                                      className="border-primary"
                                    ></Input>
                                    {parseFloat(modelHeight) <
                                      frameStyleProdId?.minimumHeight ||
                                    modelHeight === "" ? (
                                      <span className="text-danger text-small">{`Min value ${frameStyleProdId?.minimumHeight}`}</span>
                                    ) : null}
                                    {parseFloat(modelHeight) >
                                    frameStyleProdId?.maximumHeight ? (
                                      <span className="text-danger text-small">{`Max value ${frameStyleProdId?.maximumHeight}`}</span>
                                    ) : null}
                                  </>
                                )}
                              </div>

                              <Button
                                outline
                                color="primary"
                                size="md"
                                className="px-3 mb-1 w-50"
                                disabled={isDisabled}
                                onClick={(e) => {
                                  handleApply(e);
                                }}
                              >
                                <BiSolidCheckSquare
                                  size={18}
                                  className="me-2"
                                />
                                Apply
                              </Button>
                            </div>
                          </FormGroup>

                          <div className="d-none gap-2">
                            <Button
                              color="primary"
                              size="md"
                              className="px-3 w-50"
                            >
                              <FaGripLines size={18} className="me-2" />
                              Frame
                            </Button>

                            <Button
                              color="primary"
                              size="md"
                              className="px-3 w-50"
                            >
                              <FaGripLines size={18} className="me-2" />
                              Glass
                            </Button>
                          </div>
                        </React.Fragment>
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
export default EditFrameSizeModel;
