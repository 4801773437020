import React, { useEffect } from "react";
import { FormGroup, OutlinedInput, Select, MenuItem } from "material-ui-core";
import { BiInfoCircle, BiPlus } from "react-icons/bi";
import { FaArrowLeftLong, FaArrowRightLong, FaCheck } from "react-icons/fa6";
import { GoPencil } from "react-icons/go";
import { IoAddSharp } from "react-icons/io5";
import {
  MdKeyboardArrowDown,
  MdOutlineKeyboardArrowDown,
  MdPersonOutline,
} from "react-icons/md";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import { useDesigner } from "../../context/designerContext";
import { MenuProps } from "../../utility/data";
import NumberInputNegative from "../NumberInputNegative";
import { handleMultiSelect } from "../../utility/threeJsHelpers/general";
import { Image } from "react-bootstrap";
import { BsTools } from "react-icons/bs";
import { getAddOnRef } from "./BlockWindow/createWindowFrame";

const ConfigurationPoints = ({
  manufacturingToggle,
  newFrameRefPoints,
  bayPostAngleManual,
  setBayPostAngleManual,
  bayPostDeg,
  setBayPostDeg,
  headerSelectedItem,
  multiSelectRefPoints,
  handleDeleteObject,
  hardwareType,
  testRef,
  setPopoverIndex,
  popoverIndex,
  elementData,
  handleAddHardware,
  frameDrop,
  toggleFrameDrop,
  checkSingleSide,
  handleFrameSingleSide,
  handleFrameDrop,
  currentModel,
  frameType,
  allFrameCollection,
  allStyleCollection,
  toggleStyleDrop,
  styleDrop,
  handleStyleDrop,
  styleType,
}) => {
  const {
    deleteRefPoints,
    changeHandleDirection,
    setChangeHandleDirection,
    bayPostList,
    setHardwareReferencePoint,
    customModelData,
    setMultiSelectRefPoints,
    editHardware,
  } = useDesigner();

  useEffect(() => {
    if (bayPostAngleManual < 90) {
      setBayPostAngleManual(90);
      setBayPostDeg(90);
    }
  }, [bayPostDeg]);

  const bayPostData = bayPostList.find((item) => item.id === bayPostDeg);

  const checkManufacturingHardware = () => {
    let checked = false;
    if (manufacturingToggle) {
      if (
        hardwareType?.name === "bar handles offset" ||
        hardwareType?.name === "trickle vent" ||
        hardwareType?.name === "Frame" ||
        hardwareType?.name === "hinge" ||
        hardwareType?.name === "door handle" ||
        hardwareType?.name === "escutcheon" ||
        hardwareType?.name === "knocker" ||
        hardwareType?.name === "numeral" ||
        hardwareType?.name === "spyhole" ||
        hardwareType?.name === "letterplate"
      ) {
        checked = true;
      } else {
        checked = false;
      }
    }
    return checked;
  };

  const disableRefPoints = (index) => {
    let value = false;
    if (
      customModelData &&
      customModelData.sashData &&
      customModelData.sashData.length > 0
    ) {
      customModelData?.sashData?.forEach((item) => {
        if (item.index === index && !item.visible) {
          value = true;
        }
      });
    }
    return value;
  };

  const handleBayPostAngle = (event) => {
    setBayPostDeg(event.target.value);
  };

  const getEmptyStatus = (arr, type) => {
    if (type) {
      let ctr = 0;
      arr.forEach((element) => {
        if (element?.status) {
          ctr++;
        }
      });
      if (ctr !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      {/* this condition has selection with listing Dropdown */}
      {!manufacturingToggle &&
      (hardwareType?.name === "bar handles offset" ||
        hardwareType?.name === "trickle vent" ||
        hardwareType?.name === "Frame" ||
        hardwareType?.name === "hinge" ||
        hardwareType?.name === "door handle" ||
        hardwareType?.name === "escutcheon" ||
        hardwareType?.name === "knocker" ||
        hardwareType?.name === "numeral" ||
        hardwareType?.name === "spyhole" ||
        hardwareType?.name === "letterplate" ||
        hardwareType == "addOn") ? (
        testRef?.current?.length > 0 &&
        testRef?.current?.map((item, index) => {
          return (
            <div id={`overlay${index}`} key={index} className="ref_btn_wrap">
              <Button
                type="button"
                color="light"
                className="rounded position-absolute ref_btn"
                style={{
                  position: "absolute",
                  left: item.overlayX,
                  top: item.overlayY,
                }}
                id={`positionbutton${index}`}
              >
                <IoAddSharp className="text-muted text-center" />
              </Button>
              <UncontrolledPopover
                placement="bottom"
                target={`positionbutton${index}`}
                trigger="click"
                isOpen={popoverIndex === index}
                toggle={() => {
                  setPopoverIndex(popoverIndex === index ? null : index);
                  setHardwareReferencePoint(index);
                }}
              >
                {hardwareType?.name !== "Frame" && (
                  <PopoverBody className="panel_object_list p-2">
                    {elementData &&
                    elementData.length > 0 &&
                    getEmptyStatus(
                      elementData,
                      hardwareType === "addOn" ? false : true
                    ) ? (
                      <>
                        {elementData?.map((d, i) => {
                          const shouldRender =
                            (hardwareType === "addOn" && !d?.status) ||
                            (hardwareType !== "addOn" && d?.status);
                          if (!shouldRender) return null;

                          return (
                            <React.Fragment key={d?.id}>
                              {hardwareType?.name !== "Frame" && (
                                <div
                                  className="position-relative d-flex align-items-center right_item_wrap"
                                  onClick={() => handleAddHardware(d, item)}
                                >
                                  <div
                                    className={`right_item w-100 p-1 clickable`}
                                    key={i}
                                  >
                                    <span className="me-2 image_icon">
                                      {d.imagePath ? (
                                        <Image src={d.imagePath} />
                                      ) : (
                                        <BsTools size={20} color="#989898" />
                                      )}
                                    </span>
                                    <div className="right_title">
                                      <span className="text-truncate">
                                        {d?.name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <div className="flex-column">
                        <span className="text-muted fs-6 d-flex align-items-center gap-1">
                          <BiInfoCircle size={16} />
                          No hardware configured!
                        </span>
                      </div>
                    )}
                  </PopoverBody>
                )}

                {hardwareType?.name == "Frame" && (
                  <PopoverBody className="panel_object_list p-2">
                    <React.Fragment>
                      <div>
                        {item?.name === "FrameRightPerpendicular" ||
                        item?.name === "FrameLeftPerpendicular" ||
                        item?.name === "FrameTopPerpendicular" ||
                        item?.name === "FrameBottomPerpendicular" ? (
                          <>
                            <FormGroup className="form-group mn-3">
                              {headerSelectedItem.name !== "Oriel Window" && (
                                <>
                                  <Label for="baypost">BayPost</Label>
                                  <Select
                                    labelId="printdoc-label"
                                    id="baypost"
                                    value={bayPostDeg}
                                    label="baypost"
                                    onChange={handleBayPostAngle}
                                    input={
                                      <OutlinedInput
                                        className="w-100"
                                        name="baypost"
                                      />
                                    }
                                    MenuProps={MenuProps}
                                    IconComponent={() => (
                                      <MdOutlineKeyboardArrowDown
                                        size={20}
                                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                                      />
                                    )}
                                  >
                                    {bayPostList.map((option) => (
                                      <MenuItem
                                        key={option.id}
                                        value={option.id}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                              )}
                            </FormGroup>
                            {bayPostData?.name === "Variable Bay Post 120°" && (
                              <FormGroup className="mb-1">
                                <Label for="angle" className="text-dark">
                                  Angle
                                </Label>
                                <NumberInputNegative
                                  name="angle"
                                  value={bayPostAngleManual}
                                  setValue={setBayPostAngleManual}
                                />
                              </FormGroup>
                            )}
                            <>
                              <Dropdown
                                className="mx-auto"
                                isOpen={frameDrop}
                                toggle={toggleFrameDrop}
                              >
                                <DropdownToggle
                                  color="none"
                                  className="border-0 p-0"
                                >
                                  <div className="d-flex align-items-center">
                                    <span className="fw-semibold">
                                      {frameType
                                        ? frameType?.name
                                        : allFrameCollection[0]?.name}
                                      <MdKeyboardArrowDown
                                        className="ms-1"
                                        size={20}
                                      />
                                    </span>
                                  </div>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {allFrameCollection?.map((item, index) => {
                                    return (
                                      <DropdownItem
                                        key={index}
                                        onClick={() => handleFrameDrop(item)}
                                      >
                                        {item?.name}
                                      </DropdownItem>
                                    );
                                  })}
                                </DropdownMenu>
                              </Dropdown>
                              <Dropdown
                                className="mx-auto mt-2"
                                isOpen={styleDrop}
                                toggle={toggleStyleDrop}
                              >
                                <DropdownToggle
                                  color="none"
                                  className="border-0 p-0"
                                >
                                  <div className="d-flex align-items-center">
                                    <span className="fw-semibold">
                                      {styleType
                                        ? styleType?.name
                                        : allStyleCollection[0]?.name}
                                      <MdKeyboardArrowDown
                                        className="ms-1"
                                        size={20}
                                      />
                                    </span>
                                  </div>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {allStyleCollection?.map((item, index) => {
                                    return (
                                      <DropdownItem
                                        key={index}
                                        onClick={() => handleStyleDrop(item)}
                                      >
                                        {item?.name}
                                      </DropdownItem>
                                    );
                                  })}
                                </DropdownMenu>
                              </Dropdown>
                            </>
                          </>
                        ) : (
                          <>
                            <Dropdown
                              className="mx-auto"
                              isOpen={frameDrop}
                              toggle={toggleFrameDrop}
                            >
                              <DropdownToggle
                                color="none"
                                className="border-0 p-0"
                              >
                                <div className="d-flex align-items-center">
                                  <span className="fw-semibold">
                                    {frameType
                                      ? frameType?.name
                                      : allFrameCollection[0]?.name}
                                    <MdKeyboardArrowDown
                                      className="ms-1"
                                      size={20}
                                    />
                                  </span>
                                </div>
                              </DropdownToggle>
                              <DropdownMenu>
                                {allFrameCollection?.map((item, index) => {
                                  return (
                                    <DropdownItem
                                      key={index}
                                      onClick={() => handleFrameDrop(item)}
                                    >
                                      {item?.name}
                                    </DropdownItem>
                                  );
                                })}
                              </DropdownMenu>
                            </Dropdown>
                            <Dropdown
                              className="mx-auto mt-2"
                              isOpen={styleDrop}
                              toggle={toggleStyleDrop}
                            >
                              <DropdownToggle
                                color="none"
                                className="border-0 p-0"
                              >
                                <div className="d-flex align-items-center">
                                  <span className="fw-semibold">
                                    {styleType
                                      ? styleType?.name
                                      : allStyleCollection[0]?.name}
                                    <MdKeyboardArrowDown
                                      className="ms-1"
                                      size={20}
                                    />
                                  </span>
                                </div>
                              </DropdownToggle>
                              <DropdownMenu>
                                {allStyleCollection?.map((item, index) => {
                                  return (
                                    <DropdownItem
                                      key={index}
                                      onClick={() => handleStyleDrop(item)}
                                    >
                                      {item?.name}
                                    </DropdownItem>
                                  );
                                })}
                              </DropdownMenu>
                            </Dropdown>
                          </>
                        )}

                        <Button
                          color="primary"
                          size="md"
                          className={`px-4 ${
                            item?.name === "AddFrameRefRightParallel001" ||
                            item?.name === "AddFrameRefRightParallel002"
                              ? " "
                              : "mt-3"
                          } me-auto text-white`}
                          onClick={
                            () => {
                              handleAddHardware("", item);
                              setPopoverIndex(
                                popoverIndex === index ? null : index
                              );
                            } // Toggle the specific popover
                          }
                        >
                          <BiPlus size={20} className="me-1" />
                          Add
                        </Button>
                      </div>
                    </React.Fragment>
                  </PopoverBody>
                )}
              </UncontrolledPopover>
            </div>
          );
        })
      ) : (
        <></>
      )}

      {/* this condition has single side selection only */}
      {hardwareType === "Shaped Frame" ||
      hardwareType === "glazingPanel" ||
      hardwareType === "Panel" ||
      hardwareType === "transomHorizontal" ||
      hardwareType === "transomVertical" ? (
        testRef?.current?.map((item, index) => {
          return (
            <div id={`overlay${index}`} key={index} className="ref_btn_wrap">
              {checkSingleSide === index ? (
                <Button
                  id={index}
                  type="button"
                  color="primary"
                  className="rounded position-absolute ref_btn"
                  onClick={() => handleFrameSingleSide(item, index, false)}
                  style={{
                    position: "absolute",
                    left: item.overlayX,
                    top: item.overlayY,
                  }}
                >
                  <FaCheck className="text-muted text-center" />
                </Button>
              ) : (
                <Button
                  id={index}
                  type="button"
                  color="light"
                  className="rounded position-absolute ref_btn"
                  onClick={() => handleFrameSingleSide(item, index, true)}
                  style={{
                    position: "absolute",
                    left: item.overlayX,
                    top: item.overlayY,
                  }}
                >
                  {hardwareType === "Shaped Frame" ? (
                    <GoPencil className="text-muted text-center" />
                  ) : (
                    <IoAddSharp className="text-muted text-center" />
                  )}
                </Button>
              )}
            </div>
          );
        })
      ) : (
        <></>
      )}

      {/* this condition has window handle direction with arrow icon*/}
      {hardwareType === "handleDirection" &&
        testRef?.current?.map((item, index) => {
          return (
            <div id={`overlay${index}`} key={index} className="ref_btn_wrap">
              {changeHandleDirection == "right" ? (
                <Button
                  id={index}
                  type="button"
                  color="light"
                  className="rounded position-absolute ref_btn"
                  onClick={() => setChangeHandleDirection("left")}
                  style={{
                    position: "absolute",
                    left: item.overlayX,
                    top: item.overlayY,
                  }}
                >
                  <FaArrowLeftLong className="text-muted text-center" />
                </Button>
              ) : (
                <Button
                  id={index}
                  type="button"
                  color="light"
                  className="rounded position-absolute ref_btn"
                  onClick={() => setChangeHandleDirection("right")}
                  style={{
                    position: "absolute",
                    left: item.overlayX,
                    top: item.overlayY,
                  }}
                >
                  <FaArrowRightLong className="text-muted text-center" />
                </Button>
              )}
            </div>
          );
        })}

      {/* for delete only in cases */}
      {/* {(deleteItem) && testRef?.current?.map((item, index) => {
                return (
                    <div id={`overlay${index}`} key={index} className="ref_btn_wrap">
                        <Button
                            id={index}
                            type="button"
                            color='light'
                            className='rounded position-absolute ref_btn'
                            onClick={() => handleDeleteObject(index)}
                            style={{
                                position: 'absolute',
                                left: item.overlayX,
                                top: item.overlayY,
                            }}
                        >
                            <AiOutlineDelete className='text-muted text-center' />
                        </Button>
                    </div>
                )
            })} */}

      {/* for multiple selection frame areas */}
      {checkManufacturingHardware() ? (
        testRef?.current?.map((item, index) => {
          const selectedIndex = multiSelectRefPoints.find(
            (obj) => obj.index === index
          );
          return (
            <div
              id={`overlay${index}`}
              key={index}
              className={`ref_btn_wrap ${
                hardwareType === "glazing" && index === 0 ? "d-none" : ""
              }`}
            >
              <Button
                id={index}
                type="button"
                disabled={disableRefPoints(index)}
                color={`${
                  selectedIndex?.index === index &&
                  selectedIndex?.data?.name === item.name
                    ? "primary"
                    : "light"
                }`}
                className={`rounded position-absolute ref_btn`}
                onClick={() =>
                  handleMultiSelect(index, item, setMultiSelectRefPoints)
                }
                style={{
                  position: "absolute",
                  left: item?.name?.includes("verticalBarRight")
                    ? `${item?.overlayX?.slice(0, -2) - 10}px`
                    : item?.name?.includes("verticalBarLeft")
                    ? `${item?.overlayX?.slice(0, -2) + 40}px`
                    : item.overlayX,
                  top: item.overlayY,
                }}
              >
                {selectedIndex?.index === index &&
                selectedIndex?.data?.name === item.name ? (
                  <FaCheck className="text-muted text-center" />
                ) : (
                  <GoPencil className="text-muted text-center" />
                )}
              </Button>
            </div>
          );
        })
      ) : (
        <></>
      )}

      {manufacturingToggle &&
      (hardwareType === "sashHangingProfile" ||
        hardwareType === "glazing" ||
        hardwareType === "sashprofiles" ||
        hardwareType === "additionalHardware" ||
        hardwareType === "customize frame style" ||
        hardwareType === "sashHanging" ||
        hardwareType === "sill" ||
        hardwareType === "addOn" ||
        hardwareType === "transom") ? (
        testRef?.current?.map((item, index) => {
          const selectedIndex = multiSelectRefPoints.find(
            (obj) => obj.index === index
          );
          {
            /* const boundingBox = new THREE.Box3().setFromObject(item);
                console.log(boundingBox , "440" , item.overlayY , item.overlayX) */
          }
          return (
            <div
              id={`overlay${index}`}
              key={index}
              className={`ref_btn_wrap ${
                hardwareType === "glazing" && index === 0 ? "d-none" : ""
              }`}
            >
              <Button
                id={index}
                type="button"
                disabled={disableRefPoints(index)}
                color={`${
                  selectedIndex?.index === index &&
                  selectedIndex?.data?.name === item.name
                    ? "primary"
                    : "light"
                }`}
                className={`rounded position-absolute ref_btn`}
                onClick={() =>
                  handleMultiSelect(index, item, setMultiSelectRefPoints)
                }
                style={{
                  position: "absolute",
                  left: item?.name?.includes("verticalBarRight")
                    ? `${item?.overlayX?.slice(0, -2) - 10}px`
                    : item?.name?.includes("verticalBarLeft")
                    ? `${item?.overlayX?.slice(0, -2) + 40}px`
                    : item.overlayX,
                  top: item.overlayY,
                }}
              >
                {selectedIndex?.index === index &&
                selectedIndex?.data?.name === item.name ? (
                  <FaCheck className="text-muted text-center" />
                ) : (
                  <GoPencil className="text-muted text-center" />
                )}
              </Button>
            </div>
          );
        })
      ) : (
        <></>
      )}

      {!manufacturingToggle &&
      (hardwareType === "glazing" ||
        hardwareType === "sashprofiles" ||
        hardwareType === "sashHanging" ||
        hardwareType === "editAddOn" ||
        hardwareType === "customize frame style" ||
        editHardware) ? (
        testRef?.current?.map((item, index) => {
          const selectedIndex = multiSelectRefPoints.find(
            (obj) => obj.index === index
          );
          {
            /* const boundingBox = new THREE.Box3().setFromObject(item);
                console.log(boundingBox , "440" , item.overlayY , item.overlayX) */
          }
          return (
            <div
              id={`overlay${index}`}
              key={index}
              className={`ref_btn_wrap`}
            >
              <Button
                id={index}
                type="button"
                disabled={disableRefPoints(index)}
                color={`${
                  selectedIndex?.index === index &&
                  selectedIndex?.data?.name === item.name
                    ? "primary"
                    : "light"
                }`}
                className={`rounded position-absolute ref_btn`}
                onClick={() =>
                  handleMultiSelect(index, item, setMultiSelectRefPoints)
                }
                style={{
                  position: "absolute",
                  left: item?.name?.includes("verticalBarRight")
                    ? `${item?.overlayX?.slice(0, -2) - 10}px`
                    : item?.name?.includes("verticalBarLeft")
                    ? `${item?.overlayX?.slice(0, -2) + 40}px`
                    : item.overlayX,
                  top: item.overlayY,
                }}
              >
                {selectedIndex?.index === index &&
                selectedIndex?.data?.name === item.name ? (
                  <FaCheck className="text-muted text-center" />
                ) : (
                  <GoPencil className="text-muted text-center" />
                )}
              </Button>
            </div>
          );
        })
      ) : (
        <></>
      )}

      {/* 
            {(hardwareType === "sashHanging") ? newFrameRefPoints.current?.map((item, index) => {
                return (

                    <div id={`overlay${index}`} key={index} className="ref_btn_wrap">
                        {(checkSingleSide === index) ?
                            <Button
                                id={index}
                                type="button"
                                color='primary'
                                className='rounded position-absolute ref_btn'
                                onClick={() => handleFrameSingleSide(item, index, false)}
                                style={{
                                    position: 'absolute',
                                    left: item.overlayX,
                                    top: item.overlayY,
                                }}
                            >
                                <FaCheck className='text-muted text-center' />
                            </Button>
                            :
                            <Button
                                id={index}
                                type="button"
                                color='light'
                                className='rounded position-absolute ref_btn'
                                onClick={() => handleFrameSingleSide(item, index, true)}
                                style={{
                                    position: 'absolute',
                                    left: item.overlayX,
                                    top: item.overlayY,
                                }}
                            >
                                {hardwareType === 'Shaped Frame' ? <GoPencil className='text-muted text-center' /> : <IoAddSharp className='text-muted text-center' />}
                            </Button>
                        }
                    </div>
                )
            }) : <></>} */}

      {newFrameRefPoints?.current?.length > 0 ? (
        newFrameRefPoints.current?.map((item, index) => {
          const selectedIndex = multiSelectRefPoints.find(
            (obj) => obj.index === index
          );
          return (
            <div id={`overlay${index}`} key={index} className="ref_btn_wrap">
              <Button
                id={index}
                type="button"
                color={`${
                  selectedIndex?.index === index &&
                  selectedIndex?.data?.name === item.name
                    ? "primary"
                    : "light"
                }`}
                className={`rounded position-absolute ref_btn`}
                onClick={() =>
                  handleMultiSelect(index, item, setMultiSelectRefPoints)
                }
                style={{
                  position: "absolute",
                  left: item?.name?.includes("verticalBarRight")
                    ? `${item?.overlayX?.slice(0, -2) - 10}px`
                    : item?.name?.includes("verticalBarLeft")
                    ? `${item?.overlayX?.slice(0, -2) + 40}px`
                    : item.overlayX,
                  top: item.overlayY,
                }}
              >
                {selectedIndex?.index === index &&
                selectedIndex?.data?.name === item.name ? (
                  <FaCheck className="text-muted text-center" />
                ) : (
                  <GoPencil className="text-muted text-center" />
                )}
              </Button>
            </div>
          );
        })
      ) : (
        <></>
      )}

      {hardwareType === "transomHorizontal" ||
      hardwareType === "transomVertical" ? (
        deleteRefPoints?.map((item, index) => {
          const selectedIndex = multiSelectRefPoints.find(
            (obj) => obj.index === index
          );
          return (
            (item.type === "Group" || item.type === "Object3D") && (
              <div id={`overlay${index}`} key={index} className="ref_btn_wrap">
                <Button
                  id={index}
                  type="button"
                  color={`${
                    selectedIndex?.index === index ? "primary" : "light"
                  }`}
                  className={`rounded position-absolute ref_btn`}
                  onClick={() =>
                    handleMultiSelect(index, item, setMultiSelectRefPoints)
                  }
                  style={{
                    position: "absolute",
                    left: item.overlayX,
                    top: item.overlayY,
                  }}
                >
                  {selectedIndex?.index === index ? (
                    <FaCheck className="text-muted text-center" />
                  ) : (
                    <GoPencil className="text-muted text-center" />
                  )}
                </Button>
              </div>
            )
          );
        })
      ) : (
        <></>
      )}

      {/* {(hardwareType === "glazingPanel" && !deleteItem) && testRef?.current?.map((item, index) => {
                return (
                    <div id={`overlay${index}`} key={index} className="ref_btn_wrap">
                        {(changeHandleDirection == 'right') ? (
                            <Button
                                id={index}
                                type="button"
                                color='light'
                                className='rounded position-absolute ref_btn'
                                onClick={() => setChangeHandleDirection('left')}
                                style={{
                                    position: 'absolute',
                                    left: item.overlayX,
                                    top: item.overlayY,
                                }}
                            >
                                <FaArrowLeftLong className='text-muted text-center' />
                            </Button>
                        )
                            : (
                                <Button
                                    id={index}
                                    type="button"
                                    color='light'
                                    className='rounded position-absolute ref_btn'
                                    onClick={() => setChangeHandleDirection('right')}
                                    style={{
                                        position: 'absolute',
                                        left: item.overlayX,
                                        top: item.overlayY,
                                    }}
                                >
                                    <FaArrowRightLong className='text-muted text-center' />
                                </Button>
                            )}
                    </div>
                )
            })} */}
    </>
  );
};

export default ConfigurationPoints;
