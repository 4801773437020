import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import PaletteLeft from './paletteleft'
import PaletteRight from './paletteright'
import { Oval } from 'react-loader-spinner'
import { getAllColours, getAllColoursV2 } from '../../../../../../services/productServices'
import { useProductPaletteContext } from '../../../../../../context/productPaletteContext'
import { useMessage } from '../../../../../../context/messageContext'
import { handleError } from '../../../../../../utility/commonHelper'
import { useNavigate } from 'react-router-dom'
// import ComingSoon from '../../../errors/coming-soon'

const Palette = () => {

    // const [wip, setWIP] = useState(true)
    const [loading, setLoading] = useState(false)
    const { setMessage, setMessageType } = useMessage()
    const [allColours, setAllColours] = useState([])

    const { setNewItemList, setTimestamp, setActiveIndex, newItemList } = useProductPaletteContext()
    const accessToken = localStorage.getItem('access_token')
    const [metalNessVal, setMetalNessVal] = useState(0)
    const [roughnessVal, setRoughnessVal] = useState(0)
    const [colorVal, setColorVal] = useState('')
    const [bumpinessVal, setbumpinessVal] = useState(0)
    const [bumpinessChanged, setBumpinessChanged] = useState(false)
    const [textureChanged, setTextureChanged] = useState(false)
    const [bumpArray, setBumpArray] = useState([])
    const [bumpObject, setBumpObject] = useState()

    const navigate=useNavigate()


    useEffect(() => {
        getAllColoursServices('')
    }, [])

    const bumpChange = (bump) => {
        return bump;
    }

    const textureChange = (texture) => {
        return texture;
    }

    const newList = allColours?.map((item) => {
        return {
            name: item?.paletteName,
            id: item?.colourPaletteId,
            textureId: item?.textureId,
            description: item?.paletteDescription,
            chartColourId: item?.chartColourId,
            textureId: item?.textureId,
            red: item?.red,
            green: item?.green,
            blue: item?.blue,
            hex: item?.hex,
            roughness: item?.roughness,
            metalness: item?.metalness,
            bumpId: item?.bumpId,
            bumpiness: item?.bumpiness,
            filePath: item?.filePath,
            bump: "",
            texture: "",
            capturedImage: item?.capturedImage,
            isEnabled: item?.isEnabled,
            isSelf:item?.isSelf,
            isLast:item?.isLast,
        }
    })

    useEffect(() => {
        console.log("NEW LISt", newList)
        setNewItemList(newList)
    }, [allColours])


    const getAllColoursServices = async (type) => {
        setLoading(true)
        const res = await getAllColoursV2(accessToken)
        if (res.statusCode === 200) {
            setNewItemList(res?.entity)
            setAllColours(res?.entity)
            if (type === 'added') {
                let data = res.entity;
                setTimestamp(data[data?.length - 1]?.colourPaletteId)
                setActiveIndex(newItemList?.length)
            }
            type = '';
            setLoading(false)
            setMessageType('success')
            setMessage(res.message)
        } else {
            setLoading(false)
            setMessageType('error')
            setMessage(res.message)
            handleError(res, false, navigate)
        }

    }

    return (
        <>

            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}

            <InnerLayout pageTitle="Palette" layoutType=""
                leftContent={<PaletteLeft setLoading={setLoading} getAllColoursServices={getAllColoursServices} allColours={allColours} />}
                rightContent={<PaletteRight setLoading={setLoading} getAllColoursServices={getAllColoursServices} />} />
        </>
    )
}

export default Palette