import React, { useState } from 'react'
import InternalLayout from '../../../../../../../layouts/internalLayout'
import CollectionsLeft from './collections-left'
import CollectionsRight from './collections-right'
import { getAllColoursCollection } from '../../../../../../../services/productServices'
import { useProductPaletteContext } from '../../../../../../../context/productPaletteContext'
import { useMessage } from '../../../../../../../context/messageContext'
import { useEffect } from 'react'
import { Oval } from 'react-loader-spinner'
import { useLocation, useNavigate } from 'react-router-dom'
import { handleError } from '../../../../../../../utility/commonHelper'


const CollectionsHardware = () => {

    const [loading, setLoading] = useState(false)
    const [allCollection, setAllCollection] = useState([])
    const { setNewItemList, setActiveIndex, setTimestamp } = useProductPaletteContext()
    const { setMessage, setMessageType } = useMessage()
    const location=useLocation()
    const locationId=location?.state?.data
    const navigate=useNavigate();

     useEffect(()=>{
        if(locationId){
            setTimestamp(locationId)
        }
     },[locationId])

    const accessToken = localStorage.getItem('access_token')

    useEffect(() => {
        getAllCollectionServices()
    }, [])

    const getAllCollectionServices = async (type) => {

        setLoading(true)
        const res = await getAllColoursCollection("2", accessToken)
        if (res.data?.statusCode === 200) {
            let data = res?.data?.entity;
            setNewItemList(res?.data.entity)
            setAllCollection(res?.data.entity)
            setLoading(false)
            if (type === 'added') {
                setTimestamp(data[data.length - 1]?.id);
                setActiveIndex(data.length - 1)
            }
            setMessageType('success')
            setMessage(res.message)
            type = '';
        } else {
            setLoading(false)
            setMessageType('error')
            setMessage(res.message)
            handleError(res, false, navigate)
        }

    }




    return (
        <div>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
            <InternalLayout pageTitle="Hardware Colour Collections" layoutType="" leftContent={<CollectionsLeft loading={loading} setLoading={setLoading} locationId={locationId} allCollection={allCollection} getAllCollectionServices={getAllCollectionServices} />} rightContent={<CollectionsRight getAllCollectionServices={getAllCollectionServices} loading={loading} setLoading={setLoading} locationId={locationId}/>} />
        </div>
    )
}

export default CollectionsHardware