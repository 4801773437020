import React from 'react'
import { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { BsSquare } from 'react-icons/bs';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import {
    Button,
    Label,
} from 'reactstrap';
import { PiProhibitBold } from "react-icons/pi";

const DualListHideShowSystems = ({ isSelf, hiddenItems, visibleItems, visibleData, moveToRight, moveToLeft, selectedHiddenIndex, setDefaultItem, setSelectedHiddenIndex, setVisibleData, selectedVisibleIndex, setSelectedVisibleIndex, updateOrder }) => {

    useEffect(() => {
        if (visibleItems) {
            setVisibleData(visibleItems[selectedVisibleIndex])
        }
    }, [visibleItems, selectedVisibleIndex])

    console.log(visibleItems, "visibleItems", hiddenItems, "hiddenitems")

    const handleDown = () => {
        updateOrder('DOWN')
    }

    const handleUp = () => {
        updateOrder('UP')
    }

    const getImage = (imageUrl) => {
      let image = imageUrl;
      if (imageUrl?.includes("svg")) {
        image = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
          imageUrl
        )}`;
      }
      return image;
    };

    return (
        <div>
            <div className="d-flex flex-wrap dual_box_design">
                <div className="dual_box_wrap">
                    <Label for="name">
                        Hidden
                    </Label>
                    <div className='dual_box'>
                        <div className='dual_box_list'>
                            {hiddenItems?.map((item, index) =>
                                <div key={index} className='position-relative d-flex align-items-center'>
                                    <div className={`list_item w-100`}>
                                        <span className='me-2 image_icon'>
                                            {item.imagePath ? (
                                                <Image src={getImage(item.imagePath)} style={{ width: "55px", height: "45px" }} />
                                            ) : (
                                                <span>
                                                    <svg viewBox="0 0 50 20" width="55" height="45"><path className="stroke" stroke='#989898' fill='#fff' strokeWidth="2" d={`M 0,20 0,0 50,0 50,20 Z`}></path></svg>
                                                </span>
                                            )}
                                        </span>
                                        <span className='text-truncate'>
                                            {item.name}
                                        </span>
                                    </div>

                                    {isSelf && (
                                        <span className='position-absolute py-1 px-2 h-100 d-flex align-items-center' style={{ right: "0" }}>
                                            <Link to="#" onClick={() => { moveToRight(item?.id); setSelectedHiddenIndex(index) }}> <AiOutlineArrowRight className='text-primary' size={18} /> </Link>
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="dual_box_wrap">
                    <Label for="name">
                        Visible
                    </Label>
                    <div className='dual_box has_btns'>
                        <div className='dual_box_list'>
                            {visibleItems?.map((item, index) =>
                                <div key={index} className='position-relative d-flex align-items-center clickable' onClick={() => { setVisibleData(item); setSelectedVisibleIndex(index) }}>
                                    <div className={`list_item w-100 ${index === selectedVisibleIndex && "active"}`}>
                                        <span className='me-2 image_icon'>
                                            {item.imagePath ? (
                                                <Image src={getImage(item.imagePath)} style={{ width: "55px", height: "45px" }} />
                                            ) : (
                                                <PiProhibitBold size={40} />
                                            )}
                                        </span>
                                        <span className='text-truncate'>
                                            {item.name}
                                        </span>
                                    </div>

                                    {/* <span className='position-absolute py-1 px-2 h-100 d-flex align-items-center' style={{ right: "0" }}>
                                        <Link to="#"
                                            onClick={() => { moveToLeft(item?.id); setSelectedVisibleIndex(index) }}
                                            disabled={(item?.defaultProfile && item?.defaultSubProduct)}
                                        >
                                            <AiOutlineArrowLeft className='text-primary' size={18} />
                                        </Link>
                                    </span> */}

                                    {isSelf && (
                                        <span className='position-absolute py-1 px-2 h-100 d-flex align-items-center' style={{ right: "0" }}>
                                            <Link to="#" onClick={() => { moveToLeft(item?.id); setSelectedVisibleIndex(index) }}> <AiOutlineArrowLeft className='text-primary' size={18} /> </Link>
                                        </span>
                                    )}

                                    {/* {(!item?.defaultProfile && !item?.defaultSubProduct) ? (
                                        <span className='position-absolute py-1 px-2 h-100 d-flex align-items-center' style={{ right: "0" }}>
                                            <Link to="#" onClick={() => { moveToLeft(item?.id); setSelectedVisibleIndex(index) }}> <AiOutlineArrowLeft className='text-primary' size={18} /> </Link>
                                        </span>
                                    ) : (
                                        <span className='position-absolute py-1 px-2 h-100 d-flex align-items-center' style={{ right: "0" }}>
                                            <Link to="#" className='disabled opacity-50'
                                            >
                                                <AiOutlineArrowLeft className='text-primary' size={18} />
                                            </Link>
                                        </span>
                                    )} */}

                                    {/* {(!item?.defaultProfile && !item?.defaultSubProduct) && (
                                        <span className='position-absolute py-1 px-2 h-100 d-flex align-items-center' style={{ right: "0" }}>
                                            <Link to="#" onClick={() => { moveToLeft(item?.id); setSelectedVisibleIndex(index) }}> <AiOutlineArrowLeft className='text-primary' size={18} /> </Link>
                                        </span>
                                    )} */}
                                </div>
                            )}
                        </div>

                        {isSelf && (
                            <div className={`d-flex justify-content-between gap-2 px-4 py-2 ${visibleItems?.length < 1 && "opacity-75 pointer_none"}`}>
                                <Button
                                    color=""
                                    size="md"
                                    className='border-0 text-primary px-0'
                                    onClick={() => handleUp()}
                                    disabled={visibleItems?.length < 1 || visibleData?.id === '' || selectedVisibleIndex === 0}
                                >
                                    <MdArrowUpward size={22} className='me-1' />
                                    Up
                                </Button>
                                <Button
                                    color=""
                                    size="md"
                                    className='border-0 text-primary px-0'
                                    onClick={() => handleDown()}
                                    disabled={(selectedVisibleIndex === visibleItems?.length - 1) || visibleItems?.length < 1}
                                >
                                    <MdArrowDownward size={22} className='me-1' />
                                    Down
                                </Button>

                                <Button
                                    color=""
                                    size="md"
                                    className='border-0 text-primary px-0'
                                    onClick={() => setDefaultItem()}
                                    disabled={visibleItems?.length < 1 || visibleData?.defaultProfile || visibleData?.defaultSubProduct}
                                >
                                    <BsSquare size={16} className='me-1' />
                                    Default
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DualListHideShowSystems