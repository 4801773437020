// import { FormControl, FormControlLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import {
    Row,
    Col,
    Button,
    Label,
    FormGroup,
    Input,
} from 'reactstrap';
// import { MenuProps, bump, chartColor, texture } from '../../../../../../utility/data';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useProductPaletteContext } from '../../../../../../context/productPaletteContext';
import { useMessage } from '../../../../../../context/messageContext';
import { addCollectionColours, addExternalColours, addInternalColours, deleteExternalColours, deleteInternalColours, getUniqueCollection, hiddenColourListExternal, hiddenColourListInternal, setDefaultExternalColour, visibleColourListExternal, visibleColourListInternal, updateInternalVisibleOrder, updateExternalVisibleOrder, setDefaultInternalColour } from '../../../../../../services/productServices';
import CollectionColours from '../../../../../../components/CollectionColours';
import Editprices from '../../../../../../components/Editprices';
import { useRightContext } from '../../../../../../context/rightMenuContext';
import { handleError } from '../../../../../../utility/commonHelper';

const CollectionsLeft = ({ setLoading, getAllCollectionServices, allCollection, getColourCollectionNavigatonService }) => {

    const [name, setName] = useState('')
    const accessToken = localStorage.getItem('access_token')

    const { timestamp, handleEdit, setTimestamp, activeIndex, newItemList } = useProductPaletteContext();
    const { setMessage, setMessageType } = useMessage()
    const [hiddenExternalColours, setHiddenExternalColours] = useState([])
    const [hiddenInternalColors, setHiddenInternalColors] = useState([])
    const [visibleExternalColors, setVisibleExternalColors] = useState([])
    const [visibleInternalColors, setVisibleInternalColors] = useState([])
    const [visibleData, setVisibleData] = useState('')
    const [selectedHiddenExternalIndex, setSelectedHiddenExternalIndex] = useState(0)
    const [selectedHiddenInternalIndex, setSelectedHiddenInternalIndex] = useState(0)
    const [selectedVisibleInternalIndex, setselectedVisibleInternalIndex] = useState(0)
    const [selectedVisibleExternalIndex, setSelectedVisibleExternalIndex] = useState(0)
    const [selectedVisibleExternalData, setSelectedVisibleExternalData] = useState('')
    const [selectedVisibleInternalData, setSelectedVisibleInternalData] = useState('')
    // const [selectedHdden, setselectedHdden] = useState(second)
    const [id, setId] = useState()

    const { setSelectedRightItem, selectedRightItem } = useRightContext()

    const navigate = useNavigate()
    const firstElId = allCollection[0]?.id
    let externalVisibleFirstEl = visibleExternalColors[0];

    useEffect(() => {
        if (firstElId) {
            if (allCollection?.length && activeIndex !== 0) {
                setTimestamp(timestamp)
            } else {
                setTimestamp(firstElId)
            }
        }
    }, [allCollection, timestamp])

    // settting here the initial data for external visible colour

    useEffect(() => {
        if (visibleExternalColors?.length > 0) {
            if (selectedVisibleExternalIndex !== 0) {
                setSelectedVisibleExternalData(selectedVisibleExternalData)
            } else {
                setSelectedVisibleExternalData(visibleExternalColors[0])
            }
        }
    }, [visibleExternalColors, selectedVisibleExternalIndex])

    useEffect(() => {
        if (selectedVisibleExternalData) {
            internalHiddenListService(timestamp, selectedVisibleExternalData?.primaryId)
        }
    }, [selectedVisibleExternalData])

    useEffect(() => {
        if (visibleInternalColors && visibleInternalColors.length > 0) {
            if (selectedVisibleInternalIndex != null) {
                console.log(visibleInternalColors[selectedVisibleInternalIndex], "aadi 123")
                setSelectedVisibleInternalData(visibleInternalColors[selectedVisibleInternalIndex])
            }
        } else {
            setSelectedVisibleInternalData('')
        }
    }, [selectedVisibleInternalIndex, visibleInternalColors])

    useEffect(() => {
        if (newItemList?.length === 0) {
            setName('')
            setTimestamp('')
        }
    }, [newItemList])

    const reset = () => {
        getUniqueCollectionsServices(timestamp)
        getAllCollectionServices()
    }

    useEffect(() => {
        if (timestamp) {
            getUniqueCollectionsServices(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if (timestamp) {
            externalHiddenListService(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if (timestamp && selectedVisibleExternalData) {
            internalHiddenListService(timestamp, selectedVisibleExternalData?.primaryId)
        }
    }, [timestamp, selectedVisibleExternalData])

    const addUpdateColorsServices = async () => {
        setLoading(true)
        const data = {
            id: timestamp,
            name: name,
            type: 1,
        }
        const res = await addCollectionColours(data, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res?.data?.message : 'Added new colour')
                // getAllCollectionServices('added')
                setLoading(false)
            } else {
                setMessage(res?.data?.message ? res?.data?.message : 'Something went wrong')
                setMessageType('error')
                // getAllCollectionServices()
                setLoading(false)
            }

            getColourCollectionNavigatonService()
        } else {
            getColourCollectionNavigatonService()
            handleError(res, false, navigate)
        }
    }

    const getUniqueCollectionsServices = async (timestamp) => {
        setLoading(true)
        const res = await getUniqueCollection(timestamp, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                const selectedRight = allCollection?.find((p) => p.id == timestamp)
                setSelectedRightItem(selectedRight)
                setName(res?.data.entity?.name)
                setTimestamp(timestamp)
                setLoading(false)
                setId(res?.data?.entity?.id)

            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    const deleteExternalColourServices = async (externalColorId) => {
        const res = await deleteExternalColours(externalColorId, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                externalHiddenListService(timestamp)
                setSelectedVisibleExternalIndex(0)
            } else {
                setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('error')
                externalHiddenListService(timestamp)
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }


    const deleteInternalColourServices = async (internalColorId) => {
        const res = await deleteInternalColours(internalColorId, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                internalHiddenListService(timestamp, selectedVisibleExternalData?.primaryId)
            } else {
                setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('error')
                internalHiddenListService(timestamp, selectedVisibleExternalData?.primaryId)
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    const handleNameChange = (e) => {
        const value = e.target.value;
        if (e.target.value.length <= 51) {
            setName(value)
            handleEdit(value)
        }
    }

    const addExternalProductService = async (colorId, colorName) => {
        const res = await addExternalColours(timestamp, colorId, colorName, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Updated Palette')
                setMessageType('success')
                setLoading(false)
                // getAllColoursServices()
                externalHiddenListService(timestamp)
                // internalHiddenListService(timestamp, selectedVisibleExternalData?.primaryId)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                // getAllColoursServices()
                externalHiddenListService(timestamp)
                internalHiddenListService(timestamp, selectedVisibleExternalData?.primaryId)
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    const addInternalProductService = async (colorId, colorName) => {
        const res = await addInternalColours(selectedVisibleExternalData?.primaryId, colorId, colorName, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                internalHiddenListService(timestamp, selectedVisibleExternalData?.primaryId)
                internalVisibleListService(timestamp, selectedVisibleExternalData?.primaryId)
            } else {
                setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('error')
                internalHiddenListService(timestamp, selectedVisibleExternalData?.primaryId)
                internalVisibleListService(timestamp, selectedVisibleExternalData?.primaryId)
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            // getAllColoursServices()
            handleError(res, false, navigate)
        }
    }

    const externalHiddenListService = async (collectionId) => {
        setLoading(true)
        const res = await hiddenColourListExternal(accessToken, collectionId, 1)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                setHiddenExternalColours(res?.data?.entity)
                externalVisibleListService(collectionId)
            }
        }
        else{
            handleError(res, false, navigate)
        }
    }

    const internalHiddenListService = async (collectionId, externalId) => {
        setLoading(true)
        const res = await hiddenColourListInternal(accessToken, collectionId, 1, externalId)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                setHiddenInternalColors(res?.data?.entity)
                internalVisibleListService(timestamp, selectedVisibleExternalData?.primaryId)
            }
        }
        else{
            handleError(res, false, navigate)
        }
    }

    const externalVisibleListService = async (collectionId) => {
        setLoading(true)
        const res = await visibleColourListExternal(accessToken, collectionId, 1)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                setVisibleExternalColors(res?.data?.entity)
            }
        }
        else{
            handleError(res, false, navigate)
        }
    }

    // const updateExternalOrder = () => {
    //     console.log("order update");
    // }



    // const updateInternalOrder = () => {
    //     console.log("order update");
    // }

    const internalVisibleListService = async (collectionId, externalId) => {
        setLoading(true)
        const res = await visibleColourListInternal(accessToken, collectionId, 1, externalId)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                setVisibleInternalColors(res?.data?.entity)
            }
        }
        else{
            handleError(res, false, navigate)
        }
    }

    const setDefaultExternalServices = async () => {
        const res = await setDefaultExternalColour(accessToken, selectedVisibleExternalData?.primaryId)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                externalVisibleListService(timestamp)
            } else {
                setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('error')
                externalVisibleListService(timestamp)
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            // getAllColoursServices()
        	handleError(res, false, navigate)
        }
    }

    const setDefaultInternalServices = async () => {

        console.log(selectedVisibleInternalData, "selectedVisibleInternalData")

        const res = await setDefaultInternalColour(accessToken, selectedVisibleInternalData?.primaryId)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message)
                setMessageType('success')
                setLoading(false)
                internalVisibleListService(timestamp, selectedVisibleExternalData?.primaryId)
            } else {
                setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('error')
                internalVisibleListService(timestamp, selectedVisibleExternalData?.primaryId)
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    const editNavData = { url: `/pricing/colours/price-list/${timestamp}` }


    const updateExternalVisibleOrderServices = async (direction) => {
        setLoading(true)
        const res = await updateExternalVisibleOrder(accessToken, selectedVisibleExternalData?.primaryId, direction, timestamp)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                externalVisibleListService(timestamp)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    const updateExternalOrder = async (direction, type) => {
        updateExternalVisibleOrderServices(direction, type)

        if (direction === "UP") {
            setSelectedVisibleExternalIndex(selectedVisibleExternalIndex - 1)
        } else {
            setSelectedVisibleExternalIndex(selectedVisibleExternalIndex + 1)
        }
    }


    const updateInternalVisibleOrderServices = async (direction) => {
        setLoading(true)
        // console.log(visibleData.colourId, "251");
        const res = await updateInternalVisibleOrder(accessToken, visibleData?.primaryId, direction, selectedVisibleExternalData?.primaryId)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                internalVisibleListService(timestamp, selectedVisibleExternalData?.primaryId)

            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    const updateInternalOrder = async (direction, type) => {
        updateInternalVisibleOrderServices(direction, type)

        if (direction === "UP") {
            setselectedVisibleInternalIndex(selectedVisibleInternalIndex - 1)
        } else {
            setselectedVisibleInternalIndex(selectedVisibleInternalIndex + 1)
        }
    }

    return (
        <div className='d-flex flex-column h-100'>
            {/* <h5 className='main_title'>Lorem ipsum dolor sit amet</h5> */}
            {/* <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                    <NavLink
                        className={`${activeTab === "General" && 'active'}`}
                        onClick={() => noRefCheck("General")}
                    >
                        General
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "Discount" && 'active'}`}
                        onClick={() => noRefCheck("Discount")}
                    >
                        Discount
                    </NavLink>
                </NavItem>
            </Nav> */}
            <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
                {name && (
                    <h5 className="mb-0 text-wrap text-break me-4 main_title">{name?.slice(0, 50)}{name?.length >= 50 && "..."}</h5>
                )}

                <Editprices data={editNavData} />
            </div>
            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns has_head'>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="name">
                                    Name
                                </Label>
                                <Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name} onChange={(e) => handleNameChange(e)} />
                                {name && name?.length > 50 && (
                                    <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                )}

                                {(!name && id) && (
                                    <div className='mt-1 text-danger text-small'>Name is required</div>
                                )}
                            </FormGroup>
                        </Col>
                        {newItemList?.length > 0 &&
                            <>
                                <div className='d-flex align-items-center mb-3'>
                                    <span className='fs-5'>Exterior colours</span>
                                </div>

                                <Col lg="12">
                                    <CollectionColours shownColours={visibleExternalColors} hiddenColours={hiddenExternalColours} moveToRight={addExternalProductService} moveToLeft={deleteExternalColourServices} selectedHiddenIndex={selectedHiddenExternalIndex} selectedVisibleIndex={selectedVisibleExternalIndex} setSelectedVisibleIndex={setSelectedVisibleExternalIndex} setSelectedHiddenIndex={setSelectedHiddenExternalIndex} setVisibleData={setSelectedVisibleExternalData} visibleData={selectedVisibleExternalData} setDefaultColour={setDefaultExternalServices} updateOrder={updateExternalOrder} isSelf={selectedRightItem?.isSelf} />
                                </Col>

                                <Col lg="12" className='my-4'>
                                    {selectedVisibleExternalData && <div className='fs-5 mb-3'>{selectedVisibleExternalData?.name} interior colours</div>}
                                    <CollectionColours shownColours={visibleInternalColors} hiddenColours={hiddenInternalColors} moveToRight={addInternalProductService} moveToLeft={deleteInternalColourServices} selectedHiddenIndex={selectedHiddenInternalIndex} setSelectedHiddenIndex={setSelectedHiddenInternalIndex} selectedVisibleIndex={selectedVisibleInternalIndex} setSelectedVisibleIndex={setselectedVisibleInternalIndex} setVisibleData={setVisibleData} visibleData={visibleData} updateOrder={updateInternalOrder} setDefaultColour={setDefaultInternalServices} isSelf={selectedRightItem?.isSelf} />
                                </Col>

                                {/* <Col lg="12" className='my-4'>
                                    <div className='d-flex flex-column flex-lg-row gap-3'>
                                        <Button outline size='md' className='w-100 max-w-400' color='secondary'>
                                            Match palette order
                                        </Button>

                                        <Button outline size='md' className='w-100 max-w-400' color='secondary'>
                                            Allow any internal colour
                                        </Button>
                                    </div>
                                </Col> */}
                            </>}
                    </Row>
                </div>
                {selectedRightItem?.isSelf && <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        onClick={() => addUpdateColorsServices()}
                        disabled={newItemList?.length === 0 || !name || name?.length > 50}

                    >
                        Save Changes
                    </Button>

                    <Button
                        color="primary"
                        size="md"
                        outline
                        className='px-4'
                        disabled={newItemList?.length === 0 || !name || name?.length > 49}
                        onClick={() => reset()}
                    >
                        Reset
                    </Button>
                </div>}
            </Form>

        </div>
    )
}

export default CollectionsLeft