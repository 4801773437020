import * as THREE from 'three';
import { createShapeFromWidthHeight } from './utils/threeUtils'


export const addAddOns = (side, data, setMethod , jsonIndex = 0) => {

    const { width, height } = data;
    const newWidth = width / 1000;
    const newHeight = height / 1000;
    return (prevModelJson) => {

        const updatedModelArray = [...prevModelJson]
        const updatedModelJson = { ...updatedModelArray[jsonIndex] };

        updatedModelJson.addOn[side]?.push([newWidth, newHeight]);

        // storing data in the json
        setMethod(data, side)
        updatedModelArray[jsonIndex] = updatedModelJson;
        return updatedModelArray;

    };
};


export const deleteAddOns = (side,setMethod , jsonIndex = 0) => {
    
    //this will update the state for deletion 
    setMethod(side)
    
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = {...updatedModelArray[jsonIndex]};
        const addOn = updateJson?.addOn;

        if(addOn && addOn[side]){
            addOn[side].shift()
        }else{
            console.error("Invalid side");
        }

        updatedModelArray[jsonIndex] = updateJson;

        return updatedModelArray;
    }
}


export function createAddons(addonRef, sceneRef, addOnFrames, frames, frameStyleBottom) {

    if (!addonRef || typeof addonRef.current === "undefined") {
        console.error("addonRef is not properly initialized. Creating a new object.");
        // addonRef = { current: null }; // Initialize if undefined
        // return;
        addonRef.current = [];
    }

    if (addonRef?.current) {
        addonRef.current.visible = false;
        sceneRef.remove(addonRef.current);
    }

    if (sceneRef) {
        var addonLocal
        sceneRef?.traverse(function (object) {
            if (object.name.includes("addon")) {
                object.visible = false;
                addonLocal = object
            }
        });
        sceneRef.remove(addonLocal);
    }


    // const addonDepth = 0.2;
    const addonHeight = 0.04;

    const extrudeSettings = (path, steps) => ({
        // steps: 100,
        steps: steps,
        extrudePath: path, // Path to extrude along
        bevelEnabled: false,  // No bevel
        morphTargetsRelative: true
    });

    const addons = {};
    Object.keys(addOnFrames)?.forEach((side) => {
 
        const frameData = addOnFrames[side];

        // Skip if the array for the side is empty
        if (!frameData || frameData?.length === 0) return;
        
        frameData?.forEach((value, index) => {

            const addonHeight = value[0];
            let boundingBox;
            let addonPathStart, addonPathEnd;

            const material = frames[side][2].material;
            boundingBox = new THREE.Box3().setFromObject(addons[side] || frames[side][2]);
            // Determine bounding box based on the side
            if (side === 'bottom') {
                let leftX = boundingBox.min.x;
                let rightX = boundingBox.max.x;
                if (addons.left) leftX = new THREE.Box3().setFromObject(addons.left).min.x;
                if (addons.right) rightX = new THREE.Box3().setFromObject(addons.right).max.x;
                addonPathStart = new THREE.Vector3(leftX, boundingBox.min.y - 0, 0);
                addonPathEnd = new THREE.Vector3(rightX, boundingBox.min.y - 0, 0);
            } else if (side === 'top') {
                let leftX = boundingBox.min.x;
                let rightX = boundingBox.max.x;
                if (addons.left) leftX = new THREE.Box3().setFromObject(addons.left).min.x;
                if (addons.right) rightX = new THREE.Box3().setFromObject(addons.right).max.x;
                addonPathEnd = new THREE.Vector3(leftX, boundingBox.max.y, 0);
                addonPathStart = new THREE.Vector3(rightX, boundingBox.max.y, 0);
            } else if (side === 'left') {
                addonPathEnd = new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, 0);
                addonPathStart = new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, 0);
            } else if (side === 'right') {
                addonPathStart = new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, 0);
                addonPathEnd = new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, 0);
            }
            const addonDepth = value[1];

            if(addonDepth && addonHeight){

            const sideBoundingBox = new THREE.Box3().setFromObject(frames[side][2]);
            const depth = sideBoundingBox.max.z - sideBoundingBox.min.z;

            const addonPath = new THREE.LineCurve3(addonPathStart, addonPathEnd);

            // Update extrude settings and shape
            const addonShape = createShapeFromWidthHeight(addonDepth, addonHeight);

            // Create the geometry and mesh
            const addonGeometry = new THREE.ExtrudeGeometry(addonShape, extrudeSettings(addonPath, 2));
            const exmesh = new THREE.Mesh(addonGeometry, material);
            exmesh.castShadow = true;
            exmesh.receiveShadow = true;
            if (!index % 2) exmesh.material.shininess += 30;
            addons[side] = exmesh;

            // // Adjust position based on the side
            exmesh.position.z = -(depth - addonDepth) / 2;

            // Set a unique name and add to the scene
            exmesh.name = 'addon ' + side;
            if (side == "bottom") {
                frameStyleBottom.current = exmesh;
            }

            if(exmesh){
                addonRef.current = exmesh;
            }
            sceneRef.add(exmesh);
        }
        });
    });

}


export const calculateOffsets = (addOnFrames, sillHeight) => {
    let wHeightOffset = 0;
    let wWidthOffset = 0;


    const calculateWidth = (frames) => {
        return frames?.reduce((accumulator, currentArray) => {
            return accumulator + (currentArray[0] || 0); // Sum the first element of each inner array
        }, 0);
    };


    if (addOnFrames) {

        const aoRightWidth = calculateWidth(addOnFrames?.right) || 0;
        const aoLeftWidth = calculateWidth(addOnFrames?.left) || 0;
        const aoTopWidth = calculateWidth(addOnFrames?.top) || 0;
        const aoBottomWidth = calculateWidth(addOnFrames?.bottom) || 0;
 
        const aoWidth = aoRightWidth + aoLeftWidth;
        const aoHeight = aoTopWidth + aoBottomWidth;

        wWidthOffset = aoWidth;
        wHeightOffset = aoHeight + sillHeight;
    }

    return { wWidthOffset, wHeightOffset }
}
