import React from 'react'
import InternalLayout from '../../../../../../layouts/internalLayout'
import CollectionsRight from "./collections-right"
import CollectionsLeft from "./collections-left"
import { useProductPaletteContext } from '../../../../../../context/productPaletteContext'
import { useMessage } from '../../../../../../context/messageContext'
import { useState } from 'react'
import { useEffect } from 'react'
import { Oval } from 'react-loader-spinner'
import { allHardwareCollection } from '../../../../../../services/productHardwareServices'
import { handleError } from '../../../../../../utility/commonHelper'
import { useNavigate } from 'react-router-dom'

const HardWareCollections2 = () => {

  const [loading, setLoading] = useState(false)
  const [allCollection, setAllCollection] = useState([])
  const { setNewItemList, setTimestamp, setActiveIndex } = useProductPaletteContext()
  const { setMessage, setMessageType } = useMessage()
  const [usedProducts, setUsedProducts] = useState([]);
  const accessToken = localStorage.getItem('access_token')
  const navigate=useNavigate()

  useEffect(() => {
    getAllCollectionServices()
  }, [])


  const getAllCollectionServices = async (type) => {
    setLoading(true)

    const res = await allHardwareCollection(accessToken)
    if (res?.data?.statusCode === 200) {
      let data = res?.data?.entity;
      setNewItemList(res?.data?.entity)
      setAllCollection(res?.data?.entity)
      if (type === 'added') {
        setTimestamp(data[data?.length - 1]?.id)
        setActiveIndex(data?.length - 1)
        
      }
      type = '';
      setLoading(false)
      setMessageType('success')
      setMessage(res.message)
    } else {
      setLoading(false)
      setMessageType('error')
      setMessage(res.message)
      handleError(res, false, navigate)
    }

  }

  const handleUsedProducts=(data)=>{
    setUsedProducts(data);
  }

  return (
    <div>
      {loading && (
        <div className='loader_main full_loader'>
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      <InternalLayout pageTitle="Hardware Style Collection" layoutType="" leftContent={<CollectionsLeft allCollection={allCollection} loading={loading} setLoading={setLoading} getAllCollectionServices={getAllCollectionServices} setUsedProductsFromLeft={handleUsedProducts} />} rightContent={<CollectionsRight getAllCollectionServices={getAllCollectionServices} loading={loading} setLoading={setLoading} usedProducts={usedProducts} />} />
    </div>
  )
}

export default HardWareCollections2