import React from "react";
import { MdClose } from "react-icons/md";

const SidePanelBar = ({
  title,
  type,
  leftPanelOpen,
  setLeftPanelOpen,
  content,
}) => {
  const handleToggleSidebar = () => {
    setLeftPanelOpen(false);
  };

  return (
    <>
      <div
        className={`custom_panel_wrap ${leftPanelOpen ? "sidebar-open" : ""}`}
      >
        <div className="custom_panel py-3 px-2">
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <h5 className="mb-0 text-wrap text-break me-4 fs-4 fw-bold">
                {title} {type ? type : ""}
              </h5>

              <MdClose
                className="close-icon clickable"
                onClick={handleToggleSidebar}
                size={20}
              />
            </div>
          </div>

          {content}
        </div>
      </div>
    </>
  );
};

export default SidePanelBar;
