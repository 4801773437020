import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { MdOutlineKeyboardArrowDown, MdOutlineModeEdit } from "react-icons/md";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { MenuProps, noSelectOptions } from "../utility/data";
import { useRightContext } from "../context/rightMenuContext";
import {
  addUpdateAdditionalArticlesMappingService,
  deleteAdditionalArticlesMapping,
  getAllAdditionalArticlesMappingService,
  getIndividualArticleService,
} from "../services/productProfileServiceV";
import NumberInput from "../components/NumberInput";
import DataTable from "react-data-table-component";
import { BiSort, BiTrashAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useMessage } from "../context/messageContext";
import { useNavigate } from "react-router-dom";
import { getAllAdditionalArticlesService } from "../services/additionalArticlesService";
import { handleError } from "../utility/commonHelper";

const ProfileAdditionalArticles = ({
  setLoading,
  activeTab,
  accessToken,
  selectItemDetails,
  isDisabled,
}) => {
  const { timestamp } = useRightContext();
  const navigate = useNavigate();
  const { setMessage, setMessageType } = useMessage();
  const [additionalArticlesCollection, setAdditionalArticlesCollection] =
    useState([]);
  const [quantity, setQuantity] = useState("1");
  const [additionalArticleId, setAdditionalArticleId] = useState("");
  const [deleteIndividualAccessoriesId, setDeleteIndividualAccessoriesId] =
    useState("");
  const [showTableDeleteModal, setTableShowDeleteModal] = useState(false);
  const [individualArticle, setIndividualArticle] = useState("");
  const [itemId, setItemId] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [accessoriesId, setAccessoriesId] = useState("");
  const [selectedPriceType, setSelectedPriceType] = useState("");

  const [allArticlesData, setAllArticlesData] = useState([]);

  const [readOnlyQuantity, setReadOnlyQuantity] = useState(false);
  const [disableArticleType, setDisableArticleType] = useState(false);
  const [disableArticle, setDisableArticle] = useState(false);

  const typeOptions = [
    {
      id: 1,
      name: "Additional Profile",
    },
    {
      id: 2,
      name: "Additional Hardware",
    },
  ];

  useEffect(() => {
    if (activeTab === "Additional Articles" && selectedPriceType !== "") {
      setLoading(true);
      getAllAdditionalArticles(selectedPriceType);
    }
  }, [activeTab, selectedPriceType]);

  useEffect(() => {
    if (activeTab === "Additional Articles" && selectItemDetails?.id) {
      setLoading(true);
      getAllArticlesMapping(selectItemDetails?.id);
    }
  }, [activeTab, selectItemDetails?.id]);

  useEffect(() => {
    if (additionalArticleId) {
      getIndividualArticle(additionalArticleId);
    }
  }, [additionalArticleId]);

  useEffect(() => {
    if (individualArticle) {
      setItemId(individualArticle?.id);
      setAccessoriesId(individualArticle?.articleId);
      setQuantity(individualArticle?.qty);
      setSelectedPriceType(individualArticle?.typeId);
    }
  }, [individualArticle]);

  useEffect(() => {
    if (timestamp) {
      setAdditionalArticleId("");
      setItemId("");
      setAccessoriesId("");
      setQuantity("1");
    }
  }, [timestamp]);

  useEffect(() => {
    if (selectedPriceType === "") {
      setAdditionalArticlesCollection([]);
    }
  }, [selectedPriceType]);

  // Validation for quantity
  useEffect(() => {
    if (quantity < 0) {
      setQuantity(0);
    }
  }, [quantity]);

  // This is for making the quantity read only in case of profile with price type as per meter
  useEffect(() => {
    if (
      additionalArticlesCollection &&
      additionalArticlesCollection.length > 0
    ) {
      let item = additionalArticlesCollection.find(
        (item) => item?.id === accessoriesId
      );
      if (item?.priceType == 1) {
        setReadOnlyQuantity(true);
        setQuantity(1);
      } else {
        setReadOnlyQuantity(false);
      }
    }
  }, [accessoriesId]);

  // This is for making the quantity read only in case of profile with price type as per meter while editing the data
  useEffect(() => {
    if (
      additionalArticlesCollection &&
      additionalArticlesCollection.length > 0 &&
      individualArticle
    ) {
      setDisableArticle(true);
      setDisableArticleType(true);
      let item = additionalArticlesCollection.find(
        (item) => item?.id === individualArticle?.articleId
      );
      if (item?.priceType == 1) {
        setReadOnlyQuantity(true);
        setQuantity(1);
      } else {
        setReadOnlyQuantity(false);
        // setDisableArticle(false)
        // setDisableArticleType(false)
      }
    }
  }, [individualArticle, additionalArticlesCollection]);

  const getAllAdditionalArticles = async (typeId) => {
    setLoading(true);
    const res = await getAllAdditionalArticlesService(accessToken, typeId);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setAdditionalArticlesCollection(res?.data?.entity);
            setLoading(false);
          } else {
            setAdditionalArticlesCollection(noSelectOptions);
            setLoading(false);
          }
        }
      }
    } else {
      setLoading(false);
    }
  };

  const getAllArticlesMapping = async (productId) => {
    const res = await getAllAdditionalArticlesMappingService(
      accessToken,
      productId
    );

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setAllArticlesData(res?.data?.entity);
            setLoading(false);
          } else {
            setAllArticlesData([]);
            setLoading(false);
          }
        }
      }
    } else {
      setAllArticlesData([]);
      setLoading(false);
    }
  };

  const getIndividualArticle = async (id) => {
    const res = await getIndividualArticleService(accessToken, id);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity) {
            setIndividualArticle(res?.data?.entity);
          } else {
            setIndividualArticle("");
          }
        }
      }
    }
  };

  const handleFormSubmit = () => {
    var formDataValues = {
      Id: itemId ? itemId : "",
      ArticleId: accessoriesId,
      ArticleName: getName(accessoriesId),
      Qty: quantity,
      ProductId: selectItemDetails?.id,
      TypeId: selectedPriceType,
    };
    addUpdateAdditionalArticleMapping(formDataValues);
    if (additionalArticleId || timestamp) {
      setItemId("");
      setAdditionalArticleId("");
    }
  };

  const addUpdateAdditionalArticleMapping = async (data) => {
    setLoading(true);
    const res = await addUpdateAdditionalArticlesMappingService(
      accessToken,
      data
    );
    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessage(res?.data?.message);
        setMessageType("success");
        getAllArticlesMapping(data?.ProductId);
        setLoading(false);
        setSelectedPriceType("");
        setQuantity(1);
        setAccessoriesId("");
        setDisableArticle(false);
        setDisableArticleType(false);
        setIndividualArticle("");
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  const getArticlesMapping = allArticlesData?.map((item) => {
    return {
      accessoriesId: item?.articleId,
      accessoriesDescription: item?.articleName,
      quantity: item?.qty,
      id: item?.id,
    };
  });

  const columns = [
    {
      name: <span>Additional Articles Name</span>,
      selector: (row) => row.accessoriesDescription,
    },
    {
      name: <span>Quantity</span>,
      selector: (row) => row.quantity,
    },
    {
      name: <span>Action</span>,
      cell: (row) => (
        <div className="d-flex gap-2">
          <Link onClick={() => setAdditionalArticleId(row?.id)}>
            <MdOutlineModeEdit size={20} />
          </Link>

          {!isDisabled && (
            <Link>
              <BiTrashAlt
                size={20}
                onClick={() => {
                  setTableShowDeleteModal(true);
                  setDeleteIndividualAccessoriesId(row);
                }}
              />
            </Link>
          )}
        </div>
      ),
    },
  ];

  const deleteAccessoriesServices = async (Id) => {
    setIsDeleting(true);
    setLoading(true);

    const res = await deleteAdditionalArticlesMapping(accessToken, Id);
    if (res && res.status === 200) {
      setTableShowDeleteModal(false);
      if (res?.data?.statusCode === 200) {
        setMessage(res?.data.entity);
        setMessageType("success");
        getAllArticlesMapping(selectItemDetails?.id);
        setIsDeleting(false);
        setSelectedPriceType("");
        setQuantity(1);
        setAccessoriesId("");
        setTimeout(() => {
          setLoading(false);
        }, 1200);
      } else {
        setLoading(false);
        setIsDeleting(false);
        setMessage(
          res?.data?.message ? res?.data?.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setIsDeleting(false);
      setMessage(
        res?.data.message ? res?.data?.message : "Something went wrong"
      );
      setMessageType("error");

      setTableShowDeleteModal(false);
      handleError(res, false, navigate);
    }
  };

  const getName = (id) => {
    let item = additionalArticlesCollection.find((item) => item?.id === id);
    return item?.name;
  };

  return (
    <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
      <div className="inside_scroll has_tab has_btns has_head">
        <Row>
          <Col xxl="12">
            <Row>
              <Col lg="6">
                <FormGroup className="form-group">
                  <Label for="articleType">Article Type</Label>

                  <Select
                    labelId="articleType"
                    id="articleType"
                    disabled={disableArticleType || isDisabled}
                    value={selectedPriceType}
                    label="articleType"
                    onChange={(e) => {
                      setSelectedPriceType(e.target.value);
                    }}
                    input={
                      <OutlinedInput className="w-100" name="articleType" />
                    }
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    {typeOptions &&
                      typeOptions.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </Select>

                  {!selectedPriceType && (
                    <div className="mt-1 text-danger text-small">
                      Article type is required
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-group">
                  <Label for="collectionSelect">Additional Articles</Label>

                  <Select
                    labelId="collectionSelect"
                    id="collectionSelect"
                    disabled={disableArticle || isDisabled}
                    value={accessoriesId}
                    label="collectionSelect"
                    onChange={(e) => {
                      setAccessoriesId(e.target.value);
                    }}
                    input={
                      <OutlinedInput
                        className="w-100"
                        name="collectionSelect"
                      />
                    }
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    {additionalArticlesCollection &&
                      additionalArticlesCollection.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className="form-group">
                  <Label for="minimumOrderQuantity">Quantity</Label>

                  <NumberInput
                    className="mb-1"
                    name="Quantity"
                    disabled={readOnlyQuantity || isDisabled}
                    setValue={setQuantity}
                    value={quantity}
                  />

                  {readOnlyQuantity && !isDisabled && (
                    <div className="mt-1 text-muted text-small">
                      The field is disabled as the selected article type has per
                      meter price type.
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            {!isDisabled && (
              <div className="d-flex gap-2 gap-xl-3 main_buttons mb-2">
                <Button
                  color="primary"
                  size="md"
                  className="px-4"
                  onClick={() => handleFormSubmit()}
                  disabled={!timestamp || !accessoriesId || !quantity}
                >
                  {!additionalArticleId ? "Add" : "Update"}
                </Button>
              </div>
            )}

            <div className="d-flex flex-column flex-grow-1 justify-content-between border border-1">
              <div
                className={`profile_link ${isDisabled ? "not_allowed" : ""}`}
              >
                <DataTable
                  columns={columns}
                  data={getArticlesMapping}
                  className="custom_datatable clickable"
                  sortIcon={<BiSort size={18} className="text-muted" />}
                  // onRowClicked={handleItemClick}
                  highlightOnHover
                  // pointerOnHover
                  pagination
                  paginationPerPage={10}
                  // paginationPerPage={window?.innerHeight <= 720 ? 8 : window?.innerWidth <= 1200 ? 10 : 12}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
                />
              </div>
            </div>

            <Modal
              isOpen={showTableDeleteModal}
              toggle={() => setTableShowDeleteModal(false)}
            >
              <ModalHeader>Delete Selected Item</ModalHeader>
              <ModalBody>
                Are you sure you want delete the{" "}
                <strong>
                  {deleteIndividualAccessoriesId?.accessoriesDescription}
                </strong>{" "}
                ?
              </ModalBody>
              <ModalFooter>
                <Button
                  color="outline-danger"
                  size="md"
                  disabled={isDeleting}
                  onClick={() =>
                    deleteAccessoriesServices(deleteIndividualAccessoriesId?.id)
                  }
                >
                  Delete
                </Button>
                <Button
                  onClick={() => setTableShowDeleteModal(false)}
                  color="primary"
                  size="md"
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default ProfileAdditionalArticles;
