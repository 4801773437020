import React, { useState } from 'react'
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { MdArrowDownward, MdArrowUpward, MdContentCopy, MdFolderOpen } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useEffect } from 'react';
import { useProductPaletteContext } from '../../../../../../context/productPaletteContext';
import { useMessage } from '../../../../../../context/messageContext';
import { addUpdateHardwareCollection, deleteHardwareCollection, updateHardwareCollectionOrder } from '../../../../../../services/productHardwareServices';
import { copyItem } from '../../../../../../services/commonServices';
import { useRightContext } from '../../../../../../context/rightMenuContext';
import { handleError } from '../../../../../../utility/commonHelper';

const CollectionsRight = ({ setLoading, getAllCollectionServices, usedProducts }) => {

  const { newItemList, fetchSelected, activeIndex, handleActiveIndexDown, handleActiveIndexUp, setActiveIndex, timestamp } = useProductPaletteContext();

  const { selectedRightItem, setSelectedRightItem } = useRightContext()

  const { setMessage, setMessageType } = useMessage();
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const accessToken = localStorage.getItem('access_token')

  const navigate = useNavigate()


  useEffect(() => {
    setActiveIndex(0)
  }, [])

  useEffect(() => {
    if (newItemList?.length > 0) {
      let selectedRight = newItemList?.find((p) => p.id === timestamp);

      setSelectedRightItem(selectedRight);
    }
  }, [timestamp, newItemList]);

  const getSingleColor = newItemList?.find((item) => item.id === timestamp)




  const addCollectionServices = async (name) => {
    setLoading(true)
    const data = {
      id: "",
      name: name,
    }
    const res = await addUpdateHardwareCollection(data, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res?.data?.message : 'Added new colour')
        getAllCollectionServices("added")
        setLoading(false)
      } else {
        setMessage(res?.data?.message ? res?.data?.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        setLoading(false)
        handleError(res, false, navigate)
      }
    }
  }

  const deleteCollectionServices = async () => {
    setLoading(true)
    const res = await deleteHardwareCollection(timestamp, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message ? res.data.message : 'Deleted Color Successfully')
        getAllCollectionServices()
        setLoading(false)
        fetchSelected(0, newItemList[0]?.id)
        setShowDeleteModal(false)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        setLoading(false)
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllCollectionServices()
      handleError(res, false, navigate)
    }

  }

  const copyCollectionServices = async (id) => {
    setLoading(true)
    const res = await copyItem(accessToken, id, 6)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message)
        getAllCollectionServices()
        setLoading(false)
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        setLoading(false)
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllCollectionServices()
      handleError(res, false, navigate)
    }

  }

  const updateCollectionOrderServices = async (direction) => {
    const res = await updateHardwareCollectionOrder(timestamp, 6, direction, accessToken)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessageType('success')
        setMessage(res?.data?.message)
        getAllCollectionServices()
        setLoading(false)
        fetchSelected(activeIndex, timestamp)
        if (direction === 'UP') {
          handleActiveIndexUp()
        } else {
          handleActiveIndexDown()
        }
      } else {
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
        setMessageType('error')
        getAllCollectionServices()
        setLoading(false)
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      getAllCollectionServices()
      handleError(res, false, navigate)
    }
  }

  const handleDown = () => {
    updateCollectionOrderServices('DOWN')
  }

  const handleUp = () => {
    updateCollectionOrderServices('UP')
  }

  return (
    <>
      <div className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
        <div className='inside_scroll has_btns'>
          <div className='right_list'>
            {newItemList?.map((item, index) =>
              <div key={index} className='position-relative d-flex align-items-center right_item_wrap has_delete has_copy'>
                <Link to="#" className={`right_item w-100 ${activeIndex === index && "active"}`} onClick={() => { fetchSelected(index, item?.id) }} >
                  <span className='me-2'>
                    <MdFolderOpen size={22} />
                  </span>
                  <span className='text-truncate'>
                    {item.name}
                  </span>
                </Link>
                <span className='position-absolute action_btns'>
                  {item?.isSelf && <>
                    <Link to="#" onClick={() => { fetchSelected(index, item?.id); setShowDeleteModal(true) }} > <BiTrashAlt className='text-muted' size={20} /> </Link>
                    <Link to="#" onClick={() => copyCollectionServices(item?.id)}> <MdContentCopy className='text-muted' size={18} /> </Link>
                  </>}
                </span>
              </div>
            )}
          </div>
        </div>

        <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
          {usedProducts?.length > 0 ? (
            <h2 className='px-3 pt-3'>
              Oops!
            </h2>
          ) : (
            <ModalHeader >
              Delete Selected Item
            </ModalHeader>
          )}
          {/* <ModalBody>
            Are you sure you want delete <strong>{getSingleColor?.name}</strong> ?
          </ModalBody> */}
          {usedProducts?.length > 0 ? <ModalBody>
            <p className='bt-0'>This Collection is being used by the following products:</p>
            {usedProducts.map((item, index) =>
              <span key={index}>{index == 0 ? null : <br></br>}{item.productType}- {item.usedIn}</span>
            )}

            <p className='mt-4'>Remove the Collection from these products first.</p>
          </ModalBody> : <ModalBody>
            Are you sure you want delete <strong>{getSingleColor?.name}</strong> ?
          </ModalBody>}
          <ModalFooter>
            {usedProducts == 0 ? <Button color="outline-danger" size="md" onClick={() => deleteCollectionServices()}>Delete</Button> : null}
            <Button onClick={() => setShowDeleteModal(false)} color="primary" size="md">Cancel</Button>
          </ModalFooter>
        </Modal>

        <div className='d-flex gap-2 gap-xl-3 main_buttons'>
          <Button
            color="primary"
            size="md"
            className='px-4'
            onClick={() => addCollectionServices(`Hardware Collection ${newItemList.length + 1}`)}
          >
            <BiPlus size={20} className='me-1' />
            New
          </Button>

          {selectedRightItem?.isSelf && <>
            <Button
              color=""
              size="md"
              className='border-0 text-primary px-0'
              onClick={() => handleUp()}
              disabled={activeIndex === 0 || newItemList?.length === 0}
            >
              <MdArrowUpward size={22} className='me-1' />
              Up
            </Button>
            <Button
              color=""
              size="md"
              className='border-0 text-primary px-0'
              onClick={() => handleDown()}
              disabled={activeIndex === newItemList?.length - 1 || newItemList?.length === 0 || newItemList?.length === 1 || (selectedRightItem?.isLast)}
            >
              <MdArrowDownward size={22} className='me-1' />
              Down
            </Button>
          </>}
        </div>
      </div>
    </>
  )
}

export default CollectionsRight