import React, { useState } from "react";
import { Form } from "react-bootstrap";
import {
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { useEffect } from "react";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import PricingHeader from "../../../../../../components/Pricing/PricingHeader";
import CustomizedButton from "../../../../../../components/CustomizedButton";
import {
  handleEmptyValues,
  newHandleInput,
} from "../../../../../../utility/helper";
import PricingTableComp from "../../../../../../components/PricingTableComp";
const Left = ({
  pageName,
  updateItemDetailsServices,
  getPriceTableDataService,
  selectItemTableData,
  setSelectItemTableData,
}) => {
  const { timestamp, userDataContext } = useRightContext();

  const [selectedPricing, setSelectedPricing] = useState();
  const [selectedSupplyOnly, setSelectedSupplyOnly] = useState("");
  const [activeTab, setActiveTab] = useState("Lump Sum");

  useEffect(() => {
    var dataFecth = {
      collectionId: timestamp,
      priceMasterId: selectedPricing,
      markupMasterId: selectedSupplyOnly,
    };

    setTimeout(() => {
      if (timestamp && selectedPricing && selectedSupplyOnly) {
        getPriceTableDataService(dataFecth);
      }
    }, 200);
  }, [timestamp, selectedPricing, selectedSupplyOnly]);

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const validate = (value) => {
    if (value < 0) return false;
    return true;
  };

  const handleInput = (id, key, value) => {
    if (validate(value)) {
      setSelectItemTableData((prevData) =>
        prevData.map((item, index) =>
          index === id ? { ...item, [key]: parseFloat(value) } : item
        )
      );
    }
  };

  const handleFormSubmit = () => {
    updateItemDetailsServices(handleEmptyValues([...selectItemTableData]));
  };

  const handleFormReset = () => {
    if (timestamp) {
      var dataFecth = {
        collectionId: timestamp,
        priceMasterId: selectedPricing,
        markupMasterId: selectedSupplyOnly,
      };
      getPriceTableDataService(dataFecth);
    }
  };

  const data1 = {
    url: `/product/hardware/colours/collections`,
    stateValue: timestamp,
  };

  return (
    <div className="d-flex flex-column h-100">
      <PricingHeader
        setSelectedSupplyOnly={setSelectedSupplyOnly}
        setSelectedPricing={setSelectedPricing}
        selectedPricing={selectedPricing}
        selectedSupplyOnly={selectedSupplyOnly}
      />

      <div className="d-flex align-items-center mb-4 flex-wrap gap-3 left_header">
        <h5 className="mb-0 text-wrap text-break me-4 main_title">
          {pageName}
        </h5>

        <CustomizedButton data={data1} hasState={true} />
      </div>

      <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
        <div className="inside_scroll has_btns has_head">
          <Row>
            <Col lg="12">
              <Nav tabs className="details_header border-0 nav-tabs-custom">
                <NavItem>
                  <NavLink
                    className={`${activeTab === "Lump Sum" && "active"}`}
                    onClick={() => handleActiveTab("Lump Sum")}
                  >
                    Lump Sum
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
                <TabPane tabId="Lump Sum" className="flex-grow-1 w-100">
                  <PricingTableComp
                    data={selectItemTableData}
                    handleInput={handleInput}
                    newHandleInput={newHandleInput}
                    setData={setSelectItemTableData}
                    userDataContext={userDataContext}
                  ></PricingTableComp>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>

        <div className="d-flex gap-2 gap-xl-3 main_buttons">
          <Button
            color="primary"
            size="md"
            className="px-4"
            onClick={() => handleFormSubmit()}
            disabled={!timestamp}
          >
            Save Changes
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            disabled={!timestamp}
            onClick={() => handleFormReset()}
          >
            Reset
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Left;
