import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import ComingSoon from '../../../errors/coming-soon'
import { useNavigate } from 'react-router-dom'
import PanelProductLeft from './left'
import PanelProductRight from './right'
import { useMessage } from '../../../../../../context/messageContext'
import { useRightContext } from '../../../../../../context/rightMenuContext'
import { addNewRightItem, getAllPanelProductMaster, getPanelProductMaster, updateItemDetails } from '../../../../../../services/panelProductsService'
import { Oval } from 'react-loader-spinner'
import { getSysFrameSashInUsed, removePanelProdInUsed } from '../../../../../../services/commonServices'
import { allHardwareCollection } from '../../../../../../services/productHardwareServices'
import { noSelectOptions } from '../../../../../../utility/data'
import { getAllColoursCollection } from '../../../../../../services/productServices'
import { deleteSysFrameSashInUsed } from '../../../../../../services/sysSashProductsService'
import { interchangeFirstIndex } from '../../../../../../utility/helper'
import { getAllPanelSidebarList, getPanelMenuListForPricing } from '../../../../../../services/SidebarServices'
import { handleError } from '../../../../../../utility/commonHelper'

const PanelProduct = () => {

    const accessToken = localStorage.getItem('access_token')

    const [hardwareCollectionOptions, setHardwareCollectionOptions] = useState([]);
    const [productCollectionOptions, setProductCollectionOptions] = useState([]);

    const [wip, setWIP] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectItemDetails, setSelectItemDetails] = useState([])
    const [allRightListItems, setAllRightListItems] = useState([])
    const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true)
    const [usedProducts, setUsedProducts] = useState([])
    const { setMessage, setMessageType } = useMessage()
    const { timestamp, setSelectedRightItem, fetchSelected, setTimestamp, activeIndex, setActiveIndex, setPanelMenuList, setPricingPanelMenuList } = useRightContext()

    const navigate = useNavigate();

    var getFirstElId = allRightListItems[0]?.id || ''
    var itemAddedTrue = ''

    useEffect(() => {
        setLoading(true)
        setTimestamp('')

        getHardwareCollectionSelectOptions()
        getProductColourCollections()

        getRightMenuListData()
    }, [])

    useEffect(() => {
        if (allRightListItems?.length > 0 && timestamp) {
            getRightItemDetails(timestamp)
            getPanelProdInUsedService()
        }
    }, [timestamp])

    useEffect(() => {
        if (allRightListItemsOrder) {
            getFirstElId = allRightListItems[0]?.id

            setActiveIndex(0)

            if (getFirstElId && allRightListItems?.length) {
                getRightItemDetails(getFirstElId)
            }
        } else {
            if (getFirstElId && allRightListItems?.length) {
                if (timestamp && allRightListItems?.length && activeIndex !== 0) {
                    getRightItemDetails(timestamp)
                } else {
                    getRightItemDetails(getFirstElId)
                }
            }
        }
    }, [allRightListItems])

    // get Hardware collection from hardware collection API
    const getHardwareCollectionSelectOptions = async () => {
        const res = await allHardwareCollection(accessToken)
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setHardwareCollectionOptions(interchangeFirstIndex(res?.data?.entity))
                    } else {
                        setHardwareCollectionOptions(noSelectOptions)
                    }
                }
            }
        }
        else{
            handleError(res,false,navigate)
        }
    }

    // get product color collection
    const getProductColourCollections = async () => {
        const res = await getAllColoursCollection("1", accessToken)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setProductCollectionOptions(res?.data?.entity)
                    } else {
                        setProductCollectionOptions(noSelectOptions)
                    }
                }
            }
        }
        else{
            handleError(res,false,navigate)
        }
    }

    // get all right menu list
    const getRightMenuListData = async () => {

        getAllPanelSidebarList(accessToken, setPanelMenuList, navigate);
        getPanelMenuListForPricing(accessToken, setPricingPanelMenuList, navigate);

        const res = await getAllPanelProductMaster(accessToken);

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    if (res?.data?.entity?.length > 0) {
                        setAllRightListItems(res?.data?.entity)

                        if (itemAddedTrue == 'added') {
                            const addedItemIndex = res.data.entity.length - 1

                            if (addedItemIndex) {
                                fetchSelected(addedItemIndex, res.data.entity[addedItemIndex]?.id)
                                itemAddedTrue = ''
                            }
                        }
                    } else {
                        setAllRightListItems([])
                        setSelectItemDetails([])
                        setTimestamp('')

                        fetchSelected(0, '');
                    }
                }
                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res,false,navigate)
        }
    }

    // get details of selected item in right menu by passing item id
    const getRightItemDetails = async (id) => {

        setLoading(true)

        const res = await getPanelProductMaster(accessToken, id);

        setAllRightListItemsOrder(false)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    const selectedRight = allRightListItems?.find((p) => p.id == id)
                    setSelectedRightItem(selectedRight)

                    // set state to get details of current selected item which will display data in left side component
                    setSelectItemDetails(res.data.entity)

                    // set selected item id which will update context
                    setTimestamp(id)
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
                if (timestamp) {
                    getPanelProdInUsedService()
                }


            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res,false,navigate)
        }
    }

    // api call to update all selected item details
    const updateItemDetailsServices = async (data) => {
        setLoading(true)

        const res = await updateItemDetails(accessToken, timestamp ? timestamp : getFirstElId, data)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Updated details');
                setMessageType('success')

                getAllPanelSidebarList(accessToken, setPanelMenuList, navigate);
                getPanelMenuListForPricing(accessToken, setPricingPanelMenuList, navigate);

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res,false,navigate)
        }
    }

    // api call to added new right item
    const addNewRightItemServices = async (title) => {
        setLoading(true)
        itemAddedTrue = "added"

        const res = await addNewRightItem(accessToken, title)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Record created successfully!');
                setMessageType('success')

                getRightMenuListData()
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res,false,navigate)
        }
    }

    const handleFormReset = () => {
        if (timestamp) {
            getRightMenuListData()
            getRightItemDetails(timestamp)
        }
    }

    const getPanelProdInUsedService = async () => {
        setLoading(true)

        const res = await getSysFrameSashInUsed(accessToken, timestamp, 7)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {

                if (res.data.entity.result !== null) {
                    setUsedProducts(res.data.entity.result)
                } else {
                    setUsedProducts([])
                }

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res,false,navigate)
        }
    }
    const removePanelProdInUsedService = async (itemId,productType) => {

        const res = await removePanelProdInUsed(accessToken, itemId,productType)

        if (res && res.status === 200) {
            debugger;
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ?  res?.data?.message: 'Invalid data');
                setMessageType('success')
                getPanelProdInUsedService()

            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                getPanelProdInUsedService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getPanelProdInUsedService()
            handleError(res,false,navigate)
        }
    }

    const deletePanelProdInUsedService = async (itemId) => {

        const res = await deleteSysFrameSashInUsed(accessToken, itemId)

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? "Profile unlinked" : 'Profile unlinked');
                setMessageType('success')
                getPanelProdInUsedService()

            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                getPanelProdInUsedService()
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getPanelProdInUsedService()
            handleError(res,false,navigate)

        }
    }

    return (
        <>
            {wip ? (
                <ComingSoon title="Panel Products Coming Soon" />
            ) : (

                <>
                    {loading && (
                        <div className='loader_main full_loader'>
                            <Oval
                                height={50}
                                width={50}
                                color="#44C8F5"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#44C8F5"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </div>
                    )}

                    <InnerLayout pageTitle="Panel Products" layoutType=''
                        leftContent={<PanelProductLeft selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} handleFormReset={handleFormReset} hardwareCollectionOptions={hardwareCollectionOptions} productCollectionOptions={productCollectionOptions} usedInProductsList={usedProducts} setUsedInProductsList={setUsedProducts} deletePanelProdInUsedService={deletePanelProdInUsedService} removePanelProdInUsedService={removePanelProdInUsedService} />}
                        rightContent={<PanelProductRight accessToken={accessToken} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getRightMenuListData={getRightMenuListData} addNewRightItemServices={addNewRightItemServices} usedProducts={usedProducts} />} />
                </>
            )}
        </>
    )
}

export default PanelProduct