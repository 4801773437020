import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
} from 'reactstrap';
import { MenuProps, noSelectOptions } from '../utility/data';
import { getPrintTempApi } from '../services/printDocsService';
import { useRightContext } from '../context/rightMenuContext';
import { handleError } from '../utility/commonHelper';
import { useNavigate } from 'react-router-dom';

const DocsForeword = ({ activeTab, fontOptions, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset }) => {
    const { timestamp } = useRightContext()
    useEffect(() => {
        if (activeTab === "Foreword") {
            getPrintTempDropdownService(1)
        }
    }, [activeTab])

    const [foreWordTextOptions, setForeWordTextOptions] = useState([])
    const [foreWordTextOptions2, setForeWordTextOptions2] = useState([noSelectOptions])

    const [textSelectIds, setTextSelectIds] = useState([])
    const [textSelect, setTextSelect] = useState([])
    const [newPageText, setNewPageText] = useState([])

    const [fontSize, setFontSize] = useState('')
    const navigate=useNavigate()

    // setting all the value for prefilled inputs
    // Filtering and setting here the project items in dropdown
    useEffect(() => {

        if (selectItemDetails && selectItemDetails?.foreWordId?.length > 0) {
            setTextSelectIds(selectItemDetails?.foreWordId)
            const textSelectedNames = selectItemDetails?.foreWordId?.map((item) => {
                const proj = foreWordTextOptions?.find((p) => p?.id == item)
                return proj ? proj?.name : null
            })?.filter(id => id !== null);

            setTextSelect(textSelectedNames);
        } else {
            setTextSelect([]);
            setTextSelectIds([])
        }

        if (fontOptions && fontOptions.length > 0) {
            setFontSize(selectItemDetails?.fontSize || fontOptions[3]?.value)
        }

    }, [selectItemDetails])

    useEffect(() => {
        const selectIds = textSelect?.map((name) => {
            const project = foreWordTextOptions.find((item) => item?.name === name)
            return project ? project?.id : null;
        })

        setTextSelectIds(selectIds)
    }, [textSelect])

    useEffect(() => {
        if (textSelectIds && textSelectIds.length > 0) {
            const getSelected = textSelectIds?.map((item) => {
                const proj = foreWordTextOptions?.find((p) => p?.id == item)
                return proj ? proj : null
            })?.filter(id => id !== null);

            setForeWordTextOptions2(getSelected)
        } else {
            setForeWordTextOptions2(noSelectOptions)
        }
    }, [textSelectIds])

    useEffect(() => {
        if (foreWordTextOptions2 && foreWordTextOptions2.length > 0 && selectItemDetails && selectItemDetails?.startNewPageId?.length > 0) {
            const newPageTextNames = selectItemDetails?.startNewPageId?.map((item) => {
                const proj = foreWordTextOptions2?.find((p) => p?.id == item)
                return proj ? proj?.name : null
            })?.filter(id => id !== null);

            setNewPageText(newPageTextNames);
        } else {
            setNewPageText([]);
        }
    }, [foreWordTextOptions2])

    // get forewords options for dropdown from master
    const getPrintTempDropdownService = async (type) => {
        const res = await getPrintTempApi(accessToken, type);
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setForeWordTextOptions(res?.data?.entity)
                    } else {
                        setForeWordTextOptions(noSelectOptions)
                    }
                }
            }
        }
        else{
            handleError(res,false,navigate)
        }
    }

    const handleTextChange = (e) => {
        const value = e.target.value

        setTextSelect(typeof value === 'string' ? value.split(',') : value)
    }

    const handleNewPageTextChange = (e) => {
        const value = e.target.value

        setNewPageText(typeof value === 'string' ? value.split(',') : value)
    }

    const handleFormSubmit = () => {

        const newPageTextIds = newPageText?.map((name) => {
            const project = foreWordTextOptions.find((item) => item?.name === name)
            return project ? project?.id : null;
        })

        var formDataValues = {
            'id': selectItemDetails?.id,
            'text': textSelectIds,
            'newPageAfter': newPageTextIds,
            'fontSize': fontSize || fontOptions[3]?.value,
        }

        updateItemDetailsServices(formDataValues, "Foreword")
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col xxl="12">
                        <Row>
                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <FormControl fullWidth>
                                        <Label for="textSelect">
                                            Text
                                        </Label>

                                        <Select
                                            labelId="textSelect-label"
                                            id="textSelect"
                                            multiple
                                            value={textSelect}
                                            onChange={(e) => handleTextChange(e)}
                                            input={<OutlinedInput name='textSelect' />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {foreWordTextOptions && foreWordTextOptions?.map((item, index) => (
                                                <MenuItem key={index} value={item?.name} className='check_list'>
                                                    <Checkbox checked={textSelect?.indexOf(item?.name) > -1} />
                                                    <ListItemText primary={item?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup className="form-group">
                                    <FormControl fullWidth>
                                        <Label for="newPageText">
                                            Start a new page after the text
                                        </Label>

                                        <Select
                                            labelId="newPageText-label"
                                            id="newPageText"
                                            multiple
                                            value={newPageText}
                                            onChange={(e) => handleNewPageTextChange(e)}
                                            input={<OutlinedInput name='newPageText' />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}

                                            IconComponent={() => (
                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                            )}
                                        >
                                            {foreWordTextOptions2 && foreWordTextOptions2?.map((item, index) => (
                                                <MenuItem key={index} value={item?.name} className='check_list'>
                                                    <Checkbox checked={newPageText?.indexOf(item?.name) > -1} />
                                                    <ListItemText primary={item?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Col>

                            {/* <Col lg="6">
                                <FormGroup className="form-group">
                                    <Label for="fontSize">
                                        Font size
                                    </Label>

                                    <Select
                                        labelId="fontSize"
                                        id="fontSize"
                                        value={fontSize}
                                        label="fontSize"
                                        onChange={(e) => { setFontSize(e.target.value) }}
                                        input={<OutlinedInput className='w-100' name='fontSize' />}

                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {fontOptions && fontOptions.map((o, index) => (
                                            <MenuItem key={index} value={o.value} style={{ fontSize: `${o.value}px` }}>
                                                {o.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'

                    onClick={() => handleFormSubmit()}
                    disabled={!timestamp}
                >
                    Save Changes
                </Button>

                <Button
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => handleFormReset()}
                    disabled={!timestamp || selectItemDetails?.initialValue}
                >
                    Reset
                </Button>
            </div>
        </Form>
    )
}

export default DocsForeword