import * as THREE from 'three';
export function createShapeFromWidthHeight(width, height, radius) {
    // const arcRatio = 0.001;
    const arcRatio = 0;


    const frameShape = new THREE.Shape();
    radius = radius || 0.004;  // Radius for rounded corners

    // Start from the top left, adjust the coordinates to include radius.
    frameShape.moveTo(-width / 2 + radius, 0);

    // Top edge (left to right)
    frameShape.lineTo(width / 2 - radius, 0);
    frameShape.absarc(width / 2 - radius, -radius, radius, Math.PI / 2, 0, true);

    // Right edge (top to bottom)
    frameShape.lineTo(width / 2, -height + radius);
    frameShape.absarc(width / 2 - radius, -height + radius, radius, 0, -Math.PI / 2, true);

    // Bottom edge (right to left)
    frameShape.lineTo(-width / 2 + radius, -height);
    frameShape.absarc(-width / 2 + radius, -height + radius, radius, -Math.PI / 2, -Math.PI, true);

    // Left edge (bottom to top)
    frameShape.lineTo(-width / 2, -radius);
    frameShape.absarc(-width / 2 + radius, -radius, radius, Math.PI, Math.PI / 2, true);

    return frameShape;
}