import React, { useState, useEffect } from 'react'
import InnerLayout from "../../../../layouts/internalLayout"
import Left from './detailsLeft'
import { useMessage } from '../../../../context/messageContext'
import { Oval } from 'react-loader-spinner'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useRightContext } from '../../../../context/rightMenuContext'
import { IoCartOutline } from "react-icons/io5";
import SidePanelBar from '../../../../components/SidePanelBar'
import { QuotationPricingJob } from '../../../../components/QuotationPricingJob'
import { getAllPrintTemplate } from '../../../../services/printDocsService'
import { useDesigner } from '../../../../context/designerContext'
import { formatValue } from '../../../../utility/helper'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap'
import EditQuotationDetails from '../../../../components/EditQuotationDetails'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { BiArrowBack } from 'react-icons/bi'
import { getAllQuotations, getQuotationDetails } from '../../../../services/platformService'
import { updateQuotationInvoiceJsonData } from '../../../../services/3dModelServices'
import { accessQuote } from '../../../../utility/data'
import { handleError } from '../../../../utility/commonHelper'

const Quotations = () => {

  const { quoteId } = useParams()

  const navigate = useNavigate()

  const accessToken = localStorage.getItem('access_token')

  const { setMessage, setMessageType } = useMessage();
  const { userDataContext, projectDefault } = useRightContext();
  const { totalQuotationFramePrice } = useDesigner()

  const [loading, setLoading] = useState(true)
  const [pricingLeftOpen, setPricingLeftOpen] = useState(false)
  const [printingLinks, setPrintingLinks] = useState([])
  const [framesList, setFramesList] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false)
  const [quoteDetails, setQuoteDetails] = useState("")
  const [quoteEditDrop, setQuoteEditDrop] = useState(false);
  const [quoteUserHostUrl, setQuoteUserHostUrl] = useState("");
  const [isCopied, setIsCopied] = useState(false)

  const toggleTabDropdown = () => setQuoteEditDrop((prevState) => !prevState);

  useEffect(() => {
    if (userDataContext && !accessQuote?.includes(userDataContext?.role)) {
      navigate('/unauthorized')
    }
  }, [userDataContext])

  useEffect(() => {
    if (userDataContext?.website_url && userDataContext?.user_id && quoteId) {
      getAllQuotationsService()
    } else {
      setLoading(false);
    }
  }, [userDataContext, quoteId]);

  useEffect(() => {
    if (framesList && framesList.length > 0) {
      getTemplatePrinntingData();
    }
  }, [framesList]);

  useEffect(() => {
    if (quoteUserHostUrl && quoteUserHostUrl !== '' && quoteId) {
      getQuotationDetailsService(quoteUserHostUrl, quoteId)
    }
  }, [quoteUserHostUrl]);

  const getAllQuotationsService = async () => {
    let res = await getAllQuotations(userDataContext?.website_url, userDataContext?.user_id);

    if (res?.data?.status === true) {
      if (res?.data?.quotations) {
        const quoteItem = res?.data?.quotations?.find(
          (p) => p.quotation_id == quoteId
        );

        setQuoteUserHostUrl(quoteItem?.website_url);
      }

      setLoading(false);
    } else {
      if (res?.data?.status === false) {
        navigate("/error/404");
      } else {
        setMessage(res?.data?.message);
        setMessageType("error");
      }

      setLoading(false);
      handleError(res,false,navigate)
    }
  };

  const getQuotationDetailsService = async (host_url, quoteId) => {

    const res = await getQuotationDetails(accessToken, host_url, quoteId)

    // console.log(res, "quote res")

    if (res?.data?.status === true) {
      if (res?.data?.status) {
        setQuoteDetails(res?.data?.details)
      }

      setLoading(false);
    } else {

      setQuoteDetails('')

      if (res?.data?.status === false) {
        navigate("/error/404");
      } else {
        setMessage(res?.data?.message);
        setMessageType("error");
      }

      setLoading(false);
      handleError(res,false,navigate)
    }
  };

  const getTemplatePrinntingData = async () => {
    const res = await getAllPrintTemplate(accessToken);
    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          setPrintingLinks(res.data.entity);
        } else {
          setPrintingLinks([]);
        }
      }
    }
    else{
      handleError(res,false,navigate)
    }
  };

  // get general taxes
  // const getGeneralTax = async () => {

  //   const res = await getAllVat(accessToken);

  //   if (res && res && res.status === 200) {
  //     if (res && res?.data?.statusCode === 200) {

  //       if (res.data.entity && res.data.entity.length > 0) {
  //         const defaultValues = res.data.entity?.find((p) => p.default == true)
  //         setGeneralTax(defaultValues)
  //       } else {
  //         setGeneralTax([])
  //       }
  //     } else {
  //       setGeneralTax([])
  //     }
  //   } else {
  //     if (res?.response?.status === 401) {
  //       navigate('/unauthorized')

  //       localStorage.removeItem("access_token")
  //     }

  //     if (res?.response?.status == 404) {
  //       navigate('/error/503')
  //     }

  //     if (res?.message === "Network Error") {
  //       navigate('/error/network')
  //     }
  //   }
  // }

  // const getProjectDefault = async () => {

  //   const res = await pricingDefault(accessToken);

  //   if (res && res && res.status === 200) {
  //     if (res && res?.data?.statusCode === 200) {
  //       if (res.data.entity && res.data.entity.length > 0) {
  //         setProjectDefault(res.data.entity)
  //       } else {
  //         // setProjectDefault('')
  //       }
  //     }
  //   } else {
  //     if (res?.response?.status === 401) {
  //       navigate('/unauthorized')

  //       localStorage.removeItem("access_token")
  //     }

  //     if (res?.response?.status == 404) {
  //       navigate('/error/503')
  //     }

  //     if (res?.message === "Network Error") {
  //       navigate('/error/network')
  //     }
  //   }
  // }

  const updateQuotationInvoiceJsonDataService = async (quotationId) => {
    const res = await updateQuotationInvoiceJsonData(accessToken, quotationId)
    if (res?.data?.statusCode === 200) {
      console.log("price updated")
    } else {
      if (res?.response?.status === 401) {
        navigate('/unauthorized')
        localStorage.removeItem("access_token")
      }
      if (res?.response?.status == 404) {
        navigate('/error/503')
      }
      handleError(res,false,navigate)
    }
  }

  const handlePricingPanel = () => {
    setPricingLeftOpen(!pricingLeftOpen)
  };

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      <InnerLayout
        pageTitle=''
        filterButton={
          <div className="d-flex align-items-center gap-3">
            <Link to="/" className='btn btn-primary d-flex align-items-center gap-1 fw-bold back_btn'>
              <BiArrowBack size={22} />
              Back
            </Link>

            {quoteDetails?.quotation_name && (
              <Dropdown
                isOpen={quoteEditDrop}
                toggle={toggleTabDropdown}
                className='quote_edit_form'
              >
                <DropdownToggle color="none" className="border-0 p-0">
                  <div className="d-flex align-items-center">
                    <span className="fw-semibold">
                      {quoteDetails?.quotation_name}
                      <MdKeyboardArrowDown className="ms-1" size={20} />
                    </span>
                  </div>
                </DropdownToggle>

                <DropdownMenu className='full_height'>
                  <EditQuotationDetails
                    data={quoteDetails}
                    toggleTabDropdown={toggleTabDropdown}
                    quoteId={quoteId}
                    userDataContext={userDataContext}
                    accessToken={accessToken}
                    getQuotationDetailsService={getQuotationDetailsService}
                    setLoading={setLoading}
                    setMessage={setMessage}
                    setMessageType={setMessageType}
                    quoteUserHostUrl={quoteUserHostUrl}
                  />
                </DropdownMenu>
              </Dropdown>
            )}

            {formatValue(totalQuotationFramePrice) > 0 && (
              <div className="cursor-pointer lh-1 d-flex align-items-center" onClick={handlePricingPanel}>
                <IoCartOutline
                  className="text-primary me-1"
                  size={20}
                />
                <span className="text-primary lh-1 fs-5 fw-normal">
                  {`${(projectDefault && projectDefault?.currency) ? projectDefault?.currency : '£'}${isNaN(formatValue(totalQuotationFramePrice)) ? 0 : formatValue(totalQuotationFramePrice)}`}
                </span>
              </div>
            )}
          </div>
        }
        layoutType="full"
        leftContent={
          <Left
            printingLinks={printingLinks}
            setPrintingLinks={setPrintingLinks}
            accessToken={accessToken}
            setMessage={setMessage}
            setMessageType={setMessageType}
            loading={loading}
            setLoading={setLoading}
            quoteId={quoteId}
            framesList={framesList}
            setFramesList={setFramesList}
            isDeleted={isDeleted}
            setIsDeleted={setIsDeleted}
            setIsCopied={setIsCopied}
            quoteDetails={quoteDetails}
            updateQuotationInvoiceJsonDataService={updateQuotationInvoiceJsonDataService}
            userDataContext={userDataContext}
          />
        }
        rightContent=""
      />

      <SidePanelBar
        title={`${quoteDetails?.quotation_name}`}
        type="price"
        setLeftPanelOpen={setPricingLeftOpen}
        leftPanelOpen={pricingLeftOpen}
        content={
          <QuotationPricingJob
            quotationId={quoteId}
            leftPanelOpen={pricingLeftOpen}
            accessToken={accessToken}
            printId={printingLinks?.length > 0 ? printingLinks[0]?.id : null}
            isDeleted={isDeleted}
            quoteDetails={quoteDetails}
            isCopied={isCopied}
            setIsCopied={setIsCopied}
            setLoading={setLoading}
            quoteUserHostUrl={quoteUserHostUrl}
          />
        }
      />
    </>
  );
}

export default Quotations