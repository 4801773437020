import React, { useEffect, useState } from 'react'
import { Form, Table } from 'react-bootstrap';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from 'material-ui-core';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import { MenuProps, subAccountCostPrice } from '../../../../../utility/data';
import { discountableList, getPorjectList, updateSubAccountSettings } from '../../../../../services/accountservices';
import { ToastContainer } from "react-toastify";
import { useMessage } from '../../../../../context/messageContext';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../../../../utility/commonHelper';

const CollectionLeft = ({ accessToken, subAccountId, subAccountSettings, setLoading, discountableProductList, setDiscountableProductList, getSubAccountName, handleFormReset, handleFormResetDiscount }) => {

    const initialValues = {
        costprice: "",
        discountableItems: "",
        subaccounts: "",
        markupsAndOthers: "",
        printDocuments: ""
    };

    const [activeTab, setActiveTab] = useState("General")
    const [useMask, setUseMask] = useState(false)
    const [usePrint, setUsePrint] = useState(true)

    const [costprice, setCostprice] = useState('')
    const [discountedItems, setDiscountedItems] = useState([])
    const [subAccountsProjects, setSubAccountsProjects] = useState([])
    const [formErrors, setFormErrors] = useState({})
    const [formValues, setFormValues] = useState(initialValues)
    const [projectDropDown, setProjectDropDown] = useState([])
    const [discountableDropdown, setDiscountableDropdown] = useState([])
    const [discountData, setDiscountData] = useState([])

    const { setMessage, setMessageType } = useMessage()

    const navigate = useNavigate();

    useEffect(() => {
        getProjectListDropwDownService()
        getDiscountabelDropDownService()
    }, [])

    // Filtering and setting here the discounted items in dropdown
    useEffect(() => {
        if (subAccountSettings && subAccountSettings?.discountableItem?.length > 0) {
            const dicountedNames = subAccountSettings?.discountableItem?.map((item) => {
                const dis = discountableDropdown?.find((d) => d.id == item)
                return dis ? dis?.text : null
            }).filter((item) => item !== null)

            setDiscountedItems(dicountedNames);
        }
    }, [subAccountSettings, discountableDropdown])

    // Filtering and setting here the project items in dropdown
    useEffect(() => {
        if (subAccountSettings && subAccountSettings?.discountableItem?.length > 0) {
            const projectNames = subAccountSettings?.projectToShow?.map((item) => {
                const proj = projectDropDown?.find((p) => p.id == item)
                return proj ? proj.text : null
            }).filter((item) => item !== null)

            setSubAccountsProjects(projectNames);
        }
    }, [subAccountSettings, discountableDropdown])

    useEffect(() => {
        if (subAccountSettings) {
            setCostprice(subAccountSettings?.costPriceId)
            setUseMask(subAccountSettings?.useMarkUpsAndOtherSettings)
            setUsePrint(subAccountSettings?.usePrintDocumentSettings)
        } else {
            setCostprice(subAccountCostPrice[0]?.id)
            setUseMask(false)
        }
    }, [subAccountSettings])

    useEffect(() => {
        if(!costprice){
            setCostprice(subAccountCostPrice[0]?.id)
        }
    }, [costprice])

    const getProjectListDropwDownService = async () => {
        const res = await getPorjectList(accessToken)
        setProjectDropDown(res.entity)
    }

    const getDiscountabelDropDownService = async () => {
        const res = await discountableList(accessToken)
        setDiscountableDropdown(res.entity);
    }

    const noRefCheck = (tab) => {
        setActiveTab(tab)
    }

    // function to store input values
    const handleValidationChange = (e) => {
        const { name, value } = e.target
        setFormValues({ ...formValues, [name]: value })
    }

    // function to handle discount changedropdown
    const handleDiscountChange = (e) => {
        const value = e.target.value

        setDiscountedItems(typeof value === 'string' ? value.split(',') : value)
        handleValidationChange(e)
    }

    // function to handle cost price dropdown
    const handleCostPrice = (e) => {
        const value = e.target.value
        setCostprice(typeof value === 'string' ? value.split(',') : value)
        handleValidationChange(e)
        validate(formValues)
    }

    const handleSubAccountChange = (e) => {
        const value = e.target.value
        setSubAccountsProjects(value)
        handleValidationChange(e)
    }

    const getSelectedProjectId = subAccountsProjects?.map((name) => {
        const project = projectDropDown?.find((item) => item?.text === name)
        return project ? project?.id : null;
    })
    const getSelectedDiscountableId = discountedItems?.map((name) => {
        const project = discountableDropdown?.find((item) => item?.text === name)
        return project ? project?.id : null;
    })

    const validate = (values) => {
        let error = {};
        let isValid = true;
        if (!costprice) {
            error.costprice = "Cost price list is required!";
            isValid = false
        }
        // if (!values.discountableItems.length > 0) {
        //     error.discountableItems = "Please select atleast one option";
        //     isValid = false;
        // }

        // if (!values.subaccounts.length > 0) {
        //     error.subaccounts = "Please select atleast one option"
        //     isValid = false;
        // }

        setFormErrors(error)
        return isValid;
    }

    const handleDiscountTableChange = (index, id, e, userId) => {
        const updatedData = [...discountableProductList]
        updatedData[index] = {
            ...updatedData[index],
            discount: e.target.value,
        }
        setDiscountData(updatedData)
        setDiscountableProductList(updatedData)
    }

    const handleUseMask = (e) => {
        setUseMask(!useMask)

        if (e.target.checked) {
            setUsePrint(false)
        }
    }

    // api call to update the subaccounts settings
    const updateSubAccountsServices = async () => {
        setLoading(true)
        const data = {
            Id: subAccountSettings.id,
            UserId: subAccountSettings.userId,
            CostPriceId: costprice,
            UseMarkUpsAndOtherSettings: useMask,
            UsePrintDocumentSettings: useMask ? false : true
        }
        const res = await updateSubAccountSettings(accessToken, data, getSelectedDiscountableId, getSelectedProjectId, discountData)
        if (res?.statusCode === 200) {
            setLoading(false)
            setMessage(res.message);
            setMessageType('success')
        } else if (res?.statusCode === 404) {
            setLoading(false)
            navigate('/error/404')
        } else {
            setLoading(false)
            setMessage(res.message)
            setMessageType('error')
            handleError(res,false,navigate)
        }
    }


    return (
        <>
            <ToastContainer />

            <div className='d-flex flex-column h-100'>

                <h5 className='main_title'>{getSubAccountName}</h5>

                <Nav tabs className="details_header border-0 nav-tabs-custom">
                    <NavItem>
                        <NavLink
                            className={`${activeTab === "General" && 'active'}`}
                            onClick={() => noRefCheck("General")}
                        >
                            General
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={`${activeTab === "Discount" && 'active'}`}
                            onClick={() => noRefCheck("Discount")}
                        >
                            Discount
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className='flex-grow-1 d-flex'>
                    <TabPane tabId="General" className='flex-grow-1 w-100'>
                        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                            <div className='inside_scroll has_btns has_head'>
                                <Row>
                                    <Col xxl="12">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup className="form-group">
                                                    <Label for="CostPrice">
                                                        Cost price list
                                                    </Label>

                                                    <Select
                                                        labelId="costprice-label"
                                                        id="costprice"
                                                        value={costprice || ''}
                                                        label="CostPrice"
                                                        onChange={handleCostPrice}
                                                        input={<OutlinedInput className='w-100' name='costprice' />}

                                                        MenuProps={MenuProps}

                                                        IconComponent={() => (
                                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                                        )}
                                                    >
                                                        {subAccountCostPrice.map((item, index) => {
                                                            return (
                                                                <MenuItem key={index} value={item.id}>{item.value}</MenuItem>
                                                            )
                                                        })}

                                                    </Select>

                                                    {formErrors.costprice && (
                                                        <div className='mt-1 text-danger'>{formErrors.costprice}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>

                                            <Col lg="6">
                                                <FormGroup className="form-group">
                                                    <FormControl fullWidth>
                                                        <Label for="discountableItems">
                                                            Discountable items
                                                        </Label>
                                                        <Select
                                                            labelId="discountedItems-label"
                                                            id="discountedItems"
                                                            multiple
                                                            value={discountedItems || ''}
                                                            onChange={handleDiscountChange}
                                                            input={<OutlinedInput name='discountableItems' />}
                                                            renderValue={(selected) => selected.join(', ')}
                                                            MenuProps={MenuProps}

                                                            IconComponent={() => (
                                                                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                                            )}
                                                        >
                                                            {discountableDropdown?.map((name, index) => (
                                                                <MenuItem key={index} value={name?.text} className='check_list'>
                                                                    <Checkbox checked={discountedItems?.indexOf(name?.text) > -1} />
                                                                    <ListItemText primary={name?.text} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                    {formErrors.discountableItems && (
                                                        <div className='mt-1 text-danger'>{formErrors.discountableItems}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>

                                            <Col lg="6">
                                                <FormGroup className="form-group">
                                                    <Label for="subaccounts">
                                                        Show sub account projects in this account
                                                    </Label>
                                                    <Select
                                                        labelId="subaccounts-label"
                                                        id="subaccounts"
                                                        multiple
                                                        value={subAccountsProjects || ''}
                                                        onChange={handleSubAccountChange}
                                                        input={<OutlinedInput className='w-100 ' name='subaccounts' />}
                                                        renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}

                                                        IconComponent={() => (
                                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                                        )}

                                                    >
                                                        {projectDropDown?.map((name, index) => (
                                                            <MenuItem key={index} value={name.text} className='check_list'>
                                                                <Checkbox checked={subAccountsProjects?.indexOf(name.text) > -1} />
                                                                <ListItemText primary={name.text} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                    {formErrors.subaccounts && (
                                                        <div className='mt-1 text-danger'>{formErrors.subaccounts}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>

                                            <Col lg="6">
                                            </Col>

                                            <Col lg="6">
                                                <FormGroup className="form-group">
                                                    <Label for="mark-ups">
                                                        Use mark-ups and other settings from this account
                                                    </Label>
                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="mark-ups"
                                                        className='form-switch-lg'
                                                        onChange={(e) => { handleUseMask(e) }}
                                                        label={useMask ? 'On' : 'Off'}
                                                        checked={useMask}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="6" className={`${useMask ? 'opacity-75' : ''}`}>
                                                <FormGroup className="form-group">
                                                    <Label for="UsePrint">
                                                        Use print document settings from this account
                                                    </Label>
                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="UsePrint"
                                                        className='form-switch-lg'
                                                        onChange={() => { setUsePrint(!usePrint) }}
                                                        checked={usePrint}
                                                        label={usePrint ? 'On' : 'Off'}
                                                        disabled={useMask}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                                <Button
                                    color="primary"
                                    size="md"
                                    className='px-4'
                                    disabled={!subAccountId}
                                    onClick={() => validate(formValues) ? updateSubAccountsServices(getSelectedDiscountableId, getSelectedProjectId) : ""}
                                >
                                    Save Changes
                                </Button>

                                <Button
                                    color="primary"
                                    size="md"
                                    outline
                                    className='px-4'
                                    onClick={() => handleFormReset()}
                                    disabled={!subAccountId}
                                >
                                    Reset
                                </Button>
                            </div>
                        </Form>
                    </TabPane>
                    <TabPane tabId="Discount" className='w-100'>
                        <div className='h-100 d-flex flex-column flex-grow-1'>
                            <div className='inside_scroll has_tab has_head has_btns'>
                                <Table responsive className='w-100 discount_table'>
                                    <thead className='border-bottom'>
                                        <tr>
                                            <th>
                                                Product
                                            </th>
                                            <th>
                                                Discount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {discountableProductList?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {item?.productName}
                                                    </td>
                                                    <td>
                                                        <Form.Control type="number" onChange={(e) => handleDiscountTableChange(index, item?.id, e, item?.userId)} value={item.discount} />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                            <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                                <Button
                                    color="primary"
                                    size="md"
                                    className='px-4'
                                    onClick={() => validate(formValues) ? updateSubAccountsServices() : ""}
                                >
                                    Save Changes
                                </Button>

                                <Button
                                    color="primary"
                                    size="md"
                                    outline
                                    className='px-4'
                                    onClick={() => handleFormResetDiscount()}
                                    disabled={!subAccountId}
                                >
                                    Reset
                                </Button>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </>

    )
}

export default CollectionLeft