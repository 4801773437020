import React, { useEffect, useState } from "react";
import InnerLayout from "../../../../../../layouts/internalLayout";
import ComingSoon from "../../../errors/coming-soon";
import { useNavigate } from "react-router-dom";
import SashCollectionLeft from "./left";
import SashCollectionRight from "./right";
import { useMessage } from "../../../../../../context/messageContext";
import { useRightContext } from "../../../../../../context/rightMenuContext";
import {
  addNewRightItem,
  getSashProductsCollection,
  getItemDetails,
  updateItemDetails,
} from "../../../../../../services/sysSashCollectionService";
import { Oval } from "react-loader-spinner";
import { noSelectOptions } from "../../../../../../utility/data";
import { getAllSysFrameProductsList } from "../../../../../../services/sysFrameProductsService";
import { handleError } from "../../../../../../utility/commonHelper";
// import { GetFrameProductsMenuListForPricing, getFrameProductsSidebarList } from '../../../../../../services/SidebarServices'

const SashCollection = () => {
  const accessToken = localStorage.getItem("access_token");

  const [productListOptions, setProductListOptions] = useState([]);

  const [wip, setWIP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectItemDetails, setSelectItemDetails] = useState([]);
  const [allRightListItems, setAllRightListItems] = useState([]);
  const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true);

  const { setMessage, setMessageType } = useMessage();
  const {
    timestamp,
    setSelectedRightItem,
    fetchSelected,
    setTimestamp,
    activeIndex,
    setActiveIndex,
    setFrameProductsMenuList,
    setPricingFrameProductsMenuList,
  } = useRightContext();

  const navigate = useNavigate();

  var getFirstElId = allRightListItems[0]?.id || "";
  var itemAddedTrue = "";

  useEffect(() => {
    setLoading(true);
    setTimestamp("");

    setTimeout(() => {
      getRightMenuListData();
    }, 400);
  }, []);

  useEffect(() => {
    if (allRightListItems?.length > 0 && timestamp) {
      getRightItemDetails(timestamp);

      getProductListOptions(timestamp);
    }
  }, [timestamp]);

  useEffect(() => {
    if (allRightListItemsOrder) {
      getFirstElId = allRightListItems[0]?.id;

      setActiveIndex(0);

      if (getFirstElId && allRightListItems?.length) {
        getRightItemDetails(getFirstElId);
      }
    } else {
      if (getFirstElId && allRightListItems?.length) {
        if (timestamp && allRightListItems?.length && activeIndex !== 0) {
          getRightItemDetails(timestamp);
        } else {
          getRightItemDetails(getFirstElId);
        }
      }
    }
  }, [allRightListItems]);

  // get Hardware collection from hardware collection API
  const getProductListOptions = async (fetchId) => {
    const res = await getAllSysFrameProductsList(accessToken, fetchId);
    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setProductListOptions(res?.data?.entity);
          } else {
            setProductListOptions(noSelectOptions);
          }
        }
      }
    }
  };

  // get all right menu list
  const getRightMenuListData = async () => {
    // getFrameProductsSidebarList(accessToken, setFrameProductsMenuList, navigate);
    // GetFrameProductsMenuListForPricing(accessToken, setPricingFrameProductsMenuList, navigate);

    const res = await getSashProductsCollection(accessToken);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setAllRightListItems(res?.data?.entity);

            if (itemAddedTrue == "added") {
              const addedItemIndex = res.data.entity.length - 1;

              if (addedItemIndex) {
                fetchSelected(
                  addedItemIndex,
                  res.data.entity[addedItemIndex]?.id
                );
                itemAddedTrue = "";
              }
            }
          } else {
            setAllRightListItems([]);
            setSelectItemDetails([]);
            setTimestamp("");

            fetchSelected(0, "");
          }
        }
        setTimeout(() => {
          setLoading(false);
        }, 1200);
      } else {
        setLoading(false);
        setMessage(
          res?.data.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      handleError(res, false, navigate);
    }
  };

  // get details of selected item in right menu by passing item id
  const getRightItemDetails = async (id) => {
    setLoading(true);

    const res = await getItemDetails(accessToken, id);

    setAllRightListItemsOrder(false);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          const selectedRight = allRightListItems?.find((p) => p.id == id);
          setSelectedRightItem(selectedRight);

          // set state to get details of current selected item which will display data in left side component
          setSelectItemDetails(res.data.entity);

          // set selected item id which will update context
          setTimestamp(id);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1200);
      } else {
        setLoading(false);
        setMessage(
          res?.data.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      handleError(res, false, navigate);
    }
  };

  // api call to update all selected item details
  const updateItemDetailsServices = async (data) => {
    setLoading(true);

    const res = await updateItemDetails(
      accessToken,
      timestamp ? timestamp : getFirstElId,
      data
    );

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessage(res?.data?.message ? res.data.message : "Updated details");
        setMessageType("success");

        // getFrameProductsSidebarList(accessToken, setFrameProductsMenuList, navigate);
        // GetFrameProductsMenuListForPricing(accessToken, setPricingFrameProductsMenuList, navigate);

        setLoading(false);
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      handleError(res, false, navigate);
    }
  };

  // api call to added new right item
  const addNewRightItemServices = async (title) => {
    setLoading(true);

    itemAddedTrue = "added";

    const res = await addNewRightItem(accessToken, title);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessage(
          res?.data?.message ? res.data.message : "Record created successfully!"
        );
        setMessageType("success");

        getRightMenuListData();
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");

      handleError(res, false, navigate);
    }
  };

  const handleFormReset = () => {
    if (timestamp) {
      getRightMenuListData();
      getRightItemDetails(timestamp);
    }
  };

  return (
    <>
      {wip ? (
        <ComingSoon title="Collections Coming Soon" />
      ) : (
        <>
          {loading && (
            <div className="loader_main full_loader">
              <Oval
                height={50}
                width={50}
                color="#44C8F5"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#44C8F5"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          )}

          <InnerLayout
            pageTitle="Sash Collections"
            layoutType=""
            leftContent={
              <SashCollectionLeft
                selectItemDetails={selectItemDetails}
                updateItemDetailsServices={updateItemDetailsServices}
                handleFormReset={handleFormReset}
                productListOptions={productListOptions}
                setLoading={setLoading}
                accessToken={accessToken}
              />
            }
            rightContent={
              <SashCollectionRight
                accessToken={accessToken}
                selectItemDetails={selectItemDetails}
                allRightListItems={allRightListItems}
                setLoading={setLoading}
                getRightMenuListData={getRightMenuListData}
                addNewRightItemServices={addNewRightItemServices}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default SashCollection;
