import axios from "axios";
import { servicePath } from "../utility/data";

// get All frame products collections
export const getAllSysFrameProductsList = (accessToken, collectionId) => {

    const formData = new FormData();
    formData.append("FrameCollectionId", collectionId);

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Product/GetAllSysFrameCollectionProduct`, formData, config)

        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getItemDetails = (accessToken, id) => {

    const formData = new FormData();

    formData.append("Id", id)

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Product/GetSysFrameCollectionProduct`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData()
    formData.append("Id", id)

    return axios
        .post(`${servicePath}/Product/DeleteSysFrameCollectionProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, direction, collectionId) => {

    // console.log(accessToken, id, direction, "update docs order")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction,
        "typeText": collectionId,
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Product/UpdateSysframeCollectionProductOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, data, tabName) => {
    console.log(data, "data data")

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }
    var apiName = "AddUpdateSysFrameCollectionProduct"
    const formData = new FormData()

    if (tabName != "Accessories") {
        formData.append("Id", id);
        formData.append("CollectionId", data.collectionId);
        formData.append("Name", data.name);

        formData.append("Description", data.description || '');
        formData.append("ColourId", data.colourId || '');
        formData.append("FrameStyleId", data.frameStyleId || '');
        formData.append("JointsId", data.jointsId || '');
        formData.append("DefaultTransomDrop", data.defaultTransomDrop || 0);
        formData.append("DefaultSideHungWidth", data?.defaultSideHungWidth || 0);
        formData.append("DefaultSill", data?.defaultSill || 0);
        formData.append("DefaultWindow", data?.defaultWindow || 0);

        if (data.specifications) {
            for (let i = 0; i < data.specifications.length; i++) {
                formData.append(`Specifications`, data.specifications[i]);
            }
        }

        formData.append("DefaultSpecificationId", data.defaultSpecifications || '');
        formData.append("FrameClearance", data.frameClearance || 0);
        formData.append("GasketClearance", data.gasketClearance || 0);
        formData.append("Bead", data?.beadRadio || "Internal");
        formData.append("ProfileId", data?.profileId || '');
        formData.append("SubProductId", data?.subProductId || '');
        formData.append("HardwareId", data?.hardwareId || '');
        formData.append('ProfilePath', data.filePath || "");
        formData.append('ProfilePathUrl', '');
    } else {
        apiName = "AddUpdateFrameProductAccessories"
        formData.append("Id", data.id || '');
        formData.append("AccessoriesId", data.AccessoriesId)
        formData.append("Quantity", data.Quantity)
        formData.append("FrameProductGeneralId", id)
    }

    return axios
        .post(`${servicePath}/Product/${apiName}`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data, type) => {

    const formData = new FormData();

    formData.append("Id", '');
    formData.append("Name", data);
    formData.append("CollectionId", type);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }
    return axios
        .post(`${servicePath}/Product/AddUpdateSysFrameCollectionProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// API's for Dual List Box of Profile Tab

export const getAllHiddenVisibleProfileItems = (accessToken, profileId, collectionID, productId) => {

    const formData = new FormData();

    formData.append("ProfileMasterId", profileId);
    formData.append("SashFrameMasterId", collectionID);
    formData.append("SashFrameGeneralId", productId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/GetFrameProductProfiles`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const addFrameProductProfiles = (accessToken, profileId, collectionID, productId, itemId) => {

    const formData = new FormData();

    formData.append("Id", '');
    formData.append("ProfileMasterId", profileId);
    formData.append("SashFrameMasterId", collectionID);
    formData.append("SashFrameGeneralId", productId);
    formData.append("ProfileId", itemId);
    formData.append("DefaultProfile", "");

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/AddFrameProductProfiles`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

export const dualListItemMoveToLeft = (accessToken, itemId, tabName) => {

    var apiName = "DeleteFrameProductProfiles"

    if (tabName !== "Profiles") {
        apiName = "DeleteFrameProductSubProduct"
    }

    const formData = new FormData();

    formData.append("Id", itemId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/${apiName}`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

export const deleteFrameProductProfiles = (accessToken, itemId, tabName) => {

    var apiName = "DeleteFrameProductProfiles"

    if (tabName !== "Profiles") {
        apiName = "DeleteFrameProductSubProduct"
    }

    const formData = new FormData();

    formData.append("Id", itemId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/${apiName}`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

// API's for Dual List Box of Sub products Tab

export const getAllHiddenVisibleSubProductsItems = (accessToken, subProductId, collectionID, productId) => {

    console.log(subProductId, collectionID, productId, "sub products dual")

    const formData = new FormData();

    formData.append("SashProductMasterId", subProductId);
    formData.append("SashFrameGeneralId", productId);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/GetFrameProductSubProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const addFrameProductSubProducts = (accessToken, subProductId, collectionID, productId, getId) => {

    console.log(getId, "itemId")
    const formData = new FormData();

    formData.append("Id", '');
    formData.append("SashProductMasterId", subProductId);
    formData.append("SashFrameGeneralId", productId);

    formData.append("SashProductId", getId);
    formData.append("DefaultSubProduct", "");

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/AddFrameProductSubProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

// set selected profile item as default 
export const SetDefaultProductProfile = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData()
    formData.append("id", id)

    return axios
        .post(`${servicePath}/Product/SetDefaultFrameProductProfiles`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// set selected profile item as default 
export const SetDefaultSubProduct = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData()
    formData.append("id", id)

    return axios
        .post(`${servicePath}/Product/SetDefaultFrameProductSubProduct`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const UpdateFrameProfileOrder = (accessToken, id, direction, generalId, profileId) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction,
        "masterParentId": generalId,
        "subMasterParentId": profileId,
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Product/UpdateFrameProfileOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const UpdateFrameSubProductOrder = (accessToken, id, direction, generalId, profileId) => {

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction,
        "masterParentId": generalId,
        "subMasterParentId": profileId,
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Product/UpdateFrameSubProductOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}


export const getAllFrameAccessories = (accessToken, Id) => {

    const formData = new FormData();

    formData.append("FrameProductGeneralId", Id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/GetAllFrameProductAccessories`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}


export const getIndividualFrameProductAccessories = (accessToken, Id) => {

    const formData = new FormData();

    formData.append("Id", Id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/GetFrameProductAccessories`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}


export const deleteAccessories = (accessToken, Id) => {

    const formData = new FormData();

    formData.append("Id", Id);

    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .post(`${servicePath}/Product/DeleteFrameProductAccessories`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error
        });
}

export const getSashTypeListsService=(accessToken)=>{
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    return axios
        .get(`${servicePath}/Master/GetSashTypeMaster`, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error.response;
        });  
}