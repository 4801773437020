import React from 'react'
import InnerLayout from "../../../../../../../layouts/internalLayout"
import PaletteLeft from './palette-left'
import PaletteRight from './palette-right'
import { getAllColours, getHardwareVisualElements } from '../../../../../../../services/productServices'
import { useEffect } from 'react'
import { useState } from 'react'
import { useMessage } from '../../../../../../../context/messageContext'
import { useProductPaletteContext } from '../../../../../../../context/productPaletteContext'
import { Oval } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import { handleError } from '../../../../../../../utility/commonHelper'

const PaletteHardware = () => {

    const [loading, setLoading] = useState(false)
    const { setNewItemList, setActiveIndex, setTimestamp } = useProductPaletteContext()

    const accessToken = localStorage.getItem('access_token')

    const { setMessage, setMessageType } = useMessage()
    const [allColours, setAllColours] = useState([])

    const navigate=useNavigate()

    useEffect(() => {
        getAllColoursServices('')
    }, [])

    const getAllColoursServices = async (type) => {
        setLoading(true)
        // const res = await getAllColours("2", accessToken)
        const res = await getHardwareVisualElements(accessToken)
        if (res.statusCode === 200) {
            let data = res?.entity;
            setNewItemList(res?.entity)
            setAllColours(res?.entity)
            if (type === 'added') {
                setTimestamp(data[data?.length - 1]?.colourPaletteId)
                setActiveIndex(data?.length - 1)
            }
            setLoading(false)
            setMessageType('success')
            setMessage(res.message)
            type = '';
        } else {
            setLoading(false)
            setMessageType('error')
            setMessage(res.message)
            handleError(res, false, navigate)
        }

    }

    return (

        <>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}

            <InnerLayout pageTitle="Palette" layoutType="" leftContent={<PaletteLeft setLoading={setLoading} getAllColoursServices={getAllColoursServices} allColours={allColours}/>} rightContent={<PaletteRight setLoading={setLoading} getAllColoursServices={getAllColoursServices} />} />
        </>
    )
}

export default PaletteHardware