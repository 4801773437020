import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMessage } from "../context/messageContext";
import { useSashHangingContext } from "../context/sashHangingContext";
import {
  addNewRightItem,
  getRightMenuList,
  getItemDetails,
  updateItemDetails,
} from "../services/sysSashHangingService";
import { Modal, ModalBody } from "reactstrap";
import { MdClose } from "react-icons/md";
import SystemSashHangingLeft from "./SystemSashHangingLeft";
import SystemSashHangingRight from "./SystemSashHangingRight";
import { handleError } from "../utility/commonHelper";

const SystemSashHanging = ({
  isEditable,
  accessToken,
  activeTab,
  sashId,
  productId,
  setLoading,
  sashMasterId,
}) => {
  const [showSashHangingModal, setShowSashHangingModal] = useState(false);

  const [selectItemDetails, setSelectItemDetails] = useState([]);
  const [allRightListItems, setAllRightListItems] = useState([]);
  const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true);

  const { setMessage, setMessageType } = useMessage();
  const {
    timestamp,
    fetchSelected,
    setTimestamp,
    activeIndex,
    setActiveIndex,
  } = useSashHangingContext();

  const navigate = useNavigate();

  var getFirstElId = allRightListItems[0]?.id || "";
  var itemAddedTrue = "";

  useEffect(() => {
    if (activeTab === "Sash hanging") {
      setLoading(true);
      setTimestamp("");

      // getHardwareCollectionSelectOptions()
      // getProductColourCollections()

      getRightMenuListData();
    }
  }, [activeTab, sashId, productId]);

  useEffect(() => {
    if (allRightListItems?.length > 0 && timestamp) {
      getRightItemDetails(timestamp);
    }
  }, [timestamp]);

  useEffect(() => {
    if (allRightListItemsOrder) {
      getFirstElId = allRightListItems[0]?.id;

      setActiveIndex(0);

      if (getFirstElId && allRightListItems?.length) {
        getRightItemDetails(getFirstElId);
      }
    } else {
      if (getFirstElId && allRightListItems?.length) {
        if (timestamp && allRightListItems?.length && activeIndex !== 0) {
          getRightItemDetails(timestamp);
        } else {
          getRightItemDetails(getFirstElId);
        }
      }
    }
  }, [allRightListItems]);

  // get all right menu list
  const getRightMenuListData = async () => {
    // console.log(productId, "productId")

    const res = await getRightMenuList(accessToken, productId);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setAllRightListItems(res?.data?.entity);

            if (itemAddedTrue == "added") {
              const addedItemIndex = res.data.entity.length - 1;

              if (addedItemIndex) {
                fetchSelected(
                  addedItemIndex,
                  res.data.entity[addedItemIndex]?.id
                );
                itemAddedTrue = "";
              }
            }
          } else {
            setAllRightListItems([]);
            setSelectItemDetails([]);
            setTimestamp("");

            fetchSelected(0, "");
          }
        }
        setTimeout(() => {
          setLoading(false);
        }, 1200);
      } else {
        setLoading(false);
        setMessage(
          res?.data.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  // get details of selected item in right menu by passing item id
  const getRightItemDetails = async (id) => {
    setLoading(true);

    const res = await getItemDetails(accessToken, id);

    setAllRightListItemsOrder(false);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          // set state to get details of current selected item which will display data in left side component
          setSelectItemDetails(res.data.entity);

          // set selected item id which will update context
          setTimestamp(id);
        }

        setTimeout(() => {
          setLoading(false);
        }, 1200);
      } else {
        setLoading(false);
        setMessage(
          res?.data.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  // api call to update all selected item details
  const updateItemDetailsServices = async (data) => {
    setLoading(true);

    const res = await updateItemDetails(
      accessToken,
      timestamp ? timestamp : getFirstElId,
      data
    );

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessage(res?.data?.message ? res.data.message : "Updated details");
        setMessageType("success");

        getRightMenuListData();
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  // api call to added new right item
  const addNewRightItemServices = async (title) => {
    setLoading(true);
    itemAddedTrue = "added";

    const res = await addNewRightItem(accessToken, title, sashId, productId);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setMessage(
          res?.data?.message ? res.data.message : "Record created successfully!"
        );
        setMessageType("success");

        getRightMenuListData();
      } else {
        setLoading(false);
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      handleError(res, false, navigate);
    }
  };

  const handleFormReset = () => {
    if (timestamp) {
      getRightMenuListData();
      getRightItemDetails(timestamp);
    }
  };

  return (
    <>
      <SystemSashHangingLeft
        isEditable={isEditable}
        sashId={sashId}
        accessToken={accessToken}
        activeTab={activeTab}
        setLoading={setLoading}
        setShowSashHangingModal={setShowSashHangingModal}
        selectItemDetails={selectItemDetails}
        updateItemDetailsServices={updateItemDetailsServices}
        handleFormReset={handleFormReset}
        allRightListItems={allRightListItems}
        getRightMenuListData={getRightMenuListData}
        sashMasterId={sashMasterId}
      />

      <Modal
        centered
        isOpen={showSashHangingModal}
        toggle={() => setShowSashHangingModal(false)}
        className="right_menu_items"
      >
        <ModalBody className="p-1">
          <div className="d-flex justify-content-end mb-3">
            <Link
              className="text-muted"
              to={{}}
              onClick={() => setShowSashHangingModal(false)}
            >
              <MdClose size={22} />
            </Link>
          </div>

          <SystemSashHangingRight
            accessToken={accessToken}
            productId={productId}
            selectItemDetails={selectItemDetails}
            allRightListItems={allRightListItems}
            setLoading={setLoading}
            getRightMenuListData={getRightMenuListData}
            addNewRightItemServices={addNewRightItemServices}
            setShowSashHangingModal={setShowSashHangingModal}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default SystemSashHanging;
