export const applyGlazingSpec = (specs, id , index , jsonIndex = 0,setData) => {

    const { hexValue } = specs;
   
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updatedJson = { ...updatedModelArray[jsonIndex] };
        const sash = updatedJson?.sash ? updatedJson?.sash : {};
        const transom = updatedJson?.transom ? updatedJson?.transom : {};

        if ((sash && !sash.sections?.length) && (transom && !transom?.splits?.length)) {
            updatedJson.glass.color = hexValue;
            setData(specs,-1)

        } else if (sash && sash.sections?.length) {
            if (index > -1 && index !== undefined) {
                sash.sections[index].color = hexValue;
            } else {
                sash?.sections?.forEach((section) => {
                    section.color = hexValue;
                })
            }
            setData(specs,index)
        }

        updatedModelArray[index] = updatedJson
        return updatedModelArray;
    }
}