import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { MdClose } from "react-icons/md";

const UploadedPhotoPreview = ({
  uploadedFile,
  setUploadedFile,
  filePreview,
  setFilePreview,
}) => {
  const handleRemovePhoto = () => {
    setUploadedFile("");
    setFilePreview("");
  };
  const [newFilePreview, setNewFilePreview] = useState("");

  useEffect(() => {
    if (filePreview && filePreview?.includes("svg")) {
      const svgDataUri = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
        filePreview
      )}`;      
      setNewFilePreview(svgDataUri);
    } else {
      setNewFilePreview(filePreview);
    }
  }, [filePreview]);

  return (
    <div className="dimention_image img-fluid">
      {newFilePreview ? (
        <div className="position-relative">
          {/* <span className="clickable delete_cross small_bordered box_right_top" onClick={(e) => { handleRemovePhoto(e) }}>
                        <MdClose size={16} />
                    </span> */}
          <Image src={newFilePreview} alt="photo" />
          {/* <div dangerouslySetInnerHTML={{ __html: `${filePreview || imagePath}` }} /> */}
        </div>
      ) : (
        <span>
          <svg viewBox="0 0 100 50" width="100" height="50">
            <path
              className="stroke"
              stroke="#989898"
              fill="#fff"
              strokeWidth="2"
              d={`M 0,50 0,0 100,0 100,50 Z`}
            ></path>
          </svg>
        </span>
      )}
    </div>
  );
};

export default UploadedPhotoPreview;
