import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Button,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
import { MdInfoOutline } from "react-icons/md";
import { MenuProps, customizeFrame, positionsOpts } from "../../utility/data";
import NumberInput from "../NumberInput";
import NumberInputNegative from "../NumberInputNegative";
import {
  getApperturesService,
  getFrameData,
  getProductDataService,
  getProfileDataService,
  getSashProfileData,
  getTrasomsFrameService,
} from "../../services/3dModelServices";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from "material-ui-core";
import { BiTrash } from "react-icons/bi";
import { Image } from "react-bootstrap";
import { GiSettingsKnobs } from "react-icons/gi";
import { getAllFrameStyleColletion } from "../../services/frameCollectionsService";
import { useDesigner } from "../../context/designerContext";
import { updateFrameStyleAppenture } from "../../services/commonServices";
import { GoArrowSwitch, GoCircleSlash } from "react-icons/go";
import HandleHanging from "./HandleHanging";
import { getCountForStatus } from "../../utility/commonHelper";
// import { isButtonDisabled } from "../3DComponents/BlockWindow/transoms";

const FrameStyle = ({
  collectionId,
  accessToken,
  setHardwareType,
  isSave,
  receivedProductId,
  frameStyleProdId,
  quotationId,
  setLoading,
  collectionDetails,
  headerSelectedItem,
  setSashProductId,
  sashProductId,
  isThreshold,
  frameStyles,
  setFrameStyleSection,
  layoutSizing,
  setLayoutSizing,
}) => {
  const checkboxItems = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];

  const [appertureCount, setAppertureCount] = useState("");
  const [appertureActive, setAppertureActive] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [customise, setCustomise] = useState(false);
  const [activeTab, setActiveTab] = useState("Transoms");
  const [sashRadio, setSashRadio] = useState("Product");
  const [frameStyleList, setFrameStyleList] = useState([]);
  const [frameCollectionId, setFrameCollectionId] = useState("");
  const [checkedItems, setCheckedItems] = useState([1]);
  const [apperturesList, setApperturesList] = useState([]);
  const [position, setPosition] = useState(0);
  const [profileData, setProfileData] = useState([]);
  const [transomsData, setTransomsData] = useState([]);
  const [frameStyleFrameData, setFrameStyleFrameData] = useState([]);
  const [sashCountError, setSashCountError] = useState(false);

  // This is for getting the index for the current refrence point
  const [referencePoint, setReferencePoint] = useState(null);

  //This is for showing the alert for the frame profile
  const [showFrameAlert, setShowFrameAlert] = useState(false);
  const [showSashAlert, setShowSashAlert] = useState(false);
  const [showSashHangingAlert, setShowSashHangingAlert] = useState(false);
  const [referenceArray, setRefrerenceArray] = useState([]);
  const [highState, setHighState] = useState(1);
  const [wideState, setWideState] = useState(1);
  const [sashHangingValue,setSashHangingValue]=useState("")
  const [disableDelete, setDisableDelete] = useState(false)

  const [sashNames, setShashNames] = useState([]);
  const {
    multiSelectRefPoints,
    setFrameProfiles,
    frameProfiles,
    setToggleHelper,
    toggleHelper,
    setSashProfileType,
    sashHangingNo,
    setSashHangingNo,
    sashHanging,
    setSashHanging,
    transomsOrientation,
    setTransomsOrientation,
    delete3DItems,
    sashProfileType,
    setSashRadioActive,
    customModelData,
    modelJson,
    setCustomModelData,
    checkSingleSide,
    setNewModelId,
    setNewStyleId,
    newModelId,
    setAppliedModel,
    transomDistance,
    setTransomDistance,
    productsData,
    setProductsData,
    setTransomType,
    setInitialProfile,
    threshHolData,
    setThresholdType,
    setMultiSelectRefPoints,
    checkClickedProfile,
    setUiLinesLocation,
    designerAccess,
    setValidWidths,
    setValidHeights,
    setHangingPosition,
    high,
    wide,
    setHigh,
    setWide,
    jsonIndex
  } = useDesigner();


  useEffect(() => {
    getAllFrameStyles();

    getProductsData();
  }, []);


  // useEffect(() => {
  //   if (multiSelectRefPoints[0]) {
  //     const sectionId = multiSelectRefPoints[0]?.data?.name
  //     const checkDisable = isButtonDisabled(modelJson, sectionId)
  //     setDisableDelete(checkDisable)
  //   }else{
  //     setDisableDelete(false)
  //   }
  // }, [multiSelectRefPoints[0]] , disableDelete)

  useEffect(() => {
    if (activeTab === "Transoms") {
      setTransomsOrientation("");
      setHardwareType("transomHorizontal");
    }

    if (activeTab === "Sash") {
      setSashRadio("Product");
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "Transoms") {
      if (transomsOrientation) {
        setUiLinesLocation(transomsOrientation);
      }
    } else {
      // setUiLinesLocation('')
    }
  }, [transomsOrientation]);

  useEffect(() => {
    if (isThreshold) {
      setCustomise(true);
      setActiveTab("Frame");
    }
  }, [isThreshold]);

  useEffect(() => {
    if (transomsData.length && activeTab === "Transoms") {
      const defaultTransom = transomsData?.find(
        (item) => item.defaultProfile === true
      );
      setTransomType(defaultTransom);
    }
  }, [transomsData, activeTab]);

  useEffect(() => {
    setSashProfileType(sashProfileType ? sashProfileType : profileData[0]);
  }, [profileData]);

  useEffect(() => {
    if (customModelData && customModelData?.numberOfSash?.wide) {
      setWideState(customModelData.numberOfSash.wide)
    } else {
      setWideState(1)
    }

    if (customModelData && customModelData?.numberOfSash?.high) {
      setHighState(customModelData.numberOfSash.high)
    } else {
      setHighState(1)
    }
  }, [customModelData]);

  useEffect(() => {
    if (frameStyleProdId && customModelData) {
      if (
        customModelData &&
        customModelData.appliedModel &&
        customModelData.appliedModel.styleId
      ) {
        setAppertureActive(customModelData.appliedModel.styleId);
      } else {
        setAppertureActive(frameStyleProdId?.id);
      }
    }
  }, [frameStyleProdId]);

  // This is for selecting and unselecting the applied sash from the list
  // useEffect(() => {
  //   if (customModelData?.sashData && profileData) {
  //     let url = false;
  //     let objArr = [];
  //     customModelData?.sashData?.forEach((item) => {
  //       if (item?.index === "") {
  //         const data = profileData?.find((ele) => ele?.id === item?.id);
  //         objArr.push(data);
  //       } else {
  //         const data = multiSelectRefPoints?.find(
  //           (ele) => ele?.index == item?.index
  //         );
  //         url = true;
  //         if (data) {
  //           console.log("data-->",data);
            
  //           objArr.push(item);
  //         }
  //       }
  //     });
  //     let tempArr = [];
  //     if (url) {
  //       let obj = objArr.pop();
  //       tempArr.push(obj?.name);
  //     } else {
  //       objArr?.forEach((item) => {
  //         tempArr.push(item?.customePath);
  //       });
  //     }
  //     console.log("tempArray==>",tempArr,objArr);
      
  //     setShashNames(tempArr);
  //   }
  // }, [customModelData, multiSelectRefPoints, profileData]);

  // For showing the selected profile for a reference point
  useEffect(()=>{
    if(customModelData?.sashData && customModelData?.sashData?.length>0 && multiSelectRefPoints?.length>0 && profileData?.length>0){
      let data=null;
      let tempSashNames=[]
      multiSelectRefPoints?.forEach((item=>{
        data=customModelData?.sashData?.find(ele=>ele?.index==item?.index)
        if(data){
          tempSashNames.push(data?.name)
        }
      }))
      setShashNames(tempSashNames)
    }
  },[multiSelectRefPoints,profileData,customModelData])  

  // This is for getting the currently selected refrence point
  // useEffect(() => {
  //   if (multiSelectRefPoints.length === 1) {
  //     setReferencePoint(multiSelectRefPoints[0]);
  //   } else if (multiSelectRefPoints?.length > 1) {
  //     let lastElement = multiSelectRefPoints.pop();
  //     setMultiSelectRefPoints([lastElement]);
  //     setReferencePoint(lastElement);
  //   }
  // }, [multiSelectRefPoints]);

  // This is for getting all the ticked reference points
  useEffect(() => {
    if (multiSelectRefPoints?.length > 0) {
      let temp = [];
      multiSelectRefPoints?.forEach((item) => {
        temp.push(item?.index);
      });
      setRefrerenceArray(temp);
    }
  }, [multiSelectRefPoints]);

  // useEffect(() => {
  //   if (activeTab === "Transoms") {
  //     cons
  //   }

  // }, [activeTab])

  useEffect(() => {
    if (activeTab === "Transoms") {
      profileListService();
    }

    if (activeTab === "Frame" && referenceArray?.length > 0) {
      // profileListService();
      getFrameDataService(referenceArray);
      setTransomsOrientation("");
      delete3DItems(false, "");
    }

    if (activeTab === "Sash") {
      setTransomsOrientation("");
      delete3DItems(false, "");

      if (
        sashRadio === "Profiles" ||
        (activeTab === "Sash" && referenceArray?.length > 0)
      ) {
        // getProfileData();
        getSashProfileDataService(referenceArray);
      }

      // for getting wide and high
      if (sashRadio === "Hanging") {
        if (
          customModelData?.numberOfSash?.wide &&
          customModelData?.numberOfSash?.wide !== ""
        ) {
          setWide(customModelData?.numberOfSash?.wide);
        } else {
          setWide(1);
        }
        if (
          customModelData?.numberOfSash?.high &&
          customModelData?.numberOfSash?.high !== ""
        ) {
          setHigh(customModelData?.numberOfSash?.high);
        } else {
          setHigh(1);
        }
      }      
      if (sashRadio === "Hanging" 
        // && checkSingleSide >= 0
        && multiSelectRefPoints[0]
      ) {
        setSashRadioActive("Hanging");
        // getSashHangingData();
        // getSashDataService(checkSingleSide);
        if (
          collectionDetails &&
          collectionDetails.typeId == 1 &&
          parseFloat(modelJson[jsonIndex]?.sash?.hangings) > 0
        ) {          
          setSashHangingNo(modelJson[jsonIndex]?.sash?.hangings);
          // setWide(customModelData?.numberOfSash?.wide);
          // setHigh(customModelData?.numberOfSash?.high);
        } else {
          setSashHangingNo(0);
          // setWide(customModelData?.numberOfSash?.wide);
          // setHigh(customModelData?.numberOfSash?.high);
        }
      }
    }
  }, [activeTab, sashRadio, referencePoint, referenceArray, checkSingleSide]);
  
  // This is for selecting the hanging position
  useEffect(() => {
    if (
      activeTab === "Sash" &&
      sashRadio === "Hanging" &&
      multiSelectRefPoints?.length > 0
    ) {
      if (
        customModelData?.sashHanging &&
        customModelData?.sashHanging?.length > 0 &&
        positionsOpts?.length > 0
      ) {
        let findPosition = null;
        multiSelectRefPoints.forEach((item) => {
          findPosition = customModelData?.sashHanging?.find(
            (ele) => ele?.index == item?.index
          );
        });
        if (findPosition) {
          setSashHangingValue(findPosition.type);
        }
      } else {
        setSashHangingValue(positionsOpts[2]?.id);
      }
    }
  }, [multiSelectRefPoints, sashRadio, activeTab]);


  useEffect(() => {
    if (headerSelectedItem.name === "French Door") {
      handleSashChange(1);
    }
  }, [headerSelectedItem]);

  useEffect(() => {
    if (frameStyleList && frameStyleList.length > 0 && collectionId) {
      setFrameCollectionId(collectionId);
    }
  }, [frameStyleList, collectionId]);

  useEffect(() => {
    if (frameCollectionId && !isNaN(wideState) && !isNaN(highState)) {
      getAppertures(frameCollectionId);
    }
  }, [checkedItems, frameCollectionId, wideState, highState]);

  // useEffect(() => {
  //   setCustomModelData((prevData) => ({
  //     ...prevData,
  //     windowData: [],
  //   }));
  // }, [])

  useEffect(() => {
    if (sashRadio === "Profiles") {
      setHardwareType("sashprofiles");
    } else if (sashRadio === "Hanging") {
      setHardwareType("sashHanging");
      // if (
      //   !customModelData?.windowData?.length &&
      //   collectionDetails &&
      //   collectionDetails.typeId == 2
      // ) {
      //   var handleJson = {
      //     isHangingAdded: true,
      //     hangingId: sashHangingData[0]?.id || "",
      //     hangingType: sashHangingData[0]?.hangingName || "",
      //     handleDirection: "right",
      //   };

      //   setCustomModelData((prevData) => ({
      //     ...prevData,
      //     windowData: [handleJson],
      //   }));

      //   setWindowHandleOrientation(sashHangingData[0]?.hangingName || "");
      // }
    } else if (sashRadio === "Handle direction") {
      setHardwareType("handleDirection");
    }
    if (sashRadio === "Product") {
      setHardwareType("");
    }
  }, [sashRadio]);

  useEffect(() => {
    if (transomsOrientation === "Horizontal") {
      setHardwareType("transomHorizontal");
    } else if (transomsOrientation === "Vertical") {
      setHardwareType("transomVertical");
    } else {
      setHardwareType("");
    }
  }, [transomsOrientation]);

  useEffect(() => {
    if (positionsOpts.length > 0) {
      setPosition(positionsOpts[2].id)
    }
  }, [positionsOpts]);

  const noRefCheck = (tab) => {
    setActiveTab(tab);
    if (tab === "Frame") {
      setHardwareType("customize frame style");
    } else {
      setHardwareType("");
    }
  };

  //This is for setting the alert in case of any frame profile is not visible
  useEffect(() => {
    getCountForStatus(frameStyleFrameData, setShowFrameAlert);
  }, [frameStyleFrameData]);

  useEffect(() => {
    getCountForStatus(profileData, setShowSashAlert);
  }, [profileData]);

  // Here setting the reference points array as empty so that on changing the tab the selected reference points should not come checked under the frame tab if the currently selected tab is sash and vice-versa
  useEffect(() => {
    setMultiSelectRefPoints([]);
  }, [activeTab]);

  const profileListService = async () => {
    const res = await getTrasomsFrameService(accessToken, collectionId, 6);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setFrameStyleFrameData(res?.data?.entity?.frame);
        setTransomsData(res?.data?.entity?.transom);
      }
    }
  };

  const getFrameDataService = async (referencePoint) => {
    const res = await getFrameData(
      accessToken,
      collectionId,
      frameStyleProdId?.id,
      referencePoint,
      1
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setFrameStyleFrameData(res?.data?.entity?.frame);
      }
    }
  };

  const getAllFrameStyles = async () => {
    const res = await getAllFrameStyleColletion(accessToken);
    if (res && res.status == 200) {
      if (res?.data?.entity.length > 0) {
        setFrameStyleList(res?.data?.entity);
        setNewModelId(res?.data?.entity[0]?.id);
        setNewStyleId("");
      }
    } else {
      setFrameStyleList([]);
    }
  };

  const handleAperturesItemClick = (item) => {
    setFrameCollectionId(collectionId);

    setAppertureActive(item.id);

    if (!receivedProductId) {
      updateFrameStyleAppenture(
        accessToken,
        frameStyleProdId?.id,
        // checkedItems.length || 1,
        sashHangingNo,
        wide,
        high
      );

      setSashHangingNo(checkedItems.length || 0);

      setTimeout(() => {
        getAppertures(frameCollectionId);
      }, 200);
    }

    let aperturesJson = {
      count: sashHangingNo.toString(),
      collectionId: frameCollectionId,
      styleId: item?.id,
      typeId: collectionDetails.typeId,
    };

    setAppliedModel(aperturesJson);
    
    setNewModelId(frameCollectionId);
    setNewStyleId(item?.id);
  };

  // const getSashHangingData = async () => {
  //   const res = await getSashHangingDataService(
  //     accessToken,
  //     collectionId,
  //     sashProductId
  //   );
  //   if (res && res.status === 200) {
  //     if (res?.data?.statusCode === 200) {
  //       setSashHangingData(res?.data?.entity);
  //     }
  //   }
  // };

  const getProfileData = async () => {
    const res = await getProfileDataService(
      accessToken,
      collectionId,
      sashProductId
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setProfileData(res?.data?.entity?.sash);
        setInitialProfile(res?.data?.entity);
      }
    }
  };

  const getSashProfileDataService = async (referencePoint) => {
    let data = {
      collectionId: collectionId,
      sashProductId: sashProductId?.id,
      frameStyleId: frameStyleProdId?.id,
      referencePoint: referencePoint,
      type: 1,
    };
    const res = await getSashProfileData(accessToken, data);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setProfileData(res?.data?.entity?.sash);
        setInitialProfile(res?.data?.entity);
      }
    }
  };

  const getProductsData = async () => {
    const res = await getProductDataService(accessToken, collectionId);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setProductsData(res?.data?.entity);

        if (customModelData?.sashProd) {
          setSashProductId(customModelData?.sashProd);
        } else {
          setSashProductId(res?.data?.entity[0]);

          setCustomModelData((prevData) => ({
            ...prevData,
            sashProd: res?.data?.entity[0],
          }));
        }
      }
    }
  };

  const getAppertures = async (frameCollectionId) => {
    const appertures = checkedItems.length;

    const res = await getApperturesService(
      accessToken,
      frameCollectionId,
      sashHangingNo,
      wideState,
      highState
    );
    if (res && res.status === 200) {
      if (res?.data?.entity.length > 0) {
        setApperturesList(res?.data?.entity);
      } else if (res?.data?.entity.length === 0) {
        setApperturesList([]);
      }
    } else {
      setApperturesList([]);
    }
  };

  const handleSashProfile = (item, index) => {
    if (sashProfileType === item) {
      setToggleHelper(!toggleHelper);
    } else {
      setSashProfileType(item);
    }

    setActiveIndex(index);
  };

  const handleProfile = (item) => {
    if (frameProfiles === item) {
      setToggleHelper(!toggleHelper);
    }
    {
      setFrameProfiles(item);
    }
  };

  const handleFrameProfileCLicked = (item, index) => {
    if (item.width > 0 && item.height > 0 && item.customePath) {
      handleProfile(item);
      setActiveIndex(index);
    }
  };

  const handleFrameStyle = (e) => {
    const value = e.target.value;
    setFrameCollectionId(value);
    setNewModelId(value);
    setNewStyleId("");
  };

  useEffect(() => {
    setCustomModelData((prevModelData) => {
      const isObject =
        typeof prevModelData.windowData === "object" &&
        !Array.isArray(prevModelData.windowData);
      const isEmpty =
        !prevModelData.windowData || prevModelData.windowData.length === 0;

      // Check if windowData is an object or empty, then set it accordingly
      return {
        ...prevModelData,
        windowData: isObject || isEmpty ? [] : prevModelData.windowData,
      };
    });
  }, []);

  const handleMenuActive = useCallback((profileId) => {
  
    const selectedProfile = customModelData?.frameProfileData?.find(
      (item) => item?.side == multiSelectRefPoints[0]?.data.name
    );
    
    const active = selectedProfile?.id === profileId
    
    return active;
  }, [multiSelectRefPoints]);

  const handleCheckboxChange = (id) => {
    const currentIndex = checkboxItems.findIndex((item) => item.id === id);

    if (currentIndex === -1) {
      // If the ID is not found in the array
      return;
    }

    const newCheckedItems = [];

    for (let i = 0; i <= currentIndex; i++) {
      newCheckedItems.push(checkboxItems[i].id);
    }

    setCheckedItems(newCheckedItems);
  };

  const handlePostion = (e) => {
    const value = e.target.value;
    setPosition(value);
    let findPosition = positionsOpts.find((item) => item.id === value);
    setSashHangingValue(value)
    setHangingPosition(findPosition?.name);
    // setSashHanging((prevState) => ({
    //   ...prevState,
    //   sashAlignmentIndex: value,
    // }));
    var positionJson = {
      type:value,
      index: multiSelectRefPoints[0]?.index,
  };
  setSashHanging((prevData = []) => {
    // Ensure `prevData` is an array
    const sashHanging = Array.isArray(prevData) ? [...prevData] : [];
    
    // Find if an object with the same index already exists
    const existingIndex = sashHanging.findIndex(
        (data) => data.index === positionJson.index
    );
    
    if (existingIndex !== -1) {
        // Replace the existing object
        sashHanging[existingIndex] = positionJson;
    } else {
        // Add the new object
        sashHanging.push(positionJson);
    }

    return sashHanging; // Directly return the array
});
  };

  const handleThresholdClicked = (item, index) => {
    setActiveIndex(index);
    setThresholdType(item);
  };

  const handleSashChange = (value) => {
    setSashHangingNo(value);
    setWide(value)

    if (value !== "") {
      setSashCountError(false);
    } else {
      setSashCountError(true);
    }
  };

  const handleSashProducts = (data) => {
    setSashProductId(data);
    setCustomModelData((prevData) => ({
      ...prevData,
      sashProd: data,
    }));
  };

  return (
    <>
      <div
        className={`d-flex gap-3 align-items-center justify-content-between ${layoutSizing && "mb-3"
          } cursor-pointer`}
        onClick={() => {
          setLayoutSizing(!layoutSizing);
          setFrameStyleSection(false)
        }}
      >
        <button
          type="button"
          className={`accordion_btn_custom accordion-button ${layoutSizing ? '' : 'collapsed'}`}
        >
          <span className="fw-bold">Style</span>
        </button>
      </div>

      {!customise ? (
        <>
          <div className="frame_style_scroll">
            <div className="flex-column h-100 w-100">
              <div className="align-items-center mb-1 mt-1 left_header">
                <Select
                  className="w-100"
                  labelId="printdoc-label"
                  id="door"
                  value={frameCollectionId}
                  label="door"
                  onChange={handleFrameStyle}
                  input={<OutlinedInput className="w-100" name="door" />}
                  MenuProps={MenuProps}
                  IconComponent={() => (
                    <MdOutlineKeyboardArrowDown
                      size={20}
                      className="me-1 MuiSvgIcon-root MuiSelect-icon"
                    />
                  )}
                >
                  {frameStyleList &&
                    frameStyleList.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>

              {/* <div className="d-flex mt-3 mb-3 align-items-center apertures_check">
              {checkboxItems.map((item, index) => (
                <Checkbox
                  key={index}
                  checked={checkedItems.includes(item.id)}
                  onChange={() => handleCheckboxChange(item.id)}
                />
              ))}
              <span>
                {checkedItems.length >= 5 ? "5+" : checkedItems.length}{" "}
                {checkedItems.length === 1 ? "Aperture" : "Apertures"}
              </span>
            </div> */}

              <div className="d-flex gap-3 mt-3 mb-1">
                <FormGroup className="mb-1">
                  <Label for="wide" className="text-dark">
                    Wide
                  </Label>
                  <NumberInput
                    name="wide"
                    id="wide"
                    value={wideState}
                    setValue={setWideState}
                  />
                </FormGroup>
                <FormGroup className="mb-1">
                  <Label for="high" className="text-dark">
                    High
                  </Label>
                  <NumberInput
                    name="high"
                    id="high"
                    value={highState}
                    setValue={setHighState}
                  />
                </FormGroup>
              </div>

              <div className="mt-1 right_list">
                {apperturesList && apperturesList.length > 0 ? (
                  <>
                    {apperturesList &&
                      apperturesList?.map((item, index) => (
                        // add has_delete and has_copy class in below div based on availability of icons in action_btns div
                        <div
                          key={index}
                          className="position-relative d-flex align-items-center right_item_wrap"
                          onClick={() => handleAperturesItemClick(item)}
                        >
                          <div
                            className={`right_item w-100 ${
                              appertureActive === item.id ? "active" : ""
                            }`}
                            onClick={() => setActiveIndex(index)}
                          >
                            <span className="me-2 image_icon">
                              {item?.profilePathUrl ? (
                                <>
                                  {item?.profilePathUrl?.includes("https") ? (
                                    <Image
                                      src={item?.profilePathUrl}
                                      className="img-fluid"
                                      alt={item?.name}
                                    />
                                  ) : (
                                    <Image
                                      src={`data:image/svg+xml;base64,${item?.profilePathUrl}`}
                                      className="img-fluid"
                                      alt={item?.name}
                                    />
                                  )}
                                </>
                              ) : (
                                <svg
                                  viewBox="0 0 100 50"
                                  width="100"
                                  height="50"
                                >
                                  <path
                                    className="stroke"
                                    stroke="#989898"
                                    fill="#fff"
                                    strokeWidth="2"
                                    d={`M 0,50 0,0 100,0 100,50 Z`}
                                  ></path>
                                </svg>
                              )}
                            </span>
                            <div className="right_title">
                              <span className="text-truncate">{item.name}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {designerAccess && (
            <Button
              color="light"
              size="md"
              className="px-4 w-100 d-flex justify-content-between fw-medium mb-2 mt-4"
              onClick={() => {
                setCustomise(!customise);
              }}
            >
              <GiSettingsKnobs size={20} />
              Customise frame style
              <span></span>
            </Button>
          )}
        </>
      ) : (
        <div className="d-flex flex-column h-100">
          <Nav tabs className="details_header border-0 nav-tabs-custom bg-none">
            {customizeFrame &&
              customizeFrame.map((item, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={`${activeTab === item.name && "active"}`}
                    onClick={() => noRefCheck(item.name)}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
          </Nav>

          <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
            <TabPane tabId="Transoms" className="w-100">
              <>
                <FormControl className="mb-3">
                  <Label for="orientation" className="text-dark">
                    Orientation
                  </Label>
                  <RadioGroup
                    aria-labelledby="orientation-label"
                    name="orientation"
                    className="flex-row gap-4"
                    value={transomsOrientation}
                    onChange={(e) => {
                      setTransomsOrientation(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      className="page_type_radio_2"
                      value="Horizontal"
                      control={<Radio />}
                      label={
                        <div>
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="3.75"
                                y="3.75"
                                width="16.5"
                                height="6.5"
                                rx="1.25"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinejoin="round"
                              />
                              <rect
                                x="3.75"
                                y="13.75"
                                width="16.5"
                                height="6.5"
                                rx="1.25"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="text-center">Horizontal</span>
                        </div>
                      }
                    />

                    <FormControlLabel
                      className="page_type_radio_2"
                      value="Vertical"
                      control={<Radio />}
                      label={
                        <div>
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="20.25"
                                y="3.75"
                                width="16.5"
                                height="6.5"
                                rx="1.25"
                                transform="rotate(90 20.25 3.75)"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinejoin="round"
                              />
                              <rect
                                x="10.25"
                                y="3.75"
                                width="16.5"
                                height="6.5"
                                rx="1.25"
                                transform="rotate(90 10.25 3.75)"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="text-center">Vertical</span>
                        </div>
                      }
                    />
                  </RadioGroup>
                </FormControl>

                <div className="right_list mb-3">
                  {transomsData &&
                    transomsData?.map((item, index) => (
                      <div
                        key={index}
                        className="position-relative d-flex align-items-center right_item_wrap"
                        onClick={() => setTransomType(item)}
                      >
                        <div
                          className={`right_item w-100 ${
                            activeIndex === index && "active"
                          }`}
                          onClick={() => setActiveIndex(index)}
                        >
                          <span className="me-2 image_icon">
                            {item.imageUrl ? (
                              <Image src={item.imageUrl} />
                            ) : (
                              <svg viewBox="0 0 100 50" width="100" height="50">
                                <path
                                  className="stroke"
                                  stroke="#989898"
                                  fill="#fff"
                                  strokeWidth="2"
                                  d={`M 0,50 0,0 100,0 100,50 Z`}
                                ></path>
                              </svg>
                            )}
                          </span>
                          <div className="right_title">
                            <span className="text-truncate">{item.name}</span>
                            <span className="text-truncate d-block text-small">
                              {item.description}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <FormGroup className="mb-1">
                  <Label for="height" className="text-dark">
                    Distance
                  </Label>
                  <NumberInputNegative
                    name="height"
                    value={transomDistance}
                    setValue={setTransomDistance}
                  />
                </FormGroup>
                <Button
                  color="light"
                  size="md"
                  className="px-4 w-100 d-flex justify-content-between fw-medium mb-2 mt-4"
                  onClick={() => delete3DItems(true, "transoms")}
                  disabled = {disableDelete}
                >
                  <BiTrash size={20} />
                  Delete
                  <span></span>
                </Button>
              </>
            </TabPane>

            <TabPane tabId="Sash" className="w-100">
              <>
                <FormControl className="form-group">
                  <RadioGroup
                    aria-labelledby="sash-label"
                    name="sash"
                    className="flex-row gap-1 justify-content-evenly"
                    value={sashRadio}
                    onChange={(e) => {
                      setSashRadio(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      className="page_type_radio_2"
                      value="Product"
                      control={<Radio />}
                      label={
                        <div>
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14 14.6089V3.5H21V21.5H19"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14 6H17.5"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14 9H17"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14 12H18"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15 15H18"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M3 21.5V3L20.5 21.5H3Z"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7 17V13L11 17H7Z"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="text-center">Product</span>
                        </div>
                      }
                    />

                    <FormControlLabel
                      className="page_type_radio_2"
                      value="Profiles"
                      control={<Radio />}
                      label={
                        <div>
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22 17.5H10L12 12H20L22 17.5Z"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M17.5 12L16.5 9.5H8.5L6.5 15H10.5"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14 9.5L13 7H5L3 12.5H7.3071"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="text-center">Profiles</span>
                        </div>
                      }
                    />

                    <FormControlLabel
                      className="page_type_radio_2"
                      value="Hanging"
                      control={<Radio />}
                      label={
                        <div>
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.5 18.5L5 3V21.5C6 21.6667 8.9 21.2 12.5 18"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.5 17.5H13V22"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="text-center">Hanging</span>
                        </div>
                      }
                    />

                    <FormControlLabel
                      className="page_type_radio_2 max_w_100"
                      value="Handle direction"
                      control={<Radio />}
                      label={
                        <div>
                          <span>
                            <GoArrowSwitch size={18} />
                            {/* <Image src={Directions} alt="icon" /> */}
                          </span>
                          <span className="text-center">Handle direction</span>
                        </div>
                      }
                    />
                  </RadioGroup>
                </FormControl>

                {sashRadio === "Product" && (
                  <>
                    <div className="mt-1 right_list">
                      {productsData &&
                        productsData?.map((item, index) => (
                          // add has_delete and has_copy class in below div based on availability of icons in action_btns div
                          <div
                            key={index}
                            className="position-relative d-flex align-items-center right_item_wrap"
                            onClick={() => handleSashProducts(item)}
                          >
                            <div
                              className={`right_item w-100 ${
                                activeIndex === index && "active"
                              }`}
                              onClick={() => setActiveIndex(index)}
                            >
                              <span className="me-2 image_icon">
                                {item?.profilePathUrl ? (
                                  <Image src={item?.profilePathUrl} />
                                ) : (
                                  <svg
                                    viewBox="0 0 100 50"
                                    width="100"
                                    height="50"
                                  >
                                    <path
                                      className="stroke"
                                      stroke="#989898"
                                      fill="#fff"
                                      strokeWidth="2"
                                      d={`M 0,50 0,0 100,0 100,50 Z`}
                                    ></path>
                                  </svg>
                                )}
                              </span>
                              <div className="right_title">
                                <span className="text-truncate">
                                  {item.name}
                                </span>
                                <span className="text-truncate d-block text-small">
                                  {item.description}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>

                    {productsData && productsData.length > 0 && (
                      <Button
                        color="light"
                        size="md"
                        className="px-4 w-100 d-flex justify-content-between fw-medium mb-2 mt-4"
                        onClick={() => {}}
                      >
                        <BiTrash size={20} />
                        Delete
                        <span></span>
                      </Button>
                    )}
                  </>
                )}

                {sashRadio === "Profiles" && (
                  <>
                    {multiSelectRefPoints && multiSelectRefPoints.length > 0 ? (
                      <div className="mt-1 right_list">
                        {profileData && profileData.length > 0 ? (
                          <>
                            {profileData?.map((item, index) => (
                              <>
                                {item?.status && !showSashAlert && (
                                  <div
                                    key={index}
                                    className="position-relative d-flex align-items-center right_item_wrap"
                                    onClick={(e) => {
                                      referenceArray?.length === 1
                                        ? handleSashProfile(item, index)
                                        : e.preventDefault();
                                    }}
                                  >
                                    <div
                                      className={`right_item w-100  ${
                                        (sashNames.includes(
                                          item?.customePath
                                        ) ||
                                          sashNames.includes(item?.name)) &&
                                        "active"
                                      } ${
                                        referenceArray?.length > 1
                                          ? "opacity-50"
                                          : ""
                                      }`}
                                    >
                                      <span className="me-2 image_icon">
                                        {item.imagePath ? (
                                          <Image src={item.imagePath} />
                                        ) : (
                                          <svg
                                            viewBox="0 0 100 50"
                                            width="100"
                                            height="50"
                                          >
                                            <path
                                              className="stroke"
                                              stroke="#989898"
                                              fill="#fff"
                                              strokeWidth="2"
                                              d={`M 0,50 0,0 100,0 100,50 Z`}
                                            ></path>
                                          </svg>
                                        )}
                                      </span>
                                      <div className="right_title">
                                        <span className="text-truncate">
                                          {item.name}
                                        </span>
                                        <span className="text-truncate d-block text-small">
                                          {item.description}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                          </>
                        ) : (
                          <>
                            {showSashAlert && (
                              <Alert
                                color="secondary"
                                className="d-flex gap-2 p-2 my-2"
                              >
                                <span>
                                  <MdInfoOutline size={18} />
                                </span>
                                <span>
                                  Sash profile is not enable for this refrence
                                  point
                                </span>
                              </Alert>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <Alert
                        color="secondary"
                        className="d-flex gap-2 p-2 my-2"
                      >
                        <span>
                          <MdInfoOutline size={18} />
                        </span>
                        <span>Select one or more sash to edit</span>
                      </Alert>
                    )}
                  </>
                )}

                {(sashRadio === "Hanging" && multiSelectRefPoints[0]) && (
                  <>
                    {collectionDetails &&
                      collectionDetails.typeId == 1 &&
                      headerSelectedItem.name !== "French Door" && (
                        <>
                          <div className="d-flex gap-3">
                            <FormGroup className="mb-1">
                              <Label for="wide" className="text-dark">
                                Wide
                              </Label>
                              <NumberInput
                                name="wide"
                                id="wide"
                                value={wide}
                                setValue={setWide}
                              />
                            </FormGroup>
                            <FormGroup className="mb-1">
                              <Label for="high" className="text-dark">
                                High
                              </Label>
                              <NumberInput
                                name="high"
                                id="high"
                                value={high}
                                setValue={setHigh}
                              />
                            </FormGroup>
                          </div>

                          <FormGroup className="mb-1">
                            <Label for="numberSash" className="text-dark">
                              Number of sash
                            </Label>
                            <NumberInput
                              name="numberSash"
                              id="numberSash"
                              value={sashHangingNo}
                              setValue={handleSashChange}
                              // onChange={() => handleSashChange()}
                            />

                            {sashCountError && (
                              <span className="text-danger">
                                Enter number of sash
                              </span>
                            )}
                          </FormGroup>

                          <FormGroup className="form-group">
                            <Label for="postion">Position</Label>
                            <Select
                              labelId="printdoc-label"
                              id="postion"
                              value={sashHangingValue}
                              label="postion"
                              onChange={handlePostion}
                              input={
                                <OutlinedInput
                                  className="w-100"
                                  name="postion"
                                />
                              }
                              MenuProps={MenuProps}
                              IconComponent={() => (
                                <MdOutlineKeyboardArrowDown
                                  size={20}
                                  className="me-1 MuiSvgIcon-root MuiSelect-icon"
                                />
                              )}
                            >
                              {positionsOpts &&
                                positionsOpts.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormGroup>
                        </>
                      )}

                    <HandleHanging
                      collectionDetails={collectionDetails}
                      accessToken={accessToken}
                      collectionId={collectionId}
                      sashProductId={sashProductId}
                      frameStyleProdId={frameStyleProdId}
                      hideDelete={false}
                    />
                  </>
                )}
              </>
            </TabPane>

            <TabPane tabId="Frame" className="w-100">
              <div className="mt-1 right_list">
                {!isThreshold && (
                  <>
                    {multiSelectRefPoints && multiSelectRefPoints.length > 0 ? (
                      <>
                        {frameStyleFrameData &&
                          frameStyleFrameData?.length > 0 &&
                          frameStyleFrameData?.map((item, index) => (
                            <>
                              {item?.status && !showFrameAlert && (
                                <div
                                  key={index}
                                  className="position-relative d-flex align-items-center right_item_wrap"
                                >
                                  <div
                                    className={`right_item w-100 ${
                                      handleMenuActive(item?.id) && "active"
                                    } ${
                                      referenceArray?.length > 1
                                        ? "opacity-50"
                                        : ""
                                    }`}
                                    onClick={(e) => {
                                      referenceArray?.length === 1
                                        ? handleFrameProfileCLicked(item, index)
                                        : e.preventDefault();
                                    }}
                                  >
                                    <span className="me-2 image_icon">
                                      {item.width > 0 &&
                                      item.height > 0 &&
                                      item.customePath ? (
                                        <>
                                          {item.imagePath ? (
                                            <Image src={item.imagePath} />
                                          ) : (
                                            <svg
                                              viewBox="0 0 100 50"
                                              width="100"
                                              height="50"
                                            >
                                              <path
                                                className="stroke"
                                                stroke="#989898"
                                                fill="#fff"
                                                strokeWidth="2"
                                                d={`M 0,50 0,0 100,0 100,50 Z`}
                                              ></path>
                                            </svg>
                                          )}
                                        </>
                                      ) : (
                                        <GoCircleSlash
                                          size={40}
                                          color="#989898"
                                        />
                                      )}
                                    </span>
                                    <div className="right_title">
                                      <span className="text-truncate">
                                        {item.name}
                                      </span>
                                      <span className="text-truncate d-block text-small">
                                        {item.description}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ))}

                        {showFrameAlert && (
                          <Alert
                            color="secondary"
                            className="d-flex gap-2 p-2 my-2"
                          >
                            <span>
                              <MdInfoOutline size={18} />
                            </span>
                            <span>
                              Frame profile is not enable for this reference
                              point.
                            </span>
                          </Alert>
                        )}
                      </>
                    ) : (
                      <Alert
                        color="secondary"
                        className="d-flex gap-2 p-2 my-2"
                      >
                        <span>
                          <MdInfoOutline size={18} />
                        </span>
                        <span>Select one or more profiles to edit</span>
                      </Alert>
                    )}
                  </>
                )}
                {isThreshold &&
                  threshHolData?.length &&
                  threshHolData?.map((item, index) => (
                    <div
                      key={index}
                      className="position-relative d-flex align-items-center right_item_wrap"
                      onClick={() => handleThresholdClicked(item, index)}
                    >
                      <div
                        className={`right_item w-100 ${
                          activeIndex === index && "active"
                        }`}
                      >
                        <span className="me-2 image_icon">
                          {item.width > 0 &&
                          item.height > 0 &&
                          item.customePath ? (
                            <>
                              {item.imagePath ? (
                                <Image src={item.imagePath} />
                              ) : (
                                <svg
                                  viewBox="0 0 100 50"
                                  width="100"
                                  height="50"
                                >
                                  <path
                                    className="stroke"
                                    stroke="#989898"
                                    fill="#fff"
                                    strokeWidth="2"
                                    d={`M 0,50 0,0 100,0 100,50 Z`}
                                  ></path>
                                </svg>
                              )}
                            </>
                          ) : (
                            <GoCircleSlash size={40} color="#989898" />
                          )}
                        </span>
                        <div className="right_title">
                          <span className="text-truncate">{item.name}</span>
                          <span className="text-truncate d-block text-small">
                            {item.description}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </TabPane>
          </TabContent>
        </div>
      )}
    </>
  );
};

export default FrameStyle;
