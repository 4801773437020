import React, { useEffect } from 'react'
import Left from './left'
import { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDesigner } from '../../../../../context/designerContext'
import { useRightContext } from '../../../../../context/rightMenuContext'

const Designer = () => {
    const navigate = useNavigate()
    const location = useLocation();

    const [loading, setLoading] = useState(false)
    const [modelVisible, setModelVisible] = useState(false);
    const [captureImages, setCaptureImages] = useState(false)
    const [isNewFrame, setIsNewFrame] = useState(false)

    const receivedDataLocal = location.state ? location.state.data : null
    const is_new_fame = location.state ? location.state.is_new : false

    const { designerAccess, setDesignerAccess } = useDesigner()
    const { userDataContext } = useRightContext()

    useEffect(() => {
        setIsNewFrame(false)
        setCaptureImages(false)

        window.onload = (event) => {
            const e = event || window.event;
            // Cancel the event
            e.preventDefault();

            var quotationId = sessionStorage.getItem("quotationId")
            var collectionId = sessionStorage.getItem("collectionId")

            if (quotationId) {
                navigate(`/quotation/${quotationId}`)
            } else if (collectionId) {
                navigate(
                    `/product/systems/styles/list`,
                    { state: { data: collectionId } }
                );
                // navigate(`/product/systems/style-collections/collection/${collectionId}`)
            } else {
                navigate('/')
            }
        };

        return () => {
            sessionStorage.removeItem("collectionId")
            sessionStorage.removeItem("quotationId")
            sessionStorage.removeItem("productId")
        };
    }, []);

    useEffect(() => {
        if (is_new_fame) {
            setIsNewFrame(true)
        } else {
            setIsNewFrame(false)
        }
    }, [is_new_fame, receivedDataLocal])

    useEffect(() => {
        if(userDataContext && (userDataContext.role === "Admin")) {
            setDesignerAccess(true)
        }
    }, [receivedDataLocal, userDataContext]);

    return (
        <>
            {(loading || !modelVisible) && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}

            {(captureImages) && (
                <div className='loader_main full_loader save_images'>
                    <div className='d-flex align-items-center gap-3'>
                        <Oval
                            height={50}
                            width={50}
                            color="#44C8F5"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#44C8F5"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />

                        <span className='fs-5 text-muted fw-500'>Saving data</span>
                    </div>
                </div>
            )}

            <Left
                loading={loading}
                setLoading={setLoading}
                setModelVisible={setModelVisible}
                modelVisible={modelVisible}
                receivedDataLocal={receivedDataLocal}
                captureImages={captureImages}
                setCaptureImages={setCaptureImages}
                is_new_fame={is_new_fame}
                isNewFrame={isNewFrame}
                setIsNewFrame={setIsNewFrame}
            />
        </>
    )
}

export default Designer