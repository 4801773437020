import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMessage } from "../../../../../context/messageContext";
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { FormControl, FormControlLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import { MenuProps, projectTypeListOptions } from "../../../../../utility/data";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import axios from "axios";
import { handleError } from "../../../../../utility/commonHelper";
const Left = ({ userData, setLoading }) => {

    const navigate = useNavigate()
    const { setMessage, setMessageType } = useMessage()
    const [userType, setUserType] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userPhone, setUserPhone] = useState('')
    const [userId, setUserId] = useState('')
    const [customerNameListOptions, setCustomerNameListOptions] = useState([])
    const [taxListOptions, setTaxListOptions] = useState([])
    const [paymentTermListOptions, setPaymentTermListOptions] = useState([])
    const [projectType, setProjectType] = useState(projectTypeListOptions && projectTypeListOptions[0]?.id)
    const [tax, setTax] = useState('')

    const [paymentTerms, setPaymentTerms] = useState()

    const host_url = userData?.website_url;
    const host_user_Id = userData?.user_id;

    useEffect(() => {
        if (host_url) {
            setUserType("Customer")
            // getAllUserNameList(host_url)
            // getAllUserNameListNew(host_url, host_user_Id)
            getAllTex(host_url)
        }
    }, [host_url])

    useEffect(() => {
        if (host_url && userType) {
            // getAllUserNameList(host_url)
            getAllUserNameListNew(host_url, host_user_Id)
        }
    }, [host_url, userType])

    useEffect(() => {
        if (customerNameListOptions.length > 0) {
            setUserId(customerNameListOptions[0]?.userId || "");
            setUserEmail(customerNameListOptions[0]?.email || "");
            setUserPhone(customerNameListOptions[0]?.mobile_number || "");
        }
    }, [customerNameListOptions]);

    useEffect(() => {
        if (userType && host_url) {
            if (host_url?.includes("glideline")) {
                getAllPaymentItemsListGlideline(host_url, userType, projectType)
            } else {
                getAllPaymentItemsList(host_url)
            }
        }
    }, [userType, projectType]);

    useEffect(() => {
        if (paymentTermListOptions && paymentTermListOptions.length > 0) {
            const defaultPaymentTerm = paymentTermListOptions.find(term => term.is_default === 1);
            setPaymentTerms(defaultPaymentTerm?.id || (paymentTermListOptions[0]?.id));
        }
    }, [paymentTermListOptions]);

    useEffect(() => {
        if (userType) {
            if (!tax || !projectType) {
                setTax(taxListOptions.length > 0 ? taxListOptions[0].id : '');
            }
        }
    }, [userType, tax, taxListOptions]);

    // const getAllUserNameList = async (fetch_url) => {
    //     setLoading(true);
    //     const data = new FormData();
    //     data.append("role_name", userType);
    //     try {
    //         const response = await axios.post(`https://${fetch_url}/api/orbit/company/trade-customer-lists`, data);

    //         if (response?.data?.status === true) {
    //             const filteredUsers = response?.data?.data.filter(user => user.role.includes(userType.toLowerCase()));
    //             setCustomerNameListOptions(filteredUsers || []);
    //             setTimeout(() => {
    //                 setLoading(false);
    //             }, 400);
    //         } else {
    //             setTimeout(() => {
    //                 setLoading(false);
    //             }, 600);

    //             if (response?.data?.status === false) {
    //                 navigate('/error/404');
    //             } else {
    //                 setMessage(response?.data?.message);
    //                 setMessageType('error');
    //             }
    //         }
    //     } catch (error) {
    //         console.error(error);
    //         setMessage('Something went wrong');
    //         setMessageType('error');
    //         setLoading(false);
    //     }
    // };

    const getAllUserNameListNew = async (fetch_url, host_user_Id) => {
        setLoading(true);

        const data = new FormData();
        data.append("role_name", userType === "Customer" ? userType : 'Trade');
        data.append("user_id", host_user_Id)

        try {
            const response = await axios.post(`https://${fetch_url}/api/orbit/company/trade-customer-lists`, data);

            if (response?.data?.status === true) {
                // const filteredUsers = response?.data?.data.filter(user => user?.role?.includes(userType?.toLowerCase()));
                setCustomerNameListOptions(response?.data?.data);

                setLoading(false);

            } else {
                setCustomerNameListOptions([])
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setMessage('An error occurred while fetching data');
            setMessageType('error');
            setLoading(false);
            handleError(error,false,navigate)
        }
    };

    const getAllTex = async (fetch_url) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://${fetch_url}/api/orbit/company/tax`);

            if (response?.data?.status === true) {
                setTaxListOptions(response?.data?.data);
                setLoading(false);
            } else {
                setTaxListOptions([])
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setMessage('Something went wrong');
            setMessageType('error');
            setLoading(false);
            handleError(error,false,navigate)
        }
    };

    const getAllPaymentItemsList = async (fetch_url) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://${fetch_url}/api/orbit/company/payment-term-list`);

            if (response?.data?.status === true) {
                setPaymentTermListOptions(response?.data?.data);

                setLoading(false);
            } else {
                setPaymentTermListOptions([])
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setMessage('Something went wrong');
            setMessageType('error');
            setLoading(false);
            handleError(error,false,navigate)
        }
    };

    const getAllPaymentItemsListGlideline = async (fetch_url, user_type, projectType) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://${fetch_url}/api/orbit/company/payment-term-list?project_type=${projectType}&type=${user_type}`);

            if (response?.data?.status === true) {

                if (response?.data?.payment_terms && response?.data?.payment_terms.length > 0) {

                    setPaymentTermListOptions(response?.data?.payment_terms);
                }

                setLoading(false);
            } else {
                setLoading(false);
                setPaymentTermListOptions([])
            }
        } catch (error) {
            console.error(error);
            setMessage('Something went wrong');
            setMessageType('error');
            setLoading(false);
            handleError(error,false,navigate)
        }
    };

    const createQuotation = async (fetch_url) => {
        setLoading(true);
        const data = new FormData();
        data.append("employee_id", host_user_Id);
        data.append("payment_term_id", paymentTerms);
        data.append("tax_id", tax);
        data.append("project_type", projectType);

        if (host_url?.includes("glideline")) {
            data.append('customer_id', userId);
            data.append("role_type", userType === "Customer" ? userType : 'Trade');
        } else {
            data.append(userType == "Customer" ? "customer_id" : "trade_id", userId);
            data.append("role_type", userType);
        }

        try {
            const response = await axios.post(`https://${fetch_url}/api/orbit/quotations/create`, data);

            if (response?.data?.status == true) {
                setMessage(response?.data?.message);
                setMessageType("success");

                setTimeout(() => {
                    if (response?.data?.generate_quotation_id) {
                        navigate(`/quotation/${response?.data?.generate_quotation_id}`);
                    }

                    setLoading(false);
                }, 1000);
            } else {
                setLoading(false);
                setMessage('Something went wrong');
                setMessageType('error');
            }
        } catch (error) {
            console.error(error);
            setMessage('Something went wrong');
            setMessageType('error');
            setLoading(false);
            handleError(error,false,navigate)
        }
    };

    const handleUserSelect = (value) => {
        const selectedUserDetails = customerNameListOptions.find(user => user.userId === value);
        console.log(selectedUserDetails, "selectedUserDetails")

        if (selectedUserDetails) {
            setUserId(selectedUserDetails?.userId)
            setUserEmail(selectedUserDetails?.email);
            setUserPhone(selectedUserDetails?.mobile_number)
        }
    };

    const getProjectTypeId = (name) => {
        return projectTypeListOptions.find(option => option.id === name)?.id || null;
    };

    const handleRest = () => {
        if (customerNameListOptions.length > 0) {
            setUserId(customerNameListOptions[0]?.userId || "");
            setUserEmail(customerNameListOptions[0]?.email || "");
            setUserPhone(customerNameListOptions[0]?.mobile_number || "");
        }
    }

    return (
        <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
            <div className='inside_scroll has_tab has_btns has_head'>
                <Row>
                    <Col lg="12">
                        <FormControl className="form-group">
                            <Label for="pageSize">
                                Type
                            </Label>

                            <RadioGroup
                                aria-labelledby="pageSize-label"
                                className="d-flex flex-row gap-2"
                                name="pageSize"
                                value={userType}
                                onChange={(e) => setUserType(e.target.value)}
                            >
                                <FormControlLabel value="Customer" control={<Radio />} label="Customer" />
                                <FormControlLabel value="trade" control={<Radio />} label="Trade" />
                            </RadioGroup>
                        </FormControl>
                    </Col>

                    <Col lg="6">
                        <FormGroup className="form-group">
                            <FormControl fullWidth>
                                <Label for="markUpPriceList">
                                    Customer Name
                                </Label>

                                <Select
                                    labelId="markUp-label"
                                    id="markUpPriceList"
                                    value={userId || ''}
                                    onChange={(e) => handleUserSelect(e.target.value)}
                                    input={<OutlinedInput name='markUpPriceList' />}
                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {customerNameListOptions && customerNameListOptions.map((o, index) => (
                                        <MenuItem key={index} value={o.userId}>
                                            {o.userName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup className="form-group">
                            <Label for="fileName">
                                Phone Number
                            </Label>
                            <Input type="number" className='mb-1' name='fileName' value={userPhone} onChange={(e) => setUserPhone(e.target.value)} />
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup className="form-group">
                            <Label for="name">
                                Email
                            </Label>
                            <Input className='mb-1' name='email' value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup className="form-group">
                            <FormControl fullWidth>
                                <Label for="markUpPriceList">
                                    Project Type
                                </Label>

                                <Select
                                    labelId="markUp-label"
                                    id="markUpPriceList"
                                    value={projectType || ''}
                                    onChange={(e) => setProjectType(e.target.value)}
                                    input={<OutlinedInput name='markUpPriceList' />}
                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {projectTypeListOptions && projectTypeListOptions.map((o, index) => (
                                        <MenuItem key={index} value={o.id}>
                                            {o?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup className="form-group">
                            <FormControl fullWidth>
                                <Label for="markUpPriceList">
                                    Tax
                                </Label>

                                <Select
                                    labelId="markUp-label"
                                    id="tax"
                                    value={tax || ''}
                                    onChange={(e) => setTax(e.target.value)}
                                    input={<OutlinedInput name='tax' />}
                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {taxListOptions && taxListOptions.map((o, index) => (
                                        <MenuItem key={index} value={o?.id}>
                                            {o.tax_title}-{o.tax_percentage}%
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup className="form-group">
                            <FormControl fullWidth>
                                <Label for="markUpPriceList">
                                    Payment Terms
                                </Label>

                                <Select
                                    labelId="markUp-label"
                                    id="paymentTerms"
                                    value={paymentTerms || ''}
                                    onChange={(e) => setPaymentTerms(e.target.value)}
                                    input={<OutlinedInput name='paymentTerms' />}
                                    MenuProps={MenuProps}

                                    IconComponent={() => (
                                        <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                    )}
                                >
                                    {paymentTermListOptions && paymentTermListOptions.map((o, index) => (
                                        <MenuItem key={index} value={o?.id}>
                                            {o.title}- {o.terms_values}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
            </div>

            <div className='d-flex gap-2 gap-xl-3 main_buttons'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'
                    onClick={() => createQuotation(host_url)}
                >
                    Create
                </Button>

                <Button
                    type="reset"
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => handleRest()}
                >
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

export default Left;
