import { useDesigner } from '../../context/designerContext';
import { getFrameType } from '../helper';

const useDataStorage = () => {

    const { setCustomModelData, customModelData } = useDesigner()

    // Function to update the frame dimensions
    const storeFrameProfile = (item, frameProfile, index) => {        

        const side = getFrameType(item)        

        const newData = {
            id: frameProfile?.id,
            index: index,
            width: frameProfile?.width,
            height: frameProfile?.height,
            shapedWidth: frameProfile?.width / 2,
            shapedHeight: frameProfile?.height / 2,
            side,
            orientation: item,
            name: frameProfile?.name,
            price: frameProfile?.price,
            frameType: "Frame profile",
            externalVisibleSurface: frameProfile?.externalVisibleSurface,
            internalVisibleSurface: frameProfile?.internalVisibleSurface,
            paintSurfaceArea: frameProfile?.paintSurfaceArea,
            barLength: frameProfile?.barLength,
            weight: frameProfile?.weight,
            usableLength: frameProfile?.usableLength,
            imagePath: frameProfile?.imagePath,
            externalPaintSurfaceArea: frameProfile?.externalPaintSurfaceArea,
            internalPaintSurfaceArea: frameProfile?.internalPaintSurfaceArea,
            profileTypePrice: frameProfile?.profileTypePrice,
            url: "https://testorbitapp.augursapps.com/api/ThreeJSModel/Glb/GS20TripleTrack.glb"
        };

        // Update or add to frameProfileData array
        setCustomModelData((prevData) => {            
            const updatedFrameProfileData = [...(prevData?.frameProfileData || [])];            

            // Find index of existing profile with the same orientation
            const existingIndex = updatedFrameProfileData.findIndex(
                (profile) => profile.index == index
            );

            if (existingIndex !== -1) {                
                // Replace the existing profile with new data
                updatedFrameProfileData[existingIndex] = { ...newData };
            } else {
                // Add new profile to the array
                updatedFrameProfileData.push(newData);
            }            

            return {
                ...prevData,
                frameProfileData: updatedFrameProfileData,
            };
        });
    };


    const storeSashProfile = (index, defaultSash, orientation, maxComputedIndex) => {
        const orientations = orientation.includes('Right')
            ? "Right"
            : orientation.includes('Left')
                ? "Left"
                : orientation.includes('Top')
                    ? "Top"
                    : orientation.includes('Bottom')
                        ? "Bottom"
                        : "";

        let data = {
            id: defaultSash.id,
            index: index,
            width: defaultSash.width,
            height: defaultSash.height,
            side: orientation,
            price: defaultSash?.price,
            description: defaultSash?.description,
            name: defaultSash?.name,
            frameType: "Sash profile",
            orientation: orientations,
            imagePath: defaultSash?.imagePath,
            internalPaintSurfaceArea: defaultSash?.internalPaintSurfaceArea,
            externalPaintSurfaceArea: defaultSash?.externalPaintSurfaceArea,
            weight: defaultSash?.weight,
            maxWeight: defaultSash?.maxWeight,
            alert: false,
            visible: true,
            widthFormula: defaultSash?.widthFormula,
        };

        setCustomModelData((prevData) => {
            const updatedSashProfileData = [...(prevData.sashData || [])];

            // Remove entries that exceed the maxComputedIndex
            const filteredSashProfileData = updatedSashProfileData.filter(
                (profile) => profile.index <= maxComputedIndex
            );

            // Find index of existing profile with the same index
            const existingIndex = filteredSashProfileData.findIndex(
                (profile) => profile.index === index
            );

            if (existingIndex !== -1) {
                // Replace the existing profile with new data
                filteredSashProfileData[existingIndex] = { ...data };
            } else {
                // Add new profile to the array
                filteredSashProfileData.push(data);
            }

            return {
                ...prevData,
                sashData: filteredSashProfileData,
            };
        });
    };

    const updateSashData = (updatedSash, searchedItem, profileIndex) => {
        let data = {
            id: updatedSash.id,
            index: profileIndex,
            width: updatedSash.width,
            height: updatedSash.height,
            side: searchedItem?.orientation,
            price: updatedSash?.price,
            description: updatedSash?.description,
            name: updatedSash?.name,
            frameType: "Sash profile",
            orientation: searchedItem?.orientation,
            imagePath: updatedSash?.imagePath,
            internalPaintSurfaceArea: updatedSash?.internalPaintSurfaceArea,
            externalPaintSurfaceArea: updatedSash?.externalPaintSurfaceArea,
            weight: updatedSash?.weight,
            maxWeight: updatedSash?.maxWeight,
            alert: false,
            visible: true,
            widthFormula: updatedSash?.widthFormula,
        };

        setCustomModelData((prevState) => ({
            ...prevState, // Keep other parts of the state unchanged
            sashData: prevState.sashData.map((item) =>
                item.index === profileIndex ? { ...item, ...data } : item
            ), // Update only the sashData array
        }));
    };

    const storeAddOnsData = (data, side) => {
        let obj = {
            id: data.id,
            side: side,
            width: data.width,
            height: data.height,
            price: data?.price,
            description: data?.description,
            name: data?.name,
            frameType: "Add-on",
            imagePath: data?.imagePath,
            internalPaintSurfaceArea: data?.internalPaintSurfaceArea,
            externalPaintSurfaceArea: data?.externalPaintSurfaceArea,
            weight: data?.weight,
            maxWeight: data?.maxWeight,
            alert: false,
            visible: true,
            widthFormula: data?.widthFormula,
        };

        setCustomModelData((prevState) => {
            // Create a deep copy of the previous state
            const updatedState = { ...prevState };

            // Ensure the addOn key exists
            if (!updatedState.addOn) {
                updatedState.addOn = {
                    left: [],
                    top: [],
                    right: [],
                    bottom: [],
                };
            }

            // Add the object to the correct side
            updatedState.addOn[side] = [...(updatedState.addOn[side] || []), obj];

            return updatedState; // Return the updated state
        });
    };

    const updateDeletedAddOnData = (side) => {
        setCustomModelData((prevState) => ({
            ...prevState,
            addOn: {
                ...prevState.addOn,
                [side]: prevState.addOn[side].slice(0, -1),
            },
        }));
    };

    const saveHardwareData = (
      element,
      index,
      hangingIndex,
      type,
      cylinderData
    ) => {
      if (type === "doorhandle") {
        type = "handle";
      }

      if (type == "tricklevent") {
        type = "trickleVent";
      }
      // Base object creation
      let obj = {
        id: element?.id,
        index: index,
        file: element?.modelFilePath,
        price: element?.price,
        name: element?.name,
        color: "",
        hangingIndex: hangingIndex >= 0 ? hangingIndex : null,
        type: type,
      };

      let cylinderObj = null;

      // Create a cylinder object if type is 'handle' and cylinderData exists
      if (type === "handle" && cylinderData) {
        cylinderObj = {
          id: cylinderData?.id,
          index: index,
          file: cylinderData?.modelFilePath,
          price: cylinderData?.price,
          name: cylinderData?.name,
          color: "",
          hangingIndex: hangingIndex >= 0 ? hangingIndex : null,
          type: "cylinder",
        };
      }

      setCustomModelData((prevData) => {
        const hardware = { ...prevData.hardware };

        // Update the type-specific hardware data
        const existingItems = hardware[type] || [];
        const updatedItems = existingItems.filter(
          (item) =>
            item.index !== obj.index || item.hangingIndex !== obj.hangingIndex
        );
        updatedItems.push(obj);

        // Add or update the cylinder data
        if (cylinderObj) {
          const cylinderType = "cylinder";
          const existingCylinderItems = hardware[cylinderType] || [];
          const updatedCylinderItems = existingCylinderItems.filter(
            (item) =>
              item.index !== cylinderObj.index ||
              item.hangingIndex !== cylinderObj.hangingIndex
          );
          updatedCylinderItems.push(cylinderObj);

          // Set cylinder data
          hardware[cylinderType] = updatedCylinderItems;
        }

        // Set handle data
        hardware[type] = updatedItems;

        return {
          ...prevData,
          hardware,
        };
      });
    };
    

    const deleteHardwareData = (index, hangingIndex, type) => {
        setCustomModelData((prevData) => {
            const updatedHardware = { ...prevData.hardware };
    
            // Filter the items for the given type
            updatedHardware[type] = updatedHardware[type]?.filter(
                (item) => item?.index !== index || item?.hangingIndex !== hangingIndex
            );
    
            // Handle cylinder logic if the type is 'handle'
            if (type === "handle") {
                const cylinderArray = updatedHardware.cylinder || [];
                if (cylinderArray.length > 0) {
                    let cylArray=updatedHardware?.cylinder?.filter(
                        (item) => item?.index !== index || item?.hangingIndex !== hangingIndex
                    );
                    // Create a new cylinder array without mutating the existing one
                    updatedHardware.cylinder = cylArray;
                }
            }
    
            return {
                ...prevData,
                hardware: updatedHardware,
            };
        });
    };
      

    const updateHardwarePositionColor = (
        index,
        horizontalPos,
        verticalPos,
        color,
        type
    ) => {

        setCustomModelData((prevData) => {
            const updatedTypeArray = prevData.hardware[type]?.map((item) => {
                if (item?.index === index) {
                    // Return updated object with new keys
                    return {
                        ...item,
                        horizontalPos,
                        verticalPos,
                        colorId: color?color?.id:item?.colorId,
                        colorPrice: color?color?.price:item?.colorPrice
                    };
                }
                return item; // Return other items unchanged
            });

            return {
                ...prevData,
                hardware: {
                    ...prevData.hardware,
                    [type]: updatedTypeArray, // Update the specific type array
                },
            };
        });
    };

    const storeTransomData = (data, length) => {
      let obj = {
        id: data?.id,
        name: data?.name,
        modelFilePath: data?.modelFilePath,
        price: data?.price,
        length: length,
        frameType: "Transom profile",
        externalPaintSurfaceArea: data?.externalPaintSurfaceArea,
        internalPaintSurfaceArea: data?.internalPaintSurfaceArea,
      };
      setCustomModelData((prevData) => ({
        ...prevData,
        storedTransoms: [obj],
      }));
    };

    const storeSpecData = (data, index,isDefault=false) => {
        const newData = {
            id: data?.id,
            name: data?.name,
            color: data?.hexValue,
            price: data?.price,
            imagePath: data?.capturedImage,
            index: index,
            oversizeArea: data?.maximumsquare,
            description: data?.description,
        };
    
       if(isDefault && index==-1){        
        setTimeout(()=>{
            setCustomModelData((prevData)=>({
                ...prevData,
                glazing:{
                    ...prevData?.glazing,
                    spec:[newData]
                }
            }))
        },300)
       }else{
        setCustomModelData((prevData) => {
            let updatedGlazingSpecData = [...(prevData?.glazing?.spec || [])];
    
            if (index === -1) {
                // If index is -1, reset the array to contain only the new data
                updatedGlazingSpecData = [newData];
            } else {
                // Find index of existing profile with the same orientation
                const existingIndex = updatedGlazingSpecData.findIndex(
                    (profile) => profile.index === index
                );
    
                if (existingIndex !== -1) {
                    // Replace the existing profile with new data
                    updatedGlazingSpecData[existingIndex] = { ...newData };
                } else {
                    // Add new profile to the array
                    updatedGlazingSpecData.push(newData);
                }
            }
    
            // Return the updated state
            return {
                ...prevData,
                glazing: {
                    ...prevData.glazing,
                    spec: updatedGlazingSpecData,
                },
            };
        });
       }
    };    

    return {
        storeFrameProfile,
        storeSashProfile,
        updateSashData,
        storeAddOnsData,
        saveHardwareData,
        updateDeletedAddOnData,
        deleteHardwareData,
        updateHardwarePositionColor,
        storeTransomData,
        storeSpecData
    };
};

export default useDataStorage;
