import * as THREE from 'three';
import { createShapeFromWidthHeight } from './utils/threeUtils'

export const storeSill = (sillData, jsonIndex = 0) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson];
        const updatedModel = { ...updatedModelArray[jsonIndex] };
        const { height, width } = sillData;
        if (updatedModel.sill) {
            updatedModel.sill.width = width;
            updatedModel.sill.height = height;
        }
        updatedModelArray[jsonIndex] = updatedModel;
        return updatedModelArray;
    }
}

export const updateHornLengthData = (length , jsonIndex = 0) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updatedModel = {...updatedModelArray[jsonIndex]};
        updatedModel.sill.hornLength = length;
        updatedModelArray[jsonIndex] = updatedModel;
        return updatedModelArray;
    }
}

export const removeSillData = (setData, setCustomModelData) => {
  setData((prevModelJson) => ({
    ...prevModelJson,
    sill: {
      width: 0,
      height: 0,
      hornLength: 0,
    },
  }));

  setCustomModelData((prevData) => ({
    ...prevData,
    frame: {
      ...prevData?.frame,
      sill: {
        ...prevData?.frame?.sill,
        sillData: {},
        hornLength: 0,
      },
    },
  }));
};


export function addSill(data, sillRef, sceneRef, frameStyleBottom, setDefaultSillScale, sillsHeightRef) {

    const { color } = data

    const { height , hornLength} = data?.sill


    let sillDepth = data?.sill?.width / 1000;
    let sillHeight = height / 1000;
    const sillHorn = hornLength ? hornLength *0.01 : 0
    let widthOffset = 0;
    sillsHeightRef.current.push(sillHeight)


    const boundingBox = new THREE.Box3().setFromObject(frameStyleBottom.current);
    const width = boundingBox.max.x - boundingBox.min.x;
    const botDepth = boundingBox.max.z - boundingBox.min.z;
    const sillPathStart = new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, 0);
    const sillPathEnd = new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, 0);

    const sillPath = new THREE.LineCurve3(sillPathStart, sillPathEnd);
    const sillShape = createShapeFromWidthHeight(sillDepth, sillHeight);
    const extrudeSettings = {
        steps: 2,
        bevelEnabled: true,
        extrudePath: sillPath,
        bevelSegments: 3,
        bevelSize: 0.001,
        bevelThickness: 0.1
    };
    const material = new THREE.MeshPhongMaterial({
        color: color,
        specular: 0xffffff,
        shininess: 10,
        // shininess: 27.5,
        flatShading: false,
        emissive: 0x000000,
        specular: 0x555555,
        bumpScale: 1,
    });

    if (sillRef.current) {
        sceneRef.current.remove(sillRef.current);
    }

    const sillGeometry = new THREE.ExtrudeGeometry(sillShape, extrudeSettings);
    const exmesh = new THREE.Mesh(sillGeometry, material);
    exmesh.position.z = -(botDepth - sillDepth) / 2;
    exmesh.name = "sill";
    sillRef.current = exmesh;
    if(sillHorn){
        exmesh.scale.x += sillHorn 
    }
    setDefaultSillScale(exmesh.scale.x)
    sceneRef.current.add(exmesh);


}

export const removeSill = (sillRef, sceneRef) => {
    if (sillRef.current) {
        sceneRef.current.remove(sillRef.current);
    }
}





export const sillHornLength = (sillRef , data , defaultScale) => {
    const { hornLength } = data?.sill;

    console.log(hornLength , "124");
    
    if(!sillRef.current){
        return
    }
    sillRef.current.scale.x = defaultScale + (hornLength * 0.01);
}
