import React, { useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import LeftComponent from './left'
import RightComponent from './right'
import { useMessage } from '../../../../../../context/messageContext'
import { Oval } from 'react-loader-spinner'
import { addNewRightItem, getAllPrintTemplate, getItemDetails, updateItemDetails } from '../../../../../../services/printTempService'
import { useEffect } from 'react'
import { useRightContext } from '../../../../../../context/rightMenuContext'
import { useNavigate } from 'react-router-dom'
import { handleError } from '../../../../../../utility/commonHelper'

const Terms = () => {

  const accessToken = localStorage.getItem('access_token')

  const [loading, setLoading] = useState(false)
  const [selectItemDetails, setSelectItemDetails] = useState([])
  const [allRightListItems, setAllRightListItems] = useState([])

  const { setMessage, setMessageType } = useMessage()
  const { timestamp, fetchSelected, setTimestamp, activeIndex } = useRightContext()

  const navigate = useNavigate();

  var getFirstElId = allRightListItems[0]?.id || ''
  var itemAddedTrue = ''

  useEffect(() => {
    setLoading(true)

    setTimestamp('')

    setTimeout(() => {
      getAllPrintTempData(2)
    }, 400);
  }, [])

  useEffect(() => {
    if (allRightListItems?.length > 0 && timestamp) {
      getRightItemDetails(timestamp)
    }
  }, [timestamp])

  useEffect(() => {
    if (allRightListItems?.length > 0) {
      if (getFirstElId) {
        if (allRightListItems?.length && activeIndex !== 0) {
          getRightItemDetails(timestamp)
        } else {
          getRightItemDetails(getFirstElId)
        }
      }
    } else {
      setSelectItemDetails([])
    }
  }, [allRightListItems])

  // get all right menu item list based on page/type (type = 2 for terms)
  const getAllPrintTempData = async (type) => {

    const res = await getAllPrintTemplate(accessToken, type);

    if (res && res && res.status === 200) {

      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            setAllRightListItems(res?.data?.entity)

            if (itemAddedTrue == 'added') {
              const addedItemIndex = res.data.entity.length - 1

              if (addedItemIndex) {
                fetchSelected(addedItemIndex, res.data.entity[addedItemIndex]?.id)
                itemAddedTrue = ''
              }
            }

          } else {
            setAllRightListItems([])
            setTimestamp('')

            fetchSelected(0, '');
          }
        }

        setTimeout(() => {
          setLoading(false)
        }, 1200);
      } else {
        setLoading(false)
        setMessage(res?.data.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }

    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      handleError(res, false, navigate)
    }
  }

  // get details of selected item in right menu by passing item id
  const getRightItemDetails = async (id) => {
    setLoading(true)

    const res = await getItemDetails(accessToken, id);

    if (res && res && res.status === 200) {

      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          // set state to get details of current selected item which will display data in left side component
          setSelectItemDetails(res.data.entity)

          // set selected item id which will update context
          setTimestamp(id)
        }

        setTimeout(() => {
          setLoading(false)
        }, 1200);
      } else {
        setLoading(false)
        setMessage(res?.data.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }

    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      handleError(res, false, navigate)
    }
  }

  // api call to update all selected item details
  const updateItemDetailsServices = async (data) => {
    setLoading(true)

    const res = await updateItemDetails(accessToken, timestamp ? timestamp : getFirstElId, data, 2)

    // console.log(res, "save button hit")

    if (res && res && res.status === 200) {
      // console.log(res, 'page setup info')

      if (res?.data?.statusCode === 200) {
        setLoading(false)
        setMessage(res?.data?.message ? res.data.message : 'Updated terms');
        setMessageType('success')

        setLoading(false)
      } else {
        setLoading(false)
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      handleError(res, false, navigate)
    }
  }

  // api call to added new right item (print template) type=2 for terms
  const addNewRightItemServices = async (title, type) => {
    setLoading(true)
    itemAddedTrue = "added"

    const res = await addNewRightItem(accessToken, title, type, "Payment Terms:\r\n\r\n60% deposit of {TOTAL*0.60} is payable with a 30% interim of {TOTAL*0.30} with the remaining 10% balance of {TOTAL*0.10} due on completion.")

    if (res && res && res.status === 200) {

      if (res?.data?.statusCode === 200) {
        setMessage(res?.data?.message ? res.data.message : 'Created terms');
        setMessageType('success')

        getAllPrintTempData(2)
      } else {
        setLoading(false)
        setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
        setMessageType('error')
      }
    } else {
      setLoading(false)
      setMessage(res?.message ? res.message : 'Something went wrong');
      setMessageType('error')
      handleError(res, false, navigate)
    }

  }

  const handleFormReset = () => {
    if (timestamp) {
      getAllPrintTempData(2)
      getRightItemDetails(timestamp)
    }
  }

  return (
    <>
      {loading && (
        <div className='loader_main full_loader'>
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <InnerLayout pageTitle="Terms" layoutType=""
        leftContent={<LeftComponent handleFormReset={handleFormReset} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} />}
        rightContent={<RightComponent accessToken={accessToken} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getAllPrintTempData={getAllPrintTempData} addNewRightItemServices={addNewRightItemServices} />} />

    </>
  )
}

export default Terms