import React, { useEffect, useState } from "react";
import { getOrientation } from "../utility/helper";

const DisplayDataOrientationComp = ({
  pricingData,
  getEachPrice,
  totalEachPrice,
  type,
  currency,
}) => {
  return (
    <>
      {Object.values(pricingData).map((item, index) => (
        <tr key={index} className="border-bottom">
          <td className="text-start text-wrap">
            {pricingData && pricingData?.length > 1 ? (
              <>
                {item.frameType}{" "}
                {item?.orientation !== "" &&
                  `(${getOrientation(item?.orientation)})`}
              </>
            ) : (
              <>{item.frameType}</>
            )}
          </td>
          <td className="fw-bold text-wrap text-start">{item.name}</td>
          <td className="text-end">
            {currency}
            {type !== "sill"
              ? getEachPrice(totalEachPrice, item?.id)
              : totalEachPrice}
          </td>
        </tr>
      ))}
    </>
  );
};

export default DisplayDataOrientationComp;
