import React, { useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import Wip from '../../../errors/wip'
import StylesLeft from './left'
import StylesRight from './right'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Oval } from 'react-loader-spinner'
import { useMessage } from '../../../../../../context/messageContext'
import { useRightContext } from '../../../../../../context/rightMenuContext'
import { addNewRightItem, getRightMenuList, getItemDetails, updateItemDetails } from '../../../../../../services/hardwareStylesService'
import { noSelectOptions, productHardwareStyleMenu } from '../../../../../../utility/data'
import { getHardwareCollectionInUsed, getHardwareInUsed, getHardwareModelService, removehardwareInUsed } from '../../../../../../services/commonServices'
import { getAllColoursCollection } from '../../../../../../services/productServices'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { MdKeyboardArrowDown } from 'react-icons/md'
import NoDataCard from '../../../../../../components/NoDataCard'
import { titleWrapper } from '../../profile/profiles'
import { getAllSystemProductsListService } from '../../../../../../services/productProfileServiceV'
import { handleError } from '../../../../../../utility/commonHelper'
const Styles = () => {

    const accessToken = localStorage.getItem('access_token')

    const { setMessage, setMessageType } = useMessage()

    const { timestamp, fetchSelected, setTimestamp, activeIndex, setActiveIndex, setSelectedRightItem, setMainMenuActive, setSubMenuActive } = useRightContext()

    const navigate = useNavigate();

    const [modelOptions, setModalOptions] = useState([]);
    const [colourCollectionOptions, setColourCollectionOptions] = useState([]);

    const [loading, setLoading] = useState(false)
    const [selectItemDetails, setSelectItemDetails] = useState([])
    const [allRightListItems, setAllRightListItems] = useState([])
    const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true)
    const [headerDropdown, setHeaderDropdown] = useState(false);


    var getFirstElId = allRightListItems[0]?.id || ''

    var itemAddedTrue = ''

    // let { collectionId } = useParams();

    const location = useLocation();
    const collectionId = location.state ? location.state.data : null

    const [wip, setWIP] = useState(false)
    const [pageName, setPageName] = useState('')
    // const [styleMasterId, setStyleMasterId] = useState(1)
    const [usedProducts, setUsedProducts] = useState([])
    const [mainDropdownList, setMainDropdownList] = useState([
        {
            id: -1,
            value: "",
            title: "All"
        }
    ])
    const [frameCollection, setFrameCollection] = useState('')
    const [secondDropdown, setSecondDropdown] = useState(false)

    const [systemDropdownList, setSystemDropdownList] = useState([
        {
            id: -1,
            value: "",
            name: "All"
        }
    ])
    const [selectedSystemCollection, setSelectedSystemCollection] = useState({
        value: "null",
        id: -1,
        name: "All"
    })

    useEffect(() => {
        setMainDropdownList((prevItems => [...prevItems, ...productHardwareStyleMenu]))
        setMainMenuActive("Products")
        setSubMenuActive("Hardware")
        getSystemCollectionList()
    }, [])

    useEffect(() => {
        if (mainDropdownList && mainDropdownList.length > 0) {

            if (collectionId) {
                const collectionItem = mainDropdownList?.find((p) => p.id == collectionId)

                if (collectionItem && collectionItem.id) {
                    setFrameCollection(collectionItem)
                }
            } else {
                setFrameCollection(mainDropdownList[0])
            }
        } else {
            setLoading(false)
        }
    }, [mainDropdownList]);

    // Calling get hardware master model API
    useEffect(() => {
        if (timestamp && allRightListItems?.length > 0 && selectItemDetails?.model) {
            let styleMasterId = allRightListItems?.find((item => item?.id === timestamp))?.styleMasterId
            getModalSelectOptions(styleMasterId)
        }
        else{
            getModalSelectOptions(1)
        }
    }, [timestamp, selectItemDetails])

    useEffect(() => {
        if (frameCollection.id) {

            setLoading(true);
            getColorCollectionSelectOptions()

            if (productHardwareStyleMenu && productHardwareStyleMenu.length > 0) {
                const getPageName = productHardwareStyleMenu.filter((item) => {
                    return item.id == frameCollection.id;
                });

                setPageName(getPageName[0]?.title);
            }

            setTimestamp('')
            // if(allRightListItems?.length>0){
            //     let styleMasterId=allRightListItems?.find((item=>item?.id===timestamp))?.styleMasterId
            //     // setStyleMasterId(frameCollection.id)
            //     console.log("frameCollec",frameCollection,timestamp,allRightListItems,styleMasterId)
            //     getModalSelectOptions(styleMasterId)
            // }
            setAllRightListItemsOrder(true)
            if (frameCollection?.id === -1 && selectedSystemCollection?.id === -1) {
                getRightMenuListData("", "")
            }
            else if (frameCollection?.id !== -1 && selectedSystemCollection?.id === -1) {
                getRightMenuListData(frameCollection?.id, "")
            }
            else if (frameCollection?.id === -1 && selectedSystemCollection?.id !== -1) {
                getRightMenuListData("", selectedSystemCollection?.id)
            }
            else {
                getRightMenuListData(frameCollection?.id, selectedSystemCollection?.id)
            }
        }
    }, [frameCollection.id, selectedSystemCollection?.id])

    useEffect(() => {
        if (allRightListItems?.length > 0 && timestamp) {
            getRightItemDetails(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if (allRightListItemsOrder) {
            getFirstElId = allRightListItems[0]?.id

            setActiveIndex(0)

            if (getFirstElId && allRightListItems?.length) {
                getRightItemDetails(getFirstElId)
            }
        } else {
            if (getFirstElId && allRightListItems?.length) {
                if (timestamp && allRightListItems?.length && activeIndex !== 0) {
                    getRightItemDetails(timestamp)
                } else {
                    getRightItemDetails(getFirstElId)
                }
            }
        }
    }, [allRightListItems])

    const getSystemCollectionList = async () => {
        try {
            setLoading(false);
            const res = await getAllSystemProductsListService(accessToken);
            if (res?.data?.statusCode === 200) {
                setLoading(false);
                setSystemDropdownList((prevItems) => {
                    const newItems = Array.isArray(res?.data?.entity)
                        ? res.data.entity
                        : [res.data.entity];
                    return [...prevItems, ...newItems];
                });
            } else {
                setMessage(
                    res?.data?.message ? res.data.message : "Something went wrong"
                );
                setMessageType("error");
            }
        } catch (err) {
            setLoading(false);
            console.log("Error: ", err?.message);
            handleError(err, false, navigate)
        }
    };

    // get Profile type from master API
    const getModalSelectOptions = async (fetchMasterId) => {
        const res = await getHardwareModelService(accessToken, fetchMasterId);

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setModalOptions(res?.data?.entity)
                    }
                }
            }
        }
        else {
            handleError(res, false, navigate)
        }
    }

    // get color collection from hardware collection API
    const getColorCollectionSelectOptions = async () => {
        const res = await getAllColoursCollection("2", accessToken)
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {
                    if (res?.data?.entity?.length > 0) {
                        setColourCollectionOptions(res?.data?.entity)
                    } else {
                        setColourCollectionOptions(noSelectOptions)
                    }
                }
            }
        }
        else {
            handleError(res, false, navigate)
        }
    }

    // get all right menu item list based on page/type (type = 1 for forewords)
    const getRightMenuListData = async (masterId, sysProductId = "") => {
        const res = await getRightMenuList(accessToken, masterId, sysProductId);

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {

                if (res?.data?.entity !== null) {

                    if (res?.data?.entity?.length > 0) {
                        setAllRightListItems(res?.data?.entity)

                        if (itemAddedTrue == 'added') {
                            const addedItemIndex = res.data.entity.length - 1

                            if (addedItemIndex) {
                                fetchSelected(addedItemIndex, res.data.entity[addedItemIndex]?.id)
                                itemAddedTrue = ''
                            }
                        }
                    } else {
                        setAllRightListItems([])

                        setAllRightListItems([])
                        setTimestamp('')

                        fetchSelected(0, '');

                        setSelectItemDetails([])
                    }
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    // get details of selected item in right menu by passing item id
    const getRightItemDetails = async (id) => {
        const res = await getItemDetails(accessToken, id);

        setAllRightListItemsOrder(false)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    // set state to get details of current selected item which will display data in left side component
                    setSelectItemDetails(res.data.entity)
                    const selectedRight = allRightListItems?.find((p) => p.id == id)
                    setSelectedRightItem(selectedRight)

                    // set selected item id which will update context
                    setTimestamp(id)
                    gethardwareInUsedService(id);
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    // api call to update all selected item details
    const updateItemDetailsServices = async (data) => {
        setLoading(true)

        const res = await updateItemDetails(accessToken, timestamp ? timestamp : getFirstElId, data, frameCollection.id)

        if (res && res && res.status === 200) {
            // console.log(res, 'selected item info')

            if (res?.data?.statusCode === 200) {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Updated details');
                setMessageType('success')

                getRightItemDetails(timestamp)

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    // api call to added new right item
    const addNewRightItemServices = async (title, type) => {
        setLoading(true)
        itemAddedTrue = "added"

        const res = await addNewRightItem(accessToken, title, type)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Record created successfully!');
                setMessageType('success')

                if (frameCollection?.id === -1 && selectedSystemCollection?.id === -1) {
                    getRightMenuListData("", "")
                }
                else if (frameCollection?.id !== -1 && selectedSystemCollection?.id === -1) {
                    getRightMenuListData(frameCollection?.id, "")
                }
                else if (frameCollection?.id === -1 && selectedSystemCollection?.id !== -1) {
                    getRightMenuListData("", selectedSystemCollection?.id)
                }
                else {
                    getRightMenuListData(frameCollection?.id, selectedSystemCollection?.id)
                }
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }

    const handleFormReset = () => {
        if (timestamp) {
            if (frameCollection?.id === -1 && selectedSystemCollection?.id === -1) {
                getRightMenuListData("", "")
            }
            else if (frameCollection?.id !== -1 && selectedSystemCollection?.id === -1) {
                getRightMenuListData(frameCollection?.id, "")
            }
            else if (frameCollection?.id === -1 && selectedSystemCollection?.id !== -1) {
                getRightMenuListData("", selectedSystemCollection?.id)
            }
            else {
                getRightMenuListData(frameCollection?.id, selectedSystemCollection?.id)
            }
            getRightItemDetails(timestamp)
        }
    }
    const gethardwareInUsedService = async (id) => {
        setLoading(true)

        const res = await getHardwareInUsed(accessToken, frameCollection.id, id)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {

                if (res.data.entity.result !== null) {
                    setUsedProducts(res.data.entity.result)
                } else {
                    setUsedProducts([])
                }

                setLoading(false)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res, false, navigate)
        }
    }
    const removehardwareInUsedService = async (itemId, productType) => {

        const res = await removehardwareInUsed(accessToken, itemId, productType)

        if (res && res.status === 200) {
            debugger;
            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res?.data?.message : 'Invalid data');
                setMessageType('success')
                gethardwareInUsedService(timestamp)

            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
                gethardwareInUsedService(timestamp)
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            gethardwareInUsedService(timestamp)
            handleError(res, false, navigate)
        }
    }

    const handleItemSelect = (value) => {
        setFrameCollection(value)
    }

    const handleSystemSelect = (value) => {
        setSelectedSystemCollection(value)
    }

    const toggleTabDropdown = () => setHeaderDropdown((prevState) => !prevState);
    const toggleSecondDropdown = () => setSecondDropdown((prevState) => !prevState);


    return (
        <>
            {wip ? (
                <Wip title={`${pageName} in Progress`} />
            ) : (

                <>
                    {loading && (
                        <div className='loader_main full_loader'>
                            <Oval
                                height={50}
                                width={50}
                                color="#44C8F5"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#44C8F5"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </div>
                    )}

                    {/* <InnerLayout pageTitle={`${pageName !== '' ? pageName : 'Hardware Styles'}`} layoutType=''
                        leftContent={
                            <StylesLeft accessToken={accessToken} styleMasterId={styleMasterId} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} handleFormReset={handleFormReset} modelOptions={modelOptions} colourCollectionOptions={colourCollectionOptions} usedProducts={usedProducts} removehardwareInUsedService={removehardwareInUsedService} />
                        }
                        rightContent={
                            <StylesRight accessToken={accessToken} pageName={pageName} styleMasterId={styleMasterId} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getRightMenuListData={getRightMenuListData} addNewRightItemServices={addNewRightItemServices} usedProducts={usedProducts} />
                        }
                    /> */}

                    {mainDropdownList && mainDropdownList.length > 0 ? (
                        <InnerLayout pageTitle={''} layoutType=''
                            leftContent={
                                <StylesLeft accessToken={accessToken} styleMasterId={frameCollection.id} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} handleFormReset={handleFormReset} modelOptions={modelOptions} colourCollectionOptions={colourCollectionOptions} usedProducts={usedProducts} removehardwareInUsedService={removehardwareInUsedService} />
                            }
                            rightContent={
                                <StylesRight accessToken={accessToken} pageName={pageName} styleMasterId={frameCollection.id} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getRightMenuListData={getRightMenuListData} addNewRightItemServices={addNewRightItemServices} usedProducts={usedProducts} selectedSystemCollection={selectedSystemCollection} />
                            }
                            rightMaster={true}
                            filterButton={
                                <div className='d-flex gap-3'>
                                    <Dropdown
                                        isOpen={headerDropdown}
                                        toggle={toggleTabDropdown}
                                    >
                                        <DropdownToggle color="none" className="border-0 p-0">
                                            <div className="d-flex align-items-center">
                                                <span className="fw-semibold fs-5">
                                                    {frameCollection && frameCollection?.title}
                                                    <MdKeyboardArrowDown className="ms-1" size={20} />
                                                </span>
                                            </div>
                                        </DropdownToggle>

                                        <DropdownMenu className='full_height'>
                                            {mainDropdownList && mainDropdownList?.map((item, index) => {
                                                return (
                                                    <DropdownItem
                                                        onClick={() => handleItemSelect(item)}
                                                        key={index}
                                                        disabled={item?.id == frameCollection?.id}
                                                        className={`${item?.id == frameCollection?.id ? 'text-primary fw-medium' : ''}`}
                                                    >
                                                        {item?.title}
                                                    </DropdownItem>
                                                );
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                    {/* Second dropdown */}
                                    <Dropdown
                                        isOpen={secondDropdown}
                                        toggle={toggleSecondDropdown}
                                    >
                                        <DropdownToggle color="none" className="border-0 p-0">
                                            <div className="d-flex align-items-center">
                                                <span className="fw-semibold fs-5" style={titleWrapper}>
                                                    {selectedSystemCollection && selectedSystemCollection?.name}
                                                </span>
                                                <MdKeyboardArrowDown className="ms-1" size={20} />
                                            </div>
                                        </DropdownToggle>

                                        <DropdownMenu className='full_height'>
                                            {systemDropdownList && systemDropdownList?.map((item, index) => {
                                                return (
                                                    <DropdownItem
                                                        onClick={() => handleSystemSelect(item)}
                                                        key={index}
                                                        disabled={item?.id == selectedSystemCollection?.id}
                                                        className={`${item?.id == selectedSystemCollection?.id ? 'text-primary fw-medium' : ''}`}
                                                    >
                                                        {item?.name}
                                                    </DropdownItem>
                                                );
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            } />
                    ) : (
                        <NoDataCard text="hardware" loading={loading} />
                    )}
                </>
            )}
        </>
    )
}

export default Styles