import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { BiArrowBack, BiSolidEditAlt } from "react-icons/bi";
import "choices.js/public/assets/styles/choices.css";
import { Alert, Button, FormGroup, Input, Label, NavLink } from "reactstrap";
import { addUpdateAdditionalProfileFormula } from "../services/ManufacturingServices";
import { useMessage } from "../context/messageContext";
// import Choices from "choices.js";
import Multiselect from "multiselect-react-dropdown";
import { MdInfoOutline } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import ManufacturingEditComp from "./ManufacturingEditComp";
import SearchWithCheckboxes from "./Pricing/SearchWithCheckboxes";

const ManufacturingAdditionalProfile = ({
  additionalProfileList,
  isAdditionalProfileEdit,
  setIsAdditionalProfileEdit,
  setIsEdit,
  isEdit,
  setAdditionalAcc,
  referencePoint,
  frameStyleId,
  accessToken,
  currentItem,
  getData,
  additionalItems,
  updateStatusServices,
  allProfileLoading,
  getSashHangingData,
  collectionId,
  frameStyleProdId,
  setLoading,
  activeTab,
  refetchData,
  allTagData,
  accordionType,
}) => {
  const [heightFormula, setHeightFormula] = useState("");
  const [widthFormula, setWidthFormula] = useState("");
  const [articleId, setArticleId] = useState(null);
  const [activeProfiles, setActiveProfiles] = useState([]);
  const [currentAdditonalItem, setCurrentAdditionalItem] = useState("");
  const [edit, setEdit] = useState(false);

  const { setMessage, setMessageType } = useMessage();

  useEffect(() => {
    if (
      articleId &&
      additionalProfileList &&
      additionalProfileList?.length > 0
    ) {
      const data = additionalProfileList?.find((ele) => ele?.id === articleId);
      setWidthFormula(data?.widthFormula ? data?.widthFormula : "");
      setHeightFormula(data?.heightFormula ? data?.heightFormula : "");
    }
  }, [articleId, additionalProfileList]);

  useEffect(() => {
    if (additionalItems.length) {
      const activeStatus = additionalItems.filter(
        (item) => item.status === true
      );
      setActiveProfiles(activeStatus);
    }
  }, [additionalItems]);

  const handleHeightFormula = (e) => {
    const value = e.target.value;
    setHeightFormula(value);
  };

  const handleWidthFormula = (e) => {
    const value = e.target.value;
    setWidthFormula(value);
  };

  const saveFormula = async () => {
    const data = {
      id: 0,
      articleId: articleId,
      widthFormula: widthFormula,
      heightFormula: heightFormula,
      referencePoint: referencePoint,
      frameStyleId: frameStyleId,
      profileId: currentItem?.id,
    };
    const res = await addUpdateAdditionalProfileFormula(accessToken, data);
    if (res?.data?.statusCode === 200) {
      setMessageType("success");
      setMessage(res?.data?.message);
      getData(referencePoint);
    } else {
      setMessageType("error");
      setMessage(res?.data?.message);
    }
  };

  const onSelectItem = (selectedItem) => {
    const updatedItem = { ...selectedItem, status: true };
    updateStatusServices(updatedItem);
  };

  const onRemoveItem = (removedItem) => {
    const updatedItem = { ...removedItem, status: false };
    updateStatusServices(updatedItem);
  };

  return (
    <div className="add_add_profiles">
      <div className="add_add_profiles">
        {!isAdditionalProfileEdit && (
          <div className="mt-1 right_list">
            {/* Place the SearchWithCheckboxes component here, outside the loading check */}
            <div className="mb-3">
              {/* <Multiselect
                    displayValue="name"
                    hideSelectedList
                    onKeyPressFn={function noRefCheck() { }}
                    onRemove={function removeItem(selectedList, selectedItem) {
                      const updatedItem = { ...selectedItem, status: false }
                      additionalProfileStatusServices(updatedItem)
                    }}
                    onSearch={function noRefCheck() { }}
                    onSelect={function noRefCheck(selectedList, selectedItem) {
                      const updatedItem = { ...selectedItem, status: true }
                      additionalProfileStatusServices(updatedItem)
                    }}
                    options={additionalProfiles}
                    selectedValues={activeProfiles}
                    className="custom-multiselect"

                    showCheckbox
                    loading={allProfileLoading}
                  /> */}
              {!edit && (
                <SearchWithCheckboxes
                  list={additionalItems}
                  title={"name"}
                  subtitle={"productCode"}
                  activeValues={activeProfiles}
                  onSelect={onSelectItem}
                  onRemove={onRemoveItem}
                  searchKeys={["name", "productCode"]}
                  isCheckbox={true}
                  isSingleSelect={false}
                />
              )}
            </div>

            {!edit ? (
              allProfileLoading ? (
                <div className="d-flex justify-content-center">
                  <Oval
                    height={20}
                    width={20}
                    color="#44C8F5"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#44C8F5"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              ) : (
                <>
                  {activeProfiles && activeProfiles.length > 0 ? (
                    activeProfiles.map((item, index) => (
                      <div
                        key={index}
                        className="position-relative d-flex align-items-center right_item_wrap has_delete has_copy"
                      >
                        <NavLink className={`right_item w-100`}>
                          <span className={`me-2 image_icon`}>
                            {item.imagePath ? (
                              <Image src={item.imagePath} />
                            ) : (
                              <svg viewBox="0 0 100 50" width="50" height="50">
                                <path
                                  className="stroke"
                                  stroke="#989898"
                                  fill="#fff"
                                  strokeWidth="2"
                                  d={`M 0,50 0,0 100,0 100,50 Z`}
                                ></path>
                              </svg>
                            )}
                          </span>
                          <div className="right_title">
                            <span className={`text-truncate text-dark`}>
                              {item.name}
                            </span>
                          </div>
                          <span className="position-absolute action_btns">
                            <NavLink
                              onClick={() => {
                                setEdit(true);
                                setCurrentAdditionalItem(item);
                              }}
                            >
                              <BiSolidEditAlt
                                className="text-muted"
                                size={18}
                              />
                            </NavLink>
                          </span>
                        </NavLink>
                      </div>
                    ))
                  ) : (
                    <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                      <span>
                        <MdInfoOutline size={18} />
                      </span>
                      <span>
                        No additional{" "}
                        {accordionType == 1 ? "profiles" : "hardware"} to show
                      </span>
                    </Alert>
                  )}
                </>
              )
            ) : (
              <ManufacturingEditComp
                setIsEdit={setEdit}
                frameStyleProdId={frameStyleProdId}
                referencePoint={referencePoint}
                accessToken={accessToken}
                currentItem={currentAdditonalItem}
                setLoading={setLoading}
                getData={getData}
                getSashHangingData={getSashHangingData}
                additionalProfileList={""}
                collectionId={collectionId}
                activeTab={activeTab}
                isAdditionProfile={true}
                refetchData={refetchData}
                allTagData={allTagData}
              />
            )}
          </div>
        )}
      </div>

      {isAdditionalProfileEdit && (
        <div className="text-black">
          <NavLink
            onClick={() => {
              setIsAdditionalProfileEdit(false);
              setAdditionalAcc("0");
            }}
            className="text-link mb-3"
          >
            <BiArrowBack size={20} />
            <span className="ms-1">Back</span>
          </NavLink>

          <div className="text-dark">
            <div className="cutting_section">
              <h6 className="mb-2 text-muted">Cutting Formula</h6>

              {referencePoint?.data?.name?.includes("horizontal") ? (
                <FormGroup className="mb-3 d-flex align-items-center gap-2">
                  <Label for="width" className="mb-0 mini_text">
                    Length
                  </Label>
                  <Input
                    name="width"
                    onChange={handleWidthFormula}
                    value={widthFormula}
                    id="width"
                  />
                </FormGroup>
              ) : null}
              {referencePoint?.data?.name?.includes("vertical") ? (
                <FormGroup className="mb-3 d-flex align-items-center gap-2">
                  <Label for="height" className="mb-0 mini_text">
                    Length
                  </Label>
                  <Input
                    name="height"
                    onChange={handleHeightFormula}
                    id="height"
                    value={heightFormula}
                  />
                </FormGroup>
              ) : null}
            </div>
          </div>

          <div className="d-flex gap-2 gap-xl-3 main_buttons w-100 mt-3">
            <Button
              color="primary"
              size="md"
              className="px-4"
              onClick={() => {
                saveFormula();
              }}
            >
              Save
            </Button>

            <Button
              color="primary"
              size="md"
              outline
              className="px-4"
              onClick={() => {
                setIsEdit(false);
                setIsAdditionalProfileEdit(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManufacturingAdditionalProfile;
