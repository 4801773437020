import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { MdOutlineKeyboardArrowDown, MdOutlineModeEdit } from "react-icons/md";
import { MenuItem, OutlinedInput, Select } from "material-ui-core";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { MenuProps, noSelectOptions } from "../utility/data";
import { useRightContext } from "../context/rightMenuContext";
import { allAccessoriesCollection } from "../services/productHardwareServices";
import NumberInputDecimal from "./NumberInputDecimal";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { BiSort, BiTrashAlt } from "react-icons/bi";
import {
  deleteAccessories,
  getIndividualSashProductAccessories,
} from "../services/sysSashProductsService";
import { handleError } from "../utility/commonHelper";

const SystemAccessories = ({
  isEditable,
  navigate,
  setMessageType,
  setMessage,
  setLoading,
  getAllSashProductAccessoriesServices,
  allSashProductAccessories,
  activeTab,
  accessToken,
  selectItemDetails,
  updateItemDetailsServices,
  handleFormReset,
}) => {
  const { timestamp } = useRightContext();

  let { sashId } = useParams();

  const [accessoriesCollection, SetAccessoriesCollection] = useState([]);

  const [itemId, setItemId] = useState("");
  const [accessoriesId, setAccessoriesId] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [individualAccessoriesId, setIndividualAccessoriesId] = useState("");
  const [deleteIndividualAccessoriesId, setDeleteIndividualAccessoriesId] =
    useState("");
  const [showTableDeleteModal, setTableShowDeleteModal] = useState(false);
  const [individualAccessories, SetIndividualAccessories] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  useEffect(() => {
    if (activeTab === "Accessories") {
      getAccessoriesCollections();
    }
  }, [activeTab]);

  useEffect(() => {
    if (timestamp) {
      setIndividualAccessoriesId("");
      setItemId("");
      setAccessoriesId("");
      setQuantity("1");
    }
  }, [timestamp]);

  // setting all the value for prefilled inputs
  // useEffect(() => {

  //     setItemId(selectItemDetails?.id || '')

  //     setQuantity(selectItemDetails?.quantity || 0)
  // }, [selectItemDetails])

  // useEffect(() => {
  //     if (accessoriesCollection && accessoriesCollection.length > 0) {
  //         setAccessoriesId(selectItemDetails?.accessoriesId || accessoriesCollection[0]?.id)
  //     }
  // }, [selectItemDetails, accessoriesCollection])

  useEffect(() => {
    if (individualAccessoriesId) {
      getIndividualAccessoriesCollections(individualAccessoriesId);
    }
  }, [individualAccessoriesId]);

  useEffect(() => {
    if (individualAccessories) {
      setItemId(individualAccessories?.id);
      setAccessoriesId(individualAccessories?.accessoriesId);
      setQuantity(individualAccessories?.quantity);
    }
  }, [individualAccessories]);

  const getAccessoriesCollections = async () => {
    const res = await allAccessoriesCollection(accessToken);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity?.length > 0) {
            SetAccessoriesCollection(res?.data?.entity);
          } else {
            SetAccessoriesCollection(noSelectOptions);
          }
        }
      }
    }
  };

  const getIndividualAccessoriesCollections = async (id) => {
    const res = await getIndividualSashProductAccessories(accessToken, id);

    if (res && res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        if (res?.data?.entity !== null) {
          if (res?.data?.entity) {
            SetIndividualAccessories(res?.data?.entity);
          } else {
            SetIndividualAccessories("");
          }
        }
      }
    }
  };

  const handleFormSubmit = () => {
    var formDataValues = {
      id: itemId ? itemId : "",
      AccessoriesId: accessoriesId,
      Quantity: quantity,
      SashProductGeneralId: "",
    };

    updateItemDetailsServices(formDataValues, "Accessories");
    if (individualAccessoriesId) {
      setItemId("");
      setIndividualAccessoriesId("");
    }
  };

  const getAllAccessories = allSashProductAccessories?.map((item) => {
    return {
      accessoriesId: item?.accessoriesId,
      accessoriesDescription: item?.accessoriesDescription,
      quantity: item?.quantity,
      id: item?.id,
      sashProductGeneralId: item?.sashProductGeneralId,
    };
  });

  const columns = [
    {
      name: <span>Accessories Name</span>,
      selector: (row) => row.accessoriesDescription,
    },
    {
      name: <span>Quantity</span>,
      selector: (row) => row.quantity,
    },
    {
      name: <span>Action</span>,
      cell: (row) => (
        <div className="d-flex gap-2">
          <Link onClick={() => setIndividualAccessoriesId(row?.id)}>
            <MdOutlineModeEdit size={20} />
          </Link>

          <Link>
            <BiTrashAlt
              size={20}
              onClick={() => {
                setTableShowDeleteModal(true);
                setDeleteIndividualAccessoriesId(row);
              }}
            />
          </Link>
        </div>
      ),
    },
  ];

  const deleteAccessoriesServices = async (Id) => {
    setIsDeleting(true);
    setLoading(true);

    const res = await deleteAccessories(accessToken, Id);
    console.log(res, "178");
    if (res && res.status === 200) {
      setTableShowDeleteModal(false);
      if (res?.data?.statusCode === 200) {
        setMessage(
          res?.data.message ? res.data.message : "Something went wrong"
        );
        setMessageType("success");
        getAllSashProductAccessoriesServices(timestamp);
        setIsDeleting(false);
        setTimeout(() => {
          setLoading(false);
        }, 1200);
      } else {
        setLoading(false);
        setIsDeleting(false);
        setMessage(
          res?.data?.message ? res?.data?.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setLoading(false);
      setIsDeleting(false);
      setMessage(
        res?.data.message ? res?.data?.message : "Something went wrong"
      );
      setMessageType("error");

      setTableShowDeleteModal(false);
      handleError(res, false, navigate);
    }
  };

  return (
    <Form className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
      <div className="inside_scroll has_tab has_btns has_head">
        <Row>
          <Col xxl="12">
            <Row>
              <Col lg="6">
                <FormGroup className="form-group">
                  <Label for="collectionSelect">Accessories</Label>

                  <Select
                    labelId="collectionSelect"
                    id="collectionSelect"
                    value={accessoriesId || ""}
                    label="collectionSelect"
                    onChange={(e) => {
                      setAccessoriesId(e.target.value);
                    }}
                    input={
                      <OutlinedInput
                        className="w-100"
                        name="collectionSelect"
                      />
                    }
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    {accessoriesCollection &&
                      accessoriesCollection.map((o, index) => (
                        <MenuItem key={index} value={o?.id}>
                          {o.description}
                        </MenuItem>
                      ))}
                  </Select>
                  {!accessoriesId ? (
                    <span className="text-danger">
                      Select the required field
                    </span>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup className="form-group">
                  <Label for="Quantity">Quantity</Label>

                  <NumberInputDecimal
                    name="Quantity"
                    value={quantity}
                    setValue={setQuantity}
                  />
                  {!quantity ? (
                    <span className="text-danger">Field is required</span>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </Col>
            </Row>

            {isEditable && (
              <div className="d-flex gap-2 gap-xl-3 main_buttons mb-2">
                <Button
                  color="primary"
                  size="md"
                  className="px-4"
                  onClick={() => handleFormSubmit()}
                  disabled={!timestamp || !accessoriesId || !quantity}
                >
                  {!individualAccessoriesId ? "Add" : "Update"}
                </Button>
              </div>
            )}
          </Col>
        </Row>

        <div className="d-flex flex-column flex-grow-1 justify-content-between border border-1">
          <div className="profile_link">
            <DataTable
              columns={columns}
              data={getAllAccessories}
              className="quote_list clickable"
              sortIcon={<BiSort size={18} className="text-muted" />}
              // onRowClicked={handleItemClick}
              highlightOnHover
              // pointerOnHover
              pagination
              paginationPerPage={10}
              // paginationPerPage={window?.innerHeight <= 720 ? 8 : window?.innerWidth <= 1200 ? 10 : 12}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
            />
          </div>
        </div>

        <Modal
          isOpen={showTableDeleteModal}
          toggle={() => setTableShowDeleteModal(false)}
        >
          <ModalHeader>Delete Selected Item</ModalHeader>
          <ModalBody>
            Are you sure you want delete the{" "}
            <strong>
              {deleteIndividualAccessoriesId?.accessoriesDescription}
            </strong>{" "}
            ?
          </ModalBody>
          <ModalFooter>
            <Button
              color="outline-danger"
              size="md"
              disabled={isDeleting}
              onClick={() =>
                deleteAccessoriesServices(deleteIndividualAccessoriesId?.id)
              }
            >
              Delete
            </Button>
            <Button
              onClick={() => setTableShowDeleteModal(false)}
              color="primary"
              size="md"
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Form>
  );
};

export default SystemAccessories;
