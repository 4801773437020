import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { MdInfoOutline, MdOutlineKeyboardArrowDown, MdPersonOutline } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import {
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Button,
  FormGroup,
  Label,
  Input,
  Alert
} from 'reactstrap';

import { MenuProps, hardwareColor, modelData } from '../../utility/data';

import NumberInput from '../NumberInput';
import { useDesigner } from '../../context/designerContext';
import { getModelData, setModelData, setQuotationModelData } from '../../services/3dModelServices';
import { useMessage } from '../../context/messageContext';
import { FormControl, FormControlLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import { BiBorderRadius, BiTrash } from 'react-icons/bi';
import FrameStyle from './FrameStyle';
import { GrTopCorner } from "react-icons/gr";
import { TbBorderRadius, TbRadiusTopLeft } from "react-icons/tb";
import { AiOutlineRadiusSetting } from "react-icons/ai";
import OverideHeightAndWidthComp from '../OverideHeightAndWidthComp';
import { updateSashSize } from '../../utility/threeJsHelpers/uiLinesHelper';
import { Form } from 'react-bootstrap';
import { handleError } from '../../utility/commonHelper';

const LayoutSizing = ({ isThreshold, setIsThreshold, sashProductId, setSashProductId, headerSelectedItem, collectionDetails, frameStyleSection, setFrameStyleSection, selectedLayout, setSelectedAnimation, hardwareType, layoutSizing, setLayoutSizing, setLoading, frameStyleProdId, collectionId, quotationId, setHardwareType, receivedProductId, isInvalidDimensions, setIsInvalidDimensions, frameStyles }) => {
  const accessToken = localStorage.getItem('access_token')

  const [activeTab, setActiveTab] = useState("frames")
  const [sizeRadio, setSizeRadio] = useState("")
  const [isSave, setIsSave] = useState(false)
  const [isSwitchFrame, setIsSwitchFrame] = useState(false);
  const [overrideDetails, setOverrideDetails] = useState("")
  const [shapedFrameDisable, setShapedFrameDisable] = useState(false)
  const [hideDimentions, setHideDimentions] = useState(false)
  const [shapedHeightValidation, setShapedHeightValidation] = useState(false);
  const [shapedWidthValidation, setShapedWidthValidation] = useState(false);
  const [totalShapedWidth, setTotalShapedWidth] = useState("")
  const [totalShapedHeight, setTotalShapedHeight] = useState("")
  const [modelWidthStatic, setModelWidthStatic] = useState(2500)
  const [modelHeightStatic, setModelHeightStatic] = useState(2100)
  // const [shape, setShape] = useState("Corner")

  const heightRef = useRef(null);
  const widthRef = useRef(null);

  const { setModelHeight, setModelWidth, modelHeight, modelWidth, setShapedWidth, setShapedHeight, shapedWidth, shapedHeight, cancelChanges, saveHeightWidth, setSaveHeightWidth, depth, setDepth, customModelData, setCustomModelData, saveFrameCustomization, setSaveFrameCustomization, setTransomsOrientation, setDeleteRefPoints, setCancelChanges, sashHangingNo, appliedModel, setMultiSelectRefPoints, shapeFrame, setShapeFrame, checkClickedProfile, setUiLinesLocation, lockRefIndex, checkSingleSide, shapedFrameApplied, setShapedFrameApplied, modelJson, sashHanging, validWidths, validHeights } = useDesigner()
  const { setMessage, setMessageType } = useMessage()

  const navigate = useNavigate()

  const noRefCheck = (tab) => {
    setActiveTab(tab)
  }

  useEffect(() => {
    if (isSwitchFrame) {
      setHardwareType("Shaped Frame")
    } else {
      // setHardwareType('')
    }
  }, [isSwitchFrame])

  useEffect(() => {
    if (shapedFrameApplied) {
      setTimeout(() => {
        setShapedFrameApplied(false)
      }, 100);
    }
  }, [shapedFrameApplied])



  // useEffect(() => {
  //   if (customModelData?.shapedFrame && customModelData?.shapedFrame?.length) {
  //     const findCorner = customModelData?.shapedFrame?.find((item) => item?.index === checkSingleSide)
  //     if (findCorner) {
  //       setShapeFrame(findCorner?.cornerType)
  //     } else {
  //       setShapeFrame('Corner')
  //     }
  //   }
  // }, [checkSingleSide])



  useEffect(() => {
    if (cancelChanges) {
      setTimeout(() => {
        setCancelChanges(false)
      }, 200);
    }
  }, [cancelChanges])

  useEffect(() => {
    if (isThreshold) {
      setFrameStyleSection(true)
    }
  }, [isThreshold])

  useEffect(() => {
    if (
      customModelData &&
      customModelData?.addedFrames &&
      customModelData?.addedFrames?.length > 0

      // customModelData?.frame?.framesAndCoupler &&
      // customModelData?.addedFrames?.length > 0 ||
      // customModelData?.frame?.bayPost &&
      // customModelData?.frame?.bayPost?.length > 0

    ) {
      setOverrideDetails("frame");
    }
    // This is for showing the design related to baypost
    // if (
    //   customModelData &&
    //   customModelData?.frame &&
    //   customModelData?.frame?.bayPost &&
    //   customModelData?.frame?.bayPost?.length > 0
    // ) {
    //   setOverrideDetails("baypost");
    // }
    // if (
    //   customModelData &&
    //   customModelData?.frame &&
    //   customModelData?.frame?.bayPost &&
    //   customModelData?.frame?.bayPost?.length > 0 &&
    //   customModelData?.frame?.framesAndCoupler &&
    //   customModelData?.frame?.framesAndCoupler?.length > 0
    // ) {
    //   setOverrideDetails("frameAndBayPost");
    // }
  }, [customModelData?.frame]);

  const handleCancel = () => {
    setLayoutSizing(false)
    // setSaveHeightWidth(false)
    setHardwareType('')
    setTransomsOrientation('')
    setDeleteRefPoints([])
    setMultiSelectRefPoints([])
    setIsThreshold(false)
    setCancelChanges(true)

  }

  useEffect(() => {
    if (saveHeightWidth) {
      setTimeout(() => {
        setSaveHeightWidth(false)
      }, 100);
    }

    if (saveFrameCustomization) {
      setTimeout(() => {
        setSaveFrameCustomization(false)
      }, 100);

      // setCustomModelData(prevData => ({
      //   ...prevData,
      //   numberOfSash: {
      //     number: sashHangingNo.toString()
      //   },
      // }));
    }
  }, [saveHeightWidth, saveFrameCustomization])

  useEffect(() => {
    let saveDesignerTimer;
    let saveQuotationTimer;

    if (!quotationId && isSave) {
      saveDesignerTimer = setTimeout(() => {
        saveGlazingService(customModelData);
      }, 100);
      // setMultiSelectRefPoints([])
    } else if (quotationId && isSave) {
      saveQuotationTimer = setTimeout(() => {
        saveQuotationGlazingService(customModelData);
      }, 100);
      // setMultiSelectRefPoints([])
    }
    return () => {
      if (saveDesignerTimer) {
        clearTimeout(saveDesignerTimer)
      }
      if (saveQuotationTimer) {
        clearTimeout(saveQuotationTimer)
      }
    }
  }, [customModelData, isSave]);

  useEffect(() => {
    if (!modelHeight) {
      setModelHeight(customModelData?.layoutFrame?.height)
    }

    if (!modelWidth) {
      setModelWidth(customModelData?.layoutFrame?.width)
    }

    if (customModelData.hideDimentions) {
      setHideDimentions(true)
    } else {
      setHideDimentions(false)
    }

    setModelWidthStatic(customModelData?.layoutFrame?.width)
    setModelHeightStatic(customModelData?.layoutFrame?.height)

    // setShapedWidth(customModelData?.layoutFrame?.width)
    // setShapedHeight(customModelData?.layoutFrame?.height)
  }, [customModelData])

  useEffect(() => {
    if (customModelData?.shapedFrame && customModelData?.shapedFrame?.length > 0 && checkClickedProfile) {
      const data = customModelData?.shapedFrame?.find((item => item?.name === checkClickedProfile?.name))
      if (data) {
        // setShapedWidth(data?.shapeWidth);
        // setShapedHeight(data?.shapeHeight);
        setShapeFrame(data?.shape)
        setShapedWidth(1100);
        setShapedHeight(1050);
      }
      else {
        setShapedWidth(1100);
        setShapedHeight(950);
        // setShapeFrame("")
      }
    } else {
      setShapedWidth(1100);
      setShapedHeight(1050);
      setShapeFrame("Corner")
    }
  }, [customModelData?.shapedFrame, checkClickedProfile]);

  useEffect(() => {
    if (
      modelWidthStatic < frameStyleProdId?.minimumWidth ||
      modelWidthStatic > frameStyleProdId?.maximumWidth ||
      modelHeightStatic < frameStyleProdId?.minimumHeight ||
      modelHeightStatic > frameStyleProdId?.maximumHeight ||
      // modelHeightStatic > shapedHeight ||
      // modelWidthStatic > shapedWidth ||
      // shapedWidth === "" ||
      // shapedHeight === "" ||
      modelWidthStatic === "" ||
      modelHeightStatic === ""
    ) {
      setIsInvalidDimensions(true);
    }
    else {
      setIsInvalidDimensions(false)
    }
  }, [modelHeightStatic, modelWidthStatic]);


  useEffect(() => {
    if (isSwitchFrame) {
      if (
        shapedHeight > modelHeight ||
        shapedWidth > modelWidth
      ) {
        setShapedFrameDisable(true);
      } else if (
        !parseFloat(shapedHeight) ||
        !parseFloat(shapedWidth)
      ) {
        setShapedFrameDisable(true)
      }
      else {
        setShapedFrameDisable(false)
      }
    }
  }, [shapedHeight, shapedWidth]);

  // This will get the saved height and width value from the saved data
  useEffect(() => {
    if (
      checkClickedProfile &&
      customModelData?.shapedCornerData &&
      customModelData?.shapedCornerData?.length > 0
    ) {
      let item = customModelData?.shapedCornerData?.find(
        (item) => item?.cornerName === checkClickedProfile?.name
      );
      if (item) {
        setShapeFrame(item?.name);
        setShapedHeight(item?.height);
        setShapedWidth(item?.width);
      }
    }
  }, [checkClickedProfile]);

  // This is for validating shaped height and width
  useEffect(() => {
    setTimeout(() => {
      if (shapedWidth && shapedHeight && checkClickedProfile) {
        const { allowedHeight, allowedWidth } =
          validateShapedCornerHeightAndWidt(
            customModelData?.shapedCornerData,
            checkClickedProfile?.name,
            shapedWidth,
            shapedHeight
          );
        setTotalShapedHeight(allowedHeight);
        setTotalShapedWidth(allowedWidth);
        if (allowedHeight > modelHeight) {
          setShapedHeightValidation(true);
        } else {
          setShapedHeightValidation(false);
        }
        if (allowedWidth > modelWidth) {
          setShapedWidthValidation(true);
        } else {
          setShapedWidthValidation(false);
        }
      }
    }, 300);
  }, [
    shapedWidth,
    shapedHeight,
    checkClickedProfile,
    customModelData?.shapedCornerData,
  ]);


  const handleSave = () => {
    if (frameStyleSection) {
      if (hardwareType.includes("sash")) {
        setSaveFrameCustomization(true)
      }
    } else {
      setSaveHeightWidth(true)
    }

    storeFrameData()
    setFrameStyleSection(false)

    if (appliedModel) {
      setCustomModelData(prevData => ({
        ...prevData,
        appliedModel: appliedModel,
      }));
    }

  }

  // THis is for emptying the sashData keep
  // useEffect(() => {
  //   if (parseInt(sashHangingNo) && isSave) {
  //     setCustomModelData((prevModelData) => ({
  //       ...prevModelData,
  //       sashData: []
  //     }));
  //   }
  // }, [sashHangingNo, isSave])


  const storeFrameData = () => {

    let newSashSize = []

    if (customModelData && customModelData?.numberOfSash?.number > 0) {
      newSashSize = updateSashSize(customModelData?.sashSize, customModelData?.layoutFrame?.width, modelWidth, customModelData?.numberOfSash?.number ? customModelData?.numberOfSash?.number : 1, lockRefIndex)
    } else {
      newSashSize = [modelWidth]
    }

    setCustomModelData((prevModelData) => ({
      ...prevModelData,
      layoutFrame: {
        ...prevModelData.layoutFrame,
        width: parseFloat(modelWidth),
        height: parseFloat(modelHeight),
      },
      sashSize: newSashSize,
      sashHanging: sashHanging
    }));

    setIsSave(true)
  }

  const saveQuotationGlazingService = async (data) => {
    setLoading(true)
    const res = await setQuotationModelData(accessToken, collectionId, frameStyleProdId?.id, data, quotationId, receivedProductId, modelJson)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false)
        setMessage(res?.data?.message);
        setMessageType('success')
        setLayoutSizing(false)
      }
    } else {
      setLoading(false)
      setMessageType("error")
      setMessage(res?.data?.message ? res?.data?.message : "Something went wrong !")
      handleError(res, false, navigate)
    }
  }

  const saveGlazingService = async (data) => {
    setLoading(true)
    const res = await setModelData(accessToken, collectionId, frameStyleProdId?.id, data, modelJson)
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false)
        setMessage(res?.data?.message);
        setMessageType('success')
        setLayoutSizing(false)
        setIsSave(false);
      } else {
        setLayoutSizing(false)
        setLoading(false)
        setMessage(res?.data?.message);
        setMessageType('error')
        setIsSave(false);
      }
    } else {
      setLayoutSizing(false)
      setLoading(false)
      setMessageType("error")
      setMessage(res?.data?.message ? res?.data?.message : "Something went wrong !")
      handleError(res, false, navigate)
    }
  }

  const handleClick = (reference) => {
    if (reference?.current) {
      reference?.current?.select()
    }
  }

  const handleSaveWithEnter = (e) => {
    if (e?.key === "Enter" && !isInvalidDimensions) {
      handleSave()
    }
  }

  const handleHideDimentions = (e) => {
    const value = e.target.checked;

    setHideDimentions(value)

    setCustomModelData(prevData => ({
      ...prevData,
      hideDimentions: value,
    }));
  }

  // This is for getting the total heigh and width of the selected shaped corner
  const validateShapedCornerHeightAndWidt = (
    data,
    shapedCorner,
    width,
    height
  ) => {
    const cornerMap = {
      topLeft: { heightKey: "bottomLeft", widthKey: "topRight" },
      topRight: { heightKey: "bottomRight", widthKey: "topLeft" },
      bottomLeft: { heightKey: "topLeft", widthKey: "bottomRight" },
      bottomRight: { heightKey: "topRight", widthKey: "bottomLeft" },
    };

    const { heightKey, widthKey } = cornerMap[shapedCorner] || {};

    let item = data?.find((item) => item?.cornerName === shapedCorner);
    let allowedHeight = height;
    let allowedWidth = width;

    if (item) {
      allowedHeight = item?.height;
      allowedWidth = item?.width;
    }

    if (heightKey) {
      const heightItem = data?.find((item) => item?.cornerName === heightKey);
      if (heightItem) {
        allowedHeight += heightItem?.height;
      }
    }

    if (widthKey) {
      const widthItem = data?.find((item) => item?.cornerName === widthKey);
      if (widthItem) {
        allowedWidth += widthItem?.width;
      }
    }

    return {
      allowedHeight,
      allowedWidth,
    };
  };

  const getValidationShapedHeightWidth = () => {
    return (
      isSwitchFrame &&
      (shapedHeightValidation || shapedWidthValidation)
    );
  };

  const handleFrameWidth = (e) => {
    if (e?.target?.value > 0 && ((modelWidthStatic >= validWidths?.min) && (modelWidthStatic <= validWidths?.max))) {
      setModelWidth(e?.target?.value)
    }
  };

  const handleFrameHeight = (e) => {
    if (e?.target?.value > 0 && ((modelHeightStatic >= validHeights?.min) && (modelHeightStatic <= validHeights?.max))) {
      setModelHeight(e?.target?.value)
    }
  };

  return (
    <>
      {!frameStyleSection ? (
        <div className="d-flex flex-column h-100">
          <button
            type="button"
            className={`accordion_btn_custom accordion-button`}
            onClick={() => {
              setLayoutSizing(false);
              setFrameStyleSection(false)
            }}
          >
            Size
          </button>

          {/* <div className="d-flex align-items-center mb-1 mt-1 left_header">
            <h5 className="mb-0 text-wrap text-break me-4 main_title">
              Layout and Sizing
            </h5>
          </div> */}

          {/* <Nav tabs className="details_header border-0 nav-tabs-custom bg-none">
            <NavItem>
              <NavLink
                className={`${activeTab === "frames" && "active"}`}
                onClick={() => noRefCheck("frames")}
              >
                Fames
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "walls" && "active"}`}
                onClick={() => noRefCheck("walls")}
              >
                Walls
              </NavLink>
            </NavItem>
          </Nav> */}

          <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
            <TabPane tabId="frames" className="w-100">
              {overrideDetails === "" ? <div className="d-flex gap-2">
                <FormGroup className="mb-1">
                  <Label for="width" className="text-dark">
                    Width
                  </Label>
                  <Input
                    type='number'
                    value={modelWidthStatic}
                    name='width'
                    onKeyDown={(e) => handleSaveWithEnter(e)}
                    onChange={(e) => { setModelWidthStatic(e?.target?.value); setModelWidth(e?.target?.value) }}
                    // onBlur={(e) => handleFrameWidth(e)}
                    innerRef={widthRef}
                    onClick={() => handleClick(widthRef)}
                  >
                  </Input>
                  {(parseFloat(modelWidthStatic) < frameStyleProdId?.minimumWidth) || modelWidthStatic === "" ? <span className='text-danger text-small'>{`Min value ${frameStyleProdId?.minimumWidth}`}</span> : null}
                  {(parseFloat(modelWidthStatic) > frameStyleProdId?.maximumWidth) ? <span className='text-danger text-small'>{`Max value ${frameStyleProdId?.maximumWidth}`}</span> : null}
                </FormGroup>

                <FormGroup className="mb-1">
                  <Label for="height" className="text-dark">
                    Height
                  </Label>
                  <Input
                    onKeyDown={(e) => handleSaveWithEnter(e)}
                    innerRef={heightRef}
                    type='number'
                    name='height'
                    value={modelHeightStatic}
                    onChange={(e) => { setModelHeightStatic(e?.target?.value); setModelHeight(e?.target?.value)}}
                    // onBlur={(e) => handleFrameHeight(e)}
                    onClick={() => handleClick(heightRef)}
                  >
                  </Input>
                  {(parseFloat(modelHeightStatic) < frameStyleProdId?.minimumHeight) || modelHeightStatic === "" ? <span className='text-danger text-small'>{`Min value ${frameStyleProdId?.minimumHeight}`}</span> : null
                  }
                  {parseFloat(modelHeightStatic) > frameStyleProdId?.maximumHeight ? <span className='text-danger text-small'>{`Max value ${frameStyleProdId?.maximumHeight}`}</span> : null}
                </FormGroup>
              </div> : <OverideHeightAndWidthComp design={overrideDetails}></OverideHeightAndWidthComp>
              }

              <FormGroup className="form-group d-flex gap-3 align-items-center">
                <Label for="hideDimentions" className='text-dark form-label mb-0'>Hide dimensions</Label>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="hideDimentions"
                  className="form-switch-lg default_switch"
                  onChange={(e) => handleHideDimentions(e)}
                  label={hideDimentions ? "On" : "Off"}
                  checked={hideDimentions}
                />
              </FormGroup>

              <div style={{
                pointerEvents: isInvalidDimensions ? 'none' : 'auto',
                opacity: isInvalidDimensions ? 0.5 : 1,
              }}
                aria-disabled={isInvalidDimensions}>

                {/* <FormGroup className="form-group">
                  <Label for="depth" className="text-dark">
                    Depth
                  </Label>
                  <NumberInput name="depth" value={depth} setValue={setDepth} />
                </FormGroup> */}

                <Button
                  color="primary"
                  size="md"
                  outline
                  className="px-4 w-100 mb-3"
                  onClick={() => {
                    setIsSwitchFrame(!isSwitchFrame)

                  }}
                >
                  {!isSwitchFrame
                    ? "Switch to shaped frame"
                    : "Cancel shaped frame"}
                </Button>

                {isSwitchFrame && (
                  <>
                    {checkClickedProfile ? (
                      <div className="mb-2 mt-2 text-dark border p-2 br-8">
                        <FormControl className="form-group">
                          <Label for="shape">Shape</Label>
                          <RadioGroup
                            aria-labelledby="shape-label"
                            name="shape"
                            className="flex-row gap-2 justify-content-evenly"
                            value={shapeFrame}
                            onChange={(e) => {
                              setShapeFrame(e.target.value)
                            }}
                          >
                            <FormControlLabel
                              className="page_type_radio_2"
                              value="Corner"
                              control={<Radio />}
                              label={
                                <div>
                                  <span>
                                    <GrTopCorner className="no_svg" />
                                  </span>
                                  <span className="text-center">Corner</span>
                                </div>
                              }
                            />

                            <FormControlLabel
                              className="page_type_radio_2"
                              value="Chamfer"
                              control={<Radio />}
                              label={
                                <div>
                                  <span>
                                    <AiOutlineRadiusSetting className="no_svg" />
                                  </span>
                                  <span className="text-center">Chamfer</span>
                                </div>
                              }
                            />
                            <FormControlLabel
                              className="page_type_radio_2"
                              value="Radius"
                              control={<Radio />}
                              label={
                                <div>
                                  <span>
                                    <TbBorderRadius className="no_svg" />
                                  </span>
                                  <span className="text-center">Radius</span>
                                </div>
                              }
                            />
                            <FormControlLabel
                              className="page_type_radio_2"
                              value="Ellipse"
                              control={<Radio />}
                              label={
                                <div>
                                  <span>
                                    <TbRadiusTopLeft className="no_svg" />
                                  </span>
                                  <span className="text-center">Ellipse</span>
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                        <div className="d-flex w-100 gap-2">
                          <FormGroup className="mb-0">
                            <Label for="shapeWidth" className="text-dark">
                              Width
                            </Label>
                            <NumberInput
                              name="shapeWidth"
                              disabled={shapeFrame === "Corner"}
                              id="shapeWidth"
                              value={shapedWidth}
                              setValue={setShapedWidth}
                            />
                            {(parseFloat(shapedWidth)) > (parseFloat(modelWidth)) ? <span className='text-danger text-small'>{`Max value ${modelWidth}`}</span> : null}
                            {(parseFloat(totalShapedWidth)) > (parseFloat(modelWidth)) ? <span className='text-danger text-small'>{`Your Value ${totalShapedWidth} `}{`Max value ${modelWidth}`}</span> : null}
                          </FormGroup>
                          <FormGroup className="mb-0">
                            <Label for="shapeHeight" className="text-dark">
                              Height
                            </Label>
                            <NumberInput
                              name="shapeHeight"
                              disabled={shapeFrame === "Corner"}
                              id="shapeHeight"
                              value={shapedHeight}
                              setValue={setShapedHeight}
                            />
                            {(parseFloat(shapedHeight)) > (parseFloat(modelHeight)) ? <span className='text-danger text-small'>{`Max value ${modelHeight}`}</span> : null}
                            {(parseFloat(totalShapedHeight)) > (parseFloat(modelHeight)) ? <span className='text-danger text-small'>{`Your Value ${totalShapedHeight} `}{`Max value ${modelHeight}`}</span> : null}
                          </FormGroup>
                        </div>
                      </div>
                    ) : (
                      <Alert color="secondary" className="d-flex gap-2 p-2 my-2 mb-4 mt-2">
                        <span>
                          <MdInfoOutline size={18} />
                        </span>
                        <span>Select a corner to edit the shape.</span>
                      </Alert>
                    )}
                  </>
                )}

                {/* <div>
                  <Button
                    color="secondary"
                    size="md"
                    outline
                    className="px-4 w-100"
                    onClick={() => { setFrameStyleSection(!frameStyleSection); setUiLinesLocation('All') }}
                  >
                    Frame Style
                  </Button>
                </div> */}
              </div>

            </TabPane>

            <TabPane tabId="walls" className="w-100">
              <>
                <FormGroup className="form-group">
                  <Label for="depth" className="text-dark">
                    Sizes
                  </Label>
                  <FormControl className="mb-2 w-100">
                    <RadioGroup
                      aria-labelledby="customerInfoSelection-label"
                      name="customerInfoSelection"
                      className=""
                      value={sizeRadio}
                      onChange={(e) => setSizeRadio(e.target.value)}
                    >
                      <FormControlLabel
                        value="internal"
                        control={<Radio />}
                        label="Internal"
                      />
                      <FormControlLabel
                        className=""
                        value="external"
                        control={<Radio />}
                        label="External"
                      />
                    </RadioGroup>
                  </FormControl>
                </FormGroup>
                <FormGroup className="form-group">
                  <Label for="externalThick" className="text-dark">
                    External thickness
                  </Label>
                  <NumberInput
                    name="externalThick"
                    value={0}
                    setValue={() => { }}
                  />
                </FormGroup>
                <FormGroup className="form-group">
                  <Label for="internalThick" className="text-dark">
                    Internal thickness
                  </Label>
                  <NumberInput
                    name="internalThick"
                    value={0}
                    setValue={() => { }}
                  />
                </FormGroup>
                <FormGroup className="form-group">
                  <Label for="wallDepth" className="text-dark">
                    Depth
                  </Label>
                  <NumberInput name="wallDepth" value={0} setValue={() => { }} />
                </FormGroup>
                <FormGroup className="form-group">
                  <Label for="externalFinish">External finish</Label>
                  <Select
                    labelId="printdoc-label"
                    id="externalFinish"
                    value={"Red Brick"}
                    label="externalFinish"
                    onChange={() => { }}
                    input={
                      <OutlinedInput className="w-100" name="externalFinish" />
                    }
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    <MenuItem value={"Red Brick"}>Red Brick</MenuItem>
                  </Select>
                </FormGroup>
                <FormGroup className="form-group">
                  <Label for="internalFinish">Internal finish</Label>
                  <Select
                    labelId="printdoc-label"
                    id="internalFinish"
                    value={"Paint Matte"}
                    label="internalFinish"
                    onChange={() => { }}
                    input={
                      <OutlinedInput className="w-100" name="internalFinish" />
                    }
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    <MenuItem value={"Paint Matte"}>Paint Matte</MenuItem>
                  </Select>
                  <Select
                    className="mt-3 w-100"
                    labelId="printdoc-label"
                    id="paint"
                    value={"9002-Grey white"}
                    label="paint"
                    onChange={() => { }}
                    input={<OutlinedInput className="w-100" name="paint" />}
                    MenuProps={MenuProps}
                    IconComponent={() => (
                      <MdOutlineKeyboardArrowDown
                        size={20}
                        className="me-1 MuiSvgIcon-root MuiSelect-icon"
                      />
                    )}
                  >
                    <MenuItem value={"9002-Grey white"}>
                      9002-Grey white
                    </MenuItem>
                  </Select>
                </FormGroup>
                <Button
                  color="light"
                  size="md"
                  className="px-4 w-100 d-flex justify-content-between fw-medium mb-4 mt-5"
                >
                  <BiTrash size={20} />
                  Delete
                  <span></span>
                </Button>
                <FormGroup className="form-group">
                  <Label for="rotatingAngle" className="text-dark">
                    Rotating angle
                  </Label>
                  <NumberInput
                    name="rotatingAngle"
                    value={0}
                    setValue={() => { }}
                  />
                  <NavLink className="text-primary" to={{}}>
                    Align start and end
                  </NavLink>
                </FormGroup>
              </>
            </TabPane>
          </TabContent>
        </div>
      ) : (
        <div className='frame_style_scroll'>
          <FrameStyle
            isSave={isSave}
            setIsSave={setIsSave}
            setLoading={setLoading}
            quotationId={quotationId}
            frameStyleProdId={frameStyleProdId}
            receivedProductId={receivedProductId}
            setSelectedAnimation={setSelectedAnimation}
            collectionId={collectionId}
            setHardwareType={setHardwareType}
            accessToken={accessToken}
            collectionDetails={collectionDetails}
            headerSelectedItem={headerSelectedItem}
            sashProductId={sashProductId}
            setSashProductId={setSashProductId}
            isThreshold={isThreshold}
            frameStyles={frameStyles}
            setFrameStyleSection={setFrameStyleSection}
            layoutSizing={layoutSizing}
            setLayoutSizing={setLayoutSizing}
          />
        </div>
      )}

      <div className="d-flex gap-2 gap-xl-3 main_buttons mt-4 w-100">
        <Button
          color="primary"
          size="md"
          className="px-4"
          disabled={isInvalidDimensions || shapedFrameDisable || getValidationShapedHeightWidth()}
          onClick={() => handleSave()}
        >
          Save
        </Button>

        <Button
          color="primary"
          size="md"
          outline
          className="px-4"
          onClick={() => {
            // setFrameStyleSection(!frameStyleSection);
            setFrameStyleSection(false);
            // setUiLinesLocation('');
            handleCancel();
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}

export default LayoutSizing