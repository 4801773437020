import React, { useEffect } from 'react'
import { useState } from 'react';
import { MdOutlineSearch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    Button,
    FormGroup,
    Label,
    Input,
    InputGroup
} from 'reactstrap';
import { colorList } from '../../services/productServices';
import { useDesigner } from '../../context/designerContext';
import { setModelData } from '../../services/3dModelServices';
import { useMessage } from '../../context/messageContext';
import { rgbStringToHex } from '../../utility/helper';
import { BiArrowBack } from 'react-icons/bi';
import { Image } from 'react-bootstrap';
import { saveModelDataQuotation, storeExtColor } from '../../utility/designerHelper';

const FrameColor = ({ frameData, setFrameData, modelId, frameStyleProdId, setLoading, quotationId, receivedProductId }) => {

    const accessToken = localStorage.getItem('access_token')

    const [showAllExtColors, setShowAllExtColors] = useState(false)
    const [showAllIntColors, setShowAllIntColors] = useState(true)
    const [activeTab, setActiveTab] = useState("external")
    const [colorListDrop, setColorListDrop] = useState([])

    const [isSaving, setIsSaving] = useState(false)
    const { setMessage, setMessageType } = useMessage()

    const [searchedColor, setSearchedColor] = useState("");
    const [internalSearchedColor, setInternalSearchedColor] = useState("")

    const [unchangableList, setUnchangableList] = useState([])

    const {
        setColorActiveTab,
        internalColor,
        externalColor,
        handleInternalColor,
        handleExternalColor,
        handleInternalRAL,
        handleExternalRAL,
        customModelData,
        modelJson,
        setCustomModelData,
        externalRAL,
        internalRAL,
        visibleExternalColors,
        visibleInternalColors,
    } = useDesigner()

    const navigate = useNavigate()

    useEffect(() => {
        colorListServices()
    }, [])

    useEffect(() => {
        if (isSaving) {
            if (quotationId) {
                saveModelDataQuotation(accessToken, modelId, frameStyleProdId?.id, customModelData, quotationId, receivedProductId, navigate, setMessage, setMessageType,modelJson)

                setTimeout(() => {
                    setFrameData()

                    setColorActiveTab('external')
                }, 100);
            } else {
                saveGlazingService()
            }

            setTimeout(() => {
                setIsSaving(false)
            }, 2000);
        }
    }, [isSaving])

    const noRefCheck = (tab) => {
        setActiveTab(tab)
    }

    useEffect(() => {

        if (frameData?.type === 'internal') {
            setActiveTab('internal')
        } else {
            setActiveTab('external')
        }
    }, [frameData])

    useEffect(() => {

        if (activeTab === 'internal') {
            setActiveTab('internal')

            if (internalColor?.name === 'Custom RAL' && externalColor?.name === 'Custom RAL') {
                setShowAllIntColors(false)
            } else {
                setShowAllIntColors(true)
            }

            setColorActiveTab('internal')
        }

        if (activeTab === 'external') {
            if (externalColor?.name === 'Custom RAL') {
                setShowAllExtColors(false)
                setShowAllIntColors(true)
            } else {
                setShowAllExtColors(true)
                setShowAllIntColors(false)
            }

            setColorActiveTab('external')
        }
    }, [activeTab, externalColor, internalColor])

    const customRALBackExt = () => {
        setShowAllExtColors(true)
    }

    const customRALBackInt = () => {
        setShowAllIntColors(true)
    }

    const cancel = () => {
        setFrameData()

        setColorActiveTab('external')
    }

    const colorListServices = async () => {
        const res = await colorList(accessToken)
        setColorListDrop(res?.data?.entity)
        setUnchangableList(res?.data?.entity);
    }

    const handleExtClick = (item) => {
        if (item && item.name === 'Custom RAL') {
            setShowAllExtColors(false)
        } else {
            setShowAllExtColors(true)
        }

        setShowAllIntColors(true)
    }

    const handleIntClick = (item) => {
        if (item && item.name === 'Custom RAL') {
            setShowAllIntColors(false)
        } else {
            setShowAllIntColors(true)
        }
    }

    const save = () => {
        storeExtColor(externalColor, externalRAL, internalColor, internalRAL, rgbStringToHex, setCustomModelData, setIsSaving)
        setActiveTab('external')
    }

    const saveGlazingService = async () => {
        setLoading(true)
        const res = await setModelData(accessToken, modelId, frameStyleProdId?.id, customModelData, modelJson)
        
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('success')
                cancel()
            } else {
                setLoading(false)
                setMessage(res?.data?.message);
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessageType("error")
            setMessage(res?.data?.message ? res?.data?.message : "Something went wrong !")
            if (res?.response?.status === 401) {
                navigate('/unauthorized')
                localStorage.removeItem("access_token")
            }
            if (res?.response?.status == 404) {
                navigate('/error/503')
            }
            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const searchColor = (setList, list, color, setColor) => {
        setColor(color);
        if (list?.length > 0) {
            let searchText = color.toLowerCase();
            const new_list = unchangableList.filter((obj) => {
                const nameMatch = obj.name.toLowerCase().includes(searchText);
                const rgbMatch = obj.rgbValue.includes(searchText);
                const numMatch = obj.number.includes(searchText);
                return nameMatch || rgbMatch || numMatch;
            });
            setList(new_list);
        }
        if (color.length === 0) {
            setList(unchangableList);
        }
    };

    return (
        <div className='d-flex flex-column h-100'>
            <Nav tabs className="details_header border-0 nav-tabs-custom bg-none">
                <NavItem>
                    <NavLink
                        className={`${activeTab === "external" && 'active'}`}
                        onClick={() => {
                            noRefCheck("external")
                            setColorListDrop(unchangableList)
                            setSearchedColor("")
                        }
                        }
                    >
                        External
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === "internal" && 'active'}`}
                        onClick={() => {
                            noRefCheck("internal")
                            setColorListDrop(unchangableList)
                            setInternalSearchedColor("")
                        }}
                    >
                        Internal
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} className='flex-grow-1 d-flex'>
                <TabPane tabId="external" className='w-100'>
                    <FormGroup className="form-group mb-3" check>
                        <Input
                            type="checkbox"
                            name="nameCheck"
                            id="radioComments1"
                        />
                        <Label check for="radioComments1" className='text-dark'>Apply to existing frames</Label>
                    </FormGroup>

                    {!showAllExtColors && (
                        <NavLink to={{}} className='text-primary d-flex align-items-center mb-2' onClick={() => customRALBackExt()}>
                            <BiArrowBack size={20} />
                            <h5 className='text-dark ms-2 mb-0'>Custom RAL</h5>
                        </NavLink>
                    )}

                    {!showAllExtColors && <div className="d-flex mt-2 mb-2 pe-3">
                        <InputGroup className="border rounded">
                            <Input
                                type="text"
                                placeholder="Search colour"
                                className="search-input border-0"
                                value={searchedColor}
                                onChange={(e) => searchColor(setColorListDrop, colorListDrop, e.target.value, setSearchedColor)}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text bg-transparent border-0 px-2">
                                    <MdOutlineSearch size={16} />
                                </span>
                            </div>
                        </InputGroup>
                    </div>}

                    <div className='panel_object_list color_menu'>

                        {showAllExtColors && visibleExternalColors && visibleExternalColors.length > 0 ? (
                            <>
                                {visibleExternalColors.map((item, index) => {
                                    return (
                                        <div className='position-relative d-flex align-items-center right_item_wrap' key={index} onClick={() => (handleExtClick(item), handleExternalColor(item, index))}>
                                            <div className={`right_item w-100 ${externalColor?.id == item.id && "active"}`} >
                                                <span className="me-2 image_icon ball_shape ball_shadow">
                                                    <Image src={item?.capturedImage} alt="ball-image.jpeg" className='rounded-circle'></Image>
                                                </span>
                                                <div className="right_title">
                                                    <span className="text-truncate">
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        ) : (
                            <>
                                {showAllExtColors && (
                                    <div className='py-3 d-flex justify-content-center'>
                                        <p className='text-muted mb-0 fs-5'>No colors configured</p>
                                    </div>
                                )}
                            </>
                        )}

                        {!showAllExtColors && colorListDrop.map((item, index) => {
                            return (
                                <div
                                    className="position-relative d-flex align-items-center right_item_wrap"
                                    key={index}
                                    onClick={() => handleExternalRAL(item, index)}
                                >
                                    <NavLink
                                        to={{}}
                                        className={`right_item w-100 ${externalRAL?.id == item.id && "active"}`}>
                                        <span className="me-2">
                                            <span
                                                className="me-2 small_svg_set modal_select_hide image_icon p-2 color_circle dot_lg"
                                                style={{
                                                    backgroundColor: `${item.rgbValue}`,
                                                    borderRadius: "50%",
                                                    width: "fit-content",
                                                }}
                                            ></span>
                                        </span>
                                        <div className="right_title">
                                            <span className="text-truncate">
                                                {item.name + " " + item.number}
                                            </span>
                                        </div>
                                    </NavLink>
                                </div>
                            );
                        })}
                    </div>
                </TabPane>

                <TabPane tabId="internal" className='flex-grow-1 w-100'>
                    <FormGroup className="form-group" check>
                        <Input
                            type="checkbox"
                            name="nameCheck"
                            id="radioComments1"

                        />
                        <Label check for="radioComments1" className='text-dark'>Apply to existing frames</Label>
                    </FormGroup>

                    {!showAllIntColors && (
                        <NavLink to={{}} className='text-primary d-flex align-items-center mb-2' onClick={() => customRALBackInt()}>
                            <BiArrowBack size={20} />
                            <h5 className='text-dark ms-2 mb-0'>Custom RAL</h5>
                        </NavLink>
                    )}

                    {!showAllIntColors && <div className="d-flex mt-2 mb-2 pe-3">
                        <InputGroup className="border rounded">
                            <Input
                                type="text"
                                placeholder="Search colour"
                                className="search-input border-0"
                                value={internalSearchedColor}
                                onChange={(e) => searchColor(setColorListDrop, colorListDrop, e.target.value, setInternalSearchedColor)}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text bg-transparent border-0 px-2">
                                    <MdOutlineSearch size={16} />
                                </span>
                            </div>
                        </InputGroup>
                    </div>}

                    <div className='panel_object_list color_menu'>

                        {showAllIntColors && visibleInternalColors && visibleInternalColors.length > 0 ? (
                            <>
                                {visibleInternalColors.map((item, index) => {
                                    return (
                                        <div className='position-relative d-flex align-items-center right_item_wrap' key={index} onClick={() => (handleIntClick(item), handleInternalColor(item, index))}>
                                            <div className={`right_item w-100 ${internalColor?.id == item.id && "active"}`} >
                                                <span className="me-2 image_icon ball_shape ball_shadow">
                                                    <Image src={item?.capturedImage} alt="ball-image.jpeg" className='rounded-circle'></Image>
                                                </span>
                                                <div className="right_title">
                                                    <span className="text-truncate">
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        ) : (
                            <>
                                {showAllIntColors && (
                                    <div className='py-3 d-flex justify-content-center'>
                                        <p className='text-muted mb-0 fs-5'>No colors configured</p>
                                    </div>
                                )}
                            </>
                        )}

                        {!showAllIntColors && colorListDrop.map((item, index) => {
                            return (
                                <div className='position-relative d-flex align-items-center right_item_wrap' key={index}>
                                    <div className={`right_item w-100} ${internalRAL?.id == item.id && "active"}`} onClick={() => handleInternalRAL(item, index)}>
                                        <span className='me-2'>
                                            <span className='me-2 small_svg_set modal_select_hide image_icon p-2 color_circle dot_lg' style={{ backgroundColor: `${item.rgbValue}`, borderRadius: "50%", width: 'fit-content' }}></span>
                                        </span>
                                        <div className="right_title">
                                            <span className="text-truncate">
                                                {item.name + " " + item.number}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </TabPane>

            </TabContent>
            <div className='d-flex gap-2 gap-xl-3 main_buttons mt-4'>
                <Button
                    color="primary"
                    size="md"
                    className='px-4'
                    onClick={() => save()}
                >
                    Save
                </Button>

                <Button
                    color="primary"
                    size="md"
                    outline
                    className='px-4'
                    onClick={() => cancel()}
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default FrameColor