import React, { useState } from 'react'
import { BiPlus, BiTrashAlt } from 'react-icons/bi';
import { MdArrowDownward, MdPersonOutline, MdArrowUpward, MdContentCopy, MdFolderOpen } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useEffect } from 'react';
import { useProductPaletteContext } from '../../../../../../context/productPaletteContext';
import { useMessage } from '../../../../../../context/messageContext';
import { addUpdateGlazingCollection, addUpdateGlazingPalette, deleteGlazingCollection, deleteGlazingPalette, updateCollectionOrder, updatePaletteOrder } from '../../../../../../services/glazingServices';
import { Image } from 'react-bootstrap';
import { copyItem } from '../../../../../../services/commonServices';
import { useRightContext } from '../../../../../../context/rightMenuContext';
import { GoCircleSlash } from 'react-icons/go';
import { handleError } from '../../../../../../utility/commonHelper';




const TexturesRight = ({ setLoading, getAllPaletteServices, getAllCollectionServices, tabType, allCollection, allPalette }) => {

    const { newItemList, fetchSelected, activeIndex, handleActiveIndexDown, handleActiveIndexUp, setActiveIndex, timestamp } = useProductPaletteContext();
    const { setMessage, setMessageType } = useMessage()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [isDeleted, setIsDeleted] = useState(true)

    const accessToken = localStorage.getItem('access_token')

    const { selectedRightItem, setSelectedRightItem } = useRightContext()

    const navigate = useNavigate()


    useEffect(() => {
        setActiveIndex(0)
    }, [])

    useEffect(() => {
        let selectedRight;
        if (tabType === "palette") {
            selectedRight = allPalette?.find((p) => p.id === timestamp);
        } else if (tabType === "collections") {
            selectedRight = allCollection?.find((p) => p.id === timestamp);
        }
        setSelectedRightItem(selectedRight);
    }, [tabType, timestamp, allPalette, allCollection]);

    const getSingleColor = newItemList?.find((item) => item.id === timestamp)

    const handleAddServices = () => {
        if (tabType === 'palette') {
            addGlazingPaletteServices(`Palette ${newItemList?.length + 1}`)
        } else if (tabType === 'collections') {
            addGlazingCollectionServices(`Collection ${newItemList?.length + 1}`)
        }
    }

    const addGlazingCollectionServices = async (name) => {
        setLoading(true)
        const data = {
            id: "",
            name: name,
            typeId: 3,
            sequenceNo: "",
        }
        const res = await addUpdateGlazingCollection(data, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res.data.message : 'Added new palette')
                getAllCollectionServices('added')
                setLoading(false)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
                setMessageType('error')
                getAllCollectionServices()
                setLoading(false)

            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getAllCollectionServices()
            handleError(res, false, navigate)
        }
    }

    const addGlazingPaletteServices = async (name) => {
        setLoading(true)
        const data = {
            id: "",
            name: name,
            desc: "",
            specification: "",
            colourId: 1,
            thickness: 0,
            noOfPanes: 0,
            textureSide: 0,
            maximumWidth: 0,
            maximumHeight: 0,
            maximumsquare: 0,
            imageId: 0,
            typeId: 3,
            hardwareCollectionId: 0,
            sequenceNo: 0
        }

        const res = await addUpdateGlazingPalette(data, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res.data.message : 'Added new palette')
                getAllPaletteServices('added')
                setLoading(false)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
                setMessageType('error')
                getAllPaletteServices()
                setLoading(false)

            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getAllPaletteServices()
            handleError(res, false, navigate)
        }
    }

    const deletePaletteServices = async () => {
        setLoading(true)
        setIsDeleted(false)
        const res = await deleteGlazingPalette(timestamp, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res.data.message : 'Deleted Palette Successfully')
                getAllPaletteServices()
                setLoading(false)
                fetchSelected(0, newItemList[0]?.id)
                setShowDeleteModal(false)
                setIsDeleted(true)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
                setMessageType('error')
                getAllPaletteServices()
                setLoading(false)
                setIsDeleted(true)

            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getAllPaletteServices()
            handleError(res, false, navigate)
        }
    }

    const deleteCollectionServices = async () => {
        setLoading(true)
        setIsDeleted(false)
        const res = await deleteGlazingCollection(timestamp, accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setMessageType('success')
                setMessage(res?.data?.message ? res.data.message : 'Deleted Collection Successfully')
                getAllCollectionServices()
                setLoading(false)
                fetchSelected(0, newItemList[0]?.id)
                setShowDeleteModal(false)
                setIsDeleted(true)
            } else {
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong')
                setMessageType('error')
                getAllCollectionServices()
                setLoading(false)
                setIsDeleted(true)

            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            getAllCollectionServices()
            handleError(res, false, navigate)
        }

    }

    const handleDeleteServices = () => {
        if (tabType === 'palette') {
            deletePaletteServices()
        } else if (tabType === 'collections') {
            deleteCollectionServices()
        }
    }


    const updateOrderServices = async (type, direction) => {
        const handleUpdateOrder = async (type, direction, serviceFunction, getAllServices) => {
            setLoading(true);

            const res = await serviceFunction(accessToken, timestamp, type, direction);

            if (res && res && res.status === 200) {
                if (res?.data?.statusCode === 200) {
                    getAllServices();
                } else {
                    setLoading(false);
                    setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                    setMessageType('error');
                }
            } else {
                setLoading(false);
                setMessage(res?.message ? res.message : 'Something went wrong');
                setMessageType('error');
                handleError(res, false, navigate)
            }
        };

        if (tabType === 'palette') {
            handleUpdateOrder(type, direction, updatePaletteOrder, getAllPaletteServices);
        } else {
            handleUpdateOrder(type, direction, updateCollectionOrder, getAllCollectionServices);
        }
    }


    const handleItemUp = () => {
        handleActiveIndexUp()
        updateOrderServices(3, "UP")
    }

    const handleItemDown = () => {
        handleActiveIndexDown()
        updateOrderServices(3, "DOWN")
    }


    const copyPaletteServices = (id) => {
        const copyItemWithServiceCode = async (id, serviceCode, getAllServices) => {
            setLoading(true);
            const res = await copyItem(accessToken, id, serviceCode);

            if (res && res.status === 200) {
                if (res?.data?.statusCode === 200) {
                    setMessageType('success');
                    setMessage(res?.data?.message ? res.data.message : 'Item copied');
                } else {
                    setMessageType('error');
                    setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                }
            } else {
                setLoading(false);
                setMessageType('error');
                setMessage(res?.message ? res.message : 'Something went wrong');
                handleError(res, false, navigate)
            }

            getAllServices();
            setLoading(false);
        };

        if (tabType === 'palette') {
            copyItemWithServiceCode(id, 16, getAllPaletteServices);
        } else {
            copyItemWithServiceCode(id, 18, getAllCollectionServices);
        }
    };

    return (
        <>
            <div className="h-100 d-flex flex-column flex-grow-1 justify-content-between">
                <div className="inside_scroll has_btns">
                    <div className="right_list">
                        {newItemList?.map((item, index) => (
                            <div
                                key={index}
                                className="position-relative d-flex align-items-center right_item_wrap has_delete has_copy"
                            >
                                <Link
                                    to="#"
                                    className={`right_item w-100 ${activeIndex === index && "active"
                                        }`}
                                    onClick={() => {
                                        fetchSelected(index, item?.id);
                                    }}
                                >
                                    <span className="me-2">
                                        {tabType === "palette" && (
                                            <span className="me-2 image_icon ball_shape">
                                                {item?.modelLinked ? (
                                                    <Image src={item?.modelLinked} alt="icon" className='rounded-circle' />
                                                ) :
                                                    <GoCircleSlash className='rounded-circle' size={40} color="#989898" />
                                                }
                                            </span>
                                        )}
                                        {tabType === "collections" && <MdFolderOpen size={22} />}
                                    </span>
                                    <span className="text-truncate">{item.name}</span>
                                </Link>
                                <span className="position-absolute action_btns">
                                    {item?.isSelf && <>
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                fetchSelected(index, item?.id);
                                                setShowDeleteModal(true);
                                            }}
                                        >
                                            {" "}
                                            <BiTrashAlt className="text-muted" size={20} />{" "}
                                        </Link>
                                        <Link to="#" onClick={() => copyPaletteServices(item?.id)}>
                                            {" "}
                                            <MdContentCopy className="text-muted" size={18} />{" "}
                                        </Link>
                                    </>}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                <Modal
                    isOpen={showDeleteModal}
                    toggle={() => setShowDeleteModal(false)}
                >
                    <ModalHeader>Delete Ancillary</ModalHeader>
                    <ModalBody>
                        Are you sure you want delete{" "}
                        <strong>{getSingleColor?.name}</strong> ?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="outline-danger"
                            size="md"
                            onClick={() => handleDeleteServices()}
                            disabled={!isDeleted}
                        >
                            Delete
                        </Button>
                        <Button
                            onClick={() => setShowDeleteModal(false)}
                            color="primary"
                            size="md"
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <div className="d-flex gap-2 gap-xl-3 main_buttons">
                    <Button
                        color="primary"
                        size="md"
                        className="px-4"
                        onClick={() => handleAddServices()}
                    >
                        <BiPlus size={20} className="me-1" />
                        New
                    </Button>

                    {selectedRightItem?.isSelf && <>
                        <Button
                            color=""
                            size="md"
                            className="border-0 text-primary px-0"
                            onClick={() => handleItemUp()}
                            disabled={newItemList.length === 0 || activeIndex === 0}
                        >
                            <MdArrowUpward size={22} className="me-1" />
                            Up
                        </Button>
                        <Button
                            color=""
                            size="md"
                            className="border-0 text-primary px-0"
                            onClick={() => handleItemDown()}
                            disabled={
                                newItemList.length === 0 ||
                                activeIndex === newItemList.length - 1 ||
                                newItemList.length === 1 || (selectedRightItem?.isLast)
                            }
                        >
                            <MdArrowDownward size={22} className="me-1" />
                            Down
                        </Button>
                    </>}
                </div>
            </div>
        </>
    );
}

export default TexturesRight