// createWindowFrame.js
import * as THREE from 'three';
import { createFramePath } from './lineShapes.js'; // Import the createFramePath function
import { createCornerPath } from './cornerShapes.js'; // Import the createCornerPath function
import { createCombinedOutlinePath } from './combinedOutline.js';
import { createSash, hangingRefs, removeHangingRefs, removeSashRefs } from './sashes.js';
import { getAngleFromLine, updateFrameJoint } from './profileJoints.js';
import { createTransomGlassPath, emptyTransomPartitions, emptyTransomRefs, storeTransomPartitionRefs } from './transoms.js';
import { calculateOffsets, createAddons } from './addOns.js'
import { addHardwareInSteps, loadHardware, positionHardwareOnFrame } from './hardwares.js';



const shapeCache = new Map();
let zOffsetMap = {}
let frameProfileRefs = []
let jointRefs = []
let addOnRefs = []
const allTrickleVents = [];

const storeFrameProfileRef = (obj, callBack) => {
    frameProfileRefs.push(obj)
    if (typeof callBack === 'function') {
        callBack(hangingRefs)
    }
}

const storeJointsRef = (obj) => {
    jointRefs.push(obj)
}

export const getJointsRef = () => {
    return jointRefs;
}

const storeAddOnRef = (obj) => {
    addOnRefs.push(obj)
}

export const getAddOnRef = () => {
    return removeDuplicatesByName(addOnRefs);
}

export const getFrameRefs = () => {
    return frameProfileRefs;
}

function removeDuplicatesByName(array) {
    const uniqueItems = new Map();

    array.forEach(item => {
        if (item.name && !uniqueItems.has(item.name)) {
            uniqueItems.set(item.name, item);
        }
    });

    return Array.from(uniqueItems.values());
}

export const updateFrameDimension = (width, height, jsonIndex = 0, newSashSize) => {
    return (prevModelJson) => {
        const updatedModelArray = [...prevModelJson]
        const updateJson = { ...updatedModelArray[jsonIndex] };
        updateJson.dimensions.width = width;
        updateJson.dimensions.height = height;
        updateJson.sash.sashSize = newSashSize;
        updatedModelArray[jsonIndex] = updateJson;
        return updatedModelArray;
    }
}

export const loadDefaultFrame = async (
    defaultFrameProfile,
    profileJoint,
    statcJson,
    storeFrameProfile,
    inititalSpec,
    storeDefaultSpecData
) => {
    const height = defaultFrameProfile?.height / 1000 || 0.1;
    const width = defaultFrameProfile?.width / 1000 || 0.1;
    const frameJoint = profileJoint?.frameName;
    const hexValue = inititalSpec?.hexValue;

    // Clone the array to avoid mutating the original reference
    const updatedModelJsonArray = statcJson.map((frame) => {
        const updatedFrame = { ...frame };

        // Update glass color and joint
        updatedFrame.glass.color = hexValue;
        updatedFrame.joint = frameJoint;

        // Update dimensions for all sides
        Object.keys(updatedFrame.frame.sides).forEach((side, index) => {
            updatedFrame.frame.sides[side].dimensions.width = width;
            updatedFrame.frame.sides[side].dimensions.height = height;

            // Call storeData for each side
            setTimeout(() => {
                storeFrameProfile(side, defaultFrameProfile, index);
                if(hexValue){                    
                    storeDefaultSpecData(inititalSpec,-1,true)
                }
            }, 200);
        });

        return updatedFrame;
    });

    return updatedModelJsonArray;
};

// };
export const updateFrameProfile = async (frameProfile, orientation, storeData, jsonIndex = 0) => {
    let height = frameProfile?.height / 1000 || 0.1;
    let width = frameProfile?.width / 1000 || 0.1;
    const finalOrientation = orientation == 'FrameTop' ? "top" : orientation == 'FrameRight' ? "right" : orientation == 'FrameBottom' ? "bottom" : "left";
    return prevModelJson => {
        const updatedModelArray = [...prevModelJson]
        const updatedModelJson = { ...updatedModelArray[jsonIndex] };
        Object.keys(updatedModelJson.frame.sides).forEach((side, index) => {
            if (finalOrientation === side) {
                updatedModelJson.frame.sides[side].dimensions.width = width;
                updatedModelJson.frame.sides[side].dimensions.height = height;
                storeData(side, frameProfile, index)
            }
        });

        updatedModelArray[jsonIndex] = updatedModelJson;
        return updatedModelArray;
    };
};

const profileHeightWidth = (height, width) => {
    const frameShape = new THREE.Shape();
    const radius = 0.004;
    frameShape.moveTo(-width / 2 + radius, 0);

    // Top edge (left to right)
    frameShape.lineTo(width / 2 - radius, 0);
    frameShape.absarc(width / 2 - radius, -radius, radius, Math.PI / 2, 0, true);

    // Right edge (top to bottom)
    frameShape.lineTo(width / 2, -height + radius);
    frameShape.absarc(width / 2 - radius, -height + radius, radius, 0, -Math.PI / 2, true);

    // Bottom edge (right to left)
    frameShape.lineTo(-width / 2 + radius, -height);
    frameShape.absarc(-width / 2 + radius, -height + radius, radius, -Math.PI / 2, -Math.PI, true);

    frameShape.lineTo(-width / 2, -radius);
    frameShape.absarc(-width / 2 + radius, -radius, radius, Math.PI, Math.PI / 2, true);

    return frameShape;

}


const getShapeCache = (height, width) => {
    const key = `${height} - ${width}`
    if (!shapeCache.has(key)) {
        const shape = profileHeightWidth(height, width)
        shapeCache.set(key, shape)
    }
    return shapeCache.get(key)
}

export async function createWindowFrame(scene, windowDataFull, groupName, sashGroup, profileOrientation, profileType, frameProfileDefault, frameStyleBottom, sillsHeightRef, addOnFrames, addonRef, allTrickleVents) {

    let sillHeight = 0;
    if (sillsHeightRef && sillsHeightRef.current) {
        sillsHeightRef.current = [0.04];
        sillHeight = sillsHeightRef.current.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    if (windowDataFull && windowDataFull?.length) {

        try {

            const addOn = windowDataFull[0]?.addOn
            //     if(!addOnFrames){
            //     addOnFrames = {
            //         left: [[0.07, 0.14] , [0.07, 0.14]],
            //         right: [],
            //         bottom: [[0.06, 0.05]],
            //         top:[[0.07, 0.14]]
            //     }
            // }
            addOnFrames = addOn


            const { wWidthOffset, wHeightOffset } = calculateOffsets(addOnFrames, sillHeight);


            const windowData = windowDataFull?.[0] || {};

            frameProfileRefs = [];
            removeHangingRefs()
            emptyTransomPartitions()
            removeSashRefs()
            jointRefs = [];
            await removeGroupByName(scene, groupName);
            const group = new THREE.Group();
            group.name = groupName;
            const { frame, sash, color, joint, transom, dimensions, glass } = windowData // Extract frame data
            const materials = {};


            const { width: frameWidth, height: frameHeight } = dimensions || {};
            const glassThickness = 0.02; // Adjust as needed for glass thickness

            // Create a material for the frame
            const frameMaterial = new THREE.MeshPhongMaterial({
                // color: 0xffffff,
                color: color,
                specular: 0xffffff,
                shininess: 10,
                // shininess: 27.5,
                flatShading: false,
                emissive: 0x000000,
                specular: 0x555555,
                bumpScale: 1,
                // wireframe : true

            });

            materials.frameMaterial = frameMaterial;
            // Extrusion settings
            const extrudeSettings = (path, steps) => ({
                // steps: 100,
                steps: steps,
                extrudePath: path, // Path to extrude along
                bevelEnabled: false,  // No bevel
                // bevelThickness: 1,
                // bevelSize: 1,
                // bevelOffset: 0,
                // bevelSegments: 100,
                // curveSegments: 120,
                morphTargetsRelative: true
            });

            const pathsAndCorners = [
                { pathType: '', cornerType: 'topRight' },
                { pathType: '', cornerType: 'topLeft' },
                { pathType: 'top', cornerType: 'bottomRight' },
                { pathType: 'right', cornerType: 'bottomLeft' },
                { pathType: 'bottom', cornerType: '' },
                { pathType: 'left', cornerType: '' }

            ];
            const jointType = joint?.toLowerCase();
            let sashHangingValue = sash?.hangings

            const frames = {};
            await pathsAndCorners.forEach(({ pathType, cornerType }) => {

                const sideData = windowData?.frame?.sides[pathType || "top"]
                let { width, height } = sideData?.dimensions;

                const newPathName = pathType == 'top' ? "FrameTop" : pathType == 'right' ? "FrameRight" : pathType == 'bottom' ? "FrameBottom" : "FrameLeft";

                let zOffset;
                if (profileOrientation && profileType) {

                    const frameProfileHeight = profileType?.height / 1000
                    const frameProfileWidth = profileType?.width / 1000

                    const defaultWidth = frameProfileDefault?.width / 1000

                    if (newPathName == profileOrientation) {
                        zOffset = (defaultWidth - frameProfileWidth) / 2;
                        width = frameProfileWidth;
                        height = frameProfileHeight;
                    } else {
                        zOffset = zOffsetMap[pathType] ?? 0
                    }
                } else {
                    zOffset = 0
                }


                zOffsetMap[pathType] = zOffset;

                const frameShape = getShapeCache(height, width)

                let framePath = pathType != "" ? createFramePath(windowData, pathType, "Corner", wHeightOffset, wWidthOffset) : null;

                let cornerPath = cornerType != "" ? createCornerPath(windowData, cornerType, frame.corners[cornerType].type, wHeightOffset, wWidthOffset) : null;

                if (framePath) {

                    const frameGeometry1 = new THREE.ExtrudeGeometry(frameShape, extrudeSettings(framePath, 2));
                    // frames[pathType+"Path"] = framePath;
                    frames[pathType] = [framePath];
                    frames[pathType].push(height);
                    // updateCornerBevel(pathType, frameGeometry1, windowData.dimensions.height, windowData.dimensions.width);
                    let startOffset = true, endOffset = true;
                    let startAngle = 0, endAngle = 0;
                    if (pathType == "top") {
                        if (frame.corners["topLeft"].type == "Radius" || frame.corners["topLeft"].type == "Ellipse") {
                            endOffset = false;
                        }
                        if (frame.corners["topRight"].type == "Radius" || frame.corners["topRight"].type == "Ellipse") {
                            startOffset = false;
                        }
                        if (frame.corners["topRight"].type == "Chamfer") {
                            startAngle = getAngleFromLine(frames.topRight[0])
                        }
                        if (frame.corners["topLeft"].type == "Chamfer") {
                            endAngle = getAngleFromLine(frames.topLeft[0])
                        }
                    }
                    if (pathType == "bottom") {
                        if (frame.corners["bottomLeft"].type == "Radius" || frame.corners["bottomLeft"].type == "Ellipse") {
                            endOffset = false;
                        }
                        if (frame.corners["bottomRight"].type == "Radius" || frame.corners["bottomRight"].type == "Ellipse") {
                            startOffset = false;
                        }
                        if (frame.corners["bottomRight"].type == "Chamfer") {
                            startAngle = getAngleFromLine(frames.bottomRight[0])
                        }
                        if (frame.corners["bottomLeft"].type == "Chamfer") {
                            endAngle = getAngleFromLine(frames.bottomLeft[0])
                        }
                    }
                    if (pathType == "left") {
                        if (frame.corners["topLeft"].type == "Radius" || frame.corners["topLeft"].type == "Ellipse") {
                            startOffset = false;
                        }
                        if (frame.corners["bottomLeft"].type == "Radius" || frame.corners["bottomLeft"].type == "Ellipse") {
                            endOffset = false;
                        }
                        if (frame.corners["bottomLeft"].type == "Chamfer") {
                            startAngle = getAngleFromLine(frames.bottomLeft[0])
                        }
                        if (frame.corners["topLeft"].type == "Chamfer") {
                            endAngle = getAngleFromLine(frames.topLeft[0])
                        }
                    }
                    if (pathType == "right") {
                        if (frame.corners["topRight"].type == "Radius" || frame.corners["topRight"].type == "Ellipse") {
                            startOffset = false;
                        }
                        if (frame.corners["bottomRight"].type == "Radius" || frame.corners["bottomRight"].type == "Ellipse") {
                            endOffset = false;
                        }
                        if (frame.corners["bottomRight"].type == "Chamfer") {
                            startAngle = getAngleFromLine(frames.bottomRight[0])
                        }
                        if (frame.corners["topRight"].type == "Chamfer") {
                            endAngle = getAngleFromLine(frames.topRight[0])
                        }
                    }
                    updateFrameJoint(jointType, pathType, frameGeometry1, height, windowData?.dimensions?.height - wHeightOffset, windowData?.dimensions?.width - wWidthOffset, startOffset, endOffset, startAngle, endAngle, sillsHeightRef)
                    // jointType, pathPos, mesh, fheight, wheight, wwidth, startOffset, endOffset, startAngle, endAngle, framePath)
                    const frameMesh = new THREE.Mesh(frameGeometry1, frameMaterial.clone());

                    frameMesh.castShadow = true;
                    frameMesh.receiveShadow = true;
                    // if (pathType == "right" || pathType == "left") frameMesh.material.shininess *= 1;

                    frameMesh.name = pathType == 'top' ? "FrameTop" : pathType == 'right' ? "FrameRight" : pathType == 'bottom' ? "FrameBottom" : "FrameLeft";
                    frameMesh.position.z -= zOffset;

                    if (pathType == "bottom") {
                        frameStyleBottom.current = frameMesh;
                    }

                    group.add(frameMesh);
                    // frames[pathType] = frameMesh;
                    frames[pathType].push(frameMesh);
                    const boundingBox = new THREE.Box3().setFromObject(frameMesh);
                    const boundHeight = boundingBox.max.y - boundingBox.min.y;


                    if (jointType != "mitre") {
                        const topObj = frames["top"][2];
                        const topObjBoundingBox = new THREE.Box3().setFromObject(topObj);
                        const topBotCorner = topObjBoundingBox.min.y;
                        if (pathType == 'left') {
                            const topLeftHeight = getWidthAndHeightFromLine(frames.topLeft[0]).height || height;
                            const topOffset = frame.corners["topLeft"].type == "Corner" || frame.corners["topLeft"].size.height == 0 ? height : 0;
                            const bottomOffset = frame.corners["bottomLeft"].type == "Corner" || frame.corners["bottomLeft"].size.height == 0 ? height : 0;
                            const heightScale = (boundHeight - topOffset - bottomOffset) / boundHeight;
                            frameMesh.scale.y = heightScale;
                            const boundingBox2 = new THREE.Box3().setFromObject(frameMesh);
                            frameMesh.position.y += topBotCorner - boundingBox2.max.y - topLeftHeight + height;
                        }
                        if (pathType == 'right') {
                            const topRightHeight = getWidthAndHeightFromLine(frames.topRight[0]).height || height;
                            const topOffset = frame.corners["topRight"].type == "Corner" || frame.corners["topRight"].size.height == 0 ? height : 0;
                            const bottomOffset = frame.corners["bottomRight"].type == "Corner" || frame.corners["bottomRight"].size.height == 0 ? height : 0;
                            const heightScale = (boundHeight - topOffset - bottomOffset) / boundHeight;
                            frameMesh.scale.y = heightScale;
                            const boundingBox2 = new THREE.Box3().setFromObject(frameMesh);
                            frameMesh.position.y += topBotCorner - boundingBox2.max.y - topRightHeight + height;
                        }
                    }

                    // calculate the position of each frameprofile

                    const center = new THREE.Vector3()
                    boundingBox.getCenter(center)

                    const width = boundingBox.max.x - boundingBox.min.x;

                    // Calculate the right and left ends
                    const rightEnd = new THREE.Vector3(center.x + width / 2, center.y, center.z);
                    const leftEnd = new THREE.Vector3(center.x - width / 2, center.y, center.z);
                    // create the copy of old mesh and then update it with calculated position

                    if (pathType === "top" || pathType === "bottom") {
                        const rightEndMesh = {
                            position: { x: rightEnd?.x, y: rightEnd?.y, z: rightEnd?.z },
                            name: `${pathType}Right`
                        }

                        const leftEndMesh = {
                            position: { x: leftEnd?.x, y: leftEnd?.y, z: leftEnd?.z },
                            name: `${pathType}Left`
                        }
                        storeJointsRef(rightEndMesh);
                        storeJointsRef(leftEndMesh);
                    }


                    const framePos = {
                        position: { x: center?.x, y: center?.y, z: center?.z },
                        name: frameMesh.name
                    }
                    storeFrameProfileRef(framePos)

                    const centerZ = (boundingBox.min.z + boundingBox.max.z) / 2;

                    // Use this center value for your frame position
                    const addOnPos = {
                        position: { x: center?.x, y: center?.y, z: centerZ }, // Use calculated centerZ
                        name: frameMesh.name
                    };
                    storeAddOnRef(addOnPos)
                }

                if (cornerType && (frame.corners[cornerType].type === "Corner" || frame.corners[cornerType].type === "corner")) {
                    cornerPath = "";
                }


                if (cornerPath) {
                    const steps = frame.corners[cornerType].type == "Chamfer" ? 2 : 100;
                    const pathAngle = frame.corners[cornerType].type == "Chamfer" ? getAngleFromLine(cornerPath) : "";
                    let shape = frameShape.clone();
                    frames[cornerType] = [cornerPath];
                    if (pathAngle > 0) {
                        let scaleFactor = 1.2;
                        if (Math.round(pathAngle) != 45) scaleFactor = 1 * (90 - pathAngle) / 90;
                        if (frame.corners[cornerType].type != "Chamfer") scaleFactor = 1;
                        // const scaleFactor = (1.35 * pathAngle) / 45;
                        shape = profileHeightWidth(height * scaleFactor, width);
                        frames[cornerType].push(height);
                    }

                    const cornerGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings(cornerPath, steps));

                    cornerGeometry.morphTargetsRelative = true;
                    cornerGeometry.lookAt = new THREE.Vector3(-1, -1, 0)
                    const cornerMesh = new THREE.Mesh(cornerGeometry, frameMaterial.clone());
                    cornerMesh.castShadow = true;
                    // cornerMesh.scale.set(1.4, 1.4, 1)
                    cornerMesh.receiveShadow = true;
                    cornerMesh.material.shininess = frameMaterial.shininess * 0.9;
                    group.add(cornerMesh);
                    frames[cornerType].push(cornerMesh);
                }
            });

            setTimeout(() => {
                Object.keys(frames).forEach(async (key) => {
                    if (frame?.sides[key]) {
                        const profile = frames[key][2];
                        const trickleVent = frame?.sides[key] ? frame?.sides[key]?.tricklevent : [];
                        if (trickleVent && trickleVent?.length) {
                            // const triclkleVentCount = trickleVent?.length;
                            const trickleModel = await Promise.all(
                                trickleVent.map(async (item) => {
                                    const hardware = await loadHardware(item.model);
                                    const hardwareClone = hardware.clone()
                                    group.add(hardwareClone); // Add to group immediately
                                    return hardwareClone;
                                })
                            );

                            allTrickleVents.current = trickleModel
                            // Call addHardwareInSteps after all hardware models are loaded
                            await addHardwareInSteps(profile, allTrickleVents, trickleVent);
                            // const modelClone = transformedModel.clone();
                        }

                        const spyHole = frame?.sides[key] ? frame?.sides[key]?.spyhole : false;

                        if (spyHole) {
                            const hardware = await loadHardware(spyHole.model);
                            const positionSpyHole = await positionHardwareOnFrame(spyHole, profile, hardware, "inside")
                            const spyHoleClone = positionSpyHole.clone();
                            group.add(spyHoleClone);
                        }

                    }
                })
            }, 0);

            function removeBaseGlass() {
                scene.traverse((child) => {
                    if ((child instanceof THREE.Mesh)) {
                        if (child.name.includes("GlassPanel")) {
                            scene.remove(child);
                        }
                    }
                })
            }

            const glassMaterial = new THREE.MeshPhysicalMaterial({
                color: glass?.color,
                roughness: 0.05,
                metalness: 0.9,
                reflectivity: 0.6,
                clearcoat: 1,
                clearcoatRoughness: 0.05,
                envMapIntensity: 1,
                opacity: 0.5,
                transparent: true,
                // wireframe: true
            });

            materials.glassMaterial = glassMaterial;
            let isMasterSlave = true, masterSlave;
            if (sashHangingValue > -1) {
                removeBaseGlass();
                // const partialWidth = [0.8, 0.2];

                const partialWidth = windowData?.sash?.sashSize;

                if (!masterSlave) {
                    // masterSlave = {
                    //     mullion: {
                    //         width: 0.07,
                    //         depth: 0.03
                    //     }
                    // }
                    // sashHangingValue = 2;
                }
                // const sashes = {
                //     "type": "sash",
                //     "material": "wood",
                //     "hangings": 3,
                //     "sashSize": [
                //         833.3333333333334,
                //         833.3333333333334,
                //         833.3333333333334
                //     ],
                //     "dimensions": {
                //         "width": 0.2,
                //         "height": 0.15,
                //         "depth": 0.05
                //     },
                //     "sections": [
                //         {
                //             "id": 0,
                //             "material": "glass",
                //             "opacity": 0.6,
                //             "reflectivity": 0.5,
                //             "color": "#B0E0E6",
                //             "type": "fixed",
                //             "model": null,
                //             "sashProfile": {
                //                 "SashTop": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 },
                //                 "SashRight": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 },
                //                 "SashBottom": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 },
                //                 "SashLeft": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 }
                //             },
                //             "splits": [
                //                 {
                //                     "id": "transom_split_0",
                //                     "type": "transom",
                //                     "orientation": "horizontal",//vertical, horizontal
                //                     "ratios": [
                //                         0.2,
                //                         0.8
                //                     ],
                //                     "material": "glass",
                //                     "sections": [
                //                         {
                //                             "id": "transom_section_1",
                //                             "material": "glass",
                //                             "opacity": 0.6,
                //                             "reflectivity": 0.5,
                //                             "color": "#B0E0E6",
                //                             "splits": [
                //                                 {
                //                                     "id": "transom_0_1_split",
                //                                     "type": "transom",
                //                                     "orientation": "vertical",//vertical, horizontal
                //                                     "ratios": [
                //                                         0.7,
                //                                         0.3
                //                                     ],
                //                                     "material": "glass",
                //                                     "sections": [
                //                                         {
                //                                             "id": "transom_section_2_nested_section_0",
                //                                             "material": "glass",
                //                                             "opacity": 0.6,
                //                                             "reflectivity": 0.5,
                //                                             "color": "#B0E0E6"
                //                                         },
                //                                         {
                //                                             "id": "transom_section_2_nested_section_1",
                //                                             "material": "glass",
                //                                             "opacity": 0.6,
                //                                             "reflectivity": 0.5,
                //                                             "color": "#B0E0E6"
                //                                         }
                //                                     ]
                //                                 }
                //                             ]
                //                         },
                //                         {
                //                             "id": "transom_section_2",
                //                             "material": "glass",
                //                             "opacity": 0.6,
                //                             "reflectivity": 0.5,
                //                             "color": "#B0E0E6",
                //                             "splits": [
                //                                 {
                //                                     "id": "transom_0_1_split",
                //                                     "type": "transom",
                //                                     "orientation": "vertical",//vertical, horizontal
                //                                     "ratios": [
                //                                         0.7,
                //                                         0.3
                //                                     ],
                //                                     "material": "glass",
                //                                     "sections": [
                //                                         {
                //                                             "id": "transom_section_2_nested_section_0",
                //                                             "material": "glass",
                //                                             "opacity": 0.6,
                //                                             "reflectivity": 0.5,
                //                                             "color": "#B0E0E6"
                //                                         },
                //                                         {
                //                                             "id": "transom_section_2_nested_section_1",
                //                                             "material": "glass",
                //                                             "opacity": 0.6,
                //                                             "reflectivity": 0.5,
                //                                             "color": "#B0E0E6"
                //                                         }
                //                                     ]
                //                                 }
                //                             ]
                //                         }
                //                     ]
                //                 }
                //             ]
                //         },
                //         {
                //             "id": 1,
                //             "material": "glass",
                //             "opacity": 0.6,
                //             "reflectivity": 0.5,
                //             "color": "#B0E0E6",
                //             "type": "fixed",
                //             "model": null,
                //             "sashProfile": {
                //                 "SashTop": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 },
                //                 "SashRight": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 },
                //                 "SashBottom": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 },
                //                 "SashLeft": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 }
                //             },
                //             "splits": [
                //                 {
                //                     "id": "transom_split_0",
                //                     "type": "transom",
                //                     "orientation": "horizontal",//vertical, horizontal
                //                     "ratios": [
                //                         0.2,
                //                         0.8
                //                     ],
                //                     "material": "glass",
                //                     "sections": [
                //                         {
                //                             "id": "transom_section_1",
                //                             "material": "glass",
                //                             "opacity": 0.6,
                //                             "reflectivity": 0.5,
                //                             "color": "#B0E0E6",
                //                             "splits": [
                //                                 {
                //                                     "id": "transom_0_1_split",
                //                                     "type": "transom",
                //                                     "orientation": "vertical",//vertical, horizontal
                //                                     "ratios": [
                //                                         0.7,
                //                                         0.3
                //                                     ],
                //                                     "material": "glass",
                //                                     "sections": [
                //                                         {
                //                                             "id": "transom_section_2_nested_section_0",
                //                                             "material": "glass",
                //                                             "opacity": 0.6,
                //                                             "reflectivity": 0.5,
                //                                             "color": "#B0E0E6"
                //                                         },
                //                                         {
                //                                             "id": "transom_section_2_nested_section_1",
                //                                             "material": "glass",
                //                                             "opacity": 0.6,
                //                                             "reflectivity": 0.5,
                //                                             "color": "#B0E0E6"
                //                                         }
                //                                     ]
                //                                 }
                //                             ]
                //                         },
                //                         {
                //                             "id": "transom_section_2",
                //                             "material": "glass",
                //                             "opacity": 0.6,
                //                             "reflectivity": 0.5,
                //                             "color": "#B0E0E6",
                //                             "splits": [
                //                                 {
                //                                     "id": "transom_0_1_split",
                //                                     "type": "transom",
                //                                     "orientation": "vertical",//vertical, horizontal
                //                                     "ratios": [
                //                                         0.7,
                //                                         0.3
                //                                     ],
                //                                     "material": "glass",
                //                                     "sections": [
                //                                         {
                //                                             "id": "transom_section_2_nested_section_0",
                //                                             "material": "glass",
                //                                             "opacity": 0.6,
                //                                             "reflectivity": 0.5,
                //                                             "color": "#B0E0E6"
                //                                         },
                //                                         {
                //                                             "id": "transom_section_2_nested_section_1",
                //                                             "material": "glass",
                //                                             "opacity": 0.6,
                //                                             "reflectivity": 0.5,
                //                                             "color": "#B0E0E6"
                //                                         }
                //                                     ]
                //                                 }
                //                             ]
                //                         }
                //                     ]
                //                 }
                //             ]
                //         },
                //         {
                //             "id": 2,
                //             "material": "glass",
                //             "opacity": 0.6,
                //             "reflectivity": 0.5,
                //             "color": "#B0E0E6",
                //             "type": "fixed",
                //             "model": null,
                //             "sashProfile": {
                //                 "SashTop": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 },
                //                 "SashRight": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 },
                //                 "SashBottom": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 },
                //                 "SashLeft": {
                //                     "width": 0.04,
                //                     "height": 0.065
                //                 }
                //             }
                //         }
                //     ]
                // }

                // createSash(sashes, group, frame.sides, windowData.dimensions.width - wWidthOffset, windowData.dimensions.height - wHeightOffset, sashHangingValue, sashGroup, partialWidth, allTrickleVents, masterSlave, windowData);
                createSash(sash, group, frame?.sides, windowData?.dimensions?.width - wWidthOffset, windowData?.dimensions?.height - wHeightOffset, sashHangingValue, sashGroup, partialWidth, allTrickleVents, masterSlave, windowData);

            }
            // let isMasterSlave = true, masterSlave;
            // if(isMasterSlave){
            //     removeBaseGlass();
            //     const partialWidth = windowData.sash.sashSize;
            //     if(!masterSlave){
            //         masterSlave = {
            //             mullion: {
            //                 width: 0.7,
            //                 depth: 0.3
            //             }
            //         }
            //     }
            //     console.clear();

            //     createSash(sash, group, frame.sides, windowData.dimensions.width - wWidthOffset, windowData.dimensions.height - wHeightOffset, 2, sashGroup, partialWidth, allTrickleVents, masterSlave);
            // }


            if (parseInt(sashHangingValue) <= 0 && transom && !transom.splits) {
                const glassExtrudeSettings = {
                    depth: glassThickness,
                    bevelEnabled: false,
                };

                const combinedOutlinePath = createCombinedOutlinePath(windowData, wHeightOffset, wWidthOffset);
                const glassGeometry = new THREE.ExtrudeGeometry(combinedOutlinePath, glassExtrudeSettings);
                const glassMesh = new THREE.Mesh(glassGeometry, glassMaterial);
                glassMesh.name = "GlassPanel";
                glassMesh.scale.set(0.999, 0.999, 1);
                glassMesh.position.z = -glassThickness * 0.5;
                group.add(glassMesh);
                hangingRefs.push(glassMesh)
                storeTransomPartitionRefs(glassMesh)
            }

            if (transom?.splits && transom?.splits[0]?.sections?.length > 1) {

                removeBaseGlass();
                const transomGroup = createTransomGlassPath(
                    frameWidth - wWidthOffset,
                    frameHeight - wHeightOffset,
                    0.04,
                    transom,
                    materials
                );
                group.add(transomGroup);
            } else {
                emptyTransomRefs()
            }


            if (addOnFrames && (addOnFrames?.left?.length || addOnFrames?.bottom?.length || addOnFrames?.top?.length || addOnFrames?.right?.length)) {
                setTimeout(() => {
                    createAddons(addonRef, scene, addOnFrames, frames, frameStyleBottom);
                }, 0);
            }
            scene.add(group);
        } catch (error) {
            console.error(`Error getting data ${error}`);

        }
    }


}

function removeGroupByName(scene, groupName) {
    const groupToRemove = scene?.getObjectByName(groupName);

    if (groupToRemove) {
        scene.remove(groupToRemove);
        groupToRemove.traverse((child) => {
            if (child.geometry) child.geometry.dispose();
            if (child.material) {
                if (Array.isArray(child.material)) {
                    child.material.forEach((material) => material.dispose());
                } else {
                    child.material.dispose();
                }
            }
        });
    } else {
        console.log(`Group with name ${groupName} not found.`);
    }
}


// Example usage:
function getWidthAndHeightFromLine(lineCurve) {
    // Retrieve start and end points of the line
    const v1 = lineCurve.v1 || lineCurve.points[0];
    const v2 = lineCurve.v2 || lineCurve.points[lineCurve.points.length - 1];

    // Calculate the differences in coordinates
    const deltaX = Math.abs(v2.x - v1.x);
    const deltaY = Math.abs(v2.y - v1.y);

    // Return width and height
    return {
        width: deltaX,
        height: deltaY,
    };
}