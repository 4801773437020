import axios from "axios";
import { servicePath } from "../utility/data";

// get all right menu item list based on page/type
export const getSashProductsCollection = (accessToken) => {
  let config = {
    method: "GET",
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  };

  return axios
    .get(`${servicePath}/Product/GetAllSashProductCollection`, config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

// get details of selected item in right menu
export const getItemDetails = (accessToken, id) => {
  const formData = new FormData();
  formData.append("Id", id);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  };

  return axios
    .post(`${servicePath}/Product/GetSashProductCollection`, formData, config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const formData = new FormData();
  formData.append("Id", id);
  return axios
    .post(`${servicePath}/Product/DeleteSashProductCollection`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

// update order of selected item
export const updateOrder = (accessToken, id, direction) => {
  var requestBody = {
    id: id,
    sequenceNo: 0,
    type: direction,
  };

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  return axios
    .post(`${servicePath}/Product/UpdateSashCollectionOrder`, requestBody, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

// update details of selected item
export const updateItemDetails = (accessToken, id, data) => {
  const headers = {
    accept: "text/plain",
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();

  formData.append("Id", id);
  formData.append("Name", data.name);
  formData.append("TypeId",data?.typeId);
  formData.append("SequenceNo", 0);
  // formData.append('ProfilePath', data.filePath || "");
  // formData.append('ProfilePathUrl', '');

  // formData.append('SystemCode', data.systemCode);
  // formData.append('ProductId', data.productId || '');
  // formData.append('HideFromProjectList', data.hide);

  return axios
    .post(`${servicePath}/Product/AddUpdateSashProductCollection`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data) => {
  const formData = new FormData();

  formData.append("Id", "");
  formData.append("Name", data);
  formData.append("SequenceNo", 0);

  // formData.append('SystemCode', 0);
  // formData.append('ProductId', '');
  // formData.append('HideFromProjectList', false);

  const headers = {
    accept: "text/plain",
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "multipart/form-data",
  };

  return axios
    .post(`${servicePath}/Product/AddUpdateSashProductCollection`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
