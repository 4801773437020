import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import ComingSoon from '../../../errors/coming-soon'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PanelStylesLeft from './left'
import PanelStylesRight from './right'
import { useMessage } from '../../../../../../context/messageContext'
import { useRightContext } from '../../../../../../context/rightMenuContext'
import { addNewRightItem, getRightMenuList, getItemDetails, getPanelCollectionList, updateItemDetails } from '../../../../../../services/panelStylesService'
import { Oval } from 'react-loader-spinner'
import { getAllPanelProductMaster } from '../../../../../../services/panelProductsService'
import { getAllPanelSidebarList, getPanelMenuListForPricing } from '../../../../../../services/SidebarServices'
import { handleError } from '../../../../../../utility/commonHelper'

const PanelStyles = () => {

    const accessToken = localStorage.getItem('access_token')
    const location = useLocation();

    const [wip, setWIP] = useState(false)
    const [loading, setLoading] = useState(false)

    const [panelMasterData, setPanelMasterData] = useState('')
    const [pageName, setPageName] = useState("Panel Styles")

    const [selectItemDetails, setSelectItemDetails] = useState([])
    const [allRightListItems, setAllRightListItems] = useState([])
    const [allRightListItemsOrder, setAllRightListItemsOrder] = useState(true)

    const { setMessage, setMessageType } = useMessage()

    const backStyleId = location.state ? location.state.styleId : null

    const { timestamp, setSelectedRightItem, fetchSelected, setTimestamp, activeIndex, setActiveIndex, setMainMenuActive, setSubMenuActive, setSubMenu2Active,  setPanelMenuList, setPricingPanelMenuList } = useRightContext()

    const navigate = useNavigate();

    var getFirstElId = allRightListItems[0]?.id || ''
    var itemAddedTrue = ''

    let { panelMasterId, collectionId } = useParams();

    useEffect(() => {
        setMainMenuActive("Products")
        setSubMenuActive("Panels")
    }, []);

    useEffect(() => {
        if(panelMasterId) {
            getPanelProductsMaster(panelMasterId)
        }
    }, [panelMasterId]);

    useEffect(() => {
        setLoading(true)
        setTimestamp('')
        setActiveIndex(0)

        if (collectionId) {
            getRightMenuListData(collectionId)

            getPanelCollectionListService(panelMasterId, collectionId)
        }
    }, [collectionId])

    useEffect(() => {
        if (allRightListItems?.length > 0 && timestamp) {
            getRightItemDetails(timestamp)
        }
    }, [timestamp])

    useEffect(() => {
        if(backStyleId) {
            if (backStyleId && allRightListItems?.length > 0) {
                setActiveIndex(allRightListItems.findIndex(e => e?.id == backStyleId))
                getRightItemDetails(backStyleId)
            }
        } else {
            if (allRightListItemsOrder) {
                getFirstElId = allRightListItems[0]?.id
    
                setActiveIndex(0)
    
                if (getFirstElId && allRightListItems?.length) {
                    getRightItemDetails(getFirstElId)
                }
            } else {
                if (getFirstElId && allRightListItems?.length) {
                    if (timestamp && allRightListItems?.length && activeIndex !== 0) {
                        getRightItemDetails(timestamp)
                    } else {
                        getRightItemDetails(getFirstElId)
                    }
                }
            }
        }
    }, [allRightListItems])

    const getPanelProductsMaster = async (fetchId) => {
        const res = await getAllPanelProductMaster(accessToken, fetchId);
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity?.length > 0) {
                    const allMasterItems = res.data.entity

                    const getPageName = allMasterItems.filter((item) => {
                        return item.id == fetchId;
                    });

                    setPanelMasterData(getPageName[0])
                }
            }
        }
        else{
            handleError(res,false,navigate)
        }
    }

    const getPanelCollectionListService = async (fetchId, getId) => {
        const res = await getPanelCollectionList(accessToken, fetchId);
        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity?.length > 0) {

                    const allMasterItems = res.data.entity

                    const getPageName = allMasterItems.filter((item) => {
                        return item.id == getId;
                    });

                    setPageName(getPageName[0].name)
                }
            }
        }
        else{
            handleError(res,false,navigate)
        }
    }

    // get all right menu item list based on page/type (type = 1 for forewords)
    const getRightMenuListData = async (fetchId) => {

        getAllPanelSidebarList(accessToken, setPanelMenuList, navigate);
        getPanelMenuListForPricing(accessToken, setPricingPanelMenuList, navigate);

        const res = await getRightMenuList(accessToken, fetchId);

        // console.log(res, 'right items res')

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    if (res?.data?.entity?.length > 0) {

                        setAllRightListItems(res?.data?.entity)

                        if (itemAddedTrue == 'added') {
                            const addedItemIndex = res.data.entity.length - 1

                            if (addedItemIndex) {
                                fetchSelected(addedItemIndex, res.data.entity[addedItemIndex]?.id)
                                itemAddedTrue = ''
                            }
                        }
                    } else {
                        setAllRightListItems([])
                        setTimestamp('')

                        fetchSelected(0, '');

                        setSelectItemDetails([])
                    }
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res,false,navigate)
        }
    }

    // get details of selected item in right menu by passing item id
    const getRightItemDetails = async (id) => {

        setLoading(true)

        const res = await getItemDetails(accessToken, id);

        setAllRightListItemsOrder(false)

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity !== null) {

                    const selectedRight = allRightListItems?.find((p) => p.id == id)
                    setSelectedRightItem(selectedRight)
                    
                    // set state to get details of current selected item which will display data in left side component
                    setSelectItemDetails(res.data.entity)

                    // set selected item id which will update context
                    setTimestamp(id)
                }

                setTimeout(() => {
                    setLoading(false)
                }, 1200);
            } else {
                setLoading(false)
                setMessage(res?.data.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }

        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res,false,navigate)
        }
    }

    // api call to update all selected item details
    const updateItemDetailsServices = async (data) => {
        setLoading(true)

        const res = await updateItemDetails(accessToken, data)

        if (res && res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Updated details');
                setMessageType('success')

                getRightMenuListData(collectionId)

                setLoading(false)

            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res,false,navigate)
        }
    }

    // api call to added new right item
    const addNewRightItemServices = async (title) => {
        setLoading(true)
        itemAddedTrue = "added"

        const res = await addNewRightItem(accessToken, title, collectionId)
        // console.log(res, 'add item res')

        if (res && res && res.status === 200) {

            if (res?.data?.statusCode === 200) {
                setMessage(res?.data?.message ? res.data.message : 'Record created successfully!');
                setMessageType('success')

                getRightMenuListData(collectionId)
            } else {
                setLoading(false)
                setMessage(res?.data?.message ? res.data.message : 'Something went wrong');
                setMessageType('error')
            }
        } else {
            setLoading(false)
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            handleError(res,false,navigate)
        }
    }

    const handleFormReset = () => {
        if (timestamp) {

            getRightMenuListData(collectionId)

            getRightItemDetails(timestamp)
        }
    }

    return (
        <>
            {wip ? (
                <ComingSoon title={`${pageName} Coming Soon`} />
            ) : (

                <>
                    {loading && (
                        <div className='loader_main full_loader'>
                            <Oval
                                height={50}
                                width={50}
                                color="#44C8F5"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#44C8F5"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </div>
                    )}

                    <InnerLayout pageTitle={pageName ? `${pageName}` : 'Panel Styles'} layoutType=''
                        leftContent={<PanelStylesLeft collectionId={collectionId} panelMasterId={panelMasterId} selectItemDetails={selectItemDetails} updateItemDetailsServices={updateItemDetailsServices} handleFormReset={handleFormReset} />}
                        rightContent={<PanelStylesRight panelMasterData={panelMasterData} collectionId={collectionId} accessToken={accessToken} selectItemDetails={selectItemDetails} allRightListItems={allRightListItems} setLoading={setLoading} getRightMenuListData={getRightMenuListData} addNewRightItemServices={addNewRightItemServices} />} />
                </>
            )}
        </>
    )
}

export default PanelStyles